import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg,
    getEditIconColumn,
    getSelectFilterCfg,
    getBadgeFormatterForKodif
} from '../grid/ColumnHelpers'
import {Button} from 'reactstrap';

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';
import Link from 'redux-first-router-link';

import {guiSet} from '../../actions/uiActions'
import {udensObjektsSelected, udensObjektsDoubleSelected} from '../../actions/pazemesUdensObjektiActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import ArchivedFilterButtonToUrl from '../grid/ArchivedFilterButtonToUrl'

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_pazemes_udens_objekts',
        payload: {
            kods: row.kods
        }
    }
    )
)

const getColumns = ({kodif}) => {
    return [editIconColumn, {
      dataField: 'kods',
      text: 'Kods',
      width: 100,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      width: 600,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'kvant_stavoklis',
      text: 'Kvantitatīvais stāvoklis',
      filterCfg: getSelectFilterCfg(kodif.pazemesUdensObjektaNovertejums),
      formatter: getBadgeFormatterForKodif(kodif.pazemesUdensObjektaNovertejums)
    }, {
      dataField: 'kim_stavoklis',
      text: 'Ķīmiskais stāvoklis',
      filterCfg: getSelectFilterCfg(kodif.pazemesUdensObjektaNovertejums),
      formatter: getBadgeFormatterForKodif(kodif.pazemesUdensObjektaNovertejums)
    }, {
      dataField: 'uo_tips',
      text: 'Tips',
      width: 220,
      filterCfg: getSelectFilterCfg(kodif.PAZEMES_UO_TIPS)
    }]
};

class PazemesUdensObjekti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSearch: false
        };
    }

    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    }

    mapPaneSizeChange = (h) => {
        this.props.guiSet('udeni_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
          this.props.udensObjektsDoubleSelected(row.kods)
        }
    }

    render() {

        const vectorLayerCfgs = {
            pazemesUdensObjekti : {
                title: 'Ūdensobjekti',
                features: this.props.pazemesUdensObjekti.list,
                styles: maputils.uoStyles.uoStyle,
                tooltipFunc: (feat) => `${feat.get('nosaukums')} (${feat.get('kods')})`,
                selected: this.props.pazemesUdensObjekti.selected,
                legend: maputils.defaultSinglePolygonLegend
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={this.props.gui.udeni_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <Karte mapId="udens_objekti_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={this.props.udensObjektsSelected}
                    loading={this.props.pazemesUdensObjekti.loading}
                    selectedId={this.props.pazemesUdensObjekti.selected}
                    dataList={this.props.pazemesUdensObjekti.list}
                    totalCount={this.props.pazemesUdensObjekti.count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >

                { this.props.user.tiesibas.includes('BAIS_PU_LABOT') ? (
                    <Link to={{ type: 'loc_pazemes_udens_objekts',
                        payload: {
                            kods: 'jauns'
                        }
                    }}>
                        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                    </Link>
                ) : null }
                <BBoxFilterButtonToUrl/>
                <ArchivedFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, pazemesUdensObjekti, gui, user}) => {
    return {kodif, pazemesUdensObjekti, gui, user}
}

export default connect(mapStateToProps, {udensObjektsSelected, guiSet, udensObjektsDoubleSelected})(PazemesUdensObjekti)
