import {callApi, getDefaultEditActions, pushUrlToBrowserHistory} from './helpers'
import {redirect} from 'redux-first-router'

export const loadAdIeraksti = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'ad_ieraksts_list',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const adIerakstsActions = getDefaultEditActions({
  actionTypeName : 'ad_ieraksts',
  stateName: 'ad_ieraksts',
  apiViewName: 'ad_ieraksts_view'
})


export const downloadIeraksts = (id) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'ad_ieraksts_download',
    crudMethod: 'get',
    crudParam: id
  }))
}


export const openAdIeraksts = (id) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_ad_ieraksts',
    payload: {
        id: id
    }
   }))
  }

export const adReimportDataActions = getDefaultEditActions({
  actionTypeName : 'ad_reimport_data',
  stateName: 'ad_reimport_data',
  apiViewName: 'ad_reimport_data',
  afterSave: (dispatch, getState, result) => {
    dispatch(adIerakstsActions.fetchAct({
      id: result.id
    }))
  },
})
