import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getNumberFormatter, getSelectFilterCfg,
  getTextFilterCfg, getYearFilterCfg,
} from '../grid/ColumnHelpers'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import { udensAiSelected } from "../../actions/udens2Actions"

import Grid from '../grid/Grid'

const getColumns = ({kodif}) => {
  return [{
    dataField: 'report_id',
    text: 'Report ID',
    width: 120,
  }, {
    dataField: 'gads',
    text: 'Gads',
    width: 120,
    filterCfg: getYearFilterCfg()
  }, {
    dataField: 'code',
    text: 'ID numurs',
    width: 120,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'name',
    text: 'Nosaukums',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'adrese',
    text: 'Adrese',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'veids',
    text: 'Veids',
    width: 320,
    filterCfg: getSelectFilterCfg(kodif.udens2AiVeids)
  }, {
    dataField: 'tips',
    text: 'Tips',
    width: 200,
    filterCfg: getSelectFilterCfg(kodif.udens2AiTips)
  }, {
    dataField: 'faktiska_pakape',
    text: 'Faktiskā attīrīšanas pakāpe',
    // width: 200,
    filterCfg: getSelectFilterCfg(kodif.udens2AiFaktiska)
  }, {
    dataField: 'daudzums',
    text: 'Attīrīto notekūdeņu apjoms, (tūkst.m3)',
    width: 140
  }, {
    dataField: 'coord_x',
    text: 'Garums',
    width: 120,
    formatter: getNumberFormatter()
  }, {
    dataField: 'coord_y',
    text: 'Platums',
    width: 120,
    formatter: getNumberFormatter()
  }]
};

class UdensAI extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalSearch: false
    };
  }

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet('udens_ai_saraksts_karte_height', h);
  }

  rowEvents = {
    // onDoubleClick: (e, row, rowIndex) => {
    //   this.props.virszemesUdensObjektsDoubleSelected(row.kods)
    // }
  }

  render() {
    const vectorLayerCfgs = {
      udens2 : {
        title: 'Attīrīšanas iekārtas',
        features: this.props.udensAi.list,
        styles: maputils.uoStyles.udens2Style,
        tooltipFunc: (feat) => feat.get('nosaukums'),
        selected: this.props.udensAi.selected
      }
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    return (
      <SplitterLayout
        vertical
        percentage
        primaryIndex={1}
        secondaryInitialSize={this.props.gui.udens_ai_saraksts_karte_height}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <Karte mapId="udens2" vectorLayerCfgs={vectorLayerCfgs} />

        <Grid
          onRowSelected={this.props.udensAiSelected}
          loading={this.props.udensAi.loading}
          selectedId={this.props.udensAi.selected}
          dataList={this.props.udensAi.list}
          totalCount={this.props.udensAi.count}
          columns={this.columns}
          rowEvents={this.rowEvents}
        >
        </Grid>

      </SplitterLayout>
    )
  }
}

const mapStateToProps = ({kodif, udensAi, gui, user}) => {
  return {kodif, udensAi, gui, user}
}

export default connect(mapStateToProps, {guiSet, udensAiSelected})(UdensAI)
