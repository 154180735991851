import React, {Component} from 'react'
import ArchivedFilterButtonToUrl from './ArchivedFilterButtonToUrl'

export default class ArchivedParskatsFilterToUrl extends Component {

    render() {
        return (
            <ArchivedFilterButtonToUrl
                titleDefault = 'Rādīt visus'
                titleBoth = 'Rādīt tikai pārskatus'
                titleArchived = 'Rādīt tikai arhivētos'
                filterBoth = 'false'
                filterArchived = 'true'
            />
        )
    }
}