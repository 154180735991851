import {
    callApi,
    getDefaultEditActions,
    addExtraFilterToQuery
} from './helpers'
import {
    createActArgsToCrudParams
} from './dokumentacijaActions'

export const loadKrajumi = (query) => (dispatch, getState) => {
    const mapSize = getState().gui['krajumu_saraksts_karte_size']
    return dispatch(callApi({
        viewName: 'krajums_list',
        crudMethod: 'listPaging',
        crudParam: addExtraFilterToQuery(query, mapSize)
    }))
}

export const krajumsActions = getDefaultEditActions({
    actionTypeName: 'krajums',
    stateName: 'krajums',
    apiViewName: 'krajums_view',
    hasKoordsField: true,
    createActArgsToCrudParams,
})

export const krajumsSelected = (id) => {
    return {
        type: 'krajums_selected',
        payload: id
    }
}
