import { callApi, getDefaultEditActions, pushUrlToBrowserHistory, addExtraFilterToQuery } from './helpers'
import { redirect } from 'redux-first-router'
import { atradnesVeids, atradnesIerakstaVeids } from '../reducers/kodifikatorsReducers'
import _ from 'lodash'

const transformAtradne = (atradne) => {
  if (atradne) {
    let saistitas = []
    if (atradne.saistitas_1) {
      saistitas = saistitas.concat(atradne.saistitas_1.map(a => { a.s = 1; return a }))
    }
    if (atradne.saistitas_2) {
      saistitas = saistitas.concat(atradne.saistitas_2.map(a => { a.s = 2; return a }))
    }
    atradne.saistitas = saistitas
    atradne.veidsObj = {}
    Object.keys(atradnesVeids).forEach(k => {
      atradne.veidsObj[k] = atradne.veids && atradne.veids.includes(k)
    })
    atradne.ierakstaVeidsObj = {}
    Object.keys(atradnesIerakstaVeids).forEach(k => {
      atradne.ierakstaVeidsObj[k] = atradne.ieraksta_veids && atradne.ieraksta_veids.includes(k)
    })
  }
  return atradne
}

const dalaTableResultTransformator = (values) => {
  const atradneKva = { ...values }
  //visu lauku keys, kurus apstrādāsim
  const multifields = []
  Object.keys(atradneKva).forEach(key => {
    if (key !== 'dalas' && key !== 'faili' && Array.isArray(atradneKva[key])) {
      multifields.push(key)
    }
  })
  multifields.forEach(field => {
    //pašas atradnes lauki
    atradneKva[field].forEach(value => {
      if (!value.dala_id) {
        value.dala_id = atradneKva.id
      }
      value.init_dala_id = atradneKva.id
      value.dalas_nosaukums_label = value.dalas_nosaukums
    })
    if (atradneKva.dalas) {
      //atradnes daļas lauki - pārnesam visu uz atradnes laukiem, piešķirot daļas id, lai pēc tam atpakaļ
      atradneKva.dalas.forEach((dala, dalaindex) => {
        if (dala[field]) {
          dala[field].forEach(value => {
            let clone = _.cloneDeep(value)
            clone.dala_id = dala.id
            clone.init_dala_id = dala.id
            clone.dalas_nosaukums_label = value.dalas_nosaukums ? `${dala.nosaukums} : ${value.dalas_nosaukums}` : dala.nosaukums
            if (!atradneKva[field]) {
              atradneKva[field] = []
            }
            atradneKva[field].push(clone)
          })
          atradneKva.dalas[dalaindex][field] = []
        }
      })
    }
  })
  return atradneKva
}

const setNullIdsForChildrenArrays = (obj) => Object.values(obj).filter(ch => Array.isArray(ch)).forEach(ch => ch.forEach(c => { if (c.id) c.id = null }))

const dataTableValuesTransformator = (values) => {
  //te pārnesam no atradnes laukiem pa daļas laukiem pēc daļas id
  const atradneKva = { ...values }
  if (atradneKva.dalas) {
    const multifields = []
    Object.keys(atradneKva).forEach((key) => {
      if (key !== 'dalas' && key !== 'faili' && Array.isArray(atradneKva[key])) {
        multifields.push(key)
      }
    })
    multifields.forEach((field) => {
      const atradneData = []
      // notīrām daļas fieldu, citādi kattreiz pēc neveiksmīgas saglabāšanas viens ieraksts te pienāks klāt
      atradneKva.dalas.forEach(dala => dala[field] = [])
      atradneKva[field].forEach((value) => {
        if (value.dala_id && value.dala_id !== atradneKva.id) {
          atradneKva.dalas.forEach((dala) => {
            if (dala.id === value.dala_id) {
              let clone = _.cloneDeep(value)
              if (clone.dala_id !== clone.init_dala_id) {
                clone.id = null
                //also set children ids to null
                setNullIdsForChildrenArrays(clone)
              }
              dala[field].push(clone)
            }
          })
        } else {
          let clone = _.cloneDeep(value)
          if (clone.dala_id !== clone.init_dala_id) {
            clone.id = null
            //also set children ids to null
            setNullIdsForChildrenArrays(clone)
          }
          atradneData.push(clone)
        }
      })
      atradneKva[field] = atradneData
    })
  }
  return atradneKva
}

export const atradnes = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['atradnes_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'atradne_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const atradneSelected = (id) => {
  return { type: 'atradne_selected', payload: id }
}

export const atradneDoubleSelected = (numurs) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_atradne',
    payload: {
      numurs: numurs
    }
  }))
}

const transformDatumsField = (values, fieldName) => {
  if (values[fieldName] === '') {
    values[fieldName] = null
  }
  return values
}

export const atradneActions = getDefaultEditActions({
  actionTypeName: 'atradne',
  stateName: 'atradne',
  apiViewName: 'atradne_view',
  resultTransformator: transformAtradne,
  valuesTransformator: (values) => {
    const atradne = { ...values }
    transformDatumsField(atradne, 'ierobezota_pieejamiba_datums_no')
    transformDatumsField(atradne, 'ierobezota_pieejamiba_datums_lidz')
    atradne.saistitas_1 = atradne.saistitas.filter(a => !a.s || a.s === 1)
    atradne.saistitas_2 = atradne.saistitas.filter(a => a.s === 2)
    atradne.veids = Object.keys(atradne.veidsObj).filter(k => atradne.veidsObj[k] === true).join("")
    atradne.ieraksta_veids = Object.keys(atradne.ierakstaVeidsObj).filter(k => atradne.ierakstaVeidsObj[k] === true).join("")
    return atradne
  },
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({ type: 'atradne_clear' })
    dispatch(redirect({
      type: 'loc_atradne',
      payload: {
        numurs: result.numurs
      }
    }))
  },
  fetchArgsToCrudParams: ({ numurs }) => ['numurs', numurs]
})

export const atradnesDalaActions = getDefaultEditActions({
  actionTypeName: 'atradnes_dala',
  stateName: 'atradnesDala',
  valuesTransformator: (values) => {
    const atradne = { ...values }
    transformDatumsField(atradne, 'ierobezota_pieejamiba_datums_no')
    transformDatumsField(atradne, 'ierobezota_pieejamiba_datums_lidz')
    return atradne
  },
  apiViewName: 'atradnes_dala_view',
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({ type: 'atradnes_dala_clear' })
    dispatch(redirect({
      type: 'loc_atradnes_dala',
      payload: {
        numurs: result.atradne,
        dala: result.id,
      }
    }))
  },
  createActArgsToCrudParams: ({ numurs }) => ({ numurs: numurs })
})

export const atrasanasActions = getDefaultEditActions({
  actionTypeName: 'atradne_atrasanas',
  stateName: 'atradneAtrasanas',
  combinedPage: true,
  apiViewName: 'atradne_atrasanas_view',
  hasKoordsField: true,
  //reload atradnes geometry
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch(atradneActions.fetchAct(result))
  },
  fetchArgsToCrudParams: ({ numurs }) => ['numurs', numurs]
})

export const dalaAtrasanasActions = getDefaultEditActions({
  actionTypeName: 'atradnes_dala_atrasanas',
  stateName: 'atradnesDalaAtrasanas',
  combinedPage: true,
  apiViewName: 'atradnes_dala_atrasanas_view',
  hasKoordsField: true,
  //reload atradnes geometry
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch(atradnesDalaActions.fetchAct({ numurs: result.atradnes_numurs, id: result.id }))
  }
})

export const geologijaActions = getDefaultEditActions({
  actionTypeName: 'atradne_geologija',
  resultTransformator: (values) => dalaTableResultTransformator(values),
  valuesTransformator: (values) => dataTableValuesTransformator(values),
  stateName: 'atradneGeologija',
  combinedPage: true,
  apiViewName: 'atradne_geologija_view',
  fetchArgsToCrudParams: ({ numurs }) => ['numurs', numurs]
})

export const dalaGeologijaActions = getDefaultEditActions({
  actionTypeName: 'atradnes_dala_geologija',
  stateName: 'atradnesDalaGeologija',
  combinedPage: true,
  apiViewName: 'atradnes_dala_geologija_view',
})

export const kvalitateActions = getDefaultEditActions({
  actionTypeName: 'atradne_kvalitate',
  resultTransformator: (values) => dalaTableResultTransformator(values),
  valuesTransformator: (values) => dataTableValuesTransformator(values),
  stateName: 'atradneKvalitate',
  combinedPage: true,
  apiViewName: 'atradne_kvalitate_view',
  fetchArgsToCrudParams: ({ numurs }) => ['numurs', numurs]
})

export const dalaKvalitateActions = getDefaultEditActions({
  actionTypeName: 'atradnes_dala_kvalitate',
  stateName: 'atradnesDalaKvalitate',
  combinedPage: true,
  apiViewName: 'atradnes_dala_kvalitate_view',
})

export const uzzinasActions = getDefaultEditActions({
  actionTypeName: 'atradne_uzzinas',
  stateName: 'atradneUzzinas',
  combinedPage: true,
  apiViewName: 'atradne_uzzinas_view',
  fetchArgsToCrudParams: ({ numurs }) => ['numurs', numurs],
  valuesTransformator: (values, getState) => {
    const atradneUzzinas = { ...values }
    atradneUzzinas.uzzinas = getState().atradneUzzinas.uzzinasInGrid
    return atradneUzzinas
  }
})

export const fetchAtradnesDoks = ({ numurs }) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'atradne_dokumentacija_view',
    crudMethod: 'get',
    crudParam: ['numurs', numurs],
  }))
}

export const fetchAtradnesDalaDoks = ({ id }) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'atradnes_dala_dokumentacija_view',
    crudMethod: 'get',
    crudParam: id,
  }))
}


export const deleteAtradneUzzina = (uzzinaId) => (dispatch, getState) => {
  dispatch({ type: 'atradne_uzzinas_delete_atradne_uzzina', payload: uzzinaId })
}

export const addAtradneUzzina = (fieldName, uzzinaId, options) => (dispatch, getState) => {
  if (uzzinaId) {
    const uzzina = options.find(o => o.id === uzzinaId)
    if (uzzina) {
      dispatch({
        type: 'atradne_uzzinas_add_uzzina',
        payload: { ...uzzina, id: null, uzzina_id: uzzinaId }
      })
    }
  }
}

const getQueryTab = (getState) => {
  const { query } = getState().location
  if (query && query.tab) {
    return { tab: query.tab }
  }
  return undefined
}

const redirectToAtradneConsideringTab = (numurs, dispatch, getState) => {
  dispatch(redirect({
    type: 'loc_atradne',
    payload: {
      numurs: numurs
    },
    query: getQueryTab(getState)
  }))
}

export const onAtradneSelectedInTopMenu = (field, value, options) => (dispatch, getState) => {
  if (value) {
    //set options and redirect to atradne number
    //keep query tab when redirecting
    redirectToAtradneConsideringTab(value, dispatch, getState)
    dispatch({ type: 'atradne_top_menu_selected', payload: { options, numurs: value } })
  } else {
    dispatch({ type: 'atradne_top_menu_clear' })
  }
}

export const onAtradneNextOrPrevInTopMenu = (nextOrPrev) => (dispatch, getState) => {
  redirectToAtradneConsideringTab(nextOrPrev, dispatch, getState)
  dispatch({ type: 'atradne_top_menu_selected', payload: { numurs: nextOrPrev } })
}

export const onAtradneNextOrPrevInTitle = (nextOrPrev) => (dispatch, getState) => {
  redirectToAtradneConsideringTab(nextOrPrev, dispatch, getState)
}

export const onAtradneDalaNextOrPrevInTitle = (currentNumurs, nextOrPrevId) => (dispatch, getState) => {
  dispatch(redirect({
    type: 'loc_atradnes_dala',
    payload: {
      numurs: currentNumurs,
      dala: nextOrPrevId,
    },
    query: getQueryTab(getState)
  }))
}

export const openUzzinaFromAtradne = ({ id, numurs }) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_atradnes_uzzina',
    payload: {
      id, numurs
    }
  }))
}

export const atradneDokSelected = (dokIdAndType) => {
  return { type: 'atradne_dokumentacija_dokselected', payload: dokIdAndType }
}

export const atradneKrajumsSelected = (payload) => {
  return { type: 'atradne_krajumi_krajums_selected', payload }
}

export const atradneKrajumsSum = (payload) => {
  return { type: 'atradne_krajumi_sum', payload }
}

export const atradneKrajumsClearSum = () => {
  return { type: 'atradne_krajumi_sum_clear' }
}

export const krajumiActions = getDefaultEditActions({
  actionTypeName: 'atradne_krajumi',
  resultTransformator: (values) => {
    const res = dalaTableResultTransformator(values)
    res.krajumi.sort((a, b) => {
      const aizp = a.izpetes_gads ? Math.min(...(a.izpetes_gads.split(/[\s,-]+/).map(g => Number(g)))) : 0.0
      const bizp = b.izpetes_gads ? Math.min(...(b.izpetes_gads.split(/[\s,-]+/).map(g => Number(g)))) : 0.0
      return aizp < bizp ? -1 : aizp > bizp ? 1 : a.id < b.id ? -1 : 1
    })
    return res
  },
  valuesTransformator: dataTableValuesTransformator,
  stateName: 'atradneKrajumi',
  combinedPage: true,
  apiViewName: 'atradne_krajums_view',
  fetchArgsToCrudParams: ({ numurs }) => ['numurs', numurs]
})

export const dalaKrajumiActions = getDefaultEditActions({
  actionTypeName: 'atradnes_dala_krajumi',
  stateName: 'atradnesDalaKrajumi',
  combinedPage: true,
  apiViewName: 'atradnes_dala_krajums_view',
})

export const atradneLimituAtlikumsSelected = (payload) => {
  return { type: 'atradne_limitu_atlikumi_limits_selected', payload }
}

const licenceColors = ['#3c9d9b', '#52de97', '#c0ffb3', '#3C97B5', '#4BE1EB', '#C1E897', '#3870AB', '#4EF572', '#F3FFA6']
const limitiColors = ['#FFE270', '#FFAB63', '#C8FF60', '#E8E15A', '#E8B65A', '#E8754F', '#E8E15A', '#FF5C57', '#C8FF61']

// fill different color for each licence and limits
const addLicenceAndLimitColors = (res) => {
  const licIds = []
  const limIds = []
  res.izmainas.forEach(izm => {
    if (izm.licence) {
      let ind = licIds.indexOf(izm.licence.id)
      if (ind < 0) {
        licIds.push(izm.licence.id)
        ind = licIds.length - 1
      }
      izm.licColor = licenceColors[ind % 9]
    }
    if (izm.limita_apjoms) {
      let ind = limIds.indexOf(izm.limita_apjoms.id)
      if (ind < 0) {
        limIds.push(izm.limita_apjoms.id)
        ind = limIds.length - 1
      }
      izm.limColor = limitiColors[ind % 9]
    }
  })
  return res
}

//TODO remove when backend is able to save references for children objects
export const setIzmainaReferences = (izm) => {
  if (izm.licence) {
    izm.licence_id = izm.licence.id
  } else {
    izm.licence_id = null
  }
  if (izm.limita_apjoms) {
    izm.limita_apjoms_id = izm.limita_apjoms.id
  } else {
    izm.limita_apjoms_id = null
  }
}
const izmReferencesSetter = (values) => {
  values.izmainas.forEach(setIzmainaReferences)
  return values
}

const izmainasActionsResultTransformator = (values) => {
  const res = dalaTableResultTransformator(values)
  res.izmainas.sort((a, b) => a.datums < b.datums ? -1 : a.datums > b.datums ? 1 : a.id < b.id ? -1 : 1)
  return addLicenceAndLimitColors(res)
}
export const izmainasActions = getDefaultEditActions({
  actionTypeName: 'atradne_izmaina',
  resultTransformator: izmainasActionsResultTransformator,
  valuesTransformator: (values) => {
    const vals = izmReferencesSetter(values)
    return dataTableValuesTransformator(vals)
  },
  stateName: 'atradneIzmainas',
  combinedPage: true,
  apiViewName: 'atradne_izmaina_view',
  overridedActions: {
    fetchAct: ((argsObj) => (dispatch, getState) => {
      return dispatch(callApi({
        viewName: argsObj.viewName ? argsObj.viewName : getState().atradneIzmainas.isGrouped ? 'atradne_izmaina_view_grouped' : 'atradne_izmaina_view',
        crudMethod: 'get',
        crudParam: ['numurs', argsObj.numurs],
        resultTransformator: izmainasActionsResultTransformator
      }))
    })
  }
})

export const dalaIzmainasActions = getDefaultEditActions({
  actionTypeName: 'atradnes_dala_izmaina',
  stateName: 'atradnesDalaIzmainas',
  combinedPage: true,
  apiViewName: 'atradnes_dala_izmaina_view',
  resultTransformator: (values) => {
    return addLicenceAndLimitColors(values)
  },
  valuesTransformator: (values) => {
    return izmReferencesSetter(values)
  },
  overridedActions: {
    fetchAct: ((argsObj) => (dispatch, getState) => {
      return dispatch(callApi({
        viewName: argsObj.viewName ? argsObj.viewName : getState().atradnesDalaIzmainas.isGrouped ? 'atradnes_dala_izmaina_view_grouped' : 'atradnes_dala_izmaina_view',
        crudMethod: 'get',
        crudParam: ['id', argsObj.id],
        resultTransformator: (values) => {
          return addLicenceAndLimitColors(values)
        }
      }))
    })
  }
})

export const atradneIzmainasSum = (payload) => {
  return { type: 'atradne_izmainas_sum', payload }
}

export const atradneIzmainasClearSum = () => {
  return { type: 'atradne_izmainas_sum_clear' }
}

export const atradneChangeGrouped = () => (dispatch, getState) => {
  dispatch(izmainasActions.fetchAct({
    numurs: getState().atradne.selectedItem.numurs,
    viewName: getState().atradneIzmainas.isGrouped ? 'atradne_izmaina_view' : 'atradne_izmaina_view_grouped'
  }))
  return dispatch({ type: 'atradne_izmainas_grouped' })
}

export const atradnesDalaChangeGrouped = () => (dispatch, getState) => {
  dispatch(dalaIzmainasActions.fetchAct({
    id: getState().atradnesDala.selectedItem.id,
    viewName: getState().atradnesDalaIzmainas.isGrouped ? 'atradnes_dala_izmaina_view' : 'atradnes_dala_izmaina_view_grouped'
  }))
  return dispatch({ type: 'atradne_izmainas_grouped' })
}