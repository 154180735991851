import {callApi, getDefaultEditActions, pushUrlToBrowserHistory} from './helpers'
import {redirect} from 'redux-first-router'

export const loadAdServisi = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'ad_serviss_list',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const loadDataSources = (forced = true) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'datasource_list',
        crudMethod: 'list'
    }))
}


export const adServissActions = getDefaultEditActions({
  actionTypeName : 'ad_serviss',
  stateName: 'ad_serviss',
  apiViewName: 'ad_serviss_edit',
  afterDelete: (dispatch, getState) => {
    dispatch(redirect({type : 'loc_ad_servisi'}))
  },
  overridedActions: {
    createAct: (argsObj) => (dispatch, getState) => {
      pushUrlToBrowserHistory(getState)
      dispatch({
        type: `ad_serviss_edit_new_success`,
        payload: {
          id: null,
          nosaukums: null,
          konfigurācijas: []
        }
      });
    }
  }
})

export const openAdServiss = (id) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_ad_serviss',
    payload: {
        id: id
    }
   }))
}

export const adServissKonfiguracijaActions = getDefaultEditActions({
  actionTypeName: 'ad_serviss_konfiguracija',
  stateName: 'konfiguracija', 
  apiViewName: 'ad_serviss_konfiguracija_edit',
  combinedPage: true,
  createActArgsToCrudParams: ({ ad_serviss_id }) => ({ ad_serviss_id }),
  afterSave: (dispatch, getState, result) => {
    dispatch(adServissActions.fetchAct({
      id: result.ad_serviss_id
    }))
  },
  afterDelete: (dispatch, getState) => {
    const ad_serviss_id = getState().ad_serviss.selectedItem.id
    dispatch(adServissActions.fetchAct({
      id: ad_serviss_id
    }))
  },
  overridedActions: {
    createAct: (argsObj) => (dispatch, getState) => {
      const oldKonfiguracija = argsObj.editable ? getState().konfiguracija.selectedItem : null;
      const newKonfiguracija = { 
        ...oldKonfiguracija, 
        id: null, 
        ad_serviss_id: argsObj.ad_serviss_id,
        var_veikt_manuālu_ielādi: true,
        ir_ieraksts: false,
        ir_aktīvs: false,
        izveidošanas_datums: null,
        izveidotājs_id: null,
        izveidotājs: null,
        labošanas_datums: null,
        labotājs_id: null,
        labotājs: null,
        stacijas : oldKonfiguracija?.stacijas.map(stacija => {
          return {
            ...stacija, 
            id: null
          }}),
        atmosferas_novērojumi : oldKonfiguracija?.atmosferas_novērojumi.map(nov => {
          return {
            ...nov,
            id: null 
          }}),
        nokrisnu_novērojumi : oldKonfiguracija?.nokrisnu_novērojumi.map(nov => {
          return {
            ...nov, 
            id: null
          }})
      };

      dispatch({
        type: `ad_serviss_konfiguracija_edit_new_success`,
        payload: newKonfiguracija
      });
    }, 
  }
})

export const adServissKonfiguracijaAktivsActions = getDefaultEditActions({
  actionTypeName: 'ad_serviss_konfiguracija_aktivs',
  stateName: 'adServissKonfiguracijaAktivs',
  apiViewName: 'ad_serviss_konfiguracija_aktivs_edit',
  afterSave: (dispatch, getState, result) => {
    dispatch(adServissActions.fetchAct({
      id: result.ad_serviss_id
    }))
  },
})

export const adImportDataActions = getDefaultEditActions({
  actionTypeName : 'ad_import_data',
  stateName: 'ad_import_data',
  apiViewName: 'ad_import_data',
  afterSave: (dispatch, getState, result) => {
    dispatch(adServissKonfiguracijaActions.fetchAct({id : result.id}))
  },
})

export const adImportStacijasNoverojumiActions = getDefaultEditActions({
  actionTypeName : 'ad_import_stacijas',
  stateName: 'ad_import_stacijas',
  apiViewName: 'ad_import_stacijas',
  afterSave: (dispatch) => {
    dispatch({
      type: "ad_serviss_konfiguracija_edit",
    })
  },
})
