import { useCallback, useEffect, useState } from 'react';
import OlLayerVector from 'ol/layer/Vector';
import OlSourceVector from 'ol/source/Vector';
import OlCollection from 'ol/Collection';
import OlMultiPolygon from 'ol/geom/MultiPolygon';
import OlMultiLineString from 'ol/geom/MultiLineString';
import OlStyleStyle from 'ol/style/Style';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleFill from 'ol/style/Fill';
import OlStyleCircle from 'ol/style/Circle';
import OlInteractionDraw from 'ol/interaction/Draw';
import OlOverlay from 'ol/Overlay';
import OlGeomPolygon from 'ol/geom/Polygon';
import OlGeomLineString from 'ol/geom/LineString';
import { getArea, getLength } from 'ol/sphere';

const fillColor = 'rgba(3, 252, 244, 0.5)';
const strokeColor = 'rgba(3, 252, 244, 1.0)';

const formatLength = (line, map) => {
    const length = getLength(line, {
        projection: map.getView().getProjection().getCode(),
        radius: 6371034.0, //earth radius in Riga
    });
    let output;
    if (length > 1000) {
        output = Math.round((length / 1000) * 100) / 100 + ' km';
    } else if (length > 1) {
        output = Math.round(length * 10) / 10 + ' m';
    } else {
        output = Math.round(length * 1000) + ' mm';
    }
    return output;
};

const formatArea = (poly, map) => {
    const area = getArea(poly, {
        projection: map.getView().getProjection().getCode(),
        radius: 6371034.0, //earth radius in Riga
    });
    let output;
    if (area > 10000) {
        output = Math.round((area / 10000) * 100) / 100 + ' ha';
    } else if (area > 1) {
        output = Math.round(area * 10) / 10 + ' m<sup>2</sup>';
    } else {
        output = '';
    }
    return output;
};

const getMeasureLabelAndCoord = (geometry, map) => {
    let geom = geometry;

    if (geom instanceof OlMultiPolygon) {
        geom = geom.getPolygons()[0];
    } else if (geom instanceof OlMultiLineString) {
        geom = geom.getLineStrings()[0];
    }

    let measureTooltipCoord = null;
    let output = null;

    if (geom instanceof OlGeomPolygon) {
        output = formatArea(geom, map);
        measureTooltipCoord = geom.getInteriorPoint().getCoordinates();
    } else if (geom instanceof OlGeomLineString) {
        output = formatLength(geom, map);
        measureTooltipCoord = geom.getLastCoordinate();
    }
    return [output, measureTooltipCoord];
};

export const Measure = ({ map }) => {
    const [measureLayer, setMeasureLayer] = useState(null);
    const [movingTooltip, setMovingTooltip] = useState(null);
    const [measureTooltips, setMeasureTooltips] = useState([]);
    const [enabledButton, setEnabledButton] = useState(null);

    const toggleEnabledButton = (type) => {
        if (enabledButton === type) {
            setEnabledButton(null);
        } else {
            setEnabledButton(type);
        }
    };

    // Add measure layer
    useEffect(() => {
        if (map) {
            const ml = new OlLayerVector({
                properties: {
                    name: '_measure',
                },
                source: new OlSourceVector({
                    features: new OlCollection(),
                }),
                style: new OlStyleStyle({
                    fill: new OlStyleFill({
                        color: fillColor,
                    }),
                    stroke: new OlStyleStroke({
                        color: strokeColor,
                        width: 2,
                    }),
                    image: new OlStyleCircle({
                        radius: 6,
                        fill: new OlStyleFill({
                            color: fillColor,
                        }),
                    }),
                }),
                zIndex: 1500,
            });
            map.addLayer(ml);
            setMeasureLayer(ml);
            return () => {
                map.removeLayer(ml);
                setMeasureLayer(null);
            };
        }
    }, [map]);

    const updateMeasureTooltip = useCallback(
        (evt) => {
            if (map && movingTooltip && evt.target) {
                const [output, measureTooltipCoord] = getMeasureLabelAndCoord(evt.target, map);
                const me = movingTooltip.getElement();
                if (output && me && measureTooltipCoord) {
                    me.innerHTML = output;
                    movingTooltip.setPosition(measureTooltipCoord);
                }
            }
        },
        [map, movingTooltip]
    );

    const onDrawStart = useCallback(
        (evt) => {
            const feature = evt.feature;
            feature.getGeometry()?.on('change', updateMeasureTooltip);
        },
        [updateMeasureTooltip]
    );

    const onDrawEnd = useCallback(
        (evt) => {
            if (!map) {
                return;
            }
            const feature = evt.feature;
            if (feature && feature.getGeometry()) {
                const [output, measureTooltipCoord] = getMeasureLabelAndCoord(feature.getGeometry(), map);
                if (output && measureTooltipCoord) {
                    const div = document.createElement('div');
                    div.className = 'measure-static-tooltip';
                    div.innerHTML = output;
                    const over = new OlOverlay({
                        element: div,
                        offset: [0, -15],
                        positioning: 'bottom-center',
                    });
                    map.addOverlay(over);
                    over.setPosition(measureTooltipCoord);
                    setMeasureTooltips((prev) => [...prev, over]);

                    if (movingTooltip && movingTooltip.getElement()) {
                        movingTooltip.getElement().innerHTML = '';
                        movingTooltip.setPosition(undefined);
                    }
                }
            }
        },
        [map, movingTooltip]
    );

    useEffect(() => {
        if (map && measureLayer && enabledButton) {
            const di = new OlInteractionDraw({
                source: measureLayer.getSource() || undefined,
                type: enabledButton === 'poly' ? 'MultiPolygon' : 'MultiLineString',
                stopClick: true,
                style: new OlStyleStyle({
                    fill: new OlStyleFill({
                        color: fillColor,
                    }),
                    stroke: new OlStyleStroke({
                        color: strokeColor,
                        width: 2,
                    }),
                    image: new OlStyleCircle({
                        radius: 5,
                        stroke: new OlStyleStroke({
                            color: strokeColor,
                        }),
                        fill: new OlStyleFill({
                            color: fillColor,
                        }),
                    }),
                }),
            });

            di.on('drawstart', (e) => onDrawStart(e));
            di.on('drawend', (e) => onDrawEnd(e));

            map.addInteraction(di);

            return () => {
                map.removeInteraction(di);
                measureLayer.getSource()?.clear();
            };
        }
    }, [map, enabledButton, measureLayer, onDrawEnd, onDrawStart]);

    useEffect(() => {
        if (map) {
            if (movingTooltip) {
                map.removeOverlay(movingTooltip);
            }
            measureTooltips.forEach((t) => {
                map.removeOverlay(t);
            });
            if (enabledButton) {
                const mte = document.createElement('div');
                mte.className = 'measure-dynamic-tooltip';
                const mt = new OlOverlay({
                    element: mte,
                    offset: [0, -15],
                    positioning: 'bottom-center',
                });
                map.addOverlay(mt);
                setMovingTooltip(mt);
            }
            return () => {
                setMovingTooltip(null);
                setMeasureTooltips([]);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, enabledButton]);

    return (
        <>
            <div className="ol-control measure-length" key="length">
                <button
                    title="Mērīt attālumu"
                    className={(enabledButton === 'line' ? 'active' : '')}
                    onClick={() => toggleEnabledButton('line')}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#ffffff"><path d="M160-240q-33 0-56.5-23.5T80-320v-320q0-33 23.5-56.5T160-720h640q33 0 56.5 23.5T880-640v320q0 33-23.5 56.5T800-240H160Zm0-80h640v-320H680v160h-80v-160h-80v160h-80v-160h-80v160h-80v-160H160v320Zm120-160h80-80Zm160 0h80-80Zm160 0h80-80Zm-120 0Z" /></svg>
                </button>
            </div>
            <div className="ol-control measure-area" key="area">
                <button
                    title="Mērīt platību"
                    className={(enabledButton === 'poly' ? 'active' : '')}
                    onClick={() => toggleEnabledButton('poly')}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#ffffff"><path d="M208-120q-37 0-62.5-25.5T120-208v-548q0-29 27-40.5t47 8.5l90 90-54 54 28 28 54-54 104 104-54 54 28 28 54-54 104 104-54 54 28 28 54-54 104 104-54 54 28 28 54-54 80 80q20 20 8.5 47T756-120H208Zm32-120h332L240-572v332Z" /></svg>
                </button>
            </div>
        </>

    );
};

export default Measure;
