import React from 'react'
import { connect } from 'react-redux'

import Link from 'redux-first-router-link'
import _ from 'lodash'

// import { Card, CardBody, CardTitle } from 'reactstrap'
import {Alert, Button, Label, Input, Modal, ModalHeader, ModalBody, Spinner} from 'reactstrap'

import * as api from '../../api'
import {exportOds, hostize} from "../../api";
import {showErrorMsg} from "../../actions/uiActions";

const RECORDS_ON_PAGE = 150

const dmy_of_ymd = (ymd) => (
  // yyyy-mm-dd
  // 0123456789
  `${ymd.substring(8, 10)}.${ymd.substring(5, 7)}.${ymd.substring(0, 4)}`
)

const newlinize = (s) => {
  if (s.match(/\n/)) {
    return s.split(/\n+/).map((t, idx) => <div key={idx}>{ t }</div>)
  } else {
    return s
  }
}

const render_wise_3_pre = (data, offset = 0, filters = []) => (
  <table className="table table-nowrap" style={{width: 'auto'}}>
      <thead>
        <tr>
        <th></th>
        <th>Kods</th>
        <th>Stacija</th>
        <th>Gads</th>
        <th>Mēnesis</th>
        <th>GWLgs: avg</th>
        <th>GWLgs: n</th>
        <th>GWLsl: avg</th>
        <th>GWLsl: n</th>
        </tr>
        <tr className="filters">
          <td></td>
          <td><input value={ filters.kods_starpt || '' } onChange={(ev) => filters.changed(ev, 'kods_starpt')}/></td>
          <td><input value={ filters.stacija || '' } onChange={(ev) => filters.changed(ev, 'stacija')}/></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {
        data.map( (o, idx) => (
          <tr key={idx}>
            <td className="text-muted">{ idx + offset + 1 }.</td>
            <td>{ o.kods_starpt }</td>
            <td>{ o.stacija }</td>
            <td>{ o.gads }</td>
            <td>{ o.menesis }</td>
            <td>{ o.gwlgs_avg ? o.gwlgs_avg.toFixed(2) : '' }</td>
            <td>{ o.gwlgs_n }</td>
            <td>{ o.gwlsl_avg ? o.gwlsl_avg.toFixed(2) : '' }</td>
            <td>{ o.gwlsl_n }</td>
            </tr>
          ) )
        }
      </tbody>
    </table>
)

const render_wise_3 = (data, offset = 0, filters={}) => (
  <table className="table table-nowrap" style={{width: 'auto'}}>
      <thead>
        <tr>
          <th></th>
          <th>monitoringSiteIdentifier</th>
          <th>monitoringSiteIdentifierScheme</th>
          <th>observedProperty</th>
          <th>phenomenonTimePeriod</th>
          <th>resultObservedValue</th>
          <th>resultObservationStatus</th>
          <th>remarks</th>
        </tr>
        <tr className="filters">
          <td></td>
          <td><input value={ filters.monitoringSiteIdentifier || '' } onChange={(ev) => filters.changed(ev, 'monitoringSiteIdentifier')}/></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {
        data.map( (o, idx) => (
          <tr key={idx}>
            <td className="text-muted">{ idx + offset + 1 }.</td>
            <td>{ o.monitoringSiteIdentifier }</td>
            <td>{ o.monitoringSiteIdentifierScheme }</td>
            <td>{ o.observedProperty }</td>
            <td>{ o.phenomenonTimePeriod }</td>
            <td>{ o.resultObservedValue ? o.resultObservedValue.toFixed(2) : '' }</td>
            <td>{ o.resultObservationStatus }</td>
            <td>{ o.remarks }</td>
            </tr>
          ) )
        }
      </tbody>
    </table>
)

const render_wise_3_ext = (data, offset = 0, filters={}) => (
  <table className="table table-nowrap" style={{width: 'auto'}}>
      <thead>
        <tr>
          <th></th>
          <th>kods_starpt</th>
          <th>stacija</th>
          <th>diena</th>
          <th>menesis</th>
          <th>datums</th>
          <th>merijums</th>
          <th>raditajs</th>
          <th>merijuma_veids</th>
          <th>mervieniba</th>
          <th>karodz_kods</th>
          <th>karodz_atsifr</th>
        </tr>
        <tr className="filters">
          <td></td>
          <td><input value={ filters.kods_starpt || '' } onChange={(ev) => filters.changed(ev, 'kods_starpt')}/></td>
          <td><input value={ filters.stacija || '' } onChange={(ev) => filters.changed(ev, 'stacija')}/></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {
        data.map( (o, idx) => (
          <tr key={idx}>
            <td className="text-muted">{ idx + offset + 1 }.</td>
            <td>{ o.kods_starpt }</td>
            <td>{ o.stacija }</td>
            <td>{ o.diena }</td>
            <td>{ o.menesis }</td>
            <td>{ o.datums.toLocaleDateString() }</td>
            <td>{ o.merijums }</td>
            <td>{ o.raditajs }</td>
            <td>{ o.merijuma_veids }</td>
            <td>{ o.mervieniba }</td>
            <td>{ o.karodz_kods }</td>
            <td>{ o.karodz_atsifr }</td>
            </tr>
          ) )
        }
      </tbody>
    </table>
)



const render_wise_6 = (data, offset = 0, filters={}) => (
  <table className="table table-nowrap" style={{width: 'auto'}}>
      <thead>
        <tr>
          <th></th>
          <th>montoringSiteIdentifier</th>
          <th>monitoringSiteIdentifierScheme</th>
          <th>parameterWaterBodyCategory</th>
          <th>observedPropertyDeterminandCode</th>
          <th>procedureAnalysedMatrix</th>
          <th>resultUom</th>
          <th>phenomenanTimeSamplingDate</th>
          <th>sampleIdentifier</th>
          <th>resultObservedValue</th>
          <th>resultQualityObservedValueBelowLOQ</th>
          <th>procedureLOQValue</th>
          <th>parameterSampleDepth</th>
          <th>resultObservationStatus</th>
          <th>karodz_kods</th>
          <th>karodz_atsifr</th>
          <th>piezime</th>
          <th>noverojumu_stac</th>
          <th>udensobjekta_kods</th>
          <th>raditaja_nosaukums</th>
          <th>rezultats</th>
          <th>mervieniba</th>
          <th>merijuma_veids</th>
          <th>metode</th>
        </tr>
        <tr className="filters">
          <td></td>
          <td><input value={ filters.monitoringSiteIdentifier || '' } onChange={(ev) => filters.changed(ev, 'monitoringSiteIdentifier')}/></td>
          <td></td>
          <td></td>
          <td><input value={ filters.observedPropertyDeterminandCode || '' } onChange={(ev) => filters.changed(ev, 'observedPropertyDeterminandCode')}/></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td><input value={ filters.karodz_atsifr || '' } onChange={(ev) => filters.changed(ev, 'karodz_atsifr')}/></td>
          <td><input value={ filters.piezime || '' } onChange={(ev) => filters.changed(ev, 'piezime')}/></td>
          <td><input value={ filters.noverojumu_stac || '' } onChange={(ev) => filters.changed(ev, 'noverojumu_stac')}/></td>
          <td><input value={ filters.udensobjekta_kods || '' } onChange={(ev) => filters.changed(ev, 'udensobjekta_kods')}/></td>
          <td><input value={ filters.raditaja_nosaukums || '' } onChange={(ev) => filters.changed(ev, 'raditaja_nosaukums')}/></td>
          <td></td>
          <td></td>
          <td><input value={ filters.merijuma_veids || '' } onChange={(ev) => filters.changed(ev, 'merijuma_veids')}/></td>
          <td><input value={ filters.metode || '' } onChange={(ev) => filters.changed(ev, 'metode')}/></td>
        </tr>
      </thead>
      <tbody>
        {
          data.map( (o, idx) => (
            <tr key={idx}>
              <td className="text-muted">{ idx + offset + 1 }.</td>
              <td>{ o.montoringSiteIdentifier }</td>
              <td>{ o.monitoringSiteIdentifierScheme }</td>
              <td>{ o.parameterWaterBodyCategory }</td>
              <td>{ o.observedPropertyDeterminandCode }</td>
              <td>{ o.procedureAnalysedMatrix }</td>
              <td>{ o.resultUom } { o.resultUom_style }</td>
              <td>{ dmy_of_ymd(o.phenomenanTimeSamplingDate) }</td>
              <td>{ o.sampleIdentifier }</td>
              <td>{ o.resultObservedValue }</td>
              <td>{ o.resultQualityObservedValueBelowLOQ }</td>
              <td>{ o.procedureLOQValue }</td>
              <td>{ o.parameterSampleDepth }</td>
              <td>{ o.resultObservationStatus }</td>
              <td>{ o.karodz_kods }</td>
              <td>{ o.karodz_atsifr }</td>
              <td>{ newlinize(o.piezime) }</td>
              <td>{ o.noverojumu_stac }</td>
              <td>{ o.udensobjekta_kods }</td>
              <td>{ o.raditaja_nosaukums }</td>
              <td>{ o.rezultats }</td>
              <td>{ o.mervieniba }</td>
              <td>{ o.merijuma_veids }</td>
              <td>{ o.metode }</td>
            </tr>
          ) )
        }
      </tbody>
    </table>
)


class WiseLoader extends React.PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      error: null,
      ready: false,
      loading: false,
      modalSearch: false,
      overview: [],
      filtered_overview: [],
      filters: {}
    }

    this.loadWise = this.loadWise.bind(this)
    this.loadWiseOds = this.loadWiseOds.bind(this)
    this.setPage = this.setPage.bind(this)
    this.filterChanged = this.filterChanged.bind(this)
    this.refilter = _.debounce(this.refilter.bind(this), 500)
  }


  loadWiseOds () {
    this.setState({ modalSearch: true })
    exportOds({
      viewName: this.props.view,
      param: {
        params: {
          gads: this.props.year,
          atskaite: this.props.atskaite
        }
      }
    }).then((hash) => {
      this.setState({ modalSearch: false })
      window.open(hostize(`/deferred/${hash}/result`))
    }).catch((err) => {
      this.setState({ modalSearch: false })
    })
  }

  loadWise () {
    if (this.state.ready && this.state.overview.length > 0) {
      // otrreizējs klikšķis aizver rezultātu palagu
      this.setState({ ready: false, overview: [], filtered_overview: [] })
      return
    }

    this.setState({
      loading: true,
      ready: false,
      error: false,
      overview: [],
      filtered_overview: [],
    })
    api.listApi(this.props.view + '_export_ods', { deferred: true, params: { gads: this.props.year, atskaite: this.props.atskaite }})
      .then( overview => {
        this.setState({
          overview,
          error: false,
          loading: false,
          // ready: true,
          ready: false, // sic: refilter uzstādīs ready
          page: 0,
        }, this.refilter)
      } )
      .catch( e => this.setState({ loading: false, ready: false, error: `${e}` }) )

  }

  refilter () {
    const filters = this.state.filters
    const used_keys = Object.keys(this.state.filters).filter( k => !! filters[k] )
    if ( ! used_keys.length ) {
      this.setState({
        filtered_overview: this.state.overview,
        ready: true
      })
    } else {
      this.setState({
        filtered_overview: this.state.overview.filter( rec => used_keys.every( k => rec[k] && rec[k].toLowerCase().includes( filters[k] ) )),
        ready: true,
      })
    }
  }

  canShowParskats = (user, atskaite) => {
    switch (atskaite) {
      case 'pazeme':
        return user.tiesibas.includes("BAIS_PU_LABOT") || user.tiesibas.includes("BAIS")
      case 'virszeme':
        return user.tiesibas.includes("BAIS_VU_KVAL_LABOT") || user.tiesibas.includes("BAIS")
      case 'watch':
        return user.tiesibas.includes("BAIS_PU_LABOT") || user.tiesibas.includes("BAIS_VU_KVAL_LABOT") || user.tiesibas.includes("BAIS")
      default:
        return false
    }
  }

  changeSettings = (user, event) => {
    if (!user.tiesibas.includes("WISE_LABOT")) {
      event.preventDefault()
      this.props.showErrorMsg("Jums nav tiesības skatīties iestatījumus!")
    }
  }

  renderForm () {
    return (
      <form onSubmit={(ev) => ev.preventDefault()} className="mb-3">
        { 
          this.state.loading ? (
            <Button color="default"><i className="fa fa-spinner fa-spin fa-fw mr-2" />Ielādē pārskatu</Button>
          ) : (
            <Button color="primary" onClick={() => this.loadWise()} disabled={!this.canShowParskats(this.props.user, this.props.atskaite)}>{ this.state.overview.length > 0 ? 'Aizvērt pārskatu' : 'Ielādēt pārskatu pārlūkā' }</Button>
          )
        }
        <Button className="ml-1" color="primary" onClick={() => this.loadWiseOds()} disabled={!this.canShowParskats(this.props.user, this.props.atskaite)}><span className="fa fa-download" /> Ielādēt pārskatu .ods formātā</Button>
        {
          this.props.has_settings === true ? (
            <Link className="ml-1 btn btn-primary" to={{ type: 'loc_wise_param', payload: { atskaite: this.props.atskaite } }} onClick={(e)=> this.changeSettings(this.props.user, e)}><span className="fa fa-cog" /> Iestatījumi</Link>
          ) : null
        }
        <Modal
          isOpen={this.state.modalSearch}
          className="modal-sm">
          <ModalHeader>Notiek datu sagatavošana</ModalHeader>
          <ModalBody className="text-center">
            <Spinner size="lg" color="primary" />{' '}
          </ModalBody>
        </Modal>
      </form>
    )
  }

  setPage(page) {
    this.setState({ page })
  }

  renderPager () {

    const recs = this.state.filtered_overview.length
    const total_pages = Math.ceil(recs / RECORDS_ON_PAGE)

    const pages = []
    if (total_pages < 30) {
      // pilnais peidžeris
      for (let i = 0; i < total_pages; i++) {
        if (i === this.state.page) {
          pages.push(<li className="page-item active" key={i}><button type="button" className="page-link" onClick={() => this.setPage( i )}>{ i + 1 }</button></li>)
        } else {
          pages.push(<li className="page-item" key={i}><button type="button" className="page-link" onClick={() => this.setPage( i )}>{ i + 1 }</button></li>)
        }
      }
    } else {
      // apgrieztais peidžeris
      let is_last_printed = true
      for (let i = 0; i < total_pages; i++) {
        if (i <= 2 || i > (total_pages - 3) || Math.abs(i - this.state.page) <= 2) {
          if ( ! is_last_printed ) {
            pages.push(<li className="page-item" key={'s-' + i}><div className="page-link">...</div></li>)
            is_last_printed = true
          }
          if (i === this.state.page) {
            pages.push(<li className="page-item active" key={i}><button type="button" className="page-link" onClick={() => this.setPage( i )}>{ i + 1 }</button></li>)
          } else {
            pages.push(<li className="page-item" key={i}><button type="button" className="page-link" onClick={() => this.setPage( i )}>{ i + 1 }</button></li>)
          }
        } else {
          is_last_printed = false
        }
      }
    }

    return (
      <ul className="pagination flex-wrap">
        { pages }
      </ul>
    )

  }

  filterChanged (ev, filterName) {
    this.setState({
      filters: { ...this.state.filters, [filterName]: ev.target.value.toLowerCase() }
    }, this.refilter)
  }

  renderResults () {
    if (this.state.loading) {
      return null
    }

    if ( ! this.state.ready ) return null

    // lietojam internālo peidžeri, jo reporti ģenerē bulk datus un nav piemēroti server-puses peidžošanai.
    // Mums ir visi dati, tikai lapojam, lai pārlūks pārāk nemokās

    const p = this.state.page
    const filters = {
      ...this.state.filters,
      changed: this.filterChanged,
    }
    return (
      <div>
        { this.renderPager() }
        {
          this.props.renderer(
            this.state.filtered_overview.slice(p * RECORDS_ON_PAGE, (p + 1) * RECORDS_ON_PAGE),
            p * RECORDS_ON_PAGE,
            filters
          )
        }
      </div>
    )
  }

  render () {
    return (
      <div className="mb-3">
        <h2>{ this.props.title }</h2>
        {
          this.state.error ? <Alert color="danger">{ this.state.error }</Alert> : null
        }
        { this.renderForm() }
        { this.renderResults() }
      </div>
    )
  }

}



class WiseParskats extends React.PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      year: '2020'
    }

    this.changed = this.changed.bind(this)
  }

  changed (ev, field) {
    this.setState({ [field]: ev.target ? ev.target.value : ev })
  }


  render () {
    return (
      <div>
        <h1>WISE</h1>
        <div>
          <Label>Gads</Label>
          <Input
            type="text"
            name="username"
            value={this.state.year}
            onChange={(ev) => this.changed(ev, 'year')}
            className="col-md-1 mb-1"
            autoFocus
            invalid={+this.state.year < 1900 || +this.state.year >= 2100 }/>
        </div>
        <WiseLoader
          title="WISE-3 sagatavošana: apkopotais pazemes pārskats"
          view="wise_3_pre"
          year={this.state.year}līmenis
          renderer={render_wise_3_pre}
          atskaite="pazeme"
          has_settings={false}
          user={this.props.user}
        />
        <WiseLoader
          title="WISE-3 sagatavošana: izvērstais pazemes pārskats (ilgi!)"
          view="wise_3_ext"
          year={this.state.year}
          renderer={render_wise_3_ext}
          atskaite="pazeme"
          has_settings={false}
          user={this.props.user}
        />
        <WiseLoader
          title="WISE-3 pazemes ūdens kvantitāte"
          view="wise_3"
          year={this.state.year}
          renderer={render_wise_3}
          atskaite="pazeme"
          has_settings={false}
          user={this.props.user}
        />
        <WiseLoader
          title="WISE-6 pazemes ūdens kvalitāte"
          view="wise_6"
          year={this.state.year}
          renderer={render_wise_6}
          atskaite="pazeme"
          has_settings={true}
          user={this.props.user}
          showErrorMsg={this.props.showErrorMsg}
        />
        <WiseLoader
          title="WISE-6 virszemes ūdens kvalitāte"
          view="wise_6"
          year={this.state.year}
          renderer={render_wise_6}
          atskaite="virszeme"
          has_settings={true}
          user={this.props.user}
          showErrorMsg={this.props.showErrorMsg}
        />
        <WiseLoader
          title="WISE-6 watch-list"
          view="wise_6"
          year={this.state.year}
          renderer={render_wise_6}
          atskaite="watch"
          has_settings={true}
          user={this.props.user}
          showErrorMsg={this.props.showErrorMsg}
        />
      </div>
    )
  }
}

const mapState = ({ location, user }) => ({ location, user })

export default connect(mapState, {showErrorMsg})(WiseParskats)
