import { callApi, getDefaultEditActions, injectActionQueryParameter } from './helpers'

export const loadParisValidacijasRezultati = (query, procesa_id) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'paris_validacijas_rezultati_list',
      crudMethod: 'listPaging',
      crudParam: injectActionQueryParameter(query, { procesa_id }),
    }))
}


export const parisParametrsActions = getDefaultEditActions({
    actionTypeName : 'paris_validacijas_rezultati_list',
    stateName: 'user',
    combinedPage: true,
    apiViewName: 'paris_validacijas_rezultati_list',
})

