import React, {Component} from 'react';
import {connect} from 'react-redux'
import {urbumiActions} from '../../actions/hidrogeoActions'
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import NotFound from "../NotFound";
import Loading from "../Loading";
import UrbumsInfo from "./UrbumsInfo";
import {addUrlQuery} from "../../actions/uiActions";
import UrbumsSastavs from "./UrbumsSastavs";
import UrbumaParaugi from "./UrbumaParaugi";
import UrbumsNoverotieLimeni from "./UrbumsNoverotieLimeni";
import UrbumsIeguvesUrbumaLimeni from "./UrbumsIeguvesUrbumaLimeni";

export const getTabCfgs = () => {
  return {
    info: {
      title: 'Pamatinformācija',
      comp: UrbumsInfo
    },
    sastavs: {
      title: 'Ķīmiskais sastāvs',
      comp: UrbumsSastavs
    },
    paraugi: {
      title: 'Urbuma paraugi',
      comp: UrbumaParaugi
    },
    noverotie_limeni: {
      title: 'Novērotie ūdens līmeņi',
      comp: UrbumsNoverotieLimeni
    },
    ieguves_limeni: {
      title: 'Urbuma ieguves līmeņi',
      comp: UrbumsIeguvesUrbumaLimeni
    }
  }
}

class UrbumsActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return <div className="actionButtons">
      {/*<Button className="float-right" color="secondary" onClick={this.toggleModal}><i className="fa fa-print"></i>&nbsp;&nbsp;Eksportēt</Button>*/}

      {/* <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="modal-lg">
                <ModalHeader toggle={this.toggleModal}>UdensObjektss eksports</ModalHeader>
                <ModalBody>
                  Te būs udens_objektss eksports, piemēram, uz PDF.
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleModal}>Aizvērt</Button>
                </ModalFooter>
              </Modal> */}
    </div>
  }
}

class UrbumsTabs extends Component {

  renderTabNavLinks = (activeTab, isNew) => {
    return Object.keys(this.tabCfgs).map(k => (
      <NavItem key={k}>
        <NavLink className={k === activeTab ? 'active' : ''} disabled={isNew && this.tabCfgs[k].disableWhenNew}
                 onClick={() => {
                   this.props.addUrlQuery(
                       {tab: k === 'info' ? undefined : k,
                         gads: k === 'sastavs' || k === 'limeni' ? new Date().getFullYear().toString() : undefined,
                         f: undefined})
                 }}>
          {this.tabCfgs[k].title}
        </NavLink>
      </NavItem>
    ))
  }

  renderTabContents = (activeTab, isNew) => {
    return Object.keys(this.tabCfgs).map(k => {
        const Comp = this.tabCfgs[k].comp ? this.tabCfgs[k].comp : NotFound
        return <TabPane key={k} tabId={k}>
          <Comp/>
        </TabPane>
      }
    )
  }

  render() {
    const {activeTab, isNew, getTabCfgsFunc} = this.props
    // we cache tabs
    if (!this.tabCfgs) {
      const f = getTabCfgsFunc ? getTabCfgsFunc : getTabCfgs
      this.tabCfgs = f()
    }
    return <>
      <Nav tabs>
        {this.renderTabNavLinks(activeTab, isNew)}
      </Nav>
      <TabContent activeTab={activeTab}>
        {this.renderTabContents(activeTab, isNew)}
      </TabContent>
    </>
  }
}

export class Urbums extends Component {

  render() {

    const {selectedItem: urbums} = this.props.urbums
    const {merijumi, paraugi, ieguves_limeni, noverotie_limeni} = this.props

    // loading while we have no urbums
    if (!urbums && !merijumi && !paraugi && !ieguves_limeni && !noverotie_limeni) {
      return (<Loading/>)
    }

    // initial cfgs can come in as props, or we can create default ones
    const {
      query, getTabCfgsFunc, addUrlQuery
    } = this.props

    const isNew = !!(urbums && !urbums.id)
    const activeTab = query && query.tab && !isNew ? query.tab : 'info'

    return (<>
        <div className="leftside-single">
          <UrbumsActionButtons/>
          <UrbumsTabs activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc}
                            addUrlQuery={addUrlQuery}/>
        </div>
      </>

    )
  }
}

const mapStateToProps = ({urbums, kodif, location, merijumi, paraugi, ieguves_limeni, noverotie_limeni}) => ({
  urbums,
  kodif,
  query: location.query,
  merijumi,
  paraugi,
  ieguves_limeni,
  noverotie_limeni
})
export default connect(mapStateToProps, {...urbumiActions, addUrlQuery})(Urbums)
