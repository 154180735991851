import React, {Component} from 'react';
import TypeaheadField from '../forms/TypeaheadField'

export default class AutocompleteGridFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataObjVal: props.defaultValue
        }
        if(props.getFilterFunc){
            props.getFilterFunc(this.setFilterValue)
        }
        this.lastOptions = props.autocompleteCfg && props.autocompleteCfg.options ? props.autocompleteCfg.options : []
    }

    componentDidMount() { 
        if(this.state.dataObjVal){
            this.props.onFilter(this.state.dataObjVal)
        }
    }

    // called from TypeaheadField
    setFieldValue = (fieldName, newValue, options) => {
        this.lastOptions = options
        this.setState({
            dataObjVal: newValue
        })
        this.props.onFilter(newValue)
    }

    // called from grid when clearing filters
    setFilterValue = (newVal) => {
        this.setState({
            dataObjVal: newVal 
        })
        this.props.onFilter(newVal)
    }

    render() {
        const {autocompleteCfg} = this.props
        const {dataObjVal} = this.state
        //controlled because we need to reset value
        let autoCfg = {...autocompleteCfg}
        if(dataObjVal){
            if(this.lastOptions && this.lastOptions.find((v) => v.tah_id === dataObjVal)){
                autoCfg.options = this.lastOptions
            } else {
                autoCfg.options = [{ tah_id : dataObjVal , tah_label : dataObjVal }]
            }
        }
        return <div onClick={e => e.stopPropagation()}><TypeaheadField 
            setFieldValue={this.setFieldValue}
            autocompleteCfg={autoCfg}
            fieldName="auto"
            currentVal={dataObjVal}
        /></div>
    }

}
