import React from 'react'

const Loading = () => (
    <div className="loading-out">
        <div className="loading-in">
            <i className="fa fa-spinner fa-spin fa-4x fa-fw"></i>
            <span className="sr-only">Loading...</span>
        </div>
    </div>
)
export default Loading
