import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {
  getDefaultTypeaheadOptionsFromKodifArray,
  kodifKeyToTypeaheadOption,
  getKlasifikatorsFieldCfg,
  getMultiKlasifikatorsFieldCfg,
  DaudzumsField, getDaudzumsRenderer, validateNoLidzVidFields,  
  checkAtradnesType, getDalaFieldCfg, 
  getNoLidzVidFieldCfgs, getKudrasSpecificFields, getSapropelisSpecificFields
} from '../forms/FormHelpers'
import {geologijaActions, dalaGeologijaActions} from '../../actions/atradnesActions'
import FormCard from '../forms/FormCard'
import { Row } from 'reactstrap'
import {TEXT_ERR_DERIGIE} from '../../constants'  

const getFormFieldWrapperCfgs = ({user}) => {
  const fieldWrappers = [
    {
      id: 'morf',
      comp: FormCard,
      compProps: {
        title: 'Morfoloģiskais raksturojums būvmateriālu izejvielām',
        size: 12,
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'BC')
    },
    {
      id: 'morf_kudra',
      comp: FormCard,
      compProps: {
        title: 'Morfoloģiskais raksturojums kūdrai, dziedniecības dūņām',
        size: 12,
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'KD')
    },
    {
      id: 'morf_sapropel',
      comp: FormCard,
      compProps: {
        title: 'Morfoloģiskais raksturojums sapropelim',
        size: 12,
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'S')
    },
    {
      id: 'geologiskais',
      comp: FormCard,
      compProps: {
        title: 'Ģeoloģiskais raksturojums'
      }
    },
    {
      id: 'hidro',
      comp: FormCard,
      compProps: {
        title: 'Hidroģeoloģija'
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'BC')
    },
    {
      id: 'izmantosana',
      comp: FormCard,
      compProps: {
        title: 'Izmantošana'
      }
    }
  ]
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fieldWrappers.push({
      id: 'pielikumi',
      comp: FormCard,
      compProps: {
        title: 'Pielikumi morfoloģiskajam raksturojumam'
      }
    })
  }
  return fieldWrappers
}

const getMorfologijaField = ({name, isDala, kodif, atradne}) => {
  const dala = getDalaFieldCfg(isDala)
  const slanis = { ...getKlasifikatorsFieldCfg(kodif, 'SLANIS'), 
    name: 'slanis'
  }
  const veidi = { 
    name: 'veidi',
    type: 'autocomplete',
    autocompleteCfg: {
      multiple: true,
      keyField: 'klasifikators',
    },
    required: true,
    renderer: veidi => veidi.map(v => v.klasifikators).join(', '),
    overridePropsFunc: (props) => {
      const changedProps = {...props}
      const {currentValRow, currentVal} = props
      let veidi = []
      let izr_veids = ""
      if (currentValRow.slanis) {
        izr_veids = kodif.SLANIS[currentValRow.slanis].izr_veids
        if(izr_veids){
          if(izr_veids === 'IZR_KRAJ' || izr_veids === 'DIZRAKT'){
            //ierobežojam uz atradnes vai daļas izrakteņiem
            let izrakteni = atradne.izrakteni
            if(currentValRow.dala_id && atradne.id !== currentValRow.dala_id){
              // take izrakteni from dala
              const dala = atradne.dalas.find(d => d.id === currentValRow.dala_id)
              if(dala){
                izrakteni = dala.izrakteni
              }
            }
            const atradnesIzrakteni = izrakteni.map(izr => izr.klasifikators)
            if(izr_veids === 'IZR_KRAJ'){
              veidi = Object.keys(kodif.IZR_KRAJ).filter(k => atradnesIzrakteni.indexOf(kodif.IZR_KRAJ[k].derigais) > -1).map(kodifKeyToTypeaheadOption(kodif.IZR_KRAJ))
            }else if (izr_veids === 'DIZRAKT'){
              veidi = Object.keys(kodif.DIZRAKT).filter(k => atradnesIzrakteni.indexOf(k) > -1).map(kodifKeyToTypeaheadOption(kodif.DIZRAKT))
            }
            changedProps.errorForField = veidi.length === 0 ? TEXT_ERR_DERIGIE : changedProps.errorForField
          } else{
            veidi = getDefaultTypeaheadOptionsFromKodifArray(kodif, izr_veids)
          }
        }
        if(currentVal && currentVal.length > 0 ){
          // reset veidi if slanis doesn't match
          const wrongItem = currentVal.find(v => !kodif[izr_veids][v.klasifikators])
          if(wrongItem){
            changedProps.currentVal = []
            changedProps.currentValRow.veidi = []
          } 
        }
      } else {
        changedProps.currentVal = []
        changedProps.currentValRow.veidi = []
      }
      changedProps.extraFieldProps = {
        disabled: veidi.length === 0,
        placeholder: 'Veids',
      }
      changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options : veidi}
      return changedProps
    }
  }
  const kategorija = { ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'), 
    name: 'kategorija',
    cellClassName: 'narrow',
    overridePropsFunc: (props) => {
      const changedProps = {...props}
      const {currentValRow} = props
      let izr_veids = ""
      if (currentValRow.slanis) {
        izr_veids = kodif.SLANIS[currentValRow.slanis].izr_veids
      }
      if(izr_veids !== 'IZR_KRAJ' && izr_veids !== 'DIZRAKT'){
        changedProps.currentVal = null
        changedProps.currentValRow.kategorija = null
        changedProps.extraFieldProps = {
          disabled: true
        }
      } else {
        changedProps.extraFieldProps = {
          disabled: false
        }
      }
      return changedProps
    }
  }

  const laukums = {
    name: 'laukums', 
    cellClassName: 'narrow'
  }

  const piezimes = {
    name: 'piezimes'
  }

  const daudzums = {
    name: 'daudzums',
    customField: (props) => {
      const {currentValRow} = props
      let izr_veids = ""
      let izrMervienibas = []
      if (currentValRow.slanis) {
        izr_veids = kodif.SLANIS[currentValRow.slanis].izr_veids
        if(currentValRow.veidi && currentValRow.veidi.length > 0 ){
          // get mervienibas from veidi
          if(izr_veids === 'IZR_KRAJ' || izr_veids === 'DIZRAKT'){
            izrMervienibas = currentValRow.veidi.flatMap(v => kodif[izr_veids][v.klasifikators].daudzuma_merv).map(m => m.nos)
          } else {
            izrMervienibas = Object.keys(kodif.DAUDZ_MERV)
          }
        }
      }
      return <DaudzumsField 
        {...props} 
        currentValuesObj={currentValRow}
        izrMervienibas={izrMervienibas}
        kodif={kodif}/>
    },
    renderer: getDaudzumsRenderer(),
    cellClassName: 'fixed-200'
  }

  let multiFieldCfgs = []
  let colsToGroup = 0
  if(name === 'morf'){
    multiFieldCfgs = [
      dala, 
      slanis, 
      veidi, 
      kategorija, 
      ...getNoLidzVidFieldCfgs(), 
      laukums, 
      daudzums
    ]
    colsToGroup = 2
  } else if(name === 'morf_kudra'){
    multiFieldCfgs = [
      dala, 
      slanis, 
      ...getKudrasSpecificFields(kodif),
      veidi, 
      kategorija, 
      ...getNoLidzVidFieldCfgs(), 
      {
        name: 'laukums_nulles', 
        cellClassName: 'narrow'
      }, 
      {
        name: 'laukums_rupn', 
        cellClassName: 'narrow'
      },{
        name: 'daudzums_apjoms',
        customField: (props) => {
          const {currentValRow} = props
          let izr_veids = ""
          let izrMervienibas = []
          if (currentValRow.slanis) {
            izr_veids = kodif.SLANIS[currentValRow.slanis].izr_veids
            if(currentValRow.veidi && currentValRow.veidi.length > 0 ){
              // get mervienibas from veidi
              if(izr_veids === 'IZR_KRAJ' || izr_veids === 'DIZRAKT'){
                izrMervienibas = currentValRow.veidi.flatMap(v => kodif[izr_veids][v.klasifikators].daudzuma_merv).map(m => m.nos)
              } else {
                izrMervienibas = Object.keys(kodif.DAUDZ_MERV)
              }
            }
          }
          return <DaudzumsField 
            {...props} 
            mervFieldName='daudzums_apjoms_merv'
            currentValuesObj={currentValRow}
            izrMervienibas={izrMervienibas}
            kodif={kodif}/>
        },
        renderer: getDaudzumsRenderer('daudzums_apjoms_merv')
      },
      daudzums
    ]
    colsToGroup = 4
  } else if(name === 'morf_sapropel'){
    multiFieldCfgs = [
      dala,  
      ...getSapropelisSpecificFields(kodif),
      slanis,
      veidi, 
      kategorija, 
      ...getNoLidzVidFieldCfgs(), 
      laukums, 
      daudzums,
      piezimes
    ]
    colsToGroup = 5
  }

  return {
    name,
    label: undefined,
    formRowProps:{className: 'multifield-form-row'},
    validation: values => validateNoLidzVidFields(name, values),
    multiFieldCfgs,
    multiFieldColsToGroup: colsToGroup,
    sizeLabel: 0,
    sizeForm: 12,
    fieldWrapper: name
  }
}

const getFormFieldCfgs = (isDala = false) => ({kodif, atradne, user}) => {

    const fields = [
      {
        name: 'faili',
        fieldWrapper: 'pielikumi'
      },
      {
        ...getMultiKlasifikatorsFieldCfg(kodif, 'GEOMORF'),
        name: 'geomorf',
        fieldWrapper: 'geologiskais'
      },
      {
        ...getMultiKlasifikatorsFieldCfg(kodif, 'RELJ_MORF'),
        name: 'relj_morf',
        fieldWrapper: 'geologiskais'
      },
      {
        ...getMultiKlasifikatorsFieldCfg(kodif, 'STRAT_IND'),
        name: 'strat_ind',
        fieldWrapper: 'geologiskais',
        isHtmlValue: true,
      },
      { 
        ...getMultiKlasifikatorsFieldCfg(kodif, 'PURVA_IEPL'),
        name: 'purva_ieplaka',
        fieldWrapper: 'geologiskais'
      },
      {
        name: 'hidro_atras_pret_gul',
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          { ...getMultiKlasifikatorsFieldCfg(kodif, 'IZR_KRAJ'),
            name: 'izrakteni_kraj',
            overridePropsFunc: (props) => {
              const changedProps = {...props}
              const {currentValRow} = props
              let izrakteni = atradne.izrakteni
              if(currentValRow.dala_id && atradne.id !== currentValRow.dala_id){
                // take izrakteni from dala
                const dala = atradne.dalas.find(d => d.id === currentValRow.dala_id)
                if(dala){
                  izrakteni = dala.izrakteni
                }
              }
              const atradnesIzrakteni = izrakteni.map(izr => izr.klasifikators)
              const optionsIzr = Object.keys(kodif.IZR_KRAJ).filter(k => atradnesIzrakteni.indexOf(kodif.IZR_KRAJ[k].derigais) > -1).map(kodifKeyToTypeaheadOption(kodif.IZR_KRAJ))
              changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options : optionsIzr}
              changedProps.errorForField = optionsIzr.length === 0 ? TEXT_ERR_DERIGIE : changedProps.errorForField
              return changedProps
            }
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'NOVIETPU'), 
            name: 'novietojums'
          }
        ],
        multiFieldColsToGroup: 1,
        fieldWrapper: 'hidro',
        sizeLabel: 3,
        sizeForm: 9,
      }, {
        name: 'hidro_gruntsudens_lim',
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          {
            name: 'vertiba_no',
            cellClassName: 'narrow',
          },{
            name: 'vertiba_lidz',
            cellClassName: 'narrow',
          }
        ],
        fieldWrapper: 'hidro',
        sizeLabel: 3,
        sizeForm: 9,
      },
      getMorfologijaField({name: 'morf', isDala, kodif, atradne}),
      getMorfologijaField({name: 'morf_kudra', isDala, kodif, atradne}),
      getMorfologijaField({name: 'morf_sapropel', isDala, kodif, atradne})
  ]

  if(!isDala){
    const izmantosanaValidation = (izmFieldName) => (values) => {
      if(values[izmFieldName].filter(izm => !izm.izraktenis || !izm.izmantosana).length > 0){
        return 'Izmantošanā gan izraktenis, gan izmantošanas veids jāaizpilda'
      }
      return null
    }
    fields.push({
      name: 'izmantosana',
      validation: izmantosanaValidation('izmantosana'),
      multiFieldCfgs: [
        { ...getKlasifikatorsFieldCfg(kodif, 'DIZRAKT'), 
            name: 'izraktenis',
            overridePropsFunc: (props) => {
              const changedProps = {...props}
              const atradnesIzrakteni = atradne.izrakteni.map(izr => izr.klasifikators)
              const optionsIzr = Object.keys(kodif.DIZRAKT).filter(k => atradnesIzrakteni.indexOf(k) > -1).map(kodifKeyToTypeaheadOption(kodif.DIZRAKT))
              changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options : optionsIzr}
              return changedProps
            }
        },
        { ...getKlasifikatorsFieldCfg(kodif, 'IZMANTOSANA'), 
            name: 'izmantosana'
        }
      ],
      multiFieldColsToGroup: 1,
      fieldWrapper: 'izmantosana',
      sizeLabel: 3,
      sizeForm: 9,
    })
    fields.push({
      name: 'kartu_izmantosana',
      validation: (values) => {
        if(values['kartu_izmantosana'].filter(izm => !izm.izmantosana).length > 0){
          return 'Izmantošanas veids ir obligāts lauks'
        }
        return null
      },
      multiFieldCfgs: [
        { 
          name: 'slanis',
          type: 'autocomplete',
          autocompleteCfg: {
            options: Object.keys(kodif.SLANIS).filter(k => kodif.SLANIS[k].izr_veids === 'SEGKARTA').map(kodifKeyToTypeaheadOption(kodif.SLANIS))
          }
        },
        { ...getKlasifikatorsFieldCfg(kodif, 'SEGKARTA'), 
          name: 'veidi'
        },
        { ...getKlasifikatorsFieldCfg(kodif, 'IZMANTOSANA'), 
          name: 'izmantosana'
        },
        {
          name: 'piezimes'
        }
      ],
      multiFieldColsToGroup: 2,
      fieldWrapper: 'izmantosana',
      sizeLabel: 3,
      sizeForm: 9,
    })
  }
  return fields;
}
  
class GeologijaComp extends Component {


      render() {
        // initial cfgs can come in as props, or we can create default ones
        const {atradneGeologija, user, getFormFieldCfgsFunc, kodif, veidsField, 
          getFormFieldWrapperCfgsFunc, metadataFormCfg} = this.props

        
        const {selectedItem, editable, submiting} = atradneGeologija
        const atradne = this.props.atradne.selectedItem

        // loading while we have no atradne
        if (!selectedItem || !atradne) {
            return (<Loading/>)
        }

        // we cache form field cfgs
        // reload form fields if atradne changes
        if(!this.formFieldCfgs || atradne.id !== this.prevAtradneId){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs()
            this.formFieldCfgs = f({
                kodif,
                user,
                veidsField,
                atradne
            })
            this.prevAtradneId = atradne.id
        }

        if(!this.formFieldWrapperCfgs){
          const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs
          this.formFieldWrapperCfgs = f({user})
        }

        return <div className="form-container">
                    <Form 
                        editable={editable}
                        submiting={submiting}
                        dataObj={selectedItem}
                        formFieldCfgs={this.formFieldCfgs}
                        metadataFormCfg={metadataFormCfg}
                        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
                        onSubmit={this.props.saveAct}
                        onReset={this.props.stopEditAct}
                        onStartEdit={this.props.startEditAct}
                        formWrapperComp={Row}
                        tiesibasLabot="LABOT_ATRADNES"
                    />
            </div>
    }
}

const mapStateToProps = ({atradneGeologija, atradne, kodif, user}) => ({atradneGeologija, atradne, kodif, user, metadataFormCfg: "atradne_geologija_view"})
const Geologija =  connect(mapStateToProps, {startEditAct:geologijaActions.startEditAct, stopEditAct:geologijaActions.stopEditAct, 
  saveAct:geologijaActions.saveAct})(GeologijaComp)
export default Geologija

const mapStateToPropsDala = ({atradnesDalaGeologija, atradnesDala, kodif, user}) => ({atradneGeologija:atradnesDalaGeologija, atradne: atradnesDala, kodif, user, 
  metadataFormCfg: "atradnes_dala_geologija_view", veidsField:'atradnes_veids', getFormFieldCfgsFunc: getFormFieldCfgs(true)})
export const GeologijaAtradnesDala =  connect(mapStateToPropsDala, {startEditAct:dalaGeologijaActions.startEditAct, stopEditAct:dalaGeologijaActions.stopEditAct, 
  saveAct:dalaGeologijaActions.saveAct})(GeologijaComp)
