import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    // getDateFilterCfg,
    // dateTimeFormatter,
    // dateFormatter,
    getNumberFilterCfg,
    getEditIconColumn,
    getBadgeFormatterForKodif,
    //getFormatterForKodif
} from '../grid/ColumnHelpers';
import {getDefaultRendererForMultiField} from '../forms/FormHelpers';
import Link from 'redux-first-router-link';
import { Button } from 'reactstrap';
// import {guiSet} from '../../actions/uiActions';
import ArchivedParskatsFilterToUrl from '../grid/ArchivedParskatsFilter';
import {parskatsSelected, openParskatsWithFilterFromList} from '../../actions/geofondActions';

import Grid from '../grid/Grid'

const editIconColumn = getEditIconColumn()
editIconColumn.formatter = (cellContent, row) => {
  return (
      <Link to={{
        type: 'loc_parskats_from_list',
        payload: {
            id: `filter${row.query.offset}`,
            inv_numurs: row.inv_numurs
        },
        query : row.query
      }}>
          <Button color="secondary" size="sm">
              <i className="fa fa-file-text-o"></i>
          </Button>
      </Link>
  )
}

const getColumns = ({kodif, user}) => {
  return [
    editIconColumn, {
      dataField: 'inv_numurs',
      text: 'Inv Num',
      width: 90,
      filterCfg: getTextFilterCfg()
  }, {
      dataField: 'virsraksts',
      text: 'Virsraksts',
      filterCfg: getTextFilterCfg(),
//      width: 300,
  }, {
    dataField: 'organizacijas',
    text: 'Organizācija',
    filterCfg: getTextFilterCfg(),
    width: 200,
  }, {
    dataField: 'autori',
    text: 'Autori',
    filterCfg: getSelectFilterCfg(kodif.PAUTORS),
    formatter: getBadgeFormatterForKodif(kodif.PAUTORS),
    width: 110,
  }, {
    dataField: 'rubrikas',
    text: 'Rubrikas',
    filterCfg: {
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'rubrikas_search',
      }
    },
    //formatter: getFormatterForKodif(kodif.PRUBRIKA),
    //formatter: (kods, row) => kods !== '-1' ? `${kods} - ${row.rubrika_label}` : null,
    renderer: getDefaultRendererForMultiField('tah_label'),
    width: 300,
  }, {
    dataField: 'gads',
    text: 'Gads',
    filterCfg: getNumberFilterCfg(),
    width: 100
  }, {
    dataField: 'teritorijas',
    text: 'Teritorija',
    filterCfg: getSelectFilterCfg(kodif.PTERITORIJA),
    formatter: getBadgeFormatterForKodif(kodif.PTERITORIJA),
    width: 100,
  }, {
    dataField: 'valoda',
    text: 'Valoda',
    width: 80
  }, {
    dataField: 'pielikumi',
    text: 'Piel.',
    width: 60,
    formatter: (pielikumi, row) => pielikumi > 0 ? 'Ir' : '',
  },
  //  {
  //   dataField: 'pamatvardi',
  //   text: 'Pamatvārdi',
  //   filterCfg: getTextFilterCfg()
  // },
]
};

//   {
//     dataField: 'autori',
//     text: 'Autori',
//     width: 100,
//     formatter: (value, row) => {
//       return [value, row.autors].join(' ')
//     } }


class GeofondParskatsList extends Component {

    rowEvents = {
        onDoubleClick: (e, row) => {
          this.props.openParskatsWithFilterFromList(row.query, row.inv_numurs)
        }
    }

    render() {

        const {kodif, parskati, parskatsSelected, user} = this.props
        const {list, selected, loading, count} = parskati

        if(!this.columns){
            this.columns = getColumns({
                kodif,
                user
            })
        }

        return (
            <div className="saraksts-wrapper">
            <Grid
                onRowSelected={parskatsSelected}
                loading={loading}
                selectedId={selected}
                dataList={list}
                totalCount={count}
                columns={this.columns}
                rowEvents={this.rowEvents}
            >
                { this.props.user.tiesibas.includes('PARSKATS_LABOT') ? (
                    <Link to={{ type: 'loc_parskats_from_list',
                        payload: {
                            id: 'jauns'
                        }
                    }}>
                        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns pārskats</Button>
                    </Link>
                ) : null }
                <ArchivedParskatsFilterToUrl/>
            </Grid>
            </div>
        )
    }
}

const mapStateToProps = ({kodif, parskati, user}) => {
    return {kodif, parskati, user}
}

export default connect(mapStateToProps, {parskatsSelected, openParskatsWithFilterFromList})(GeofondParskatsList)
