export const flash = (state = {}, { type, message }) => {
    // store flash message
    if (type === 'flash') {
        return { message, seen: false }
    }
    if (type === 'redirect') {
        const { seen } = state
        // clear flash message on second redirect
        if (seen)
            return {}
        // after first redirect keep flash message
        return {
            ...state,
            seen: true
        }
    }
    return state
}

export const errors = (state = {}, { type, error }) => {
    if (type === 'redirect' || type === 'clear_errors')
        return {}

    // skip specific actions
    if ((type === 'licence_view_get_failure' || type === 'limits_view_get_failure' || type === 'izmaina_view_grouped_save_failure') && error && error.status === 404) {
        return state
    }

    const pattern = /_([^_]*)$/
    const match = type.match(pattern, '')
    // get 'request' from 'action_name_request'
    const status = (match)
        ? match[1]
        : undefined
    // get 'action_name' from 'action_name_request'
    const name = type.replace(pattern, '')

    switch (status) {
        case 'request':
        case 'success':
            return {
                ...state,
                [name]: undefined
            }
        case 'failure':
            return {
                ...state,
                [name]: error
            }
        default:
            return state
    }
}

const guiInitialState = {
    saraksts_karte_height: 50, // splitter location
    atradne_karte_width: 35,
    atradne_karte_hor_height: 36,
    atradnes_saraksts_karte_size: [1200, 400], // current map size for bbox requests
    parskats_karte_width: 35,
    parskats_karte_hor_height: 35,
    parskats_saraksts_karte_size: [1200, 400],
    pases_saraksts_karte_size: [1200, 400], // current map size for bbox requests
    limits_saraksts_karte_size: [1200, 400],
    limitu_atlikumi_saraksts_karte_size: [1200, 400],
    licence_saraksts_karte_size: [1200, 400],
    krajumu_saraksts_karte_size: [1200, 400],
    izmainu_saraksts_karte_size: [1200, 400],
    komersanta_izmainu_saraksts_karte_size: [1200, 400],
    bilance_karte_size: [1200, 400],
    udeni_saraksts_karte_size: [1200, 400], // current map size for bbox requests
    urbumi_saraksts_karte_size: [1200, 400],
    urbumu_atradnes_saraksts_karte_size: [1200, 400],
    urbumu_ieguves_saraksts_karte_size: [1200, 400],
    vnk_iesniegumi_saraksts_karte_size: [1200, 400],
    skersprofili_saraksts_karte_size: [1200, 400],
    notekudeni_saraksts_karte_size: [1200, 400],
    dzerama_udens_sateces_baseini_saraksts_karte_size: [1200, 400],
    klasifikatori_form_width: 40,
    adresati_form_width: 40,
    lietotaji_form_width: 30,
    raditaji_kimiska_form_width: 40,
    parskats_saraksts_karte_height: 50,
    pase_saraksts_karte_height: 50, // splitter location
    limits_saraksts_karte_height: 50, // splitter location
    limitu_atlikumi_saraksts_karte_height: 50, // splitter location
    licence_saraksts_karte_height: 50, // splitter location
    krajumu_saraksts_karte_height: 50,
    izmainu_saraksts_karte_height: 50,
    komersanta_izmainu_saraksts_karte_height: 30,
    bilance_karte_height: 50,
    udeni_saraksts_karte_height: 50,
    urbumi_saraksts_karte_height: 50,
    urbumu_atradnes_saraksts_karte_height: 50,
    urbumu_ieguves_saraksts_karte_height: 50,
    vnk_iesniegumi_saraksts_karte_height: 50,
    showCoordinateTable: false,
    pases_kartina_karte_width: 40,
    limita_kartina_karte_width: 35,
    licences_kartina_karte_width: 35,
    krajuma_kartina_karte_width: 40,
    urbums_kartina_karte_width: 40,
    dokumentacija_karte_height: 35,
    urbumu_atradne_kartina_karte_width: 40,
    urbumu_atradne_kartina_karte_height: 40,
    urbumu_ieguve_kartina_karte_width: 40,
    urbumu_ieguve_kartina_karte_height: 40,
    vnk_iesniegums_karte_width: 40,
    vnk_iesniegums_karte_height: 35,
    skersprofili_saraksts_karte_height: 40,
    notekudeni_saraksts_karte_height: 40,
    troksnu_karte_saraksts_karte_height: 40,
    dzerama_udens_sateces_baseini_saraksts_karte_width: 40,
    dzerama_udens_sateces_baseini_saraksts_karte_height: 40
}

// { gui_set, { element: saraksts_karte_height, value: 60 } } { gui_unset, {
// element: saraksts_karte_height } }

export const gui = (state = guiInitialState, action) => {
    switch (action.type) {
        case 'gui_set':
            return {
                ...state,
                [action.element]: (action.value === undefined
                    ? true
                    : action.value)
            }
        case 'gui_unset':
            return {
                ...state,
                [action.element]: false
            }
        default:
            return state
    }

}

export const metadata = (state = { requested: false, loaded: false }, { type, payload }) => {
    switch (type) {
        case 'metadata_request':
            return { ...state, requested: true }
        case 'metadata_success':
            return { ...state, ...payload, loaded: true }
        default:
            return state
    }
}

export const userApi = (state = { requested: false, loaded: false }, { type, payload }) => {
    switch (type) {
        case 'userApi_request':
            return { ...state, requested: true }
        case 'userApi_success':
            return { ...state, ...payload, loaded: true }
        default:
            return state
    }
}
