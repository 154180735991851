import React, {Component} from 'react';
import {connect} from 'react-redux'
import {urbumiActions} from '../../actions/hidrogeoActions'
import {DokumentacijasKartina} from '../dokumentacija/common'
import Link from "redux-first-router-link";
import {Button} from "reactstrap";
import {getBadgeFormatterForKodif} from "../grid/ColumnHelpers";
import BootstrapTable from 'react-bootstrap-table-next';
import { format } from 'date-fns';

const  getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'id',
      renderClassName: "bold",
    },
    {
      name: 'ierikosanas_gads'
    },
    {
      name: 'urbsanas_dzilums'
    },
    {
      name: 'horizonti',
      label: null,
      sizeForm: { size: 'auto' },
      renderer: (horizonti) => {
        const columns = [{
            dataField: 'udens_nesejslanis',
            text: 'Ūdens nesējslānis',
            headerClasses: "bold italic"
          }, {
            dataField: 'atsuknesanas_beigu_datums',
            text: 'Atsūknēšanas beigu datums',
            headerClasses: "bold italic",
            formatter: (cellContent) => format(cellContent, 'dd.MM.yyyy')
          }, {
            dataField: 'paraugu_nonemsanas_intervals_no',
            text: 'Parauga noņemšanas intervāls no, m',
            headerClasses: "bold italic"
          }, {
            dataField: 'paraugu_nonemsanas_intervals_lidz',
            text: 'Parauga noņemšanas intervāls līdz, m',
            headerClasses: "bold italic"
          }, {
            dataField: 'urbuma_statiskais_limenis',
            text: 'Statiskais līmenis, m no zemes virsmas',
            headerClasses: "bold italic"
          }, {
            dataField: 'urbuma_debits',
            text: 'Debits, l/s',
            headerClasses: "bold italic"
          }, {
            dataField: 'urbuma_max_pazeminajums',
            text: 'Pazeminājums, m no zemes virsmas',
            headerClasses: "bold italic"
          }
        ];

        return <BootstrapTable
          bootstrap4
          striped
          hover
          keyField="udens_nesejslanis"
          classes='w-auto'
          data={horizonti}
          columns={columns}
        />
      }
    },
    {
      name: 'tehniskais_stavoklis',
      renderer: getBadgeFormatterForKodif(kodif.urbumiTehniskaisStavoklis)
    },
    {
      name: 'urbuma_sakotnejais_izm_merkis'
    },
    {
      name: 'urbuma_faktiskais_izm_merkis'
    },
    {
      name: 'atradne_nosaukums',
      renderer: (nos, row) => (
          row.atradne_id ?
            <Link className="float-left" to={{
              type: 'loc_urbumu_atradne',
              payload: {
                id: row.atradne_id
              }
            }}>
              <Button color="primary" className="float-left">{row.atradne_id} - {nos}</Button>
            </Link> : 'Nav noteikts'
    )
    },
    {
      name: 'adrese'
    },
    {
      name: 'kad_kadastra_apzimejumi',
      editable: false
    },
    {
      name: 'ter_iedalijums_wfs',
      label: 'Administratīvā teritorija',
      editable: false,
      renderer: (allAdm, vals) => {
        // group by limenis_1
        const grouped = allAdm.reduce((grouped, adm) => {
          if(!grouped[adm.limenis_1]) {
            grouped[adm.limenis_1] = []
          }
          if(adm.limenis_2){
            grouped[adm.limenis_1].push(adm.limenis_2)
          }
          return grouped
        }, {})
        return <>
          {Object.keys(grouped).map(adm => <p key={adm} className="links">{adm}{grouped[adm].length > 0 ? ' - ' + grouped[adm].join('; ') : null}</p>)}
          {vals.urbumi_ter_iedalijums_wfs_kluda ? <p className="danger links">{vals.urbumi_ter_iedalijums_wfs_kluda}</p> : null}
        </>
      }
    }
  ]
}

class UrbumsInfo extends Component {

  render() {

    const {urbums, kodif, koordSelectAct, koordChangeAct} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: kodif
      })
    }

    return <DokumentacijasKartina
      geomObj={urbums}
      formFieldCfgs={this.formFieldCfgs}
      koordSelectAct={koordSelectAct}
      koordChangeAct={koordChangeAct}
      karteWidthProp='urbums_kartina_karte_width'
      mapId='urbums_kartina'
      editableLayerIdName='urbums_koord'
      layerTitles={{
        number_label: 'DB Numurs',
        feat_border: 'Urbuma robeža',
        feat: 'Urbums',
        koord: 'Urbuma robežpunkti',
        koord_labels: 'Urbuma robežpunktu numuri',
      }}
      metadataFormCfg="urbumi_view"
      isPointGeometry
    />
  }
}

const mapStateToProps = ({urbums, kodif}) => ({urbums, kodif})
export default connect(mapStateToProps, {...urbumiActions})(UrbumsInfo)
