import React, {Component, useState} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'

import Form from '../forms/Form'

import {pazemesUdensObjektsAtrasanasActions} from '../../actions/pazemesUdensObjektiActions'
import {showErrorMsg} from '../../actions/uiActions'
import _ from "lodash"
import {getDefaultRendererForMultiField} from "../forms/FormHelpers";

const TerIedalijumsRenderer = ({allAdm, vals}) => {
    const [showMore, setShowMore] = useState(false)
    // group by limenis_1
    const grouped = allAdm.reduce((grouped, adm) => {
        if(!grouped[adm.limenis_1]) {
            grouped[adm.limenis_1] = []
        }
        if(adm.limenis_2){
            grouped[adm.limenis_1].push(adm.limenis_2)
        }
        return grouped
    }, {})
    const makeAdmToHtml = (admGrouped) => {
        return<tr key={admGrouped.join(' ')}>
            {admGrouped.map(adm => <td key={adm} className="links">{adm}</td>)}
        </tr>
    }
    const isObjectBig = Object.keys(grouped).length > 10
    const groupedKeys = Object.keys(grouped)
    return <>
        <table className="table react-bootstrap-table table-striped">
            <tbody>
                {showMore && isObjectBig ? _.chunk(groupedKeys, 5).map(adm => makeAdmToHtml(adm)) : _.chunk(groupedKeys.slice(0,10), 5).map(adm => makeAdmToHtml(adm))}
            </tbody>
        </table>
        {vals.atradne_ter_iedalijums_wfs_kluda ? <p className="danger links">{vals.atradne_ter_iedalijums_wfs_kluda}</p> : null}
        {isObjectBig  ? <button className="btn btn-primary" color="secondary" onClick={(e) => {e.preventDefault(); setShowMore(!showMore)} }>{showMore ? "Rādīt mazāk" : "Rādīt vairāk"}</button> : null}
    </>
}

export const  getFormFieldCfgs = () => {
  return [
     {
      name: 'ter_iedalijums_wfs',
      label: 'Administratīvā teritorija',
      editable: false,
      fieldNotes: 'Administratīvās un teritoriālās vienības tiek aizpildītas automātiski no WFS servisa.',
      renderer: (allAdm, vals) => <TerIedalijumsRenderer allAdm={allAdm} vals={vals}/>
    },
    {
      name: 'iadt',
      label: 'Īpaši aizsargājamas dabas teritorijas',
      editable: false,
      renderer: getDefaultRendererForMultiField('iadt_nosaukums'),
    }
  ]
}

class AtrasanasComp extends Component {

      render() {
        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, kodif, pazemesUdensObjektsAtrasanas} = this.props

        const {selectedItem, editable, submiting} = pazemesUdensObjektsAtrasanas

        // loading while we have no atradne
        if (!selectedItem) {
            return (<Loading/>)
        }

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs
            this.formFieldCfgs = f({
                user,
                kodif
            })
        }

        return <div className="form-with-grid-container-scrollable">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={selectedItem}
                        formFieldCfgs={this.formFieldCfgs}
                        onSubmit={this.props.saveAct}
                        onReset={this.props.stopEditAct}
                        onStartEdit={this.props.startEditAct}
                        tiesibasLabot="BAIS_PU_LABOT"
                    />
            </div>
    }
}

const mapStateToProps = ({pazemesUdensObjektsAtrasanas, kodif, user}) => ({pazemesUdensObjektsAtrasanas, kodif, user})
const PazemesAtrasanas =  connect(mapStateToProps, {...pazemesUdensObjektsAtrasanasActions, showErrorMsg})(AtrasanasComp)
export default PazemesAtrasanas
