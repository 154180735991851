import React, {Component} from 'react';
import {connect} from 'react-redux'
import {paraugsActions} from '../../actions/hidrogeoActions'
import Loading from '../Loading'
import Form from '../forms/Form'
import BootstrapTable from 'react-bootstrap-table-next'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import {dateFormatter, getDeleteIconColumn, getEditIconColumn, getNumberFormatter} from '../grid/ColumnHelpers'
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import TypeaheadField from "../forms/TypeaheadField";
import {Button} from "reactstrap";
import {listParaugsDefaultValues, listParaugsCietibaValues} from "../../api";
import {isEmptyValue, getDefaultRendererForMultiField} from '../forms/FormHelpers'

const editIconColumn = getEditIconColumn((row) => (
        {
            type: 'loc_merijums',
            payload: {
                id: row.id,
                numurs: row.urbumi_id
            }
        }
    )
)

class UrbumaParaugs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id : null,
            loading : true,
            submiting : false,
            editable : false,
            urbumi_id : null,
            parauga_numurs : null,
            horizont_id : null,
            horizont_geol_indekss : null,
            avots : null,
            tips : null,
            vertikale : null,
            horizontale : null,
            analizes_datums : null,
            datums : null,
            created : null,
            updated : null,
            usr : null,
            merijumi: []
        }
        this.state.columns = this.getColumns(this.state.editable)
        this.is_mounted = false
    }

    componentDidMount() {
        this.is_mounted = true
        if (this.props.payload && this.props.payload.id === 'jauns' ) {
            this.setDefaultValues(this.props.payload)
        }
    }

    componentWillUnmount () {
        this.is_mounted = false
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.urbuma_paraugs.selectedItem && this.props.urbuma_paraugs.selectedItem && this.is_mounted && this.props.payload.id !== 'jauns') {
            this.setState(() => {
                return {...this.props.urbuma_paraugs.selectedItem, editable: this.props.urbuma_paraugs.editable, submiting: this.props.urbuma_paraugs.submiting, loading: false, payload: this.props.payload}
            })
        }
        if ((prevProps.urbuma_paraugs.editable !== this.props.urbuma_paraugs.editable || prevProps.urbuma_paraugs.submiting !== this.props.urbuma_paraugs.submiting) && this.is_mounted) {
            const columns = this.getColumns(this.props.urbuma_paraugs.editable)
            this.setState((state) => {
                return {...this.state, columns: columns, editable: this.props.urbuma_paraugs.editable, submiting: this.props.urbuma_paraugs.submiting}
            })
        }
        if (prevProps.urbuma_paraugs.submiting !== this.props.urbuma_paraugs.submiting && this.props.urbuma_paraugs.submiting === false) {
            this.setState((state) => {
                return {...this.props.urbuma_paraugs.selectedItem, editable: this.props.urbuma_paraugs.editable, submiting: this.props.urbuma_paraugs.submiting}
            })
        }
    }


    setDefaultValues = (payload) => {
        this.setState(() => ({ loading: true }))
        let b = listParaugsDefaultValues(payload.numurs)
        let c = listParaugsCietibaValues(payload.numurs)
        Promise.all([b,c]).then( (res) => {
            this.setState((state) => {
                return {
                    ...state,
                    loading: false,
                    urbumi_id: payload.numurs,
                    merijumi: res.flat().map(obj=> ({ ...obj, karodzini: [] }))
                }
            })
        })
    }

    setRaditajsValue = (rowIndex) => (field, value) => {
        if (value) {
            this.setState((state) => {
                return {
                    ...state,
                    merijumi: state.merijumi.map((item, index) => {
                        if (index !== rowIndex) {
                            return item
                        }
                        return {
                            ...item,
                            raditaja_id: value.raditaja_id,
                            raditaja_pilns_nosaukums: value.tah_label
                        }
                    })
                }
            })
        }
    }

    setKarodziniValue = (rowIndex) => (field, value) => {
        if (value) {
            this.setState((state) => {
                return {
                    ...state,
                    merijumi: state.merijumi.map((item, index) => {
                        if (index !== rowIndex) {
                            return item
                        }
                        return {
                            ...item,
                            karodzini: value
                        }
                    })
                }
            })
        }
    }

    raditajsTypeheadCfg = (value, rowIndex) => {
        return {
            fieldName: 'raditaja_id',
            labelFieldName: 'raditaja_pilns_nosaukums',
            type: 'autocomplete',
            // currentVal: {raditaja_pilns_nosaukums: value},
            autocompleteCfg: {
                keyField: 'raditaja_id',
                labelFieldName: 'raditaja_pilns_nosaukums',
                remoteSearchViewName: 'urbuma_raditajs_search',
                isValueObjStructure: true
            },
            setFieldValue: this.setRaditajsValue(rowIndex),
            extraFieldProps: {placeholder: value}
        }
    }

    karodziniTypeheadCfg = (value, rowIndex) => {
        return {
            fieldName: 'id',
            labelFieldName: 'karodzina_kods',
            type: 'autocomplete',
            currentVal: [...value],
            autocompleteCfg: {
                multiple: true,
                keyField: 'karodzina_id',
                remoteSearchViewName: 'urbuma_vvis_karodzini_search',
                isValueObjStructure: true
            },
            setFieldValue: this.setKarodziniValue(rowIndex)
        }
    }

    getColumns = (isEditable) => {
        const columns = [editIconColumn, {
            dataField: 'raditaja_pilns_nosaukums',
            text: 'Rādītāja nosaukums',
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <TypeaheadField
                    {...this.raditajsTypeheadCfg(value, rowIndex)}
                />
            ),
            editable: isEditable,
            width: 200
        },{
            dataField: 'vertiba',
            text: 'Mērijuma vērtība',
            formatter: getNumberFormatter(),
            type: 'number',
            editable: isEditable,
            width: 100
        },{
            dataField: 'standart_rad_pilns_nosaukums',
            text: 'Standartmērvienības rādītājs',
            editable: false,
            width: 100
        },{
            dataField: 'standart_rad_vertiba_trimmed',
            text: 'Mērijuma vērtība standartmērvienībās',
            formatter: getNumberFormatter(),
            type: 'number',
            editable: false,
            width: 100
        },{
            dataField: 'standart_rad_mervieniba',
            text: 'Standartmērvienības',
            editable: false,
            width: 100
        }, {
            dataField: 'merijuma_datums',
            text: 'Mērijuma datums',
            formatter: (cell) => {
                let dateObj = cell;
                if (cell !== null) {
                    dateObj = new Date(cell);
                    return `${dateObj.getUTCFullYear()}.${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}.${('0' + dateObj.getUTCDate()).slice(-2)}`;
                } else {
                    return ''
                }
            },
            editor: {
                type: Type.DATE
            },
            editable: isEditable,
            width: 100
        } ,{
                dataField: 'karodzini',
                text: 'Karodziņi',
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                    <TypeaheadField
                        {...this.karodziniTypeheadCfg(value, rowIndex)}
                    />
                ),
                formatter: getDefaultRendererForMultiField("tah_label"),
                editable: isEditable,
                width: 100
        }, getDeleteIconColumn(this.deleteRow)
        ]
        return columns.map( col=> columnCfgToBootstrapTableColumn(col))
    }

    changePropsHandleBlur = (props) => {
        const changedProps = {...props}
        changedProps.handleBlur = (obj) => {
            const name = obj.target.name
            const value = obj.target.value
            this.setState( (state) => {
                state[name] = value
                console.log(state)
                return state
            })
        }
        return changedProps
    }

    changePropsHandleChange = (props) => {
        const changedProps = {...props}
        changedProps.handleChange = (obj) => {
            const name = obj.target.name
            const value = obj.target.value
            this.setState( (state) => {
                state[name] = value
                console.log(state)
                return state
            })
        }
        return changedProps
    }

    defaultSetFieldValue = (fieldName, value, options) => {
        this.setState( (state) => {
            state[fieldName] = value
            return state
        })
    }

    setHorizontsValue = (fieldName, value, options) => {
        this.setState(
            {
                horizont_id: value.horizont_id,
                horizont_geol_indekss: value.tah_label,
                vertikale: value.vertikale,
                horizontale: value.horizontale
            })
    }

    changePropsSetFieldValue = (newSetFieldValue = this.defaultSetFieldValue) => (props) => {
        const changedProps = {...props}
        changedProps.setFieldValue = newSetFieldValue
        return changedProps
    }

    horizontTypeheadCfg = (value) => {
        return {
            fieldName: 'horizont_id',
            type: 'autocomplete',
            autocompleteCfg: {
                keyField: 'horizont_id',
                initialOptionsViewName: 'urbuma_horizonts_search',
                extraFilterParams : {numurs: this.state.urbumi_id},
                isValueObjStructure: true
            },
            setFieldValue: this.setHorizontsValue,
            extraFieldProps: {placeholder: value}
        }
    }

    getFormFieldCfgs = () => {
        return [
            { name: 'id', renderClassName: "bold", editable: false, fieldEditable: false},
            { name: 'urbumi_id', renderClassName: "bold", editable: false, fieldEditable: false,
                validation: (values) => {
                    if(isEmptyValue(values.urbumi_id)){
                        return 'Obligāts lauks'
                    }
                    return null
                }},
            {   name: 'parauga_numurs',
                validation: (values) => {
                    if(isEmptyValue(values.parauga_numurs)){
                        return 'Obligāts lauks'
                    }
                    return null
                },
                overridePropsFunc: this.changePropsHandleBlur
            },
            { name: 'horizont_geol_indekss', label: 'Parauga horizonta kods',
                editable: true,
                customField: (props) => {
                    const {currentVal} = props
                    return <TypeaheadField
                            {...this.horizontTypeheadCfg(currentVal)}
                            />
                }
            },
            { name: 'avots', overridePropsFunc: this.changePropsHandleBlur},
            { name: 'analizes_datums', renderer: dateFormatter, overridePropsFunc: this.changePropsSetFieldValue()},
            { name: 'tips', overridePropsFunc: this.changePropsHandleBlur},
            { name: 'vertikale', overridePropsFunc: this.changePropsHandleBlur},
            { name: 'horizontale', overridePropsFunc: this.changePropsHandleBlur}
        ]
    }

    deleteRow = (row) => {
        if (this.state.editable === true) {
            this.setState((state) => {
                return {...state, merijumi: state.merijumi.filter(stateRow => stateRow.id !== row.id)}
            })
        }
    }

    render() {

        const {saveAct, stopEditAct, startEditAct, deleteAct} = this.props

        if(!this.formFieldCfgs){
            this.formFieldCfgs = this.getFormFieldCfgs()
        }

        if (this.state.loading) {
            return (<Loading/>)
        }

        const addRow = () => {
            if (this.state.editable === true) {
                this.setState((state) => {
                    // let obj = {...state.merijumi[0]}
                    let obj = {
                        id: null,
                        raditaja_pilns_nosaukums: "",
                        urbumi_id: state.urbumi_id,
                        parauga_id: null,
                        vertiba: "",
                        merijuma_datums: new Date(),
                        raditaja_id: null,
                        karodzini: []
                    }
                    // Object.keys(obj).forEach(k => {
                    //     if (k === "id") {
                    //         obj[k] = null
                    //     } else
                    //         obj[k] = ""})
                    return {...state, merijumi: [obj, ...state.merijumi]}
                })
            }
        }

        const onTableChange = (type, newState) => {
            if (newState.cellEdit.dataField !== "raditaja_pilns_nosaukums" || newState.cellEdit.dataField !== "karodzini") {
                this.setState((state) => {
                    return {
                        ...state,
                        merijumi: state.merijumi.map((item) => {
                            if (item.raditaja_id === newState.cellEdit.rowId) {
                                item[newState.cellEdit.dataField] = newState.cellEdit.newValue
                            }
                            return item
                        })
                    }
                })
            }
        }

        return (
            <div className="form-pane-scrollable">
                <Form
                    editable={this.state.editable}
                    submiting={this.state.submiting}
                    dataObj={this.state}
                    formFieldCfgs={this.formFieldCfgs}
                    metadataFormCfg="urbuma_paraugs_edit"
                    onSubmit={saveAct}
                    onReset={stopEditAct}
                    onStartEdit={startEditAct}
                    onDelete={deleteAct}
                    tiesibasLabot="URBUMI"
                />
                <h4>Parauga mērijumi</h4>
                <Button color="primary" onClick={() => addRow()} disabled={!this.state.editable}><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Pievienot mērijumu</Button>
                <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    remote={{cellEdit: true}}
                    loading = {this.state.loading}
                    keyField="raditaja_id"
                    data={ this.state.merijumi }
                    columns={ this.state.columns }
                    cellEdit={ cellEditFactory({
                        mode: 'click',
                        blurToSave: true
                    })}
                    selectRow={{
                        mode: 'radio',
                        // clickToSelect: true,
                        clickToEdit: true,
                        // hideSelectColumn: true,
                        classes: 'selection-row',
                        // onSelect: this.handleOnSelect,
                        // selected: selectedUrbums ? [selectedUrbums] : []
                    }}
                    noDataIndication = { () => <Loading/>}
                    onTableChange = {onTableChange}
                />
            </div>
        )
    }
}

const mapStateToProps = ({urbuma_paraugs, location}) => ({urbuma_paraugs, payload: location.payload})
export default connect(mapStateToProps, {...paraugsActions})(UrbumaParaugs)