import React, {Component} from 'react'
import {connect} from 'react-redux'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {upjuBaseinuApgabalsSelected, upjuBaseinuApgabalsDoubleSelected} from '../../actions/upjuBaseinuApgabaliActions'

import Grid from '../grid/Grid'

const getColumns = () => {
  return [{
    dataField: 'nosaukums',
    text: 'Nosaukums',
    // width: 235,
    // filterCfg: getTextFilterCfg()
  }]
};

class UpjuBaseinuApgabali extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSearch: false
        };
    }

    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    }

    mapPaneSizeChange = (h) => {
        this.props.guiSet('upju_baseinu_apgabali_saraksts_karte_height', h);
    }

    rowEvents = {
    }

    render() {
        const vectorLayerCfgs = {
          upjuBaseinuApgabali : {
                title: 'Upju baseinu apgabali',
                features: this.props.upjuBaseinuApgabali.list,
                styles: maputils.uoStyles.ubaStyle,
                tooltipFunc: (feat) => feat.get('nosaukums'),
                selected: this.props.upjuBaseinuApgabali.selected,
                legend: maputils.defaultSinglePolygonLegend
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={this.props.gui.udeni_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <Karte mapId="upju_baseinu_apgabali_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={this.props.upjuBaseinuApgabalsSelected}
                    loading={this.props.upjuBaseinuApgabali.loading}
                    selectedId={this.props.upjuBaseinuApgabali.selected}
                    dataList={this.props.upjuBaseinuApgabali.list}
                    totalCount={this.props.upjuBaseinuApgabali.count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, upjuBaseinuApgabali, gui, user}) => {
    return {kodif, upjuBaseinuApgabali, gui, user}
}

export default connect(mapStateToProps, {upjuBaseinuApgabalsSelected, guiSet, upjuBaseinuApgabalsDoubleSelected})(UpjuBaseinuApgabali)
