import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    dateFormatter,
    getBooleanFormatter,
    getTextFilterCfg,
    getDateFilterCfg,
    getBadgeFormatterForKodif,
    getSelectFilterCfg,
    getNumberFilterCfg
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import { guiSet } from '../../actions/uiActions'
import { limituAtlikumiSelected, openDok } from '../../actions/dokumentacijaActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import { getCommonColumns, getDoubleClickHandler, AtradneOrDalaLink, getLinkParams } from './common'
import ManiObjektiButton from '../grid/ManiObjektiButton'
import Link from 'redux-first-router-link'
import { round } from '../../utils'

const booleanFormatter = getBooleanFormatter(true)

const getColumns = ({ kodif, user }) => {
    const commonColumns = getCommonColumns({ kodif, user, locijums: 'M' })
    return [
        {
            ...commonColumns.find(c => c.dataField === 'ir_parbauditi'),
            formatter: (irParbauditi, row) => {
                if (row.izmainu_grupa_id) {
                    return <Link to={getLinkParams({
                        loc: 'loc_atradnes_izmaina_grouped',
                        locDala: 'loc_atradnes_dala_izmaina_grouped',
                        extraPayload: { id: row.izmainu_grupa_id },
                        numurs: row.atr_numurs,
                        isDala: row.atr_ieraksta_veids === 'D',
                        atrId: row.atradne_id
                    })}>{booleanFormatter(irParbauditi)}</Link>
                } else {
                    return <Link to={getLinkParams({
                        loc: 'loc_atradnes_limits',
                        locDala: 'loc_atradnes_dala_limits',
                        extraPayload: { id: row.limits_id },
                        numurs: row.atr_numurs,
                        isDala: row.atr_ieraksta_veids === 'D',
                        atrId: row.atradne_id
                    })}>{booleanFormatter(irParbauditi)}</Link>
                }
            }
        },
        {
            dataField: 'atr_numurs',
            text: 'Atradne vai daļa',
            formatter: (numurs, row) => <AtradneOrDalaLink
                id={row.atradne_id}
                numurs={numurs}
                ieraksta_veids={row.atr_ieraksta_veids}
                nosaukums={row.atr_label}
                kodif={kodif}
                query={{ tab: 'izmainas' }}
            />,
            width: 156,
            filterCfg: {
                type: 'autocomplete',
                autocompleteCfg: {
                    remoteSearchViewName: 'atradne_search'
                }
            },
            sort: false
        },
        {
            ...commonColumns.find(c => c.dataField === 'adresats_nosaukums'),
            text: 'Limita adresāts',
            formatter: (adresats, row) => adresats ? <Link to={getLinkParams({
                loc: 'loc_atradnes_limits',
                locDala: 'loc_atradnes_dala_limits',
                extraPayload: { id: row.limits_id },
                numurs: row.atr_numurs,
                isDala: row.atr_ieraksta_veids === 'D',
                atrId: row.atradne_id
            })}>{adresats}</Link> : null
        },
        {
            ...commonColumns.find(c => c.dataField === 'izsniegts_datums'),
            text: 'Limits derīgs no',
        },
        {
            ...commonColumns.find(c => c.dataField === 'derigs_lidz_datums'),
            text: 'Limits derīgs līdz',
        },
        {
            ...commonColumns.find(c => c.dataField === 'anulets_datums'),
            text: 'Limita anulēšanas datums',
        },
        {
            ...commonColumns.find(c => c.dataField === 'ir_derigs'),
            text: 'Limits derīgs',
        },
        {
            ...commonColumns.find(c => c.dataField === 'izrakteni'),
            dataField: 'limita_izrakteni',
            text: 'Limita izrakteņi'
        },
        {
            dataField: 'lic_adresats_nosaukums',
            text: 'Licences / atļaujas adresāts',
            filterCfg: getTextFilterCfg(),
            width: 120,
            formatter: (adresats, row) => row.licence_id ? <Link to={getLinkParams({
                loc: 'loc_atradnes_licence',
                locDala: 'loc_atradnes_dala_licence',
                extraPayload: { id: row.licence_id },
                numurs: row.atr_numurs,
                isDala: row.atr_ieraksta_veids === 'D',
                atrId: row.atradne_id
            })}>{adresats}</Link> : null
        }, {
            dataField: 'lic_numurs',
            text: 'Licences / atļaujas Nr.',
            width: 100,
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'lic_izsniegts_datums',
            text: 'Licence / atļauja derīga no',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 106
        }, {
            dataField: 'lic_derigs_lidz_datums',
            text: 'Licence / atļauja derīga līdz',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 106
        }, {
            dataField: 'lic_anulets_datums',
            text: 'Licence / atļauja anulēta',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 106
        }, {
            dataField: 'la_kategorija',
            text: 'Kategorija',
            formatter: getBadgeFormatterForKodif(kodif.kategorijas),
            filterCfg: getSelectFilterCfg(kodif.kategorijas),
            width: 75
        }, {
            ...commonColumns.find(c => c.dataField === 'izrakteni'),
            text: 'Izraktenis'
        }, {
            dataField: 'la_krajumu_stavoklis_uz_datums',
            text: 'Limitā noteiktais krājumu stāvoklis uz',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 106,
            hidden: true
        }, {
            dataField: 'limita_apjoms_korigetais',
            text: 'Limitā noteiktais apjoms',
            formatter: (daudzums, row) => {
                if (daudzums != null) {
                    return daudzums
                }
                return null
            },
            width: 90,
            align: 'right',
            filterCfg: getNumberFilterCfg()
        }, {
            dataField: 'krajumi_pec_visam_izmainam',
            text: 'Pēdējais konstatētais krājumu apjoms',
            formatter: (daudzums, row) => {
                if (daudzums != null) {
                    return <>
                        <span className={`${daudzums < 0 ? 'red' : ''}`}>{round(daudzums)} <span dangerouslySetInnerHTML={{ __html: row.la_daudzuma_merv_nosaukums }}></span></span>
                    </>
                }
                return null
            },
            width: 130,
            align: 'right',
            filterCfg: getNumberFilterCfg()
        }, {
            dataField: 'datums',
            text: 'Limita krājumi uz',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 106
        }, {
            ...commonColumns.find(c => c.dataField === 'ipasums_nosaukums'),
            text: 'Zemes īpašuma nosaukums'
        }, {
            dataField: 'l_kadastra_apzimejumi',
            text: 'Kadastra apzīmējumi',
            filterCfg: getTextFilterCfg(),
            width: 200
        }, {
            dataField: 'q_adm_ters',
            text: 'Administratīvā teritorija',
            filterCfg: getTextFilterCfg(),
            width: 130
        }
    ]
};

class LimituAtlikumi extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('limitu_atlikumi_saraksts_karte_height', h)
    }

    rowEvents = {
        onDoubleClick: getDoubleClickHandler({ loc: 'loc_atradnes_limits', locDala: 'loc_atradnes_dala_limits', openDok: this.props.openDok, idColumn: 'limits_id' })
    }

    render() {

        const { kodif, limitu_atlikumi, gui, limituAtlikumiSelected, user } = this.props
        const { list, selected, loading, count } = limitu_atlikumi

        const vectorLayerCfgs = {
            limitu_atlikumi: {
                title: 'Limitu apjomi',
                features: list,
                styles: maputils.dokStyles.dokLimitiStyles,
                tooltipFunc: (feat) => `${feat.get('atradne') ? feat.get('atradne').tah_label : ''} ${dateFormatter(feat.get('izsniegts_datums'))} - ${feat.get('adresats_nosaukums')}`,
                selected: selected,
                legend: {
                    features: [
                        {
                            label: 'Limitu apjomi',
                            geomType: 'polygon'
                        },
                        {
                            label: 'Limitu apjomi (izvēlēts)',
                            geomType: 'polygon',
                            selected: true
                        }
                    ]
                }
            }
        }

        if (!this.columns) {
            this.columns = getColumns({
                kodif,
                user
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.limitu_atlikumi_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="limitu_atlikumi_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={limituAtlikumiSelected}
                    loading={loading}
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >
                    <BBoxFilterButtonToUrl />
                    <ManiObjektiButton allText="Rādīt visas" maniText="Rādīt tikai manus limitus" />
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({ kodif, limitu_atlikumi, gui, user }) => {
    return { kodif, limitu_atlikumi, gui, user }
}

export default connect(mapStateToProps, { limituAtlikumiSelected, guiSet, openDok })(LimituAtlikumi)
