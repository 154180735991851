import React, {Component} from 'react';
import {connect} from 'react-redux'
import {
  dateFormatter,
  getNumberFilterCfg,
  getNumberFormatter,
  getTextFilterCfg,
  getDateFilterCfg
} from "../grid/ColumnHelpers";
import {addUrlQuery} from "../../actions/uiActions";
import Grid from "../grid/Grid";
import SplitterLayout from "react-splitter-layout";

const getColumns = ({ metadata }) => {
  return [
    {
      dataField: 'merijuma_datums',
      text: "Mērijuma datums",
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 80
    },
    {
      dataField: 'vertiba',
      text: "Statiskais līmenis, m. no z.v.",
      formatter: getNumberFormatter(),
      filterCfg: getNumberFilterCfg(),
      width: 80
    },
    {
      dataField: 'apr_merijuma_vertiba',
      text: "Statiskais līmenis, m. vjl",
      formatter: getNumberFormatter(),
      filterCfg: getNumberFilterCfg(),
      width: 80
    },
    {
      dataField: 'karodzini',
      text: "Uzstādītie karodziņi",
      filterCfg: getTextFilterCfg(),
      width: 80
    }
  ]
}

class UrbumsNoverotieLimeni extends Component {

  render() {

    const { metadata, addUrlQuery, noverotie_limeni, query, payload} = this.props

    const { selected, list, loading, count} = noverotie_limeni

    let gads = query && query.gads ? query.gads : new Date().getFullYear()

    if(!this.columns){
      this.columns = getColumns({
        metadata
      })
    }

    const onNextPrevYear = (newGads) => {
      query.gads = newGads
      query.p = 1
      addUrlQuery({gads: newGads})
      query.payload = payload
    }

    const nextYear = () => {
      return parseInt(gads) + 1
    }

    const prevYear = () => {
      return parseInt(gads) - 1
    }

    return (
        <SplitterLayout
            vertical
            percentage
            primaryIndex={1}
            secondaryInitialSize={10}
        >
          <div className="atradne-title uo-gads d-flex flex-row-reverse">
            <h4 className="atradne-num">
              <i className="fa fa-caret-left" title={prevYear()} aria-hidden="true" onClick={() => onNextPrevYear(prevYear())}></i>
              <span>{ gads }</span>
              <i className="fa fa-caret-right" title={nextYear()} aria-hidden="true" onClick={() => onNextPrevYear(nextYear())}></i>
            </h4>
          </div>
          <Grid
              currentPageSize={10}
              selectedId={selected}
              loading={loading}
              dataList={list}
              totalCount={count}
              columns={this.columns}
          >
          </Grid>
        </SplitterLayout>
    )
  }
}

const mapStateToProps = ({noverotie_limeni, metadata, location, user}) => ({noverotie_limeni, metadata, query: location.query, payload: location.payload, user})
export default connect(mapStateToProps, {addUrlQuery})(UrbumsNoverotieLimeni)
