import React, {useState} from "react";
import {Input} from "reactstrap";

const NextPrevBox = (props) => {
  const { currentValue, onChange } = props

  const [value, setValue] = useState(currentValue)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleBlur = (event) => {
    onChange(event.target.value)
  }

  const onClick = (v) => {
    setValue(v)
    onChange(v)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    document.activeElement.blur()
  }

  return <div className="atradne-title uo-gads d-flex flex-row-reverse">
    <h4 className="atradne-num next-prev-box">
      <i className="fa fa-caret-left" title={`${Number(value) - 1}`} aria-hidden="true" onClick={onClick.bind(this, Number(value) - 1)}></i>
      <form onSubmit={handleSubmit}>
        <Input
          id="next-prev-input"
          type='number'
          name="next-prev-input"
          autoFocus={false}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          invalid={false}
          title=""
        />
      </form>
      <i className="fa fa-caret-right" title={`${Number(value) + 1}`} aria-hidden="true" onClick={onClick.bind(this, Number(value) + 1)}></i>
    </h4>
  </div>
}

export default NextPrevBox

