import { callApi } from './helpers'

export const loadVvisKodifikatorsIfNeeded = (forced = false) => (dispatch, getState) => {
    if(forced || getState().vvisKodifs.requested === false){
        return dispatch(callApi({
            viewName: 'vvis_klasifikatori',
            crudMethod: 'list'
        }))
    }
}
