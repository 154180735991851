import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '../grid/Grid';
import { Button } from 'reactstrap';
import {
    getTextFilterCfg,
    getDateFilterCfg,
    getEditIconColumn,
    getFormatterForKodif,
    getmultiSelectFilterCfg,
    dateFormatter,
    dateTimeFormatter,
    getBadgeFormatterForKodif,
    getSelectFilterCfg

} from '../grid/ColumnHelpers';
import { openAdIeraksts, downloadIeraksts } from '../../actions/adIerakstiActions';
import FileSaver from 'file-saver';


const getDownloadIconColumn = (onClickDownload) => ({
    dataField: 'df2',
    text: '',
    isDummyField: true,
    formatter: (cellContent, row) => {
        return (
            <Button color='secondary' size='sm' onClick={() => onClickDownload(row.id)}>
                <i className="fa fa-download"></i>
            </Button>
        );
    },    
    width: 36,
    sort: false,
    classes: 'button',
});

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_ad_ieraksts',
        payload: { id: row.id }
    }
    )
)

export const getColumns = ({ onClickDownload, kodif }) => {
    const cols = [
        editIconColumn,
        getDownloadIconColumn(onClickDownload),
        {
            dataField: 'servisa_nosaukums',
            text: 'Servisa nosaukums',
            filterCfg: getTextFilterCfg(),
        },
        {
            dataField: 'konektors',
            text: 'Konektors',
            filterCfg: getmultiSelectFilterCfg(kodif.adServisaKonektors),
            formatter: getFormatterForKodif(kodif.adServisaKonektors)
        },
        {
            dataField: 'statuss',
            text: 'Statuss',
            width: 120,
            filterCfg: getSelectFilterCfg(kodif.adIerakstaStatuss),
            formatter: (cell, row) => {
                return getBadgeFormatterForKodif(kodif.adIerakstaStatuss)(cell, row);
                }
            },
        {
            dataField: 'izveides_laiks',
            text: 'Izveides laiks',
            filterCfg: getDateFilterCfg(),
            formatter: dateTimeFormatter
        },
        {
            dataField: 'perioda_sākuma_datums',
            text: 'Perioda sākuma datums',
            filterCfg: getDateFilterCfg(),
            formatter: dateFormatter
        },
        {
            dataField: 'perioda_beigu_datums',
            text: 'Perioda beigu datums',
            filterCfg: getDateFilterCfg(),
            formatter: dateFormatter
        },
        {
            dataField: 'uid',
            text: 'UID',
            filterCfg: getTextFilterCfg(),
        }
    ];
    return cols;
};

class AdIerakstuSaraksts extends Component {

    onClickDownload = async (rowId) => {
        try {
          await this.props.downloadIeraksts(Number(rowId));
      
          const { dati, faila_nosaukums } = this.props.ad_ieraksts_dati.selectedItem;
      
          const isJson = faila_nosaukums.endsWith('.json');
          const mimeType = isJson ? 'application/json' : 'text/csv';
      
          const blob = new Blob([dati], { type: mimeType });
          FileSaver.saveAs(blob, faila_nosaukums);
        } catch (error) {
          console.error('Kļūda lejupielādējot datus:', error);
        }
      };

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
           this.props.openAdIeraksts(row.id)
        }
    }

    render() {
        if (!this.columns) {
            this.columns = getColumns({
                onClickDownload : this.onClickDownload,
                kodif : this.props.kodif
            });
        }

        const { loading, list, count } = this.props.ad_ieraksti;

        return (
            <div className="saraksts-wrapper">
                <Grid
                    loading={loading}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ ad_ieraksti, ad_ieraksts_dati, kodif }) => ({ ad_ieraksti, ad_ieraksts_dati, kodif });

export default connect(mapStateToProps, { downloadIeraksts, openAdIeraksts })(AdIerakstuSaraksts);
