import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'

import Form from '../forms/Form'

import {virszemesUdensObjektsAtrasanasActions} from '../../actions/virszemesUdensObjektiActions'
import {showErrorMsg} from '../../actions/uiActions'

export const  getFormFieldCfgs = () => {
  return [
    {
        name: 'ter_iedalijums_wfs',
        label: 'Administratīvā teritorija (ATVK2021)',
        editable: false,
        fieldNotes: 'Administratīvās un teritoriālās vienības tiek aizpildītas automātiski no WFS servisa.',
        renderer: (allAdm, vals) => {
            // group by limenis_1
            const grouped = allAdm.reduce((grouped, adm) => {
                if(!grouped[adm.limenis_1]) {
                    grouped[adm.limenis_1] = []
                }
                if(adm.limenis_2){
                    grouped[adm.limenis_1].push(adm.limenis_2)
                }
                return grouped
            }, {})
            return <>
                {Object.keys(grouped).map(adm => <p key={adm} className="links">{adm}{grouped[adm].length > 0 ? ' - ' + grouped[adm].join('; ') : null}</p>)}
                {vals.atradne_ter_iedalijums_wfs_kluda ? <p className="danger links">{vals.atradne_ter_iedalijums_wfs_kluda}</p> : null}
            </>
        }
    }
    ]
}

class AtrasanasComp extends Component {

      render() {
        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, kodif, virszemesUdensObjektsAtrasanas} = this.props

        const {selectedItem, editable, submiting} = virszemesUdensObjektsAtrasanas

        // loading while we have no atradne
        if (!selectedItem) {
            return (<Loading/>)
        }

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif,
                user
            })
        }

        return <div className="form-with-grid-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={selectedItem}
                        formFieldCfgs={this.formFieldCfgs}
                        onSubmit={this.props.saveAct}
                        onReset={this.props.stopEditAct}
                        onStartEdit={this.props.startEditAct}
                        tiesibasLabot="BAIS_VU_PAMAT_LABOT"
                    />
            </div>
    }
}

const mapStateToProps = ({virszemesUdensObjektsAtrasanas, kodif, user}) => ({virszemesUdensObjektsAtrasanas, kodif, user})
const VirszemesAtrasanas =  connect(mapStateToProps, {...virszemesUdensObjektsAtrasanasActions, showErrorMsg})(AtrasanasComp)
export default VirszemesAtrasanas
