import * as maputils from '../components/karte/maputils'
import _ from 'lodash'

export const listGetInitialState = () => ({
    list: [],
    count: 0,
    loading: false,
    lastReqId: 0,
    selected: null,
})

export const editGetInitialState = () => ({
    selectedItem: null,
    editable: false,
    submiting: false,
})

export const listEditGetInitialState = () => ({ ...listGetInitialState(), ...editGetInitialState() })

export const createListReducer = ({ listViewName, actionsName }) => ({ state, type, payload, reqId }) => {
    if (!actionsName) {
        actionsName = listViewName
    }
    switch (type) {
        case `${listViewName}_listPaging_request`:
        case `${listViewName}_list_request`:
            return {
                ...state,
                loading: true,
                lastReqId: reqId
            }
        case `${listViewName}_listPaging_failure`:
        case `${listViewName}_list_failure`:
            if (state.lastReqId === reqId) {
                return {
                    ...state,
                    loading: false
                }
            } else {
                return state
            }
        case `${listViewName}_listPaging_success`:
            if (state.lastReqId === reqId) {
                return {
                    ...state,
                    ...payload,
                    loading: false
                }
            } else {
                return state
            }
        case `${listViewName}_list_success`:
            if (state.lastReqId === reqId) {
                return {
                    ...state,
                    list: [...payload],
                    count: payload.length,
                    loading: false
                }
            } else {
                return state
            }
        case `${actionsName}_selected`:
            return {
                ...state,
                selected: payload
            }
        default:
            return state
    }
}

export const createListReducerWithMapClicking = ({ listViewName, actionsName, mapIdName, layerIdName }) => {
    const defaultListReducer = createListReducer({ listViewName, actionsName })
    return ({ state, type, payload, reqId, mapId, layerId }) => {
        switch (type) {
            case 'MAP_clickedOutsideFeature':
                if (mapId === mapIdName) {
                    return {
                        ...state,
                        selected: undefined
                    }
                } else {
                    return state
                }
            case 'MAP_clickedFeature':
                if (mapId === mapIdName && (layerId === layerIdName || (Array.isArray(layerIdName) && layerIdName.indexOf(layerId) > -1))) {
                    return {
                        ...state,
                        selected: payload.id
                    }
                } else {
                    return state
                }
            default:
                return defaultListReducer({ state, type, payload, reqId })
        }
    }
}

export const createEditReducer = ({ editViewName, actionsName }) => ({ state, type, payload }) => {
    if (!actionsName) {
        actionsName = editViewName
    }
    switch (type) {
        case `${actionsName}_clear`:
            return editGetInitialState()
        case `${actionsName}_selected`:
            if (state.editable === true || !state.list) {
                return state
            }
            const selected = state.list.find(k => k.id === payload)
            const selectedItem = selected ? { ...selected } : null
            return {
                ...state,
                selectedItem
            }
        case `${actionsName}_edit`:
            return { ...state, editable: true }
        case `${actionsName}_stop_edit`:
            if (state.selectedItem && state.selectedItem.id == null) {
                return { ...state, editable: false, selectedItem: null }
            }
            return { ...state, editable: false }
        case `${editViewName}_delete_request`:
        case `${editViewName}_save_request`:
        case `${editViewName}_new_request`:
            return {
                ...state,
                submiting: true,
            }
        case `${editViewName}_new_failure`:
        case `${editViewName}_delete_failure`:
        case `${editViewName}_save_failure`:
            return {
                ...state,
                submiting: false,
            }
        case `${editViewName}_save_success`:
            return {
                ...state,
                selectedItem: payload,
                editable: false,
                submiting: false,
            }
        case `${editViewName}_delete_success`:
            return {
                ...state,
                selectedItem: null,
                editable: false,
                submiting: false,
            }
        case `${editViewName}_new_success`:
            return {
                ...state,
                selectedItem: payload,
                editable: true,
                submiting: false,
            }
        case `${editViewName}_get_request`:
        case `${editViewName}_listPaging_request`:
            return {
                ...state,
                editable: false
            }
        case `${editViewName}_get_success`:
            return {
                ...state,
                selectedItem: payload
            }
        case `${editViewName}_listPaging_success`: {
            //try to select prev selected item
            let selectedItem = null
            if (payload.list && payload.list.length > 0) {
                selectedItem = payload.list[0];
                if (state.selectedItem && state.selectedItem.id > -1) {
                    const found = payload.list.find(p => p.id === state.selectedItem.id)
                    if (found) selectedItem = found
                }
                selectedItem = { ...selectedItem, count: payload.count }
            }
            return {
                ...state,
                selectedItem
            }
        }
        case `${editViewName}_get_failure`:
        case `${editViewName}_listPaging_failure`:
            return {
                ...state,
                selectedItem: null,
            }
        default:
            return state
    }
}

const getKoordsFeat = (selectedItem, koordsArr, zoomTo = false, geomType) => ({
    ...selectedItem, koordsArr, validation: maputils.validateKoords(koordsArr, geomType), zoomTo, geomType
})
const getKoordsFromState = (selectedItem, geomType) => {
    let koords = []
    let koordsFeat = null
    const geom = selectedItem && selectedItem.geom
    if (geom) {
        koords = maputils.fromOlGeomToKoordArr(maputils.GeoJSONFormat.readGeometry(geom))
    }
    if (koords.length > 0) {
        koordsFeat = getKoordsFeat(selectedItem, koords, true, geomType)
    }
    return { koords, koordsFeat }
}
const getKoordsFromStateForChildEdit = (selectedItem, childGeomEditCollection, geomType) => {
    const childKoords = (selectedItem && selectedItem[childGeomEditCollection]) ?
        selectedItem[childGeomEditCollection].map(g => ({ ...g, ...getKoordsFromState(g, geomType) })) : []
    return { childKoords }
}
const getChangedKoordsByMap = (id, featureGeom, currentKoords, selectedItem, geomType) => {
    const editedKoords = featureGeom.getCoordinates()
    const koords = currentKoords.map(k => k.id === id ? { ...k, x: _.round(editedKoords[0], 6), y: _.round(editedKoords[1], 6) } : k)
    return {
        koords, koordsFeat: getKoordsFeat(selectedItem, koords, false, geomType)
    }
}
const getChangedKoordsByForm = (payload, currentKoords, selectedItem, geomType) => {
    const koords = Array.isArray(payload) ? payload : currentKoords.map(k => k.id === payload.id ? payload : k)
    return {
        koords, koordsFeat: koords.length > 0 ? { ...getKoordsFeat(selectedItem, koords, false, geomType), zoomTo: !!Array.isArray(payload) } : null
    }
}
export const editWithGeometryInitialState = () => (
    { ...editGetInitialState(), koords: [], koordsFeat: null }
)
export const createEditReducerWithGeometryEditing = ({ editViewName, actionsName, mapIdName, layerIdName, childGeomEditCollection, geomType }) => {
    const defaultEditReducer = createEditReducer({ editViewName, actionsName })
    return ({ state, type, payload, mapId, layerId, featureGeom }) => {
        switch (type) {
            case `${editViewName}_save_success`:
            case `${editViewName}_get_success`:
            case `${editViewName}_listPaging_success`:
            case `${editViewName}_new_success`:
            case `${actionsName}_stop_edit`:
                const newState = defaultEditReducer({ state, type, payload })
                const koordsState = childGeomEditCollection ? getKoordsFromStateForChildEdit(newState.selectedItem, childGeomEditCollection, geomType) : getKoordsFromState(newState.selectedItem, geomType)
                return { ...newState, ...koordsState }
            case 'MAP_clickedFeature':
                if (mapId === mapIdName && layerId === layerIdName) {
                    return {
                        ...state,
                        selectedKoord: payload.id
                    }
                } else {
                    return state
                }
            case 'MAP_clickedOutsideFeature':
                if (mapId === mapIdName) {
                    return {
                        ...state,
                        selectedKoord: undefined
                    }
                } else {
                    return state
                }
            case 'MAP_modifiedFeature':
                if (mapId === mapIdName && layerId === layerIdName) {
                    let koordsState = {}
                    if (childGeomEditCollection) {
                        // find and change active child koords
                        const childKoords = state.childKoords.map(ck => ck.active ? { ...ck, ...getChangedKoordsByMap(payload.id, featureGeom, ck.koords, ck, geomType) } : ck)
                        koordsState = {
                            childKoords
                        }
                    } else {
                        koordsState = getChangedKoordsByMap(payload.id, featureGeom, state.koords, state.selectedItem, geomType)
                    }
                    return {
                        ...state,
                        ...koordsState
                    }
                } else {
                    return state
                }
            case `${actionsName}_koord_selected`:
                return {
                    ...state,
                    selectedKoord: payload
                }
            case `${actionsName}_koord_changed`:
                let koordsStateForm = {}
                if (childGeomEditCollection) {
                    // find and change active child koords
                    const childKoords = state.childKoords.map(ck => ck.active ? { ...ck, ...getChangedKoordsByForm(payload, ck.koords, ck, geomType) } : ck)
                    koordsStateForm = {
                        childKoords
                    }
                } else {
                    koordsStateForm = getChangedKoordsByForm(payload, state.koords, state.selectedItem, geomType)
                }
                return {
                    ...state,
                    ...koordsStateForm
                }
            case 'child_geom_selected':
                if (childGeomEditCollection && state.childKoords) {
                    // clear active koords
                    let newChildKoords = state.childKoords.map(ck => ck.active ? { ...ck, active: false } : ck)
                    // payload will have key or id
                    if (payload.id) {
                        newChildKoords = newChildKoords.map(ck => ck.id === payload.id ? { ...ck, active: true } : ck)
                    } else if (payload.key) {
                        // if there is no such a key, we should add new childKoords item
                        const foundKoords = newChildKoords.find(ck => ck.key === payload.key)
                        if (!foundKoords) {
                            newChildKoords.push({
                                key: payload.key,
                                koords: [],
                                koordsFeat: null
                            })
                        }
                        newChildKoords = newChildKoords.map(ck => ck.key === payload.key ? { ...ck, active: true } : ck)
                    }
                    return {
                        ...state,
                        childKoords: newChildKoords
                    }
                } else {
                    return state
                }
            default:
                return defaultEditReducer({ state, type, payload })
        }
    }
}

const recalculateNextPrevLast = (options, last) => {
    let prev, next, lastObj = null
    if (options && last) {
        const ind = options.findIndex((o) => o.tah_id === last)
        if (ind >= 0) {
            lastObj = options[ind]
        }
        if (ind > 0) {
            prev = options[ind - 1]
        }
        if (ind >= 0 && ind + 1 < options.length) {
            next = options[ind + 1]
        }
    }
    return { prev, next, lastObj }
}

export const topMenuSearchInitialState = () => ({
    last: null,
    lastObj: null,
    options: null,
    next: null,
    prev: null
})

export const createTopMenuSearchReducer = ({ objectType, payloadLinkField }) => ({ state, type, payload }) => {
    switch (type) {
        case `${objectType}_top_menu_selected`:
            const options = payload.options ? payload.options : state.options
            const last = payload[payloadLinkField] ? payload[payloadLinkField] : state.last
            const nextPrev = recalculateNextPrevLast(options, last)
            return {
                ...state,
                last,
                options,
                ...nextPrev
            }
        case `${objectType}_top_menu_clear`:
            return topMenuSearchInitialState()
        default:
            return state
    }
}
