import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button} from 'reactstrap'
import {guiSet} from '../../actions/uiActions'
import Grid from '../grid/Grid'
import SplitterLayout from 'react-splitter-layout'
import Form from '../forms/Form'


class GridWithForm extends Component {

    formSizeChange = (w) => {
        this.props.guiSet(this.props.formWidthGuiOption, w);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
          this.props.startEditAct()
        }
    }

    render() {

        const {columns, formFieldCfgs, storeObj, formWidthGuiOption, selectedAct, metadataFormCfg,
            saveAct, stopEditAct, createAct, startEditAct, deleteAct, user, extraGridProps, tiesibasLabot, extraElementsFormButtons} = this.props
        const {editable, submiting, selectedItem} = storeObj
        
        return (
            <SplitterLayout
                customClassName="pane-paddings-hor"
                percentage
                primaryIndex={0}
                secondaryInitialSize={this.props.gui[formWidthGuiOption]}
                onSecondaryPaneSizeChange={this.formSizeChange}>

                <Grid 
                    onRowSelected={selectedAct} 
                    loading={storeObj.loading}    
                    dataList={storeObj.list}
                    totalCount={storeObj.count}
                    columns={columns} 
                    rowEvents={this.rowEvents}
                    extraGridProps={extraGridProps}
                />

                <div className="form-wrapper-pane">
                    { selectedItem ? (
                        <Form 
                            editable={editable}
                            submiting={submiting}
                            dataObj={selectedItem}
                            formFieldCfgs={formFieldCfgs}
                            metadataFormCfg={metadataFormCfg}
                            onSubmit={saveAct}
                            onReset={stopEditAct}
                            onNew={createAct}
                            onStartEdit={startEditAct}
                            onDelete={deleteAct}
                            tiesibasLabot={tiesibasLabot}
                            extraElements={extraElementsFormButtons}
                        />
                    ) : 
                    user.tiesibas.includes(tiesibasLabot) ? (
                    <>
                    <div className="actionButtons" >
                        <Button color="primary" onClick={createAct}><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                    </div>
                    <div>
                        Lai labotu, izvēlieties ierakstu sarakstā
                    </div>
                    </> ) : null
                    }
                </div>

        </SplitterLayout>
        )
    }

}

const mapStateToProps = ({gui, user}) => ({gui, user})
export default connect(mapStateToProps, {guiSet})(GridWithForm)