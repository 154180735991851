import {
  callApi, getDefaultEditActions,
  pushUrlToBrowserHistory
} from './helpers'
import {
  redirect
} from 'redux-first-router'

export const loadUdensNv = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'udens2_nv_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const udensNvSelected = (id) => {
  return {
    type: 'udensNv_selected',
    payload: id
  }
}

export const loadUdensAi = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'udens2_ai_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const udensAiSelected = (id) => {
  return {
    type: 'udensAi_selected',
    payload: id
  }
}

export const loadUdensNov = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'udens2_nov_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const udensNovSelected = (id) => {
  return {
    type: 'udensNov_selected',
    payload: id
  }
}

export const loadUdensNovSpecific = ({ id }) => (dispatch) => {
  dispatch({
    type: 'udensNovSpecific_clear'
  })
  return dispatch(callApi({
    viewName: 'udens2_nov_view',
    crudMethod: 'get',
    crudParam: id
  }))
}

export const loadUdensParskati = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'udens2_parskati_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}


export const udensParskatsActions = getDefaultEditActions({
  actionTypeName : 'udensParskats',
  stateName: 'udensParskats',
  combinedPage: true,
  apiViewName: 'udens2_parskats_view'
})

export const udensParskatsDoubleSelected = (id) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_udens_parskats',
    payload: {
      id
    }
  }))
}

export const loadParisVkn = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'paris_vkn_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const parisVknSelected = (id) => {
  return {
    type: 'parisVkn_selected',
    payload: id
  }
}

const afterSaveOrDelete = (funk) => (dispatch, getState) => {
  dispatch(funk(getState().location.query))
}
export const parisVknActions = getDefaultEditActions({
  actionTypeName: 'parisVkn',
  stateName: 'parisVkn',
  combinedPage: true,
  apiViewName: 'paris_vkn_edit',
  afterDelete: afterSaveOrDelete(loadParisVkn),
  afterSave: afterSaveOrDelete(loadParisVkn),
})
