import React from 'react';
import { connect } from 'react-redux'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class FormActionButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      askToDeleteModal: false
    };
  }

  askToDeleteToggle = () => {
    this.setState({
      askToDeleteModal: !this.state.askToDeleteModal
    });
  }

  render() {
    const { editable, submiting, handleSubmit, handleReset, handleNew, handleStartEdit, handleDelete, isValid, user, extraElements, btnTexts, tiesibasLabot, disabledEditTip } = this.props
    return (
      <>
        {tiesibasLabot === true || user.tiesibas.includes(tiesibasLabot) || extraElements ?
          <div className="actionButtons" id="btnWrapper">
            {tiesibasLabot === true || user.tiesibas.includes(tiesibasLabot) ?
              editable ?
                (
                  <>
                    <Button color="success" disabled={submiting || !isValid} onClick={handleSubmit}><i className="fa fa-save"></i>&nbsp;&nbsp;{btnTexts && btnTexts.save ? btnTexts.save : 'Saglabāt'}</Button>

                    {handleReset ?
                      <Button color="secondary" disabled={submiting} onClick={handleReset}><i className="fa fa-ban"></i>&nbsp;&nbsp;Atcelt</Button> :
                      null
                    }
                    {handleDelete ?
                      <>
                        <Button color="danger" disabled={submiting} onClick={this.askToDeleteToggle} className="float-right"><i className="fa fa-trash-o"></i>&nbsp;&nbsp;Dzēst</Button>

                        <Modal isOpen={this.state.askToDeleteModal} toggle={this.askToDeleteToggle}>
                          <ModalHeader toggle={this.toggle}>Dzēšana</ModalHeader>
                          <ModalBody>
                            Vai patiešām vēlaties neatgriezeniski dzēst ierakstu?
                          </ModalBody>
                          <ModalFooter>
                            <Button color="danger" onClick={handleDelete}>Dzēst</Button>{' '}
                            <Button color="secondary" onClick={this.askToDeleteToggle}>Atcelt</Button>
                          </ModalFooter>
                        </Modal>
                      </>
                      :
                      null
                    }
                  </>
                )
                :
                (
                  <>
                    {handleNew ?
                      <Button color="primary" onClick={handleNew}><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button> :
                      null
                    }
                    {handleStartEdit ?
                      <Button color="primary" onClick={handleStartEdit} disabled={!!disabledEditTip} title={disabledEditTip ? disabledEditTip : 'Labot'}><i className="fa fa-pencil"></i>&nbsp;&nbsp;{btnTexts && btnTexts.edit ? btnTexts.edit : 'Labot'}</Button> :
                      null
                    }
                  </>
                )
              : null}
            {extraElements || null}
          </div>
          : null}
      </>
    )

  }
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(FormActionButtons)
