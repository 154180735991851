import * as helpers from './helpers'

const defaultEditReducerForIzmaina = helpers.createEditReducer({ editViewName: 'izmaina_view', actionsName: 'izmaina' })
export const izmaina = (state = helpers.editGetInitialState(), { type, payload, reqId }) => {
    //overwrite or add custom actions catches here
    return defaultEditReducerForIzmaina({ state, type, payload, reqId })
}

const defaultEditReducerForIzmainaGrouped = helpers.createEditReducer({ editViewName: 'izmaina_view_grouped', actionsName: 'izmaina_grouped' })
export const izmaina_grouped = (state = helpers.editGetInitialState(), { type, payload, reqId, error }) => {
    switch (type) {
        // clear doks when atradne clears
        case 'izmaina_view_grouped_save_failure':
            if (error && error.status === 404) {
                return {
                    ...state,
                    editable: false,
                    submiting: false
                }
            }
            return defaultEditReducerForIzmainaGrouped({ state, type, payload, reqId })
        default:
            return defaultEditReducerForIzmainaGrouped({ state, type, payload, reqId })
    }
}

const defaultListReducerForIzmainas = helpers.createListReducerWithMapClicking({
    listViewName: 'izmainas_list',
    actionsName: 'izmainas',
    mapIdName: 'izmainu_saraksts',
    layerIdName: 'izmainas'
})
export const izmainas = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    switch (type) {
        // clear doks when atradne clears
        case 'izmainas_checked':
            const newList = state.list.map(izm => {
                if (izm.id === payload) {
                    return { ...izm, checked: !izm.checked }
                }
                return izm
            })
            return { ...state, list: newList }
        case 'izmainas_checked_all':
            const isAnyChecked = (state.list.filter(izm => izm.checked).length > 0)
            const newLists = state.list.map(izm => ({ ...izm, checked: !isAnyChecked }))
            return { ...state, list: newLists }
        default:
            return defaultListReducerForIzmainas({ state, type, payload, reqId, mapId, layerId })
    }
}

const defaultListReducerForBilance = helpers.createListReducerWithMapClicking({
    listViewName: 'bilance',
    actionsName: 'bilance',
    mapIdName: 'bilance',
    layerIdName: 'izmainas'
})
const defaultListReducerForBilanceGrouped = helpers.createListReducerWithMapClicking({
    listViewName: 'bilance_grouped',
    actionsName: 'bilance',
    mapIdName: 'bilance',
    layerIdName: 'izmainas'
})

export const bilance = (state = { ...helpers.listGetInitialState(), isGrouped: true }, { type, payload, reqId, mapId, layerId }) => {
    const newState = state.isGrouped ? defaultListReducerForBilanceGrouped({ state, type, payload, reqId, mapId, layerId }) : defaultListReducerForBilance({ state, type, payload, reqId, mapId, layerId })
    switch (type) {
        case 'bilance_grouped':
            return { ...newState, isGrouped: !newState.isGrouped }
        case 'bilance_grouped_listPaging_success':
            if (state.lastReqId === reqId) {
                newState.list.forEach(b => b._isGrouped = true)
            }
            return newState
        default:
            return newState
    }
}

const defaultListReducerForKomersantaIzmainas = helpers.createListReducerWithMapClicking({
    listViewName: 'izmainas_list_komersants',
    actionsName: 'komersantaIzmainas',
    mapIdName: 'komersanta_izmainu_saraksts',
    layerIdName: 'izmainas'
})
export const komersantaIzmainas = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    return defaultListReducerForKomersantaIzmainas({ state, type, payload, reqId, mapId, layerId })
}
