import React, {Component} from 'react';
import {connect} from 'react-redux'

import Loading from '../Loading'
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';

import SplitterLayout from 'react-splitter-layout';

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'
import {guiSet, addUrlQuery} from '../../actions/uiActions'
import Pamatinformacija, {PamatinformacijaAtradnesDala} from './Pamatinformacija'
import Atrasanas, {AtrasanasAtradnesDala} from './Atrasanas'
import Geologija, {GeologijaAtradnesDala} from './Geologija'
import Kvalitate, {KvalitateAtradnesDala} from './Kvalitate'
import AtradnesUzzinas from './AtradnesUzzinas'
import AtradnesDokumentacija, {AtradnesDokumentacijaDala} from './AtradnesDokumentacija'
import AtradnesKrajumi, {AtradnesKrajumiDala} from './AtradnesKrajumi'
import AtradnesIzmainas, {AtradnesIzmainasDala} from './AtradnesIzmainas'
import AtradnesVesture, {AtradnesVestureDala} from './AtradnesVesture'
import NotFound from '../NotFound'
import { dateFormatter } from '../grid/ColumnHelpers'

const PaperclipIcon = () => <i className="fa fa-paperclip" title="Sadaļā ir pielikumi"></i>

const ierobezotaPieejamibaHidden = ({user, atradne}) => !user.tiesibas.includes('SKATIT_ATRADNES_AR_IEROB_PIEEJAMIBU') && atradne.ir_ierobezota_pieejamiba === true

const getTabCfgs = () => {
  return {
    pamatinfo: {
      title: 'Pamatinformācija',
      comp: Pamatinformacija,
      icon: ({atradne}) => atradne.ir_faili ? <PaperclipIcon/> : null
    },
    atrasanas: {
      title: 'Atrašanās vieta',
      comp: Atrasanas,
      disableWhenNew: true,
      hideFunc: ierobezotaPieejamibaHidden
    },
    geologija: {
      title: 'Ģeoloģija',
      comp: Geologija,
      disableWhenNew: true,
      icon: ({atradne}) => atradne.ir_faili_geologija ? <PaperclipIcon/> : null,
      hideFunc: ierobezotaPieejamibaHidden
    },
    kvalitate: {
      title: 'Kvalitāte',
      comp: Kvalitate,
      disableWhenNew: true,
      icon: ({atradne}) => atradne.ir_faili_kvalitate ? <PaperclipIcon/> : null,
      hideFunc: ierobezotaPieejamibaHidden
    },
    uzzinas: {
      title: 'Uzziņas',
      comp: AtradnesUzzinas,
      disableWhenNew: true,
      icon: ({atradne}) => atradne.ir_faili_uzzinas ? <PaperclipIcon/> : null
    },
    dok: {
      title: 'Dokumentācija',
      comp: AtradnesDokumentacija,
      disableWhenNew: true,
      icon: ({atradne}) => atradne.ir_faili_dokumentacija ? <PaperclipIcon/> : null,
      // ja aizpildīts personas kods, tad šis lietotājs varētu būt pārstāvis kādam dokumentācijas ierakstam un tad tas ir jārāda arī atradnēm ar ierob. pieejamību
      hideFunc: ({user, atradne}) => ierobezotaPieejamibaHidden({user, atradne}) && atradne.ir_parstavis === false
    },
    krajumi: {
      title: 'Krājumi',
      comp: AtradnesKrajumi,
      disableWhenNew: true,
      hideFunc: ierobezotaPieejamibaHidden
    },
    izmainas: {
      title: 'Krājumu izmaiņas',
      comp: AtradnesIzmainas,
      disableWhenNew: true
    },
    vesture: {
      title: 'Izmaiņu vēsture',
      comp: AtradnesVesture,
      disableWhenNew: true,
      hideFunc: ({user}) => !user.tiesibas.includes('SKATIT_ATRADNES_AUDITU')
    }
  }
}

const getTabCfgsAtradnesDala = () => {
  const config = getTabCfgs()
  config.pamatinfo.comp = PamatinformacijaAtradnesDala
  config.atrasanas.comp = AtrasanasAtradnesDala
  config.geologija.comp = GeologijaAtradnesDala
  config.kvalitate.comp = KvalitateAtradnesDala
  config.dok.comp = AtradnesDokumentacijaDala
  config.krajumi.comp = AtradnesKrajumiDala
  config.izmainas.comp = AtradnesIzmainasDala
  config.vesture.comp =  AtradnesVestureDala
  delete config.uzzinas
  return config
}

const atradneTooltipFunc = (feat) => `${feat.get('nosaukums') || ''} (${feat.get('numurs') || ''})`
const doksPaseLimitsTooltipFunc = (feat) => `${feat.get('dala') ? feat.get('dala').nosaukums : ''} ${feat.get('dala_nosaukums') ? feat.get('dala_nosaukums') : ''} ${dateFormatter(feat.get('izsniegts_datums'))} - ${feat.get('adresats_nosaukums')}`
const doksLicenceTooltipFunc = (feat) => `${feat.get('dala') ? feat.get('dala').nosaukums : ''} ${feat.get('dala_nosaukums') ? feat.get('dala_nosaukums') : ''} ${feat.get('lic_numurs')} ${dateFormatter(feat.get('izsniegts_datums'))} - ${feat.get('adresats_nosaukums')}`
const krajumiTooltipFunc = (feat) => `${feat.get('dala') ? feat.get('dala').nosaukums : ''} ${feat.get('dalas_nosaukums') ? feat.get('dalas_nosaukums') : ''} ${dateFormatter(feat.get('sakums_datums'))} - ${feat.get('kategorija')} ${feat.get('izpetes_gads')} ${feat.get('izrakteni') ? feat.get('izrakteni').map(i => i.klasifikators).join(', ') : ''}`

const dokumentacijaTabLayerCfg = (atradneDoks) => {
  const {pases, limiti, licences} = atradneDoks.selectedItem
  const selectedPase = pases.find(p => p.selected)
  const selectedLicence = licences.find(p => p.selected)
  const selectedLimits = limiti.find(p => p.selected)
  const cfg = {}
  cfg.atradne_pase = {
    title: 'Pase',
    features: pases,
    styles: maputils.dokStyles.dokPaseStylesFill,
    tooltipFunc: doksPaseLimitsTooltipFunc,
    selected: selectedPase ? selectedPase.id : undefined,
    legend: {
      features: [
          {
              label: 'Dokumentālā pase',
              geomType: 'polygon',
              selected: false
          },
          {
            label: 'Dokumentālā pase (izvēlēta)',
            geomType: 'polygon',
            selected: true
        }
      ]
    }
  }
  cfg.atradne_pase_robeza = {
    ...cfg.atradne_pase,
    title: 'Pases robeža',
    styles: maputils.dokStyles.dokPaseStylesBorder,
    tooltipFunc: undefined,
    selectable: false
  }
  cfg.atradne_limits = {
    title: 'Limits',
    features: limiti,
    styles: maputils.dokStyles.dokLimitiStylesFill,
    tooltipFunc: doksPaseLimitsTooltipFunc,
    selected: selectedLimits ? selectedLimits.id : undefined,
    legend: {
      features: [
          {
              label: 'Limits',
              geomType: 'polygon',
              selected: false
          },
          {
            label: 'Limits (izvēlēts)',
            geomType: 'polygon',
            selected: true
        }
      ]
    }
  }
  cfg.atradne_limits_robeza = {
    ...cfg.atradne_limits,
    title: 'Limita robeža',
    styles: maputils.dokStyles.dokLimitiStylesBorder,
    tooltipFunc: undefined,
    selectable: false
  }
  cfg.atradne_licence = {
    title: 'Licence / atļauja',
    features: licences,
    styles: maputils.dokStyles.dokLicencesStylesFill,
    tooltipFunc: doksLicenceTooltipFunc,
    selected: selectedLicence ? selectedLicence.id : undefined,
    legend: {
      features: [
          {
              label: 'Licence / atļauja',
              geomType: 'polygon',
              selected: false
          },
          {
            label: 'Licence / atļauja (izvēlēta)',
            geomType: 'polygon',
            selected: true
        }
      ]
    }
  }
  cfg.atradne_licence_robeza = {
    ...cfg.atradne_licence,
    title: 'Licences / atļaujas robeža',
    styles: maputils.dokStyles.dokLicencesStylesBorder,
    tooltipFunc: undefined,
    selectable: false
  }
  return cfg
}

const krajumiTabLayerCfg = (atradneKrajumi) => {
  const {krajumi} = atradneKrajumi.selectedItem
  const {selectedKrajums} = atradneKrajumi
  const cfg = {}
  cfg.atradne_krajums = {
    title: 'Krājumi',
    features: krajumi,
    styles: maputils.dokStyles.dokKrajumiStylesFill,
    tooltipFunc: krajumiTooltipFunc,
    selected: selectedKrajums,
    legend: {
      features: [
          {
              label: 'Krājumi',
              geomType: 'polygon',
              selected: false
          },
          {
            label: 'Krājumi (izvēlēti)',
            geomType: 'polygon',
            selected: true
        }
      ]
    }
  }
  cfg.atradne_krajums_robeza = {
    ...cfg.atradne_krajums,
    title: 'Krājumu robeža',
    styles: maputils.dokStyles.dokKrajumiStylesBorder,
    tooltipFunc: undefined,
    selectable: false
  }
  return cfg
}

const atrasanasTabLayerCfg = (atradneAtrasanas, isDala = false) => {
  const cfg = {}
  if(atradneAtrasanas.koordsFeat){
    cfg.atradne_atrasanas_feat_border = {
      title: `Atradne${isDala ? 's daļa': ''}`,
      features: atradneAtrasanas.koordsFeat,
      styles: isDala ? maputils.kartinasStyles.atradneDalaBorderCurrent : maputils.kartinasStyles.atradneBorderCurrent,
      selectable: false,
      legend: {
        features: [
            {
                label: isDala ? 'Atradnes daļa' : 'Atradne',
                geomType: 'point',
                selected: false
            }
        ]
      }
    }
    /*cfg.atradne_atrasanas_feat = {
      ...cfg.atradne_atrasanas_feat_border,
      title: `Atradne${isDala ? 's daļa': ''}`,
      styles: isDala ? maputils.kartinasStyles.atradneDalaFillCurrent : maputils.kartinasStyles.atradneBorderCurrent,
    }*/
  }
  cfg.atradne_atrasanas_koord = {
    title: `Atradnes ${isDala ? 'daļas ': ''}robežpunkti`,
    features: atradneAtrasanas.koords,
    styles: maputils.borderPointStyles,
    tooltipFunc: (feat) => `Nr ${feat.get('id')} (${feat.get('x')};${feat.get('y')})`,
    selected: atradneAtrasanas.selectedKoord,
    zIndex: 15,
    editable: atradneAtrasanas.editable,
    legend: {
      features: [
          {
              label: 'Robežpunkts',
              geomType: 'point',
              selected: false
          },
          {
            label: 'Robežpunkts (izvēlēts)',
            geomType: 'point',
            selected: true
        }
      ]
    }
  }
  cfg.atradne_atrasanas_koord_labels = {
    title: `Atradnes ${isDala ? 'daļas ': ''}robežpunktu numuri`,
    features: atradneAtrasanas.koords,
    styles: maputils.borderPointStylesLabels,
    selectable: false,
    zIndex: 20,
    declutter: true
  }
  return cfg
}

const atradnesDaluLayerCfg = (dalas) => {
  const cfg = {}
  cfg.atradnes_dalas_robeza = {
    title: 'Atradnes daļas',
    features: dalas,
    styles: maputils.kartinasStyles.atradneDalaBorder,
    tooltipFunc: (feat) => `Daļa: ${feat.get('nosaukums')}`,
    legend: {
      features: [
          {
              label: 'Atradnes daļa',
              geomType: 'point',
              selected: false
          }
      ]
    }
  }
  /*cfg.atradnes_dalas = {
    ...cfg.atradnes_dalas_robeza,
    title: 'Atradnes daļa',
    styles: maputils.kartinasStyles.atradneDalaFill,
    opacity: 0.1,
    tooltipFunc: undefined,
  }*/
  return cfg
}

//create vector layers for atradnes kartina
const getVectorLayerCfgs = ({atradne, atradneAtrasanas, atradneDoks, activeTab, atradneKrajumi}) => {
  let cfg = {}
  // specific layers for dokumentacija Tab
  if(activeTab === 'dok' && atradneDoks && atradneDoks.selectedItem){
    cfg = {...cfg,...dokumentacijaTabLayerCfg(atradneDoks)}
  }
  if(activeTab === 'krajumi' && atradneKrajumi && atradneKrajumi.selectedItem){
    cfg = {...cfg,...krajumiTabLayerCfg(atradneKrajumi)}
  }
  // specific layers for atrasanas tab
  if(activeTab === 'atrasanas' && atradneAtrasanas && atradneAtrasanas.koords){
    cfg = {...cfg,...atrasanasTabLayerCfg(atradneAtrasanas)}
  } else if(!!atradne.id){
    cfg.atradne = {
      features: atradne,
      tooltipFunc: atradneTooltipFunc,
      title: 'Atradne',
      styles: maputils.kartinasStyles.atradneBorderCurrent,
      zoomTo: atradne.id,
      legend: maputils.defaultSinglePointLegend
    }
  }
  if(atradne.dalas && atradne.dalas.length > 0){
    cfg = {...cfg, ...atradnesDaluLayerCfg(atradne.dalas)}
  }
  return cfg
}


const getVectorLayerCfgsAtradnesDala = ({atradne, atradneAtrasanas, atradneDoks, activeTab, atradneKrajumi}) => {
  let cfg = {}
  // specific layers for dokumentacija Tab
  if(activeTab === 'dok' && atradneDoks && atradneDoks.selectedItem){
    cfg = {...cfg,...dokumentacijaTabLayerCfg(atradneDoks)}
  }
  if(activeTab === 'krajumi' && atradneKrajumi && atradneKrajumi.selectedItem){
    cfg = {...cfg,...krajumiTabLayerCfg(atradneKrajumi)}
  }
  // specific layers for atrasanas tab
  if(activeTab === 'atrasanas' && atradneAtrasanas && atradneAtrasanas.koords){
    cfg = {...cfg,...atrasanasTabLayerCfg(atradneAtrasanas, true)}
  } else if(!!atradne.id){
    cfg.dalas_robeza = {
      title: 'Atradnes daļas robeža',
      features: atradne,
      styles: maputils.kartinasStyles.atradneDalaBorderCurrent,
      tooltipFunc: (feat) => `${feat.get('nosaukums')} (${feat.get('atradnes_nosaukums')} ${feat.get('atradne')})`,
      legend: {
        features: [
            {
                label: 'Atradnes daļa',
                geomType: 'point',
                selected: false
            }
        ]
      }
    }
    cfg.dala = {
      ...cfg.dalas_robeza,
      title: 'Atradnes daļa',
      styles: maputils.kartinasStyles.atradneDalaFillCurrent,
      opacity: 0.1,
      tooltipFunc: undefined,
      zoomTo: atradne.id
    }
  }
  cfg.atradne = {
    features: {
      id: atradne.atradne,
      geom: atradne.atradnes_geom,
      nosaukums: atradne.atradnes_nosaukums,
      numurs: atradne.atradne,
      arhivets_iemesls: atradne.atradnes_arhivets_iemesls,
    },
    title: 'Atradne',
    styles: maputils.kartinasStyles.atradneFill,
    opacity: 0.1,
    tooltipFunc: atradneTooltipFunc,
    legend: maputils.defaultSinglePointLegend
    //zoomTo: !atradne.geom ? atradne.atradne : undefined
  }
  if(atradne.parent_citas_dalas && atradne.parent_citas_dalas.length > 0){
    cfg = {...cfg, ...atradnesDaluLayerCfg(atradne.parent_citas_dalas)}
  }
  return cfg
}

class AtradneActionButtons extends Component {
  constructor(props) {
      super(props);
      this.state = {
          modal: false
      };
  }
  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    return <div className="actionButtons">
              {/*<Button className="float-right" color="secondary" onClick={this.toggleModal}><i className="fa fa-print"></i>&nbsp;&nbsp;Eksportēt</Button>*/}

              <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="modal-lg">
                <ModalHeader toggle={this.toggleModal}>Atradnes eksports</ModalHeader>
                <ModalBody>
                  Te būs atradnes eksports, piemēram, uz PDF.
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleModal}>Aizvērt</Button>
                </ModalFooter>
              </Modal>
          </div>
  }
}

const filterTabs = ({tabCfg, user, atradne}) => !tabCfg.hideFunc || tabCfg.hideFunc({user, atradne}) !== true

class AtradneTabs extends Component {

  renderTabNavLinks = (activeTab, isNew, atradne, user) => {
    return Object.keys(this.tabCfgs).filter(k => filterTabs({tabCfg: this.tabCfgs[k], user, atradne})).map( k=> (
        <NavItem key={k}>
            <NavLink className={ k === activeTab ? 'active' : ''} disabled={isNew && this.tabCfgs[k].disableWhenNew}
                onClick={()=>this.props.addUrlQuery({tab: k === 'pamatinfo' ? undefined : k})}>
                {this.tabCfgs[k].title} {this.tabCfgs[k].icon ? this.tabCfgs[k].icon({atradne}) : null}
            </NavLink>
        </NavItem>
    ))
  }

  renderTabContents = (activeTab, isNew, atradne, user) => {
    return Object.keys(this.tabCfgs).filter(k => filterTabs({tabCfg: this.tabCfgs[k], user, atradne})).map( k=> {
        const Comp = this.tabCfgs[k].comp ? this.tabCfgs[k].comp : NotFound
        return <TabPane key={k} tabId={k}>
            <Comp/>
        </TabPane>
        }
    )
  }

  render() {
    const {activeTab, isNew, getTabCfgsFunc, atradne, user} = this.props
    // we cache tabs
    if(!this.tabCfgs){
      const f = getTabCfgsFunc ? getTabCfgsFunc : getTabCfgs
      this.tabCfgs = f()
    }
    return <>
        <Nav tabs>
            {this.renderTabNavLinks(activeTab, isNew, atradne, user)}
        </Nav>
        <TabContent activeTab={activeTab}>
            {this.renderTabContents(activeTab, isNew, atradne, user)}
        </TabContent>
    </>
  }
}

export class AtradneComp extends Component {

    mapPaneSizeChange = (w) => {
      this.props.guiSet('atradne_karte_width', w);
    }

    mapPaneSizeChangeHor = (h) => {
      this.props.guiSet('atradne_karte_hor_height', h);
    }

    render() {

        const {selectedItem:atradne} = this.props.atradne

        // loading while we have no atradne
        if (!atradne) {
            return (<Loading/>)
        }

        // initial cfgs can come in as props, or we can create default ones
        const {query, getVectorLayerCfgsFunc, getTabCfgsFunc, mapId,
          addUrlQuery, atradneAtrasanas, atradneDoks, atradneKrajumi, user} = this.props

        const isNew = !atradne.id
        const activeTab = query && query.tab && !isNew ? query.tab : 'pamatinfo'

        const f = getVectorLayerCfgsFunc ? getVectorLayerCfgsFunc : getVectorLayerCfgs
        const vectorLayerCfgs = f({atradne, atradneAtrasanas, atradneDoks, activeTab, atradneKrajumi})

        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

        return (<>
          { activeTab === 'geologija' || activeTab === 'kvalitate' || activeTab === 'uzzinas' || activeTab === 'izmainas' || activeTab === 'vesture' ?
            <div className="leftside-single">
                <AtradneActionButtons/>
                <AtradneTabs  activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc} addUrlQuery={addUrlQuery} atradne={atradne} user={user}/>
            </div>
          : activeTab === 'krajumi' || vw < 900 ?
             <SplitterLayout
                key="hor"
                vertical
                percentage
                primaryIndex={0}
                secondaryInitialSize={this.props.gui.atradne_karte_hor_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChangeHor}>
              <div className="topside">
                <AtradneActionButtons/>
                <AtradneTabs  activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc} addUrlQuery={addUrlQuery}  atradne={atradne}  user={user}/>
              </div>
                <IzraktenisMap mapId={mapId ? mapId : 'atradnes_kartina'} vectorLayerCfgs={vectorLayerCfgs}/>
            </SplitterLayout>
            :
            <SplitterLayout
                key="ver"
                customClassName="pane-paddings-hor"
                percentage
                primaryIndex={0}
                secondaryInitialSize={this.props.gui.atradne_karte_width}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

              <div className="leftside">
                 <AtradneTabs  activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc} addUrlQuery={addUrlQuery}  atradne={atradne}  user={user}/>
              </div>

              <div className="rightside">
                <AtradneActionButtons/>
                  <div className="karte-wrapper">
                      <IzraktenisMap mapId={mapId ? mapId : 'atradnes_kartina'} vectorLayerCfgs={vectorLayerCfgs}/>
                  </div>
              </div>
            </SplitterLayout>
          }
        </>

        )
    }
}

//connect atradnes componente with atradne state
const mapStateToProps = ({atradne, gui, location, atradneAtrasanas, atradneDoks, atradneKrajumi, user}) =>
({atradne, gui, query: location.query, atradneAtrasanas, atradneDoks, atradneKrajumi, user})

const Atradne =  connect(mapStateToProps, {addUrlQuery, guiSet})(AtradneComp)
export default Atradne

//connect atradnes componente with atradnes dala state
const mapStateToPropsAtradnesDala = ({atradnesDala, gui, location, atradnesDalaAtrasanas,
  atradnesDalaDoks, atradnesDalaKrajumi, user}) => ({atradne : atradnesDala,
  gui, query: location.query,
  getVectorLayerCfgsFunc: getVectorLayerCfgsAtradnesDala,
  getTabCfgsFunc: getTabCfgsAtradnesDala,
  atradneAtrasanas: atradnesDalaAtrasanas,
  atradneDoks: atradnesDalaDoks,
  atradneKrajumi: atradnesDalaKrajumi,
  mapId: 'atradnes_dala_kartina',
  user
})

export const AtradnesDala =  connect(mapStateToPropsAtradnesDala, {addUrlQuery, guiSet})(AtradneComp)