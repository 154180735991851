import {callApi, getDateCrudParams} from './helpers'

export const loadAtradneAudit = (query) => (dispatch) => {
    const filters = query.f ? JSON.parse( query.f) : {}
    filters.numurs_atr = query.payload.numurs
    return dispatch(callApi({
      viewName: 'audit_list_for_atradne',
      crudMethod: 'listPaging',
      crudParam: {...query, f: JSON.stringify(filters)}
    }))
}

export const loadAtradneDalaAudit = (query) => (dispatch) => {
  const filters = query.f ? JSON.parse( query.f) : {}
  filters.dala_id = Number(query.payload.dala)
  return dispatch(callApi({
    viewName: 'audit_list_for_atradnes_dala',
    crudMethod: 'listPaging',
    crudParam: {...query, f: JSON.stringify(filters)}
  }))
}


export const loadAudit = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'audit_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const loadAuditsAtradnesAtskaite = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'audit_stats_by_atradne',
    crudMethod: 'listPaging',
    crudParam: getDateCrudParams(query)
  }))
}

export const loadAuditPersonasDatuAudits = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'personas_datu_audit_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}
