import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import * as proj from 'ol/proj.js'
import { Image as ImageLayer, Tile } from 'ol/layer.js';
import TileWMS from 'ol/source/TileWMS.js'
import { Fill, Stroke, Circle, Style, Text, RegularShape } from 'ol/style.js'
import GeoJSON from 'ol/format/GeoJSON'
import * as olExtent from 'ol/extent'
import { atradnesVeids, atradnesIerakstaVeidsExtended, urbumiTehniskaisStavoklis } from '../../reducers/kodifikatorsReducers'
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import MultiPoint from 'ol/geom/MultiPoint';
import LinearRing from 'ol/geom/LinearRing';
import GeometryCollection from 'ol/geom/GeometryCollection'
import Geometry from 'ol/geom/Geometry'
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import IsValidOp from 'jsts/org/locationtech/jts/operation/valid/IsValidOp'
import ImageWMS from 'ol/source/ImageWMS'
import TileArcGISRest from 'ol/source/TileArcGISRest'
import TileLayer from 'ol/layer/Tile'
import { checkResponse, paramsToQuery } from '../../api'
import GML3 from 'ol/format/GML3'
import WKT from 'ol/format/WKT'
import { addCoordinateTransforms } from 'ol/proj'
import Feature from 'ol/Feature'
import WMTSCapabilities from 'ol/format/WMTSCapabilities'
import WMTS from 'ol/source/WMTS'
import { optionsFromCapabilities } from 'ol/source/WMTS';


// LKS92 / Latvia TM
proj4.defs("EPSG:3059", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 " +
    "+towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu")
proj4.defs("EPSG:3059R", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 " +
    "+towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu")
register(proj4)

const LVM_URL = 'http://lvmgeoserver.lvm.lv/geoserver/ows'
const GEOSERVER_URL = 'https://geoserver.lvgmc.lv/geoserver/'
export const LGIA_URL = '/lgia_arcgis'
//export const LGIA_URL = 'http://izraktenis.lvgmc.local/lgia_arcgis'

export const PROJ_GPS = proj.get('EPSG:4326')
export const PROJ_WEB = proj.get('EPSG:3857')
export const PROJ_LKS = proj.get('EPSG:3059')
export const PROJ_LKSR = proj.get('EPSG:3059R')

export const GeoJSONFormat = new GeoJSON()
export const WKTFormat = new WKT()
export const GML3Format = new GML3()

export const fromGPSToWEB = (coord) => proj.transform(coord, PROJ_GPS, PROJ_WEB)
export const fromWEBToGPS = (coord) => proj.transform(coord, PROJ_WEB, PROJ_GPS)
const fromLKSRToWEB = (coord) => proj.transform([coord[1], coord[0]], PROJ_LKS, PROJ_WEB)
const fromWEBToLKSR = (coord) => { const cNew = proj.transform(coord, PROJ_WEB, PROJ_LKS); return [cNew[1], cNew[0]]; }
addCoordinateTransforms(PROJ_LKSR, PROJ_WEB, fromLKSRToWEB, fromWEBToLKSR)

export const MAP_RESOLUTIONS = [
    156543.03392804097,
    78271.51696402048,
    39135.75848201024,
    19567.87924100512,
    9783.93962050256,
    4891.96981025128,
    2445.98490512564,
    1222.99245256282,
    611.49622628141,
    305.748113140705,
    152.8740565703525,
    76.43702828517625,
    38.21851414258813,
    19.109257071294063,
    9.554628535647032,
    4.777314267823516,
    2.388657133911758,
    1.194328566955879,
    0.5971642834779395,
    0.29858214173896974,
    0.14929107086948487,
    0.07464553543474244
]

export const createLVMLayer = (title, layerName, attributions, id) => new Tile({
    title,
    id,
    source: new TileWMS({
        url: LVM_URL,
        attributions: attributions,
        params: {
            'LAYERS': layerName,
            'FORMAT': 'image/jpeg'
        },
        serverType: 'geoserver',
        projection: PROJ_LKS
    }),
    visible: false,
    type: 'base'
})

export const getBBoxLoaderFunction = (typename, bboxUrl, urlParams, source, format, epsgCode, addLoading, addLoaded, reverseAxis) => {
    const outputFormat = format === 'json' ? '&outputFormat=application/json' : ''
    const epsg = epsgCode || 3857
    const epsgStr = reverseAxis ? epsg + 'R' : epsg + ''
    let extentTransformation = null
    if (epsgStr !== '3857') {
        const layersProj = proj.get('EPSG:' + epsgStr)
        extentTransformation = (coord) => proj.transform(coord, PROJ_WEB, layersProj)
    }
    return (extent) => {
        const extentTransformed = extentTransformation ? [...extentTransformation([extent[0], extent[1]]), ...extentTransformation([extent[2], extent[3]])] : extent
        let url = `${bboxUrl}?service=WFS&version=1.1.0&request=GetFeature&typename=${typename}${outputFormat}&srsname=EPSG:${epsg}&bbox=${extentTransformed.join(',')},EPSG:${epsg}`
        if (urlParams) {
            url = `${url}&${urlParams}`
        }
        addLoading(4)
        fetch(url).then(checkResponse).then(content => {
            addLoaded(4)
            let readOptions = {}
            if (epsgStr !== '3857') {
                readOptions = {
                    dataProjection: proj.get('EPSG:' + epsgStr),
                    featureProjection: PROJ_WEB
                }
            }
            source.addFeatures(source.getFormat().readFeatures(content, readOptions))
        }
        ).catch(err => {
            addLoaded(4)
            source.removeLoadedExtent(extent)
        }
        )
    }
}

const getGeoServerUrl = (url, urlParams, workspace, service) => (url ? url : GEOSERVER_URL + (workspace ? workspace + "/" : "") + service) + (urlParams ? `?${urlParams}` : '')
export const getGeoServerWMSUrl = (url, urlParams, workspace) => getGeoServerUrl(url, urlParams, workspace, "wms")
export const getGeoServerWFSUrl = (url, urlParams, workspace) => getGeoServerUrl(url, urlParams, workspace, "wfs")

export const createGeoServerWMSLayer = (title, layerName, layerProps, url, epsgCode, attributions, legend) => {
    const layersProj = epsgCode ? proj.get('EPSG:' + epsgCode) : PROJ_WEB
    const tiled = layerProps && layerProps.tiled === true
    const sourceData = {
        crossOrigin: 'anonymous',
        url,
        params: {
            'LAYERS': layerName,
            'FORMAT': 'image/png8'
        },
        ratio: 1.2,
        serverType: 'geoserver',
        projection: layersProj,
        attributions
    }
    const source = tiled ? new TileWMS(sourceData) : new ImageWMS(sourceData)
    const legendImage = legend && legend.image ? legend.image : null
    const legendWmsParams = legend && legend.wms_params ? legend.wms_params : {}
    const layerData = {
        title: title,
        id: layerName,
        legend: legendImage ? `<img src=${legendImage} alt="apzīmējumi" />` : `<img src="${source.getLegendUrl(10, { legend_options: 'wrap_limit:310;wrap:true;fontSize:11', ...legendWmsParams })}" alt="Apzīmējumi slānim ${title}">`,
        source,
        ...layerProps
    }
    return tiled ? new Tile(layerData) : new ImageLayer(layerData)
}

export const createWMSLayer = (title, layerName, layerProps, wmsSourceProps, epsgCode) => {
    const layersProj = epsgCode ? proj.get('EPSG:' + epsgCode) : PROJ_WEB
    return new ImageLayer({
        title: title,
        id: layerName,
        source: new ImageWMS({
            projection: layersProj,
            ...wmsSourceProps
        }),
        ...layerProps
    })
}


export const createWMTSLayer = (title, layerName, url, layerProps, wmtsOptions, wmtsSourceProps, addLoading, addLoaded) => {
    const layer = new TileLayer({
        title: title,
        id: layerName,
        source: undefined,
        ...layerProps
    })
    fetch(`${LGIA_URL}${url}?request=getCapabilities&service=WMTS`)
        .then((response) => response.text())
        .then((text) => {
            const parser = new WMTSCapabilities();
            const result = parser.read(text);
            const options = optionsFromCapabilities(result, wmtsOptions);
            //rewrite urls
            if (options.urls && Array.isArray(options.urls)) {
                options.urls = options.urls.map(wurl =>
                    `${LGIA_URL}${url}${wurl.substring(wurl.indexOf(url) + url.length)}`
                )
            }
            const src = new WMTS({ ...options, ...wmtsSourceProps })
            layer.setSource(src)
            src.on('tileloadstart', addLoading)
            src.on('tileloadend', addLoaded)
            src.on('tileloaderror', addLoaded)
        })
        .catch((error) => console.error("Error fetching WMTS:", error));
    return layer;
}


export const getFeatureInfo = (wmsSource, coord, viewResolution, addLoading, addLoaded) => {
    const infoUrl = wmsSource.getFeatureInfoUrl(
        coord,
        viewResolution,
        PROJ_WEB,
        { 'INFO_FORMAT': 'application/json', 'feature_count': 10 }
    )
    addLoading(2)
    return fetch(infoUrl).then(checkResponse).then(content => {
        const features = GeoJSONFormat.readFeatures(content)
        addLoaded(2)
        return features
    }
    ).catch(err => {
        addLoaded(2)
        console.log("errors:")
        console.log(err)
        return []
    })
}

const readOptionsWFS = {
    dataProjection: PROJ_LKS,
    featureProjection: PROJ_WEB
}

export const searchGeoServerWFS = ({ url, workspace, typename, filter, extraParams = {} }) => {

    const baseUrl = getGeoServerWFSUrl(url, null, workspace)

    const params = {
        request: 'GetFeature',
        service: 'WFS',
        version: '2.0.0',
        outputFormat: 'application/json',
        count: 20,
        typename,
        cql_filter: filter,
        ...extraParams
    }

    const wfsUrl = `${baseUrl}${paramsToQuery(params)}`

    return fetch(wfsUrl).then(checkResponse).then(content => GeoJSONFormat.readFeatures(content, readOptionsWFS)
    ).catch(err => {
        console.log("errors:")
        console.log(err)
        return []
    })
}

export const createArcGISLayer = (title, layerName, layerProps, url) => new TileLayer({
    title: title,
    id: layerName,
    source: new TileArcGISRest({
        crossOrigin: 'anonymous',
        url: url,
        /*params: {
          LAYERS: `show:${layerProps.idx}`
        },*/
        projection: PROJ_LKS
    }),
    ...layerProps
})

const getFillStrokeProps = ({ color = "#FF7F50", strokeColor = "#333333", strokeWidth = 1, lineDash }) => ({
    ...getStrokeProps({ strokeColor, strokeWidth, lineDash }),
    fill: new Fill({
        color: color
    })
})

const getStrokeProps = ({ strokeColor = "#333333", strokeWidth = 1, lineDash }) => ({
    stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
        lineDash
    })
})

const getCircleStyle = ({ color = "#FF7F50", strokeColor = "#333333", radius = 5, strokeWidth = 1, lineDash }) => new Circle({
    ...getFillStrokeProps({ color, strokeColor, strokeWidth, lineDash }),
    radius: radius
})

const getSquareStyle = ({ color = "#FF7F50", strokeColor = "#333333", radius = 5, strokeWidth = 1, lineDash }) => new RegularShape({
    ...getFillStrokeProps({ color, strokeColor, strokeWidth, lineDash }),
    points: 4,
    radius: radius,
    angle: Math.PI / 4,
})

export const createDefaultVectorStyle = (cfgExtra) => new Style({
    image: getCircleStyle({}),
    ...cfgExtra
})

export const createDefaultSelectStyle = (cfgExtra) => new Style({
    image: getCircleStyle({ color: "#DC143C", radius: 6 }),
    ...cfgExtra
})

const createAtradnesStyleFunc = (withBorder = true, withFill = true) => {
    //cache styles
    const cachedStyles = {}
    //return style function
    return (feature) => {
        const selected = feature.get('selected')
        const ierakstaVeids = feature.get('ieraksta_veids')
        let stColor = "#000000"
        if (atradnesIerakstaVeidsExtended && atradnesIerakstaVeidsExtended[ierakstaVeids] && atradnesIerakstaVeidsExtended[ierakstaVeids].mapColor)
            stColor = atradnesIerakstaVeidsExtended[ierakstaVeids].mapColor
        const veids = feature.get('veids') || 'B'
        const key = `${veids}_${ierakstaVeids}_${selected === true ? 'T' : 'F'}_${withBorder ? 'T' : 'F'}_${withFill ? 'T' : 'F'}`
        if (!cachedStyles[key]) {
            const colors = veids.split('').map(v => atradnesVeids[v].mapColor)
            const veidsColor = colors.length === 1 ? colors[0] : hexAverage(colors)
            const color = selected === true ? veidsColor + "FF" : veidsColor + "CC"
            const strokeColor = selected === true ? "#ff3300" : stColor //atradnesIerakstaVeidsExtended[ierakstaVeids].mapColor
            const strokeWidth = selected === true ? 2.0 : 1.6
            const circle = getCircleStyle({
                color: withFill ? color : "#ffffff00",
                strokeColor: withBorder ? strokeColor : "#ffffff00",
                strokeWidth,
                radius: selected === true ? 6 : 5
            })
            cachedStyles[key] = new Style({
                ...getFillStrokeProps({
                    color: withFill ? color : "#ffffff00",
                    strokeColor: withBorder ? strokeColor : "#ffffff00",
                    strokeWidth
                }),
                image: circle
            })
        }
        return cachedStyles[key]
    }
}

const createUrbumiStyleFunc = (withBorder = true, withFill = true) => {
    //cache styles
    const cachedStyles = {}
    //return style function
    return (feature) => {
        const selected = feature.get('selected')
        let stColor = "#000000"
        let stColorPieder = "#00ff88"
        let stColorNepieder = "#ff3300"
        const veids = feature.get('tehniskais_stavoklis') || 'default'
        const pieder_atradnei = feature.get('pieder_atradnei')
        const key = `${veids}_${selected === true ? 'T' : 'F'}_${pieder_atradnei}_${withBorder ? 'T' : 'F'}_${withFill ? 'T' : 'F'}`
        if (!cachedStyles[key]) {
            const veidsColor = urbumiTehniskaisStavoklis[veids].mapColor
            const color = selected === true ? veidsColor + "BB" : veidsColor + "88"
            const strokeColor = selected === true ? stColor : pieder_atradnei ? stColorPieder : stColorNepieder //atradnesIerakstaVeidsExtended[ierakstaVeids].mapColor
            const strokeWidth = selected === true ? 1.6 : 1
            const circle = getCircleStyle({
                color: withFill ? color : "#ffffff00",
                strokeColor: withBorder ? strokeColor : "#ffffff00",
                strokeWidth,
                radius: selected === true ? 6 : 5
            })
            const square = getSquareStyle({
                color: withFill ? color : "#ffffff00",
                strokeColor: withBorder ? strokeColor : "#ffffff00",
                strokeWidth,
                radius: selected === true ? 6 : 5
            })
            cachedStyles[key] = new Style({
                ...getFillStrokeProps({
                    color: withFill ? color : "#ffffff00",
                    strokeColor: withBorder ? strokeColor : "#ffffff00",
                    strokeWidth
                }),
                image: pieder_atradnei ? square : circle
            })
        }
        return cachedStyles[key]
    }
}

//cache styles
const cachedStylesForKartina = {}
const createAtradnesStyleFuncForKartina = ({ withBorder = true, withFill = true, isCurrent = false, isDala = false, isSaistita = false }) => {

    //return style function
    return (feature) => {
        const isArhiveta = feature.get('arhivets_iemesls_nosaukums') || feature.get('arhivets_iemesls')
        const key = `${withBorder ? 'T' : 'F'}_${withFill ? 'T' : 'F'}_${isCurrent ? 'T' : 'F'}_${isDala ? 'T' : 'F'}_${isSaistita ? 'T' : 'F'}_${isArhiveta ? 'T' : 'F'}`
        if (!cachedStylesForKartina[key]) {
            let color = "#0066ff"
            let strokeWidth = 2
            let radius = 5
            let lineDash
            if (isSaistita) { color = "#00004d"; strokeWidth = 1 }
            if (!!isArhiveta) { color = "#666633"; strokeWidth = 1 }
            if (isDala) { lineDash = [5, 5] }
            if (isCurrent) { color = "#ff3300"; strokeWidth = 3; radius = 6 }

            const circle = getCircleStyle({
                color: withFill ? color : "#ffffff01",
                strokeColor: withBorder ? color : "#ffffff00",
                strokeWidth,
                radius,
                lineDash
            })
            cachedStylesForKartina[key] = new Style({
                ...getFillStrokeProps({
                    color: withFill ? color : "#ffffff01",
                    strokeColor: withBorder ? color : "#ffffff00",
                    strokeWidth,
                    lineDash
                }),
                image: circle
            })
        }
        return cachedStylesForKartina[key]
    }
}

export const kartinasStyles = {}

const makeStyleObj = (args) => ({
    default: createAtradnesStyleFuncForKartina(args),
    select: {},
    over: {}
})

kartinasStyles.atradneFill = makeStyleObj({ withBorder: false })
kartinasStyles.atradneBorder = makeStyleObj({ withFill: false })
kartinasStyles.atradneFillCurrent = makeStyleObj({ withBorder: false, isCurrent: true })
kartinasStyles.atradneBorderCurrent = makeStyleObj({ withFill: false, isCurrent: true })

kartinasStyles.atradneDalaFill = makeStyleObj({ withBorder: false, isDala: true })
kartinasStyles.atradneDalaBorder = makeStyleObj({ withFill: false, isDala: true })
kartinasStyles.atradneDalaFillCurrent = makeStyleObj({ withBorder: false, isCurrent: true, isDala: true })
kartinasStyles.atradneDalaBorderCurrent = makeStyleObj({ withFill: false, isCurrent: true, isDala: true })

export const atradnesStyles = {
    default: createAtradnesStyleFunc(),
    select: {},
    over: {}
}

export const atradnesStylesBorder = {
    default: createAtradnesStyleFunc(true, false),
    select: {},
    over: {}
}

export const atradnesStylesFill = {
    default: createAtradnesStyleFunc(false, true),
    select: {},
    over: {}
}

export const defaultStyles = {
    default: createDefaultVectorStyle(),
    select: createDefaultSelectStyle(),
    over: {}
}

const borderPointStyle = new Style({
    image: getCircleStyle({
        color: '#00ffffaa',
        strokeColor: '#666666',
        strokeWidth: 1,
        radius: 3
    })
})

const borderPointStyleSelected = new Style({
    image: getCircleStyle({
        color: '#00ffffdd',
        strokeColor: '#ff3300',
        strokeWidth: 1.5,
        radius: 4
    })
})

const borderPointStyleFunc = (feature) => {
    const selected = feature.get('selected')
    if (selected) {
        return borderPointStyleSelected
    }
    return borderPointStyle
}

export const borderPointStyles = {
    default: borderPointStyleFunc,
    select: {},
    over: {}
}

const pointStyle = new Style({
    image: getCircleStyle({
        color: '#ff00ffcc',
        strokeColor: '#ffffff',
        strokeWidth: 1.5,
        radius: 5
    })
})

const pointStyleSelected = new Style({
    image: getCircleStyle({
        color: '#ff00ff',
        strokeColor: '#ff3300',
        strokeWidth: 2,
        radius: 6
    })
})

const pointStyleFunc = (feature) => {
    const selected = feature.get('selected')
    if (selected) {
        return pointStyleSelected
    }
    return pointStyle
}

export const pointStyles = {
    default: pointStyleFunc,
    select: {},
    over: {}
}

const createDokStyle = ({ selected, color, withFill, withBorder }) => {
    const fillColor = withFill ? (selected ? color + '22' : color + '11') : '#ffffff01'
    const strokeColor = withBorder ? (selected ? '#ff0000' : color) : '#ffffff00'
    const radius = selected ? 6 : 4
    const strokeWidth = selected ? 3 : 1.5
    return new Style({
        ...getFillStrokeProps({ color: fillColor, strokeColor, strokeWidth }),
        image: getCircleStyle({
            color: fillColor,
            strokeColor,
            strokeWidth,
            radius
        })
    })
}

const createDokStyleObj = ({ color, withFill = true, withBorder = true }) => {
    const dokStyle = createDokStyle({ color, withFill, withBorder })
    const dokStyleSelected = createDokStyle({ color, selected: true, withFill, withBorder })
    const dokStyleFunc = (feature) => {
        const selected = feature.get('selected')
        if (selected) {
            return dokStyleSelected
        }
        return dokStyle
    }
    return {
        default: dokStyleFunc,
        select: {},
        over: {}
    }
}

export const dokStyles = {}

dokStyles.dokPaseStyles = createDokStyleObj({ color: '#ff9900' })
dokStyles.dokLimitiStyles = createDokStyleObj({ color: '#cc33ff' })
dokStyles.dokLicencesStyles = createDokStyleObj({ color: '#3399ff' })
dokStyles.dokPaseStylesBorder = createDokStyleObj({ color: '#ff9900', withFill: false })
dokStyles.dokLimitiStylesBorder = createDokStyleObj({ color: '#cc33ff', withFill: false })
dokStyles.dokLicencesStylesBorder = createDokStyleObj({ color: '#3399ff', withFill: false })
dokStyles.dokPaseStylesFill = createDokStyleObj({ color: '#ff9900', withBorder: false })
dokStyles.dokLimitiStylesFill = createDokStyleObj({ color: '#cc33ff', withBorder: false })
dokStyles.dokLicencesStylesFill = createDokStyleObj({ color: '#3399ff', withBorder: false })

dokStyles.dokParskatsStyles = createDokStyleObj({ color: '#ff9900' })
dokStyles.dokParskatsStylesBorder = createDokStyleObj({ color: '#ff9900', withFill: false })
dokStyles.dokParskatsStylesFill = createDokStyleObj({ color: '#ff9900', withBorder: false })

dokStyles.dokKrajumiStyles = createDokStyleObj({ color: '#cc9900' })
dokStyles.dokKrajumiStylesBorder = createDokStyleObj({ color: '#cc9900', withFill: false })
dokStyles.dokKrajumiStylesFill = createDokStyleObj({ color: '#cc9900', withBorder: false })

export const auditStyle = createDokStyleObj({ color: '#33eeff', withFill: false })
export const urbumiStyles = {
    default: createUrbumiStyleFunc(),
    select: {},
    over: {}
}
export const urbumiLegend = {
    features: [
        {
            label: 'Aiztamponēts vai likvidēts pieder atradnei',
            geomType: 'point',
            pieder_atradnei: true,
            tehniskais_stavoklis: 'likvidēts'
        },
        {
            label: 'Cits tehniskais stāvoklis pieder atradnei',
            geomType: 'point',
            pieder_atradnei: true,
            tehniskais_stavoklis: 'default'
        },
        {
            label: 'Aiztamponēts vai likvidēts nepieder atradnei',
            geomType: 'point',
            pieder_atradnei: false,
            tehniskais_stavoklis: 'likvidēts'
        },
        {
            label: 'Cits tehniskais stāvoklis nepieder atradnei',
            geomType: 'point',
            pieder_atradnei: false,
            tehniskais_stavoklis: 'default'
        }
    ]
}


const borderPointLabelStyle = new Style({
    text: new Text({
        font: '10px Calibri,sans-serif',
        offsetX: 8,
        offsetY: -8,
        fill: new Fill({
            color: '#000'
        }),
        stroke: new Stroke({
            color: '#fff',
            width: 1
        })
    })
});

const dbNumberLabelStyle = new Style({
    text: new Text({
        font: '13px Avenir,sans-serif',
        offsetX: 0,
        offsetY: 20,
        fill: new Fill({
            color: '#000'
        }),
        stroke: new Stroke({
            color: '#fff',
            width: 1
        })
    })
});


export const borderPointStylesLabels = {
    default: (feat) => {
        borderPointLabelStyle.getText().setText(`${feat.get('id')}`)
        return borderPointLabelStyle
    },
    select: {},
    over: {}
}

export const dbNumberLabelStyleLabel = {
    default: (feat) => {
        dbNumberLabelStyle.getText().setText(`${feat.get('id')}`)
        return dbNumberLabelStyle
    },
    select: {},
    over: {}
}

const borderFeatureStyle = new Style({
    ...getFillStrokeProps({ color: '#00ffff66', strokeColor: '#666666', strokeWidth: 1 }),
    image: getCircleStyle({
        color: '#00ffff66',
        strokeColor: '#666666',
        strokeWidth: 1,
        radius: 6
    })
})

export const borderFeatureStyles = {
    default: borderFeatureStyle,
    select: {},
    over: {}
}

const zemesVienibasLabel = new Text({
    font: '10px Calibri,sans-serif',
    fill: new Fill({
        color: '#000'
    }),
    stroke: new Stroke({
        color: '#00ffff',
        width: 1
    })
})

const zemesVienibasParcelType1 = new Style({
    ...getFillStrokeProps({ color: '#00ffff00', strokeColor: '#00ffff', strokeWidth: 1.5 }),
    text: zemesVienibasLabel
})
const zemesVienibasParcelType2 = new Style({
    ...getFillStrokeProps({ color: '#00ffff00', strokeColor: '#00ffff', strokeWidth: 1.5, lineDash: [8, 8] }),
    text: zemesVienibasLabel
})
const zemesVienibasParcelType3 = new Style({
    ...getFillStrokeProps({ color: '#00ffff00', strokeColor: '#00ffff', strokeWidth: 1.5, lineDash: [2, 3] }),
    text: zemesVienibasLabel
})
const zemesVienibasParcelTypeNone = new Style({
    ...getFillStrokeProps({ color: '#ff000000', strokeColor: '#ff0000', strokeWidth: 1.5 }),
    text: zemesVienibasLabel
})

const getStrokeStyle = (props) => {
    return {
        default: new Style({
            ...getStrokeProps(props)
        }),
        select: {},
        over: {}
    };
}

const getFillStrokeStyle = (props, propsSelected) => {
    const style = new Style({
        ...getFillStrokeProps(props)
    })
    let defaultStyle = style
    if (propsSelected) {
        const styleSelected = new Style({
            ...getFillStrokeProps(propsSelected)
        })
        defaultStyle = (feature) => {
            const selected = feature.get('selected')
            if (selected) {
                return styleSelected
            }
            return style
        }
    }
    return {
        default: defaultStyle,
        select: {},
        over: {}
    }
}

export const uoStyles = {}

uoStyles.uoStyle = getFillStrokeStyle({
    color: '#00ffff66',
    strokeColor: '#3399ff',
    strokeWidth: 1.7
}, {
    color: '#b833ff66',
    strokeColor: '#b833ff',
    strokeWidth: 2.2
})

const uoStyleText = ({ selected }) => new Text({
    font: 'bold 8pt Arial, sans-serif',
    fill: new Fill({
        color: selected ? '#8124b3' : '#18324d'
    }),
    stroke: new Stroke({
        color: '#fff',
        width: 2.0
    }),
    overflow: true,
    padding: [3, 3, 3, 3]
})
const uoLabelsStyleNonSelected = new Style({
    text: uoStyleText({ selected: false })
})
const uoLabelsStyleSelected = new Style({
    text: uoStyleText({ selected: true })
})
uoStyles.uoLabelsStyle = {
    default: (feature) => {
        const selected = feature.get('selected')
        const style = selected ? uoLabelsStyleSelected : uoLabelsStyleNonSelected
        style.getText().setText(`${feature.get('nosaukums')} (${feature.get('kods')})`)
        return style
    },
    select: {},
    over: {}
}
uoStyles.ubaStyle = getStrokeStyle({
    strokeColor: '#3399ff',
    strokeWidth: 1.5
})
uoStyles.usikStyle = getFillStrokeStyle({
    strokeColor: '#c44123',
    strokeWidth: 1.5,
    color: '#c4412333',
})

uoStyles.udens2Style = {
    default: (feature) => {
        const selected = feature.get('selected')
        const ir_parsniegts = feature.get('ir_parsniegts')
        const strokeColor = selected === true ? "#ff3300" : '#2f353a'
        const strokeWidth = selected === true ? 1.6 : 1
        const color = ir_parsniegts ? '#ff0000' : '#00ffffaa'
        const circle = getCircleStyle({
            color: color,
            strokeColor: strokeColor,
            strokeWidth: strokeWidth,
            radius: selected === true ? 6 : 5
        })
        return new Style({
            ...getFillStrokeProps({
                color: "#ffffff00",
                strokeColor: strokeColor,
                strokeWidth
            }),
            image: circle
        })
    },
    select: {},
    over: {}
}

uoStyles.stacijas = {
    default: (feature) => {
        const selected = feature.get('selected')
        const strokeColor = selected === true ? "#ff3300" : '#2f353a'
        const strokeWidth = selected === true ? 1.6 : 1
        const color = '#00ffffaa'
        const circle = getCircleStyle({
            color: color,
            strokeColor: strokeColor,
            strokeWidth: strokeWidth,
            radius: selected === true ? 6 : 5
        })
        return new Style({
            ...getFillStrokeProps({
                color: "#00ffffaa",
                strokeColor,
                strokeWidth
            }),
            image: circle
        })
    },
}

uoStyles.iekartas = {
    default: new Style({
        ...getFillStrokeProps({
            color: "#ff3300",
            strokeColor: '#ff3300',
            strokeWidth: 2
        }),
        image: getCircleStyle({
            color: '#ff3300',
            strokeColor: '#ff3300',
            strokeWidth: 2,
            radius: 5
        })
    })
}

export const zemesVienibasStyle = {
    default: (feature) => {
        let zvStyle;
        switch (feature.get('objectcode')) {
            case '7201060110':
                zvStyle = zemesVienibasParcelType1
                break;
            case '7201060210':
                zvStyle = zemesVienibasParcelType2
                break;
            case '7201060310':
                zvStyle = zemesVienibasParcelType3
                break;
            default:
                zvStyle = zemesVienibasParcelTypeNone
        }
        zvStyle.getText().setText(`${feature.get('code')}`)
        return zvStyle
    },
    select: {},
    over: {}
}

const zemesVienibasDalaLabel = new Text({
    font: '10px Calibri,sans-serif',
    fill: new Fill({
        color: '#000'
    }),
    stroke: new Stroke({
        color: '#e97e25',
        width: 1
    })
})

const zemesVienibasDalaStylePlain = new Style({
    ...getFillStrokeProps({ color: '#e97e2500', strokeColor: '#e97e25', strokeWidth: 1.0 }),
    text: zemesVienibasDalaLabel
})

export const zemesVienibasDalaStyle = {
    default: (feature) => {
        zemesVienibasDalaStylePlain.getText().setText(`${feature.get('code')}`)
        return zemesVienibasDalaStylePlain
    },
    select: {},
    over: {}
}

const zemesVienibasKludainasLabel = new Text({
    font: '10px Calibri,sans-serif',
    fill: new Fill({
        color: '#000'
    }),
    stroke: new Stroke({
        color: '#cc00ff',
        width: 1
    })
})

const zemesVienibasKludainasStylePlain = new Style({
    ...getFillStrokeProps({ color: '#cc00ff00', strokeColor: '#cc00ff', strokeWidth: 1.2, lineDash: [2, 2] }),
    text: zemesVienibasKludainasLabel
})


export const zemesVienibasKludainasStyle = {
    default: (feature) => {
        zemesVienibasKludainasStylePlain.getText().setText(`${feature.get('code')}`)
        return zemesVienibasKludainasStylePlain
    },
    select: {},
    over: {}
}

export const ekasStyle = {
    default: new Style({
        ...getFillStrokeProps({ color: '#80008033', strokeColor: '#800080', strokeWidth: 0.8 })
    }),
    select: {},
    over: {}
}

export const featureInfoStyle = {
    default: new Style({
        ...getFillStrokeProps({ color: '#ff000033', strokeColor: '#ff0000', strokeWidth: 2 }),
        image: getCircleStyle({
            color: '#ff000000',
            strokeColor: '#ff0000',
            strokeWidth: 2,
            radius: 6
        })
    }),
    select: {},
    over: {}
}

export const searchedFeaturesStyle = {
    default: new Style({
        ...getFillStrokeProps({ color: '#ed580233', strokeColor: '#ed5802', strokeWidth: 2 }),
        image: getCircleStyle({
            color: '#ed580200',
            strokeColor: '#ed5802',
            strokeWidth: 2,
            radius: 6
        })
    }),
    select: {},
    over: {}
}

export const getExtentFromXYZAndMapSize = (x, y, z, mapSize) => {
    //convert to numbers
    console.log(`getExtentFromXYZAndMapSize ${x} ${y} ${z} ${mapSize[0]} ${mapSize[1]}`)
    return olExtent.getForViewAndSize(fromGPSToWEB([Number(x), Number(y)]), MAP_RESOLUTIONS[Number(z)], 0, mapSize)
}

export const zoomGeometries = (view, geoms) => {
    if (geoms && geoms.length > 0) {
        const maxZoom = geoms.length === 1 && geoms[0] instanceof Point ? 13 : 17
        zoomGeometry(view, new GeometryCollection(geoms).getExtent(), maxZoom)
    }
}

export const zoomGeometry = (view, geom, maxZoom) => {

    // console.log("FIT GEOM")

    const currentZoom = view.getZoom();
    // console.log(currentZoom)

    var fitGeom = function () {
        view.fit(geom, {
            maxZoom,
            duration: 600
        });
    }
    var timeoutedFitGeom = function () {
        window.setTimeout(fitGeom, 300);
    }

    //zoom back
    if (currentZoom > 7) {
        var zoomOutTo = 7;
        if (olExtent.containsExtent(view.calculateExtent(), Array.isArray(geom) ? geom : geom.getExtent())) {
            zoomOutTo = currentZoom - 1;
        }
        view.animate({
            zoom: zoomOutTo,
            duration: 600
        }, timeoutedFitGeom);
    } else {
        timeoutedFitGeom();
    }
}

const padToTwo = (numberString) => {
    if (numberString.length < 2) {
        numberString = '0' + numberString;
    }
    return numberString;
}

export const hexAverage = (colorsArr) => {
    return colorsArr.reduce((previousValue, currentValue) => {
        return currentValue
            .replace(/^#/, '')
            .match(/.{2}/g)
            .map((value, index) => {
                return previousValue[index] + parseInt(value, 16);
            });
    }, [0, 0, 0])
        .reduce((previousValue, currentValue) => {
            return previousValue + padToTwo(Math.floor(currentValue / colorsArr.length).toString(16));
        }, '#');
}

const polygonToKoordArr = (poly, cnt = 1) => {
    const koords = []
    poly.getLinearRings().forEach((r, rInd) => {
        const rCoords = r.getCoordinates()
        const lastInd = rCoords.length - 1
        rCoords.forEach((c, cInd) => {
            //skip last coord as it is the same as first one
            if (cInd !== lastInd) {
                const koord = {
                    id: cnt++,
                    x: c[0],
                    y: c[1],
                    startsHole: false,
                    startsPoly: false,
                }
                if (rInd > 0 && cInd === 0) {
                    koord.startsHole = true
                }
                koords.push(koord)
            }
        })
    })
    return koords
}

export const fromOlGeomToKoordArr = (geom) => {
    let koords = []
    // if point add one koord row
    if (geom instanceof Point) {
        koords.push({
            id: 1,
            x: geom.getCoordinates()[0],
            y: geom.getCoordinates()[1],
            startsHole: false,
            startsPoly: false,
        })
    } else if (geom instanceof MultiPoint) {
        koords = geom.getCoordinates().map((c, i) => ({
            id: i + 1,
            x: c[0],
            y: c[1]
        }))
    } else if (geom instanceof Polygon) {
        koords = polygonToKoordArr(geom)
    } else if (geom instanceof MultiPolygon) {
        let cnt = 1
        geom.getPolygons().forEach((p, pInd) => {
            const polyKoords = polygonToKoordArr(p, cnt)
            if (pInd > 0) {
                polyKoords[0].startsPoly = true
            }
            koords = koords.concat(polyKoords)
            cnt = cnt + polyKoords.length
        })
    }
    return koords
}

export const fromKoordArrToOlGeom = (koords, strict, geomType) => {
    if (koords.length === 0) {
        if (strict) {
            throw new Error('Ģeometrijai jāsatur vismaz viena koordināte')
        }
        return null
    }
    if (geomType === 'MultiPoint') {
        return new MultiPoint(koords.map(k => [k.x, k.y]))
    }
    if (koords.length < 3) {
        if (strict && koords.length === 2) {
            throw new Error('Ģeometrijai jāsatur viena koordināte (punktam) vai vismaz trīs koordinātes (daudzstūrim)')
        }
        return new Point([koords[0].x, koords[0].y])
    }

    const polygons = []
    let polyKoords = []
    let currentType = 'P'
    let parentPoly = null

    const appendKoords = () => {
        if (polyKoords.length > 2) {
            const ring = [...polyKoords, [...polyKoords[0]]]
            if (currentType === 'P') {
                parentPoly = new Polygon([ring])
                polygons.push(parentPoly)
            }
            if (currentType === 'H' && parentPoly) {
                const r = new LinearRing(ring)
                parentPoly.appendLinearRing(r)
            }
        } else if (strict) {
            throw new Error('Daudzstūra ģeometrijas lokam jāsastāv vismaz no trīs koordinātēm')
        }
    }

    koords.forEach(k => {
        if (k.startsHole || k.startsPoly) {
            appendKoords()
            currentType = k.startsHole ? 'H' : 'P'
            polyKoords = []
        }
        polyKoords.push([k.x, k.y])
    })
    appendKoords()
    return new MultiPolygon(polygons)
}

export const getGeometryFromFeatureObj = (f, epsgCode) => {
    if (f.koordsArr) {
        const koordsGeom = fromKoordArrToOlGeom(f.koordsArr, false, f.geomType)
        return koordsGeom.transform(PROJ_LKS, PROJ_WEB)
    }
    if (f.geom) {
        const geom = GeoJSONFormat.readGeometry(f.geom)
        if (epsgCode && epsgCode !== 3857) {
            const layersProj = proj.get('EPSG:' + epsgCode)
            return geom.transform(layersProj, PROJ_WEB)
        }
        return geom
    }
    if (f.geometry && f.geometry instanceof Geometry) {
        return f.geometry
    }
    if (f.x !== undefined & f.y !== undefined) {
        return (new Point([f.x, f.y])).transform(PROJ_LKS, PROJ_WEB)
    }
    return null
}

export const validateKoords = (koords, geomType) => {
    try {
        const olGeom = fromKoordArrToOlGeom(koords, true, geomType)
        const parser = new OL3Parser();
        let jstsGeom
        if (olGeom instanceof Point) {
            jstsGeom = parser.convertFromPoint(olGeom)
        } else if (olGeom instanceof MultiPoint) {
            jstsGeom = parser.convertFromMultiPoint(olGeom)
        } else if (olGeom instanceof Polygon) {
            jstsGeom = parser.convertFromPolygon(olGeom)
        } else if (olGeom instanceof MultiPolygon) {
            jstsGeom = parser.convertFromMultiPolygon(olGeom)
        }
        const isValidOp = new IsValidOp(jstsGeom);
        const error = isValidOp.getValidationError()
        if (error) {
            return `Ģeometrijas validācija: ${error.toString()}`
        }
    } catch (err) {
        console.log(err)
        return `Ģeometrijas validācija: ${err.message}`
    }
    return false
}

export const guidGenerator = () => {
    const S4 = () => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

const getSvgIconFromFeature = (feature, style) => {
    const olFeature = new Feature({ selected: false, ...feature })
    const fStyle = typeof style === 'function' ? style(olFeature) : style
    const { geomType } = feature
    if (geomType === 'point') {
        const imageStyle = fStyle.getImage()
        if (imageStyle) {
            const fillColor = feature.noFill === true || !imageStyle.getFill() ? 'none' : imageStyle.getFill().getColor()
            const strokeColor = imageStyle.getStroke() ? imageStyle.getStroke().getColor() : 'none'
            const strokeWidth = imageStyle.getStroke() ? imageStyle.getStroke().getWidth() : 0
            const radius = imageStyle.getRadius()
            if (imageStyle instanceof Circle) {
                return `<svg height="24" width="24">
                <circle fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" cx="12" cy="12" r="${radius}" />
                </svg>`
            } else if (imageStyle instanceof RegularShape && imageStyle.getPoints() === 4) {
                return `<svg height="24" width="24">
                <rect x="6" y="6" width="${radius * 2}" height="${radius * 2}"  fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}"/>
                </svg>`
            }
        }
        return 'jāpievieno simbolizācijas veids'
    }
    const fillColor = fStyle.getFill() ? fStyle.getFill().getColor() : 'none'
    const strokeColor = fStyle.getStroke() ? fStyle.getStroke().getColor() : 'none'
    const strokeWidth = fStyle.getStroke() ? fStyle.getStroke().getWidth() : 0
    const strokeDash = fStyle.getStroke() && fStyle.getStroke().getLineDash() ? fStyle.getStroke().getLineDash().join(' ') : 'none'
    if (geomType === 'line') {
        return `<svg height="24" width="24">
			<line fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" stroke-dasharray="${strokeDash}" strokeLinecap={'round'} strokeMiterlimit={10} x1="3" y1="21" x2="21" y2="3" />
		</svg>`
    }
    if (geomType === 'polygon') {
        return `<svg height="24" width="24">
			<rect x="3" y="3" width="16" height="16"  fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" stroke-dasharray="${strokeDash}"/>
		</svg>`
    }

}

export const getVectorLegend = (legendCfg, style, title) => {
    if (legendCfg.features) {
        return legendCfg.features.map(f => `<p>${getSvgIconFromFeature(f, style)} ${f.label ?? title}</p>`).join('')
    }
    return ''
}

export const defaultSinglePolygonLegend = {
    features: [
        {
            geomType: 'polygon'
        }
    ]
}

export const defaultSinglePointLegend = {
    features: [
        {
            geomType: 'point'
        }
    ]
}

export const objectToCoords = (coords) => {
    return coords.map((o) => ([o.x, o.y]))
}


export const coordsToObject = (geom) => {
    const coords = geom.getCoordinates()
    const [x, y] = coords
    switch (geom.getType()) {
        case 'Point':
            return [{
                id: 1,
                x,
                y
            }]
        case 'LineString':
        case 'MultiPoint':
            return coords.map(([x, y], id) => {
                return {
                    id: id + 1,
                    x,
                    y
                }
            })
        default:
            return coords[0].slice(0, coords[0].length - 1).map(([x, y], id) => {
                return {
                    id: id + 1,
                    x,
                    y
                }
            })
    }
}
