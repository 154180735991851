import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {
  raditajiKimiskaActions, raditajsKimiskaSelected
} from '../../actions/raditajiActions'
import {
  getTextFilterCfg,
  getFormatterForKodif, getSelectFilterCfg
} from '../grid/ColumnHelpers'
import {
  getDefaultRendererForMultiField,
  getKlasifikatorsFieldCfg,
  getKodifRenderer
} from '../forms/FormHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {FormGroup} from "reactstrap";
import {InputField} from "../forms/Form";

const getColumns = ({kodif, vvisKodifs}) => {
  return [
    {
      dataField: 'matrica',
      text: 'Matrica',
      width: 80,
      filterCfg: getSelectFilterCfg(kodif.kimiskaMatrica),
      formatter: getFormatterForKodif(kodif.kimiskaMatrica)
    }, {
      dataField: 'vide',
      text: 'Vide (VVIS)',
      width: 100,
      filterCfg: getSelectFilterCfg(vvisKodifs.B18)
    }, {
      dataField: 'grupa',
      text: 'Vielu grupa',
      width: 100,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums_mk',
      text: 'Vielas nosaukums MK not.',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums',
      text: 'Nosaukums AGŪNS',
      filterCfg: getTextFilterCfg(),
      classes: 'display-linebreak'
    }, {
      dataField: 'gvk',
      text: 'GVK',
      width: 60,
    }, {
      dataField: 'mpk',
      text: 'MPK',
      width: 60,
    }, {
      dataField: 'mervieniba',
      text: 'Mērvienība',
      width: 60
    }
  ]};

const  getFormFieldCfgs = ({kodif, vvisKodifs}) => {

  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'kimiskaMatrica'),
      name: 'matrica',
      required: true,
      renderer: getKodifRenderer(kodif.kimiskaMatrica)
    },
    {
      ...getKlasifikatorsFieldCfg(vvisKodifs, 'B18'),
      name: 'vide',
      required: true,
      renderer: getKodifRenderer(vvisKodifs.B18)
    },
    {
      name: 'grupa',
      type: 'text',
    },
    {
      name: 'nr_mk',
      type: 'number',
    },
    {
      name: 'nosaukums_mk',
      type: 'text',
      required: true,
    },
    {
      name: 'nosaukumi',
      label: 'Nosaukums AGŪNS',
      multiField: true,
      customField: (props) => {
        const {fieldName, currentVal} = props
        return <>
          <FormGroup check inline>
            <InputField
              {...props}
              fieldName={`${fieldName}.nosaukums`}
              currentVal={currentVal.nosaukums}
              extraFieldProps={{placeholder: 'Vielas nosaukums AGŪNS'}}
            />
            <InputField
              {...props}
              fieldName={`${fieldName}.koef`}
              currentVal={currentVal.koef}
              extraFieldProps={{placeholder: 'Koeficients', type: 'number'}}
            />
          </FormGroup>
        </>
      },
      renderer: getDefaultRendererForMultiField('nosaukums')
    },
    {
      name: 'cas_nr',
      type: 'text',
    },
    {
      name: 'gvk',
      type: 'number',
    },
    {
      name: 'mpk',
      type: 'number',
    },
    {
      name: 'mervieniba',
      type: 'text',
    },
    {
      name: 'ir_summarais',
      type: 'checkbox',
    },
  ]};

class RaditajiKimiska extends Component {

  getCurrentQueryGrupas = () => {
    const {query} = this.props
    const ef = query && query.ef ? JSON.parse(query.ef) : {}
    return ef.grupas ? ef.grupas.split(',') : []
  }

  extraGridProps = {
    rowClasses: (row, rowIndex) => {
      if(row && row.ir_arhivets === true){
        return 'row-archived';
      }
    }
  }

  render() {
    const raditajiKimiska = this.props.raditajiKimiska
    if(!raditajiKimiska) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        ...this.props
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        ...this.props
      })
    }

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={this.props.raditajiKimiska}
        formWidthGuiOption="raditaji_kimiska_form_width"
        metadataFormCfg="raditajs_kimiska_edit"
        extraGridProps={this.extraGridProps}
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
      />
    )

  }

}

const mapStateToProps = ({raditajiKimiska, kodif, vvisKodifs, location}) => ({raditajiKimiska, kodif, vvisKodifs, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : raditajsKimiskaSelected,
  addUrlQuery,
  ...raditajiKimiskaActions
})(RaditajiKimiska)
