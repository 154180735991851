import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {
  getDefaultTypeaheadOptionsFromKodifArray,
  kodifKeyToTypeaheadOption,
  getKlasifikatorsFieldCfg,
  getDalaFieldCfg, getKudrasSpecificFields, getSapropelisSpecificFields,
  checkAtradnesType, getNoLidzVidFieldCfgs, validateNoLidzVidFields
} from '../forms/FormHelpers'
import {kvalitateActions, dalaKvalitateActions} from '../../actions/atradnesActions'
import FormCard from '../forms/FormCard'
import { Row } from 'reactstrap'
import {TEXT_ERR_DERIGIE} from '../../constants'  

const getFormFieldWrapperCfgs = ({user}) => {
  const fieldWrappers = [
    {
      id: 'kudra',
      comp: FormCard,
      compProps: {
        title: 'Kūdras kvalitātes rādītāji',
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'KD')
    },
    {
      id: 'saprop',
      comp: FormCard,
      compProps: {
        title: 'Sapropeļa kvalitātes rādītāji',
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'S')
    },
    {
      id: 'kimija',
      comp: FormCard,
      compProps: {
        title: 'Ķīmiskais sastāvs',
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'B')
    },
    {
      id: 'granulo',
      comp: FormCard,
      compProps: {
        title: 'Granulometriskais sastāvs',
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'B')
    },
    {
      id: 'fizmeh',
      comp: FormCard,
      compProps: {
        title: 'Fizikāli mehāniskās īpašības',
        pinnable: true
      },
      hiddenFunc: ({dataObj}) => !checkAtradnesType(dataObj, 'B')
    }
  ]
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fieldWrappers.push({
      id: 'pielikumi',
      comp: FormCard,
      compProps: {
        title: 'Pielikumi',
      }
    })
  }
  return fieldWrappers
}

const getIzraktenisField = ({kodif, atradne}) => ({
  ...getKlasifikatorsFieldCfg(kodif, 'IZR_PREC'), 
  name: 'izraktenis_prec',
  overridePropsFunc: (props) => {
    const changedProps = {...props}
    const {currentValRow} = props
    let izrakteni = atradne.izrakteni
    if(currentValRow.dala_id && atradne.id !== currentValRow.dala_id){
      // take izrakteni from dala
      const dala = atradne.dalas.find(d => d.id === currentValRow.dala_id)
      if(dala){
        izrakteni = dala.izrakteni
      }
    }
    const atradnesIzrakteni = izrakteni.map(izr => izr.klasifikators)
    const optionsIzr = Object.keys(kodif.IZR_PREC).filter(k => atradnesIzrakteni.indexOf(kodif.IZR_PREC[k].derigais) > -1).map(kodifKeyToTypeaheadOption(kodif.IZR_PREC))
    changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options : optionsIzr}
    changedProps.errorForField = optionsIzr.length === 0 ? TEXT_ERR_DERIGIE : changedProps.errorForField
    return changedProps
  }
})

const getFormFieldCfgs = (isDala=false) => ({kodif, user, veidsField='veids', atradne}) => {

    const commonProps = {
      sizeLabel: 0,
      sizeForm: 12,
      label: undefined,
      formRowProps:{className: 'multifield-form-row'},
    }
    
    return [
      {
        name: 'faili',
        fieldWrapper: 'pielikumi'
      },
      {
        name: 'kudras_sastavs',
        // validation: (values) => tableFieldErrors(values, fieldTables, 'kudras_sastavs', 5),
        validation: values => validateNoLidzVidFields('kudras_sastavs', values),
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          ...getKudrasSpecificFields(kodif),
          { ...getKlasifikatorsFieldCfg(kodif, 'KUDRAS_KVALITATES_RADITAJS'), 
            name: 'kudras_kval_rad',
            isHtmlValue: true
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'), 
            name: 'kategorija',
            cellClassName: 'narrow'
          },...getNoLidzVidFieldCfgs()
        ],
        multiFieldColsToGroup: 4,
        fieldWrapper: 'kudra',
        ...commonProps
      },
      {
        name: 'saprop_sastavs',
        // validation: (values) => tableFieldErrors(values, fieldTables, 'saprop_sastavs', 7),
        validation: values => validateNoLidzVidFields('saprop_sastavs', values),
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          {
            name: 'slana_kods', 
            cellClassNames: 'narrow'
          },...getSapropelisSpecificFields(kodif),
          { ...getKlasifikatorsFieldCfg(kodif, 'SAPROP_KVALITATES_RADITAJS'), 
            name: 'saprop_kval_rad',
            isHtmlValue: true
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'), 
            name: 'kategorija',
            cellClassName: 'narrow'
          },...getNoLidzVidFieldCfgs()
        ],
        multiFieldColsToGroup: 5,
        fieldWrapper: 'saprop',
        ...commonProps
      },
      {
        name: 'kimiskais_sastavs',
        //validation: (values) => tableFieldErrors(values, fieldTables, 'kimiskais_sastavs', 3),
        validation: values => validateNoLidzVidFields('kimiskais_sastavs', values),
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          getIzraktenisField({kodif, atradne}),
          { ...getKlasifikatorsFieldCfg(kodif, 'KIM_SAV'), 
            name: 'kim_savienojums',
            isHtmlValue: true
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'), 
            name: 'kategorija',
            cellClassName: 'narrow'
          },...getNoLidzVidFieldCfgs()
        ],
        multiFieldColsToGroup: 2,
        fieldWrapper: 'kimija',
        ...commonProps
      },
      {
        name: 'granulometr_sastavs',
        //validation: (values) => tableFieldErrors(values, fieldTables, 'granulometr_sastavs', 3),
        validation: values => validateNoLidzVidFields('granulometr_sastavs', values),
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          getIzraktenisField({kodif, atradne}),
          { ...getKlasifikatorsFieldCfg(kodif, 'FRAKCIJA'), 
            name: 'frakcija'
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'), 
            name: 'kategorija',
            cellClassName: 'narrow'
          },...getNoLidzVidFieldCfgs()
        ],
        multiFieldColsToGroup: 3,
        fieldWrapper: 'granulo',
        ...commonProps
      },
      {
        name: 'fizmeh_ipasibas',
        //validation: (values) => tableFieldErrors(values, fieldTables, 'fizmeh_ipasibas', 4),
        validation: values => validateNoLidzVidFields('fizmeh_ipasibas', values),
        multiFieldCfgs: [
          getDalaFieldCfg(isDala),
          getIzraktenisField({kodif, atradne}),
          {
            ...getKlasifikatorsFieldCfg(kodif, 'FRAKCIJA'), 
            name: 'frakcija',
            overridePropsFunc: (props) => {
                const changedProps = {...props}
                const {currentValRow} = props
                let frakcijasOptions
                if(currentValRow.izraktenis_prec && kodif['IZR_PREC'][currentValRow.izraktenis_prec]){
                  const kodifToOpt = kodifKeyToTypeaheadOption(kodif.FRAKCIJA)
                  frakcijasOptions = kodif['IZR_PREC'][currentValRow.izraktenis_prec].frakcijas.map(e => kodifToOpt(e.nos))
                }else{
                  frakcijasOptions = getDefaultTypeaheadOptionsFromKodifArray(kodif, 'FRAKCIJA')
                }
                changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options : frakcijasOptions}
                return changedProps
            }
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'FIZMEH'), 
            name: 'fizmeh_ipasiba',
            isHtmlValue: true
          },
          { ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'), 
            name: 'kategorija',
            cellClassName: 'narrow'
          },...getNoLidzVidFieldCfgs()
        ],
        multiFieldColsToGroup: 4,
        fieldWrapper: 'fizmeh',
        ...commonProps
      }
  ]
}
  
class KvalitateComp extends Component {


      render() {
        
        const {atradneKvalitate, user, getFormFieldCfgsFunc, kodif, veidsField, 
          getFormFieldWrapperCfgsFunc, metadataFormCfg} = this.props

        const {selectedItem, editable, submiting} = atradneKvalitate
        
        const atradne = this.props.atradne.selectedItem

        // loading while we have no atradne
        if (!selectedItem || !atradne) {
            return (<Loading/>)
        }

        // we cache form field cfgs
        // reload form fields if atradne changes
        if(!this.formFieldCfgs || atradne.id !== this.prevAtradneId){
          const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs()
          this.formFieldCfgs = f({
              kodif,
              user,
              veidsField,
              atradne
          })
          this.prevAtradneId = atradne.id
        }

        if(!this.formFieldWrapperCfgs){
          const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs
          this.formFieldWrapperCfgs = f({user})
        }

        return <div className="form-container">
                    <Form 
                        editable={editable}
                        submiting={submiting}
                        dataObj={selectedItem}
                        formFieldCfgs={this.formFieldCfgs}
                        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
                        onSubmit={this.props.saveAct}
                        onReset={this.props.stopEditAct}
                        onStartEdit={this.props.startEditAct}
                        formWrapperComp={Row}
                        metadataFormCfg={metadataFormCfg}
                        tiesibasLabot="LABOT_ATRADNES"
                    />
            </div>
    }
}

const mapStateToProps = ({atradneKvalitate, kodif, user, atradne}) => ({atradneKvalitate, kodif, user, atradne, metadataFormCfg: "atradne_kvalitate_view"})
const Kvalitate =  connect(mapStateToProps, {startEditAct:kvalitateActions.startEditAct, stopEditAct:kvalitateActions.stopEditAct,
  saveAct:kvalitateActions.saveAct})(KvalitateComp)
export default Kvalitate

const mapStateToPropsDala = ({atradnesDalaKvalitate, kodif, user, atradnesDala}) => ({atradneKvalitate:atradnesDalaKvalitate, kodif, atradne: atradnesDala,
  user, veidsField:'atradnes_veids', getFormFieldCfgsFunc: getFormFieldCfgs(true), metadataFormCfg: "atradnes_dala_kvalitate_view"})
export const KvalitateAtradnesDala =  connect(mapStateToPropsDala, {startEditAct:dalaKvalitateActions.startEditAct, stopEditAct:dalaKvalitateActions.stopEditAct,
  saveAct:dalaKvalitateActions.saveAct})(KvalitateComp)
