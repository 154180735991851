import React from 'react';
import { Button, FormGroup } from 'reactstrap'
import { uploadFile } from "../../api"
import { getYear } from 'date-fns'
import Link from 'redux-first-router-link'
import { InputField } from './Form'
import TypeaheadField from './TypeaheadField'
import _ from 'lodash'
import { round } from '../../utils'

export const getFileHref = (file) => {
  return <a title={file.filename} href={'/services/download/' + file.file_id + '/' + file.file_sha256}>{file.filename}</a>
}

export const kodifKeyToTypeaheadOption = (kodif) => (k) => ({ tah_id: k, tah_label: `${kodif[k].nos}`, ir_arhivets: kodif[k].ir_arhivets, lnb_id: kodif[k].lnb_id })

export const getDefaultTypeaheadOptionsFromKodif = (kodif) => {
  return Object.keys(kodif).map(kodifKeyToTypeaheadOption(kodif))
}

export const getDefaultTypeaheadOptionsFromKodifArray = (kodifArray, kodifIndex) => {
  const kodif = kodifArray[kodifIndex];
  return Object.keys(kodif).map(kodifKeyToTypeaheadOption(kodif)).map(opt => ({ ...opt, ir_html: kodifArray.kodifGrupas[kodifIndex] && kodifArray.kodifGrupas[kodifIndex].ir_html }))
}

export const getDefaultRendererForMultiField = (fieldName) => (fieldValue) => {
  return <span className="display-linebreak">
    {fieldValue.map(e => e[fieldName]).join('\n')}
  </span>
}

export const getDefaultRendererForMultiFieldHTML = (fieldName) => (fieldValue) => (
  <span className="display-linebreak" dangerouslySetInnerHTML={{ __html: fieldValue.map(e => e[fieldName]).join('\n') }} />
)

export const getKodifRenderer = (kodifs) => (fieldValue) => kodifs?.[fieldValue] ? kodifs[fieldValue].nos : ''

function buildFileSelector(onchange) {
  const fileSelector = document.createElement('input')
  fileSelector.setAttribute('type', 'file')
  fileSelector.addEventListener('change', onchange)
  return fileSelector;
}

export class FileDialogue extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = props.onChange
  }

  componentDidMount() {
    this.fileSelector = buildFileSelector(this.onChange)
  }

  handleFileSelect = (e) => {
    e.preventDefault()
    this.fileSelector.click()
  }

  render() {
    return <Button outline color="success" size="sm" onClick={this.handleFileSelect}><i className="fa fa-folder-open-o"></i>Izvēlēties</Button>
  }
}

export const FileUploaderField = (props) => {
  const { setFieldValue, fieldName, currentVal } = props
  const fileSelected = (e) => {
    const file = e.target.files[0]
    uploadFile(file).then((response) => {
      setFieldValue(fieldName, {
        file_info_id: response.id,
        filename: response.filename,
        file_id: response.id,
        file_sha256: response.sha_256,
        key: currentVal.key || `k${response.id}`
      })
    })
  }
  return <>
    <FormGroup inline className="attach-fileselect">
      <FileDialogue onChange={fileSelected} />
    </FormGroup>
    {currentVal.file_info_id ? <FormGroup inline className="attach-filename">
      {getFileHref(currentVal)}
    </FormGroup> : null}
  </>
}

export const fileRenderer = (fieldValue) => fieldValue.map((file, i) => (
  <div key={`file_${i}`} className="attached-file">{getFileHref(file)}</div>
))

export const validateYear = (year) => {
  if (year != null && year !== '') {
    if (year < 1900) {
      return 'Ievadītais gads ir pārāk mazs!';
    }
    if (year > getYear(new Date())) {
      return 'Ievadītais gads ir lielāks par esošo!';
    }
  }
  return null
}

export const validateYears = (years) => {
  if (years != null && years !== '') {
    const errors = years.split(/[\s,-]+/).map(y => validateYear(y)).filter(msg => !!msg)
    if (errors.length > 0) {
      return errors.join(" ")
    }
  }
  return null
}

export const getMultiKlasifikatorsFieldCfg = (kodif, kodifType) => (
  {
    type: 'autocomplete',
    autocompleteCfg: {
      options: getDefaultTypeaheadOptionsFromKodifArray(kodif, kodifType),
      multiple: true,
      keyField: 'klasifikators',
    },
    renderer: kodif.kodifGrupas[kodifType].ir_html ? getDefaultRendererForMultiFieldHTML('klasifikators') : getDefaultRendererForMultiField('klasifikators')
  }
)

export const getKlasifikatorsFieldCfg = (kodif, kodifType) => (
  {
    type: 'autocomplete',
    autocompleteCfg: {
      options: getDefaultTypeaheadOptionsFromKodifArray(kodif, kodifType)
    }
  }
)


export const checkAtradnesType = (atradneVaiDala, typeLetters) =>
  checkAtradnesTypeFromVeids(atradneVaiDala.veids, typeLetters) || checkAtradnesTypeFromVeids(atradneVaiDala.atradnes_veids, typeLetters)

export const checkAtradnesTypeFromVeids = (veids, typeLetters) =>
  typeLetters.split('').filter(l => veids && veids.includes(l)).length > 0

export const getAtradnesVeidsFromIzraktKraj = (kodif, izr) => {
  const derigais = kodif.IZR_KRAJ[izr].derigais
  if (derigais) {
    return kodif.DIZRAKT[derigais].veids
  }
  return ''
}

// for use in multifield
export const DalaFieldNew = (props) => {
  const { fieldNameRow, currentValRow, dataObj, isDala, hideLaukumaNosaukums } = props
  return <div style={{ "display": 'flex' }}>
    {!isDala ?
      <div style={{ width: '50%' }}>
        <TypeaheadField
          {...props}
          fieldName={`${fieldNameRow}.dala_id`}
          currentVal={currentValRow.dala_id}
          autocompleteCfg={{
            options: [{ tah_id: dataObj.id, tah_label: `Visa atradne` }]
              .concat(dataObj.dalas ? dataObj.dalas.map(d => ({ tah_id: d.id, tah_label: `${d.nosaukums}` })) : [])
          }}
          extraFieldProps={{ placeholder: 'Atradne vai daļa' }}
        />
      </div>
      : null
    }
    {hideLaukumaNosaukums ? null :
      <div style={{ width: '50%' }}>
        <InputField
          {...props}
          fieldType='text'
          fieldName={`${fieldNameRow}.dalas_nosaukums`}
          currentVal={currentValRow.dalas_nosaukums}
          extraFieldProps={{ placeholder: 'Struktūrelements' }}
        />
      </div>
    }
  </div>
}

// currentValuesObj - object which holds "daudzuma_merv" prop
// izrMervienibas - mervienibas of currently selected izrakteni
// kodif
export const DaudzumsField = (props) => {
  const { currentValuesObj, izrMervienibas, kodif, mervFieldName, fieldNameRow, extraFieldProps } = props
  const mervField = mervFieldName ? mervFieldName : 'daudzuma_merv'
  const daudzumaMervOptions = getDefaultTypeaheadOptionsFromKodifArray(kodif, 'DAUDZ_MERV')
    .filter(merv => izrMervienibas.indexOf(merv.tah_id) >= 0)
  if (!currentValuesObj[mervField] && daudzumaMervOptions.length === 1) {
    currentValuesObj[mervField] = daudzumaMervOptions[0].tah_id
  } else if (currentValuesObj[mervField] && daudzumaMervOptions.filter(merv => merv.tah_id === currentValuesObj[mervField]).length === 0) {
    currentValuesObj[mervField] = null
  }
  const mervExtra = extraFieldProps ? { ...extraFieldProps } : {}
  mervExtra.placeholder = 'mērvienība'
  return <div style={{ display: 'flex' }}>
    <div style={{ width: '50%' }}>
      <InputField
        {...props}
        fieldType="number"
      />
    </div>
    <div style={{ width: '50%' }}>
      <TypeaheadField
        {...props}
        fieldName={fieldNameRow ? `${fieldNameRow}.${mervField}` : mervField}
        currentVal={currentValuesObj[mervField]}
        autocompleteCfg={{ options: daudzumaMervOptions }}
        extraFieldProps={mervExtra}
      />
    </div>
  </div>
}

export const getDaudzumsRenderer = (mervField = 'daudzuma_merv') => (daudz, dataObj) => daudz != null ? <><b> {daudz} </b><span dangerouslySetInnerHTML={{ __html: dataObj[mervField] }}></span></> : null

export const isEmptyNumberFieldValue = (value) => {
  return !value && value !== '0' && value !== 0;
}

export const getNoLidzVidErrors = (valObj) => {
  const errors = {}
  if (!isEmptyNumberFieldValue(valObj.vertiba_no)) {
    if (!isEmptyNumberFieldValue(valObj.vertiba_lidz) && valObj.vertiba_no > valObj.vertiba_lidz) {
      errors.vertiba_no = 'Laukam "Vērtība No" jābūt mazākam par lauku "Vērtība Līdz"'
      errors.vertiba_lidz = errors.vertiba_no
    }
    if (!isEmptyNumberFieldValue(valObj.vertiba_vid) && valObj.vertiba_no > valObj.vertiba_vid) {
      errors.vertiba_no = 'Laukam "Vērtība No" jābūt mazākam par lauku "Vērtība Vidēji"'
      errors.vertiba_vid = errors.vertiba_no
    }
  }
  if (!isEmptyNumberFieldValue(valObj.vertiba_lidz)) {
    if (!isEmptyNumberFieldValue(valObj.vertiba_vid) && valObj.vertiba_vid > valObj.vertiba_lidz) {
      errors.vertiba_lidz = 'Laukam "Vērtība Vidēji" jābūt mazākam par lauku "Vērtība Līdz"'
      errors.vertiba_vid = errors.vertiba_lidz
    }
  }
  return errors
}

export const validateNoLidzVidFields = (fieldName, values) => values[fieldName].reduce((errorObj, v, i) => {
  const noLidzVidErrors = getNoLidzVidErrors(v)
  if (!_.isEmpty(noLidzVidErrors)) {
    if (!errorObj[fieldName]) { errorObj[fieldName] = [] }
    errorObj[fieldName][i] = noLidzVidErrors
  }
  return errorObj
}, {})

export const tableFieldErrors = (values, fieldTable, field_name, fieldCount) => {
  if (values[field_name].filter(v => getNoLidzVidErrors(v).filter(e => !!e).length > 0).length > 0) {
    return 'No, līdz, vidēji vērtības nav aizpildītas korekti'
  }
  const valFields = [{ title: 'Atradnes daļa', field: 'dala_id' }, { title: 'Daļas nosaukums', field: 'dalas_nosaukums' }]
  for (let i = 1; i < fieldCount; i++) {
    valFields.push(fieldTable[field_name][i])
  }
  const checkFields = (row1, row2) => {
    const f1 = {}
    const f2 = {}
    valFields.forEach((vf) => {
      const v = vf.field
      f1[v] = row1[v] && (v !== 'dala_id' || row1[v] !== values.id) ? row1[v] : null
      f2[v] = row2[v] && (v !== 'dala_id' || row2[v] !== values.id) ? row2[v] : null
    })
    return JSON.stringify(f1) === JSON.stringify(f2)
  }
  let duplicates = false
  for (let i = 0; i < values[field_name].length && !duplicates; i++) {
    for (let j = i + 1; j < values[field_name].length && !duplicates; j++) {
      duplicates = checkFields(values[field_name][i], values[field_name][j])
    }
  }
  if (duplicates) {
    return 'Nevar būt ierasti ar vienādiem laukiem: ' + valFields.map(vf => { return vf.title }).join(', ')
  }
  return null
}

export const getNoLidzVidFieldCfgs = () => [
  { name: 'vertiba_no', cellClassName: 'narrow-number', },
  { name: 'vertiba_lidz', cellClassName: 'narrow-number', },
  { name: 'vertiba_vid', cellClassName: 'narrow-number', }
]

export const getNoLidzVidFieldTableCfgs = () => [
  { field: 'vertiba_no' },
  { field: 'vertiba_lidz' },
  { field: 'vertiba_vid' }
]

export const getDalaFieldCfg = (isDala) => (
  {
    name: 'dalas_nosaukums_label',
    label: 'Atradnes daļa / struktūrelements',
    cellClassName: 'wide',
    customField: (props) => <DalaFieldNew {...props} isDala={isDala} />,
    renderer: (dnos, row, props) => {
      if (row.dala_id && props.dataObj.id !== row.dala_id) {
        return <Link to={{
          type: 'loc_atradnes_dala',
          payload: {
            numurs: props.dataObj.numurs,
            dala: row.dala_id
          }
        }}>{dnos}</Link>
      } else {
        return dnos
      }
    }
  }
)

export const getKudrasSpecificFields = (kodif) => [
  {
    ...getKlasifikatorsFieldCfg(kodif, 'KUDRAS_TIPS'),
    name: 'kudras_tips'
  },
  {
    ...getKlasifikatorsFieldCfg(kodif, 'KUDRAS_SADAL_PAKAPE'),
    name: 'kudras_sadal_pak'
  }
]

export const getSapropelisSpecificFields = (kodif) => [
  {
    ...getKlasifikatorsFieldCfg(kodif, 'SAPROP_TIPS'),
    name: 'saprop_tips'
  },
  {
    ...getKlasifikatorsFieldCfg(kodif, 'SAPROP_VEIDS'),
    name: 'saprop_veids'
  },
  {
    ...getKlasifikatorsFieldCfg(kodif, 'SAPROP_KLASE'),
    name: 'saprop_klase'
  }
]

export const PlusButton = ({ onClick, row, fieldName }) => <span className={row.sum && row.sum.indexOf(fieldName) >= 0 ? 'plus-button green' : 'plus-button'}
  onClick={() => onClick({ id: row.id, col: fieldName })}><i className="fa fa-lg fa-plus-circle"></i></span>

export const SummingResultPanel = ({ summables, onClickClear, mervFieldName }) => {

  //resulting array - [0]- sum, [1] - text values that where summed, [2] - mervienibas are same, [3] - mervieniba
  const sumResult = summables.filter(kr => kr.sum && kr.sum.length > 0).reduce((sumArr, kr, ind, arr) => {
    const krSums = kr.sum.map(s => kr[s])
    const krSumsText = krSums.map(s => round(s)).join(' + ')
    const krSum = krSums.reduce((a, b) => a + b, 0)
    return [
      sumArr[0] + krSum,
      sumArr[1] !== '' ? `${sumArr[1]} + ${krSumsText}` : krSumsText,
      sumArr[2] && (ind > 0 ? sumArr[3] === kr[mervFieldName] || kr[mervFieldName] == null || sumArr[3] == null : true),
      kr[mervFieldName] != null ? kr[mervFieldName] : sumArr[3]
    ]
  }, [0, '', true, null])

  return <div className="form-additional data-panel">
    {sumResult[1] === '' ?
      <><i>Lai summētu krājumus, izvēlieties tabulā vērtības ar </i><span className="plus-button"><i className="fa fa-lg fa-plus-circle"></i></span> <i> ikonu!</i></>
      : <>
        {sumResult[2] ? null : <div className="danger">Izvēlētajām vērtībām mērvienības atšķiras!</div>}
        {sumResult[1]} = <b> {
          round(sumResult[0])
        } </b><span dangerouslySetInnerHTML={{ __html: sumResult[3] }}></span>
        <div className="float-right">
          <Button onClick={onClickClear} color="secondary" size="sm">
            <i className="fa fa-ban"></i> Notīrīt
          </Button>
        </div>
      </>
    }
  </div>
}

export const isEmptyValue = (value) => {
  if (_.isArrayLikeObject(value)) {
    return _.isEmpty(value)
  }
  return value === undefined || value == null || value === '' || (typeof value === 'number' && isNaN(value))
}
