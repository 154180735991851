import React, {Component} from 'react';
import {connect} from 'react-redux'

import {
    FormGroup,
    Label,
} from 'reactstrap';
import Link from 'redux-first-router-link';
import Form, {DateInputField} from '../forms/Form'
import TypeaheadField from '../forms/TypeaheadField'
import {getDefaultTypeaheadOptionsFromKodif, getMultiKlasifikatorsFieldCfg, getKlasifikatorsFieldCfg, isEmptyValue} from '../forms/FormHelpers'
import {getMultiBadgeFormatterForKodif, dateFormatter} from '../grid/ColumnHelpers'
import {addDays} from 'date-fns'
import {atradneActions} from '../../actions/atradnesActions'
import {atradnesDalaActions} from '../../actions/atradnesActions'
import _ from 'lodash'
import {DalaLink, getAradnesLinksRenderer, getHyperlinkRenderer} from '../dokumentacija/common'

const getMultiFieldRenderWithDalasData = (dalaField) => (izraktenisObj, dataObj) => {
  //group together with dalas izrakteni in map {"izraktenis": [dala1,dala2]}
  const izrakteni = {}
  izraktenisObj.forEach(izr => izrakteni[izr.klasifikators] = ['atradne'])
  //get izrakteni from dalas
  if(dataObj.dalas) {
    dataObj.dalas.forEach(d => d[dalaField] && d[dalaField].forEach(izr => {
        if(!izrakteni[izr.klasifikators]){
          izrakteni[izr.klasifikators] = []
        }
        izrakteni[izr.klasifikators].push(d)
      })
    )
  }
  return Object.keys(izrakteni).map(izr => <p key={izr} className="no-margin">
      <b>{izr}</b> {izrakteni[izr].length > 0 ? izrakteni[izr].map(d => d === 'atradne' && izrakteni[izr].length > 1 ? ' atradne ' : d !== 'atradne' ? <DalaLink key={d.id} numurs={dataObj.numurs} dala={d}/> : null) : null}
      </p> )
}

export const getFieldRenderWithDalasData = (dalaField, customFieldRenderer) => (fieldValue, dataObj) => {
  //get fonda numurs from dalas from dalas
  if(dataObj.dalas && dataObj.dalas.length > 0) {
    return <>
    {customFieldRenderer ? customFieldRenderer(fieldValue, dataObj) : fieldValue}
    {dataObj.dalas.filter(d => !!d[dalaField] || (customFieldRenderer && customFieldRenderer(d[dalaField], d))).map((d,i) => <p className="no-margin" key={i}>{customFieldRenderer ? customFieldRenderer(d[dalaField], d): d[dalaField]} (<DalaLink numurs={dataObj.numurs} dala={d}/>)</p>)}
    </>
  }
  return customFieldRenderer ? customFieldRenderer(fieldValue, dataObj) : fieldValue
}

export const  getFormFieldCfgs = ({kodif, user}) => {
    const fields = [
      {
        name: 'numurs',
        renderClassName: "bold",
        extraFieldProps: () => ({placeholder: 'Tiks piešķirts nākamais brīvais', disabled : true }),
        renderer: (fieldValue, dataObj) => dataObj.arhivets_iemesls ?
          <span className="cross-out">{fieldValue} ({dataObj.arhivets_iemesls})</span> :
          fieldValue
      },
      {
        name: 'nosaukums',
        renderClassName: "bold",
        validation: (values) => {
          // kūdras atradnēm nav obligāts
          if((values.atradnes_veids && values.atradnes_veids.includes('K')) || (values.veidsObj && values.veidsObj['K'] === true)){
            return null
          }
          if(isEmptyValue(values.nosaukums)){
            return 'Obligāts lauks'
          }
          return null
        }
      },
      {
        name: 'ped_izmainas',
        editable: false,
        hiddenFunc: ({currentValues}) => !currentValues.id
      },
      {
        name: 'sinonimi',
        //hiddenFunc: ({currentValues}) => (currentValues.veidsObj && currentValues.veidsObj.S),
      }, {
        name: 'dalas',
        renderer: (fieldValue, dataObj) => {
          return <>
          {
            fieldValue.map((d, i) => (
            <p key={i} className="links">
                <DalaLink numurs={dataObj.numurs} dala={d}/>
            </p>
          ))
          }
          {user && user.tiesibas.includes('LABOT_ATRADNES') ?
          <Link
                      to={{
                      type: 'loc_atradnes_dala',
                      payload: {
                          numurs: dataObj.numurs,
                          dala: 'jauns'
                      }
                  }}>
                      <i className="fa fa-plus-circle"></i> Pievienot jaunu daļu
                  </Link>
          : null
          }
          </>
        },
        hiddenFunc: ({currentValues}) => !currentValues.id
      },
      {
        name: 'ierakstaVeidsObj',
        required: true,
        label: 'Atradne / Prognozēto resursu laukums',
        type: 'multicheckbox',
        renderer: getMultiBadgeFormatterForKodif(kodif.atradnesIerakstaVeids),
        options: _.omit(kodif.atradnesIerakstaVeids, ['D']),
        validation: (values) => {
          if(Object.keys(values.ierakstaVeidsObj).map(k=>values.ierakstaVeidsObj[k]).filter(v=>v===true).length === 0){
            return 'Jābūt norādītam, vai objekts ir Atradne vai Prognozēto resursu laukums!';
          }
          return null
        },
      }, {
        name: 'veidsObj',
        required: true,
        label: 'Veids',
        extraFieldProps: ({dataObj}) => ({disabled : !user.tiesibas.includes('LABOT_ATRADNES') && !!dataObj.id }),
        type: 'multicheckbox',
        renderer: getMultiBadgeFormatterForKodif(kodif.atradnesVeids),
        options: _.omit(kodif.atradnesVeids, ['U']),
        validation: (values) => {
          if(Object.keys(values.veidsObj).filter(k => values.veidsObj[k]).length === 0){
            return 'Vismaz vienam veidam jābūt aizpildītam!';
          }
          return null
        },
      }, {
        ...getMultiKlasifikatorsFieldCfg(kodif, 'DIZRAKT'),
        name: 'izrakteni',
        customField: (props) => {
          const {dataObj} = props
          return <>
            <TypeaheadField {...props} />
            {getMultiFieldRenderWithDalasData('izrakteni')([], dataObj)}
          </>
        },
        validation: (values) => {
          const izrVeidi = values.izrakteni.map(izr => kodif.DIZRAKT[izr.klasifikators].veids)
          if(values.atradnes_veids){
            // daļām
            if(izrVeidi.filter(iv => !values.atradnes_veids.includes(iv)).length > 0) {
              return 'Atradnes veids neatbilst derīgajam izraktenim';
            }
          } else {
            if(izrVeidi.filter(iv => !values.veidsObj[iv]).length > 0) {
              return 'Atradnes veids neatbilst derīgajam izraktenim';
            }
          }
          return null
        },
        renderer: getMultiFieldRenderWithDalasData('izrakteni')
      }, {
        name: 'izraktenis_apraksts',
        renderer: getFieldRenderWithDalasData('izraktenis_apraksts')
      }, {
        name: 'uzzinas',
        renderer: (fieldValue, dataObj) => (
          fieldValue.filter(uzz => uzz.vgf_numurs).map((uzz,i)=>
          <span key={i} className="multiple-values">
                  <Link
                      to={{
                      type: 'loc_atradnes_uzzina',
                      payload: {
                          numurs: dataObj.numurs,
                          id: uzz.uzzina_id,
                      }
                  }}>
                      {uzz.vgf_numurs}
                  </Link>
          </span>)
        )
      }, {
        name: 'fonda_numurs',
        renderer: getFieldRenderWithDalasData('fonda_numurs'),
        hiddenFunc: ({currentValues}) => !(currentValues.veidsObj && currentValues.veidsObj.K)
      }, {
        name: 'ir_valsts_nozimes',
      }, {
        ...getMultiKlasifikatorsFieldCfg(kodif, 'ATR_STATUSS'),
        name: 'statuss',
        customField: (props) => {
          const {dataObj} = props
          return <>
            <TypeaheadField {...props} />
            {getMultiFieldRenderWithDalasData('statuss')([], dataObj)}
          </>
        },
        renderer: getMultiFieldRenderWithDalasData('statuss')
      }, {
        name: 'ierobezota_pieejamiba_datums_no',
        label: 'Ierobežota pieejamība',
        type: 'date',
        customField: (props) => {
          const otherDate = props.currentValues.ierobezota_pieejamiba_datums_lidz
            return <>
            <FormGroup check inline>
              <Label check>
                <DateInputField {...props}
                  extraFieldProps={{placeholderText: 'Datums no'}}
                />
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <DateInputField
                {...props}
                fieldName="ierobezota_pieejamiba_datums_lidz"
                currentVal={otherDate}
                extraFieldProps={{placeholderText: 'Datums līdz'}}
                />
              </Label>
            </FormGroup>
            </>
        },
        renderer: getFieldRenderWithDalasData('', (fieldValue, dataObj) => {
          const no = dataObj.ierobezota_pieejamiba_datums_no
          const lidz = dataObj.ierobezota_pieejamiba_datums_lidz
          if(!no && !lidz){
            return null
          }
          const className = (!no || no < new Date()) && (!lidz || addDays(lidz, 1) > new Date()) ? 'bold red' : ''
          return <span className={className}>{dateFormatter(no)} - {dateFormatter(lidz)}</span>
        })
      },
      {
        name: 'saistitas',
        label: 'Sasaiste ar citiem objektiem',
        multiField: true,
        validation: (values) => {
          if(values.saistitas.filter(atr => !atr.atradne || !atr.tips).length > 0){
            return 'Sasaiste ar citiem objektiem nav aizpildīta korekti'
          }
          return null
        },
        customField: (props) => {
          const {fieldName, currentVal} = props
          return <>
            <FormGroup check inline>
              <TypeaheadField
                {...props}
                fieldName={`${fieldName}.atradne`}
                currentVal={currentVal.atradne}
                autocompleteCfg={{
                  remoteSearchViewName: 'atradne_search',
                  options: [{ tah_id : currentVal.atradne , tah_label : `${currentVal.nosaukums} ${currentVal.atradne}` }]
                }}
                extraFieldProps={{placeholder: 'Atradnes nosaukums vai numurs'}}
                />
            </FormGroup>
            <FormGroup check inline>
                <TypeaheadField
                {...props}
                fieldName={`${fieldName}.tips`}
                currentVal={currentVal.tips}
                autocompleteCfg={{
                  options: getDefaultTypeaheadOptionsFromKodif(kodif.atradnesSasaistesTips)
                }}
                extraFieldProps={{placeholder: 'Sasaistes veids'}}
                />
            </FormGroup>
            </>
        },
        renderer: getAradnesLinksRenderer(
          kodif,
          'atradne',
          (kodif, atr) => (<>&nbsp;&nbsp;({kodif.atradnesSasaistesTips[atr.tips].nos})</>)
        )
      },{
        name: 'saistitas_atradnes_piezimes',
        label: '',
        extraFieldProps: {placeholder: 'Sasaistes piezīmes'},
        renderer: (fieldValue) => (
          <span className="display-linebreak italic">{fieldValue}</span>
        )
      }, {
        name: 'piezimes',
        renderer: getFieldRenderWithDalasData('piezimes')
      }, {
        ...getKlasifikatorsFieldCfg(kodif, 'ARHIVETS'),
        name: 'arhivets_iemesls',
        extraFieldProps: {placeholder: 'Nav arhivēts'},
        renderer: (fieldValue) => {
          if(!fieldValue){
              return 'Nē'
          }
          return kodif.ARHIVETS[fieldValue].nos
        },
      }
  ]
  if (user.tiesibas.includes('SKATIT_ATRADNES_AR_IEROB_PIEEJAMIBU')) {
    const saitesFieldObj = {
      name: 'saites',
      label: 'Saites',
      extraFieldProps: {placeholder: 'Vairākas saites jāatdala ar komatu'},
      type: 'textarea',
      renderer: getHyperlinkRenderer
    }
    const piezimesIndex = fields.findIndex(item => item.name === 'piezimes')
    if (piezimesIndex !== -1) {
      fields.splice(piezimesIndex + 1, 0, saitesFieldObj)
    }
  }
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fields.push({
      name: 'faili'
    })
  }
  return fields
}

class PamatinformacijaComp extends Component {
    render() {
        const {selectedItem:atradne, editable, submiting} = this.props.atradne

        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, kodif, metadataFormCfg} = this.props

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif,
                user,
            })
        }
        return <div className="form-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={atradne}
                        formFieldCfgs={this.formFieldCfgs}
                        metadataFormCfg={metadataFormCfg}
                        onSubmit={this.props.save}
                        onReset={this.props.stopEdit}
                        onStartEdit={this.props.startEdit}
                        onDelete={user.tiesibas.includes('DZEST_ATRADNES') && !!atradne.arhivets_iemesls ? this.props.delete : null}
                        tiesibasLabot="LABOT_ATRADNES"
                    />
            </div>
    }
}

// connect Pamatinformacija with atradne state
const mapStateToProps = ({atradne, kodif, user}) => ({atradne, kodif, user, metadataFormCfg: "atradne_view"})
const Pamatinformacija =  connect(mapStateToProps, {startEdit:atradneActions.startEditAct, stopEdit:atradneActions.stopEditAct,
    delete:atradneActions.deleteAct, save:atradneActions.saveAct})(PamatinformacijaComp)
export default Pamatinformacija


const getFormFieldCfgsDala = ({kodif, user}) => {
    const atradneFields = getFormFieldCfgs({kodif, user})
    const dalaFieldNames = ['nosaukums', 'ped_izmainas', 'sinonimi', 'piezimes'
      , 'arhivets_iemesls', 'fonda_numurs', 'izrakteni', 'izraktenis_apraksts'
      , 'ir_valsts_nozimes', 'statuss', 'ierobezota_pieejamiba_datums_no', 'arhivets_iemesls', 'saites', 'faili']
    return atradneFields.filter(f => dalaFieldNames.includes(f.name))
}
// connect Pamatinformacija with atradnes dala state
const mapStateToPropsDala = ({atradnesDala, kodif, user}) => ({atradne: atradnesDala, kodif, user, getFormFieldCfgsFunc: getFormFieldCfgsDala, metadataFormCfg: "atradnes_dala_view"})

export const PamatinformacijaAtradnesDala = connect(mapStateToPropsDala, {startEdit:atradnesDalaActions.startEditAct, stopEdit:atradnesDalaActions.stopEditAct,
    delete:atradnesDalaActions.deleteAct, save:atradnesDalaActions.saveAct})(PamatinformacijaComp)