import React, {Component} from 'react'
import {connect} from 'react-redux'
import {UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import {addUrlQuery} from '../../actions/uiActions'

class ArchivedFilterButtonToUrl extends Component {

    toggleFilter = (v) => () => {
        const efObj = {...this.getQueryExtraFilter(), archived: v}
        let ef = JSON.stringify(efObj)
        ef = ef === '{}' ? undefined : ef
        this.props.addUrlQuery({ef})
    }

    getQueryExtraFilter = () => {
        const {query} = this.props
        return query && query.ef ? JSON.parse(query.ef) : {}
    }

    render() {
        const {
            titleDefault = 'Slēpt arhivētos',
            titleBoth = 'Rādīt gan arhivētos, gan nearhivētos',
            titleArchived = 'Rādīt tikai arhivētos',
            filterBoth = 'all',
            filterArchived = 'arh'} = this.props
        const archived = this.getQueryExtraFilter()['archived']
        return (
            <UncontrolledButtonDropdown>
                <DropdownToggle
                    color={!archived
                    ? 'secondary'
                    : 'primary'}
                    caret>
                    Arhivētie
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem active={!archived} onClick={this.toggleFilter(undefined)}>{titleDefault}</DropdownItem>
                    <DropdownItem active={archived === filterBoth} onClick={this.toggleFilter(filterBoth)}>{titleBoth}</DropdownItem>
                    <DropdownItem active={archived === filterArchived} onClick={this.toggleFilter(filterArchived)}>{titleArchived}</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        )
    }
}

const mapStateToProps = ({location}) => {
    return {query: location.query}
}

export default connect(mapStateToProps, {addUrlQuery})(ArchivedFilterButtonToUrl)
