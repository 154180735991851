import React, {Component} from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

export default class ReplaceObjectsButton extends Component {
  
  render() {
    const {handleReplace, buttonText, children, toggleModal, modal, enabledSave} = this.props
    return  <>
            <Button color="primary" onClick={toggleModal} className="float-right"><i className="fa fa-arrows-h"></i>&nbsp;&nbsp;{buttonText ? buttonText : 'Aizvietot objektu'}</Button> 
            <Modal isOpen={modal}>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" disabled={!enabledSave} onClick={handleReplace}>Aizvietot</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Atcelt</Button>
                </ModalFooter>
            </Modal>
          </>
  }
}
