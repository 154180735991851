import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getTextFilterCfg,
} from '../grid/ColumnHelpers'

import {
  udensParskatsDoubleSelected
} from '../../actions/udens2Actions'

import Grid from '../grid/Grid'

const getColumns = ({kodif}) => {
  return [{
    dataField: 'id',
    text: 'Pārskata ID',
    width: 120,
  }, {
    dataField: 'gads',
    text: 'Gads',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'operators',
    text: 'Operators',
    filterCfg: getTextFilterCfg()
  }]
};

class Parskati extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalSearch: false
    };
  }

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  }

  rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      this.props.udensParskatsDoubleSelected(row.id)
    }
  }

  render() {

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    return (
      <div className="saraksts-wrapper">
        <Grid
          // onRowSelected={this.props.virszemesUdensObjektsSelected}
          loading={this.props.udensParskati.loading}
          selectedId={this.props.udensParskati.selected}
          dataList={this.props.udensParskati.list}
          totalCount={this.props.udensParskati.count}
          columns={this.columns}
          rowEvents={this.rowEvents}
        >
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = ({kodif, udensParskati, user}) => {
  return {kodif, udensParskati, user}
}

export default connect(mapStateToProps, {udensParskatsDoubleSelected})(Parskati)
