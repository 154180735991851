import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {baisPazemesImportActions, baisPazemesImportsSelected} from "../../actions/importsActions"
import {
  getFormatterForKodif, getSelectFilterCfg,
  getTextFilterCfg,
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {getKlasifikatorsFieldCfg} from "../forms/FormHelpers";
import {showErrorMsg} from '../../actions/uiActions'

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'file.filename',
      text: 'Fails',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'tips',
      text: 'Tips',
      formatter: getFormatterForKodif(kodif.baisPazemesImportsTipi),
      filterCfg: getSelectFilterCfg(kodif.baisPazemesImportsTipi)
    }
  ]};

const  getFormFieldCfgs = ({kodif}) => {
  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'baisPazemesImportsTipi'),
      name: 'tips',
      renderer: getFormatterForKodif(kodif.baisPazemesImportsTipi)
    },
    {
      label: 'Faila ielāde',
      name: 'file_info_id',
      type: 'file',
      required: true,
    },
  ]};

class BaisPazemesImports extends Component {

  getCurrentQueryGrupas = () => {
    const {query} = this.props
    const ef = query && query.ef ? JSON.parse(query.ef) : {}
    return ef.grupas ? ef.grupas.split(',') : []
  }

  render() {
    const { bais_pazemes_imports } = this.props
    if(bais_pazemes_imports.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={this.props.bais_pazemes_imports}
        formWidthGuiOption="bais_imports_form_width"
        metadataFormCfg="bais_pazemes_imports"
        tiesibasLabot="BAIS_PU_LABOT"
        handleDelete={null}
        editable={false}
      />
    )

  }

}

const mapStateToProps = ({bais_pazemes_imports, kodif, location}) => ({bais_pazemes_imports, kodif, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : baisPazemesImportsSelected,
  addUrlQuery,
  ...baisPazemesImportActions,
  showErrorMsg
})(BaisPazemesImports)
