export const arcgisServices = (state = {
  layers: {},
  requested: false,
  loaded: false
}, { type, payload }) => {
  switch(type) {
    case 'arcgis_services_list_request':
      return {
        ...state,
        requested: true
      }
    case 'arcgis_services_list_success':
      const servers = payload.filter(serv => serv.services.length > 0).map(serv => ({
        name: serv.name,
        layers: serv.services.reduce((layers, s) => {
          layers[s.name] = {
            type: 'arcgis',
            title: s.description || s.name,
            group: serv.name,
            url: `${serv.url}${serv.path}/${s.name}/MapServer`,
            layerProps: {
              visible: false
            }
          }
          return layers
        }, {})
      }))
      return {
        ...state,
        loaded: true,
        servers
      }
    case `arcgis_services_list_failure`:
      return {
          ...state,
          loaded: true
      }
    default:
      return state
  }
}
