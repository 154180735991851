import React, {Component} from 'react';
import {AppFooter, AppHeader, AppSidebar, AppSidebarMinimizer} from '@coreui/react';

import {routesMap, navigationLinks} from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import AppSidebarNavIzraktenis from './AppSidebarNavIzraktenis';
import {comps} from '..';
import AtradneOrParskatsSearchField from './AtradneOrParskatsSearchField'
import CurrentUserDropdown from './CurrentUserDropdown'

class DefaultLayout extends Component {

    componentDidUpdate(){
        if (this.props.gui.force_reload === true) {
            this.props.guiUnset('force_reload')
        }
    }

    render() {
        const props = this.props;
        const {type} = props.location
        let CompName;
        if (routesMap[type] && routesMap[type].comp) {
            CompName = routesMap[type].comp;
        } else {
            CompName = 'NotFound';
        }
        console.log(props.location)
        const Comp = typeof CompName === "function" ? comps[CompName(props.location.query ? props.location.query.tab : '')] : comps[CompName]

        const mainClass = (routesMap[type] && routesMap[type].scrollable)
                ? 'main main-scrollable'
                : 'main'

        const {userApi, user} = props

        if(!this.compKey){
            this.compKey = 1
        }
        if (this.props.gui.force_reload === true) {
            this.compKey = this.compKey + 1
        }

        return (
            <div className="app">
                <AppHeader fixed>
                    <DefaultHeader/>
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg" minimized>
                        <CurrentUserDropdown className="d-lg-none"/>
                        <AtradneOrParskatsSearchField className="atradne-search-sidebar d-lg-none"/>
                        <AppSidebarNavIzraktenis navConfig={navigationLinks(userApi, user)} location={props.location} />
                        <AppSidebarMinimizer/>
                    </AppSidebar>
                    <main className={mainClass}>
                        <Comp key={this.compKey}/>
                    </main>
                </div>
                <AppFooter>
                    <DefaultFooter/>
                </AppFooter>
            </div>
        );
    }

}

export default DefaultLayout;
