import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getTextFilterCfg,
} from '../grid/ColumnHelpers'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import ArchivedFilterButtonToUrl from '../grid/ArchivedFilterButtonToUrl'

// const editIconColumn = getEditIconColumn((row) => (
//     {
//       type: 'loc_virszemes_udens_objekts',
//       payload: {
//         kods: row.kods
//       }
//     }
//   )
// )

const getColumns = ({kodif}) => {
  return [{
    dataField: 'river_code',
    text: 'Upe',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'reach_code',
    text: 'Baseins',
    filterCfg: getTextFilterCfg()
  }]
};

class SkersprofiliSaraksts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalSearch: false
    };
  }

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet('skersprofili_saraksts_karte_height', h);
  }

  rowEvents = {
    // onDoubleClick: (e, row, rowIndex) => {
    //   this.props.virszemesUdensObjektsDoubleSelected(row.kods)
    // }
  }

  render() {
    const vectorLayerCfgs = {
      skersprofili : {
        title: 'Upju šķērsprofili',
        features: this.props.skersprofili.list,
        styles: maputils.uoStyles.uoStyle,
        tooltipFunc: (feat) => feat.get('nosaukums'),
        // selected: this.props.skersprofili.selected
        legend: maputils.defaultSinglePolygonLegend
      }
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    return (
      <SplitterLayout
        vertical
        percentage
        primaryIndex={1}
        secondaryInitialSize={this.props.gui.skersprofili_saraksts_karte_height}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <Karte mapId="skersprofili_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

        <Grid
          // onRowSelected={this.props.virszemesUdensObjektsSelected}
          loading={this.props.skersprofili.loading}
          selectedId={this.props.skersprofili.selected}
          dataList={this.props.skersprofili.list}
          totalCount={this.props.skersprofili.count}
          columns={this.columns}
          rowEvents={this.rowEvents}
        >

          <BBoxFilterButtonToUrl/>
          <ArchivedFilterButtonToUrl/>
        </Grid>

      </SplitterLayout>
    )
  }
}

const mapStateToProps = ({kodif, skersprofili, gui, user}) => {
  return {kodif, skersprofili, gui, user}
}

export default connect(mapStateToProps, {guiSet})(SkersprofiliSaraksts)
