import React from 'react';
import { EditorState, RichUtils, Modifier } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import { CirclePicker } from 'react-color';
import createLinkPlugin from '@draft-js-plugins/anchor';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css'
import {
  SubButton,
  SupButton,
  ItalicButton,
  BoldButton,
  UnderlineButton,
  createInlineStyleButton
} from "draft-js-buttons";
import DOMPurify from 'dompurify';

import { stateToHTML } from "draft-js-export-html";

const staticToolbarPlugin = createToolbarPlugin();
const linkPlugin = createLinkPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin, linkPlugin];

const customColorStyleMap = {};

const rgbToHex = (color) => {
  // Create a temporary DOM element to parse the color
  const dummyElement = document.createElement('div');
  dummyElement.style.color = color;
  document.body.appendChild(dummyElement);

  // Get the computed color in RGB
  const computedColor = window.getComputedStyle(dummyElement).color;
  document.body.removeChild(dummyElement);

  // Extract RGB values from "rgb(r, g, b)"
  const rgbMatch = computedColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!rgbMatch) {
    // Fallback for unsupported formats
    return null;
  }

  const r = parseInt(rgbMatch[1], 10);
  const g = parseInt(rgbMatch[2], 10);
  const b = parseInt(rgbMatch[3], 10);

  // Convert to HEX
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}


export default class HTMLEditor extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        if (nodeName === 'sup') {
          return currentStyle.add('SUPERSCRIPT');
        }
        if (nodeName === 'sub') {
          return currentStyle.add('SUBSCRIPT');
        }
        if (node.style && node.style.color) {
          const colorHex = rgbToHex(node.style.color); // Convert to HEX
          if (colorHex) {
            const colorStyle = `COLOR_${colorHex.replace('#', '')}`;
            customColorStyleMap[colorStyle] = { color: colorHex }; // Add to custom style map
            return currentStyle.add(colorStyle);
          }
        }
        return currentStyle;
      },
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
          return createEntity('LINK', 'MUTABLE', { url: node.href });
        }
      }
    })(props.value);

    this.state = {
      editorState: EditorState.createWithContent(contentState),
      currVal: props.value,
      showColorPicker: false,
      selectedColor: '#000'
    };

    this.onChange = (editorState) => {
      const { setFieldValue, fieldName } = this.props
      const html = convertToHTML({
        styleToHTML: (style) => {
          if (style === 'SUPERSCRIPT') {
            return <sup />;
          } else if (style === 'SUBSCRIPT') {
            return <sub />;
          }

          if (style.startsWith('COLOR_')) {
            const colorHex = `#${style.split('_')[1]}`
            return <span style={{ color: colorHex }} />
          }
        },
        blockToHTML: (block) => ({ start: '', end: '' }),
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return {
              start: `<a href="${entity.data.url || entity.data.href}">`,
              end: '</a>'
            }
          }

          return originalText;
        }
      })(editorState.getCurrentContent());

      this.setState({
        editorState,
        currVal: editorState.getCurrentContent().getPlainText(),
        editorContentHtml: stateToHTML(editorState.getCurrentContent())
      });
      const cleanHtml = DOMPurify.sanitize(html).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
      setFieldValue(fieldName, cleanHtml)
    }

    this.handleKeyCommand = this.handleKeyCommand.bind(this)
    this.toggleColorPicker = this.toggleColorPicker.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  toggleColorPicker = () => {
    this.setState({ showColorPicker: !this.state.showColorPicker })
  }

  handleColorChange = (color) => {
    const { editorState } = this.state
    const selection = editorState.getSelection()
    const colorStyle = `COLOR_${color.hex.replace('#', '')}`

    // Add the color style to customColorStyleMap
    customColorStyleMap[colorStyle] = { color: color.hex }

    const newContentState = Modifier.applyInlineStyle(
      editorState.getCurrentContent(),
      selection,
      colorStyle
    )

    const newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style')

    this.setState({
      editorState: newEditorState,
      showColorPicker: false,
      selectedColor: color.hex
    })
    this.onChange(newEditorState)
  }

  render() {
    // Color picker button:
    const ColorPickerButton = createInlineStyleButton({
      style: 'CUSTOM_COLOR',
      children: <span onClick={this.toggleColorPicker}><i className="fa fa-pencil" style={{ color: this.state.selectedColor }} /></span>
    });

    return (
      <div className="draftjs-editor">
        <div className="draftjs-editor-container">
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            plugins={plugins}
            customStyleMap={{
              SUBSCRIPT: { fontSize: '0.75em', verticalAlign: 'sub' },
              SUPERSCRIPT: { fontSize: '0.75em', verticalAlign: 'super' },
              ...customColorStyleMap
            }}
          />
        </div>
        <div className="draftjs-toolbar-container">
          <Toolbar>
            {
              (externalProps) => (
                <div>
                  <BoldButton {...externalProps} />
                  <SubButton {...externalProps} />
                  <SupButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  {linkPlugin.LinkButton({ ...externalProps })}

                  <ColorPickerButton
                    {...externalProps}
                  />

                  {this.state.showColorPicker && (
                    <div className="top-10" style={{ zIndex: 2 }}>
                      <CirclePicker
                        color={this.state.selectedColor}
                        onChangeComplete={this.handleColorChange}
                      />
                    </div>
                  )}
                </div>
              )
            }
          </Toolbar>
        </div>
      </div>
    );
  }
}