import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getFormatterForKodif, getNumberFormatter } from "../grid/ColumnHelpers";
import { dzeramaUdensSatecesBaseinsActions, dzeramaUdensSatecesBaseinsInMapSelected } from '../../actions/dzeramaUdensSatecesBaseiniActions';
import { getDefaultRendererForMultiField, getKlasifikatorsFieldCfg } from '../forms/FormHelpers';
import Loading from '../Loading';
import Form from '../forms/Form';
import { Badge, Input } from 'reactstrap';
import TypeaheadField from '../forms/TypeaheadField';
import Link from 'redux-first-router-link';

const getFormFieldCfgs = ({kodif}) => {
  return [
    {
        name: 'kods',
        renderClassName: "bold",
        extraFieldProps: () => ({placeholder: 'Tiks piešķirts nākamais brīvais', disabled : true }),
        renderer: (fieldValue, _) => fieldValue
    }, {
        name: 'udensapgades_sistema',
        label: 'Ūdensapgādes sistēma',
        customField: (props) => {
          const { setFieldValue } = props
          const setUasData = (field, newUas) => {
            if (newUas && newUas.tah_id && newUas.nosaukums && newUas.operators && newUas.atlaujas_nr) {
                setFieldValue('veidot_jaunu', false)
                setFieldValue('udensapgades_sistemas_id', newUas.tah_id)
                setFieldValue('nosaukums', newUas.nosaukums)
                setFieldValue('operators', newUas.operators)
                setFieldValue('atlaujas_nr', newUas.atlaujas_nr)
            }
          }
          return <TypeaheadField
            fieldName="udensapgades_sistemas_search"
            setFieldValue={setUasData}
            autocompleteCfg={{
                remoteSearchViewName: 'udensapgades_sistemas_search',
                isValueObjStructure: true
            }}
            extraFieldProps={{
                placeholder: 'Meklēt esošu ūdensapgādes sistēmu',
                disabled: props.currentValues.veidot_jaunu
            }}
        />
        }
    }, {
        name: 'veidot_jaunu',
        label: 'Veidot jaunu ūdensapgādes sistēmu',
        customField: (props) => {
            const { fieldName, currentVal, setFieldValue } = props
            const handleChange = (e) => {
                setFieldValue(fieldName, !currentVal)
                setFieldValue('udensapgades_sistemas_id', null)
                setFieldValue('nosaukums', null)
                setFieldValue('operators', null)
                setFieldValue('atlaujas_nr', null)
            }

            return <Input
                id={fieldName}
                type="checkbox"
                name={fieldName}
                onChange={handleChange}
                value={currentVal}
            />
        },
    }, {
        name: 'nosaukums',
        renderClassName: "bold",
        required: true,
        overridePropsFunc: (props) => {
            const changedProps = { ...props }
            changedProps.extraFieldProps = {
                disabled: !props.currentValues.veidot_jaunu
            }
            return changedProps
        }
    }, {
        name: 'operators',
        required: true,
        overridePropsFunc: (props) => {
            const changedProps = { ...props }
            changedProps.extraFieldProps = {
                disabled: !props.currentValues.veidot_jaunu
            }
            return changedProps
        }
    }, {
        name: 'atlaujas_nr',
        required: true,
        overridePropsFunc: (props) => {
            const changedProps = { ...props }
            changedProps.extraFieldProps = {
                maxLength: 10,
                disabled: !props.currentValues.veidot_jaunu
            }
            return changedProps
        }
    }, {
        name: 'platiba',
        type: 'number',
        renderer: getNumberFormatter(),
        editable: false
    }, {
        name: 'udens_ieguves_vietas',
        label: 'Ūdens ieguves vietas',
        type: 'autocomplete',
        autocompleteCfg: {
            multiple: true,
            keyField: 'urbumi_id',
            initialOptionsViewName: 'dzerama_udens_sateces_baseins_urbumi_search',
            extraFilterParams : {},
            isValueObjStructure: true
        },
        renderer: (fieldValue, dataObj) => (
            fieldValue.map((urb,i) =>
                <span key={i} className="multiple-values">
                        <Link
                            to={{
                                type: 'loc_urbums',
                                payload: {
                                    id: urb.urbumi_id,
                                }
                        }}>
                            {urb.urbumi_id}
                        </Link>
                </span>
            )
        )
    }, {
        name: 'pazemes_udenu_objekti',
        label: 'Pazemes ūdeņu objekti',
        type: 'autocomplete',
        autocompleteCfg: {
            multiple: true,
            keyField: 'udens_objekts_id',
            initialOptionsViewName: 'dzerama_udens_sateces_baseins_pazemes_udenu_objekti_search',
            extraFilterParams : {},
            isValueObjStructure: true
        },
        renderer: (fieldValue, dataObj) => (
            fieldValue.map((puo,i) =>
                <span key={i} className="multiple-values">
                        <Link
                            to={{
                            type: 'loc_pazemes_udens_objekts',
                            payload: {
                                kods: puo.kods,
                            }
                        }}>
                            {puo.kods}
                        </Link>
                </span>
            )
        )
    }, {
        name: 'urbumi_atradnes_id',
        label: 'Piederība pazemes ūdeņu atradnei',
        type: 'autocomplete',
        autocompleteCfg: {
            remoteSearchViewName: 'dzerama_udens_sateces_baseins_pazemes_udenu_atradne_search',
            labelFieldName: 'urbumi_atradnes_nosaukums'
        },
        renderer: (nos, row) => (
            <div className="badge-column">
                <Link to={{
                    type: 'loc_urbumu_atradne',
                    payload: { id: row.urbumi_atradnes_id }
                }}>
                    <Badge color="light">
                        {row.urbumi_atradnes_nosaukums}
                    </Badge>
                </Link>
            </div>
        )
    }, {
        ...getKlasifikatorsFieldCfg(kodif, 'dzeramaUdensSatecesBaseiniKategorija'),
        name: 'kategorija',
        required: true,
        renderer: getFormatterForKodif(kodif.dzeramaUdensSatecesBaseiniKategorija)
    }, {
        name: 'ter_iedalijums_wfs',
        label: 'Administratīvā teritorija',
        editable: false,
        fieldNotes: 'Administratīvās un teritoriālās vienības tiek aizpildītas automātiski no WFS servisa pēc ģeometrijas labošanas.',
        renderer: (allAdm, vals) => {
            // group by limenis_1
            const grouped = allAdm.reduce((grouped, adm) => {
                if(!grouped[adm.limenis_1]) {
                    grouped[adm.limenis_1] = []
                }
                if(adm.limenis_2){
                    grouped[adm.limenis_1].push(adm.limenis_2)
                }
                return grouped
            }, {})
            return <>
                {Object.keys(grouped).map(adm => <p key={adm} className="links">{adm}{grouped[adm].length > 0 ? ' - ' + grouped[adm].join('; ') : null}</p>)}
                {vals.baseins_ter_iedalijums_wfs_kluda ? <p className="danger links">{vals.baseins_ter_iedalijums_wfs_kluda}</p> : null}
            </>
        }
    }, {
        name: 'ter_iedalijums',
        label: 'Administratīvā teritorija (ATVK2010)',
        // TODO: Readonly, laikam
        type: 'autocomplete',
        autocompleteCfg: {
          multiple: true,
          keyField: 'ter_iedalijums_id',
          remoteSearchViewName: 'ter_iedalijums_search'
        },
        renderer: getDefaultRendererForMultiField('tah_label'),
    }
  ]
}

class BaseinsInfo extends Component {

    render() {

        const {dzeramaUdensSatecesBaseins, kodif, user} = this.props

        const {selectedItem, editable, submiting} = dzeramaUdensSatecesBaseins

        if (!selectedItem) {
            return (<Loading/>)
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs({
                kodif: kodif
            })
        }

        return <div className="form-container">
            <Form
                // editable={false}
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                metadataFormCfg="dzerama_udens_sateces_baseins_view"
                onSubmit={this.props.saveAct}
                onReset={this.props.stopEditAct}
                onStartEdit={this.props.startEditAct}
                onDelete={user.tiesibas.includes('SATECES_BASEINI_LABOT') ? this.props.deleteAct : null}
                tiesibasLabot="SATECES_BASEINI_LABOT"
            />
        </div>
    }
}

const mapStateToProps = ({dzeramaUdensSatecesBaseins, kodif, user}) => ({dzeramaUdensSatecesBaseins, kodif, user})

const DzeramaUdensSatecesBaseinsInfo = connect(mapStateToProps, {...dzeramaUdensSatecesBaseinsActions, dzeramaUdensSatecesBaseinsInMapSelected})(BaseinsInfo)
export default DzeramaUdensSatecesBaseinsInfo
