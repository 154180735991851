import React, { Component, createRef} from 'react'
import Chevron from "./Chevron";

export default class Accordion extends Component {

  constructor(props) {
      super(props)
      this.state = {
        active: !!props.active,
        height: 0,
      }
      this.content = createRef()
  }

  componentDidMount() {
      if(this.state.active){
        this.setState({
          ...this.state,
          height: this.content.current.scrollHeight
        })
      }
  }

  toggleAccordion = () => {
    this.setState({
      ...this.state,
      active: !this.state.active,
      height: this.state.active ? 0 : this.content.current.scrollHeight
    })
  }

  adjustHeight = () => {
    const ch = this.content.current.scrollHeight
    if(ch !== this.state.height){
      this.setState({
        height: !this.state.active ? 0 : ch
      })
    }
  }

  onResizesContent = () => {
    console.log("onResizesContent")
  }

  render() {
    const {active, height} = this.state
    return (
      <div className="accordion__section">
        <button className={active ? 'accordion active' : 'accordion'} onClick={this.toggleAccordion}>
          <p className="accordion__title">{this.props.title}</p>
          <Chevron className={active ? 'accordion__icon rotate' : 'accordion__icon'} width={8} fill={"#eee"} />
        </button>
        <div ref={this.content}
          style={{ maxHeight: height}}
          onClick={this.adjustHeight}
          className="accordion__content">
          { this.props.children }
        </div>
      </div>
    )
  }
}

