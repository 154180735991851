import React, {Component} from 'react';
import {connect} from 'react-redux'

import Loading from '../Loading'
import {
    // Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
    // Modal,
    // ModalBody,
    // ModalFooter,
    // ModalHeader
} from 'reactstrap';

import SplitterLayout from 'react-splitter-layout';

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'
import {guiSet, addUrlQuery} from '../../actions/uiActions'
import NotFound from '../NotFound'
import Pamatinformacija from "./Pamatinformacija";
// import VnkIesniegumaAtrasanas from './VnkIesniegumaAtrasanas';

const getTabCfgs = () => {
    return {
        pamatinfo: {
            title: 'Pamatinformācija',
            comp: Pamatinformacija
        },
        // atrasanas: {
        //     title: 'Atrašanās vieta',
        //     // comp: VnkIesniegumaAtrasanas
        // }
    }
}

//create vector layers for udens_objektss kartina
const getVectorLayerCfgs = ({vnkIesniegums, pazemesUdensObjektsAtrasanas, activeTab}) => {
    let cfg = {}
    if (!!vnkIesniegums.geom) {
        cfg.vnkIesniegumi = {
            title: 'VNK iesniegumi',
                features: vnkIesniegums,
                styles: maputils.pointStyles,
                zoomTo: vnkIesniegums.id,
                epsgCode: 3059,
                editable: vnkIesniegums.editable,
                tooltipFunc: (feat) => `${feat.get('vietas_adrese')}`,
                selected: vnkIesniegums.selected,
                legend: maputils.defaultSinglePointLegend
        }

        // if (activeTab === 'atrasanas' && pazemesUdensObjektsAtrasanas && pazemesUdensObjektsAtrasanas.selectedItem) {
        //     if (pazemesUdensObjektsAtrasanas.selectedItem.usik) {
        //         cfg.usik = {
        //             features: pazemesUdensObjektsAtrasanas.selectedItem.usik,
        //             title: 'ŪSIK',
        //             styles: maputils.uoStyles.usikStyle,
        //             opacity: 0.9,
        //             tooltipFunc: undefined,
        //             layerProps: {
        //                 visible: false
        //             },
        //             legend: maputils.defaultSinglePolygonLegend
        //         }
        //     }
        //     if (pazemesUdensObjektsAtrasanas.selectedItem.iadt) {
        //         cfg.iadt = {
        //             features: pazemesUdensObjektsAtrasanas.selectedItem.iadt,
        //             title: 'Īpaši aizsargājamas dabas teritorijas',
        //             styles: maputils.uoStyles.usikStyle,
        //             opacity: 0.9,
        //             tooltipFunc: undefined,
        //             layerProps: {
        //                 visible: false
        //             },
        //             legend: maputils.defaultSinglePolygonLegend
        //         }
        //     }
        // }
    }
    return cfg
}

class VnkIesniegumsActionButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return <div className="actionButtons">
            {/*<Button className="float-right" color="secondary" onClick={this.toggleModal}><i className="fa fa-print"></i>&nbsp;&nbsp;Eksportēt</Button>*/}

            {/* <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="modal-lg">
                <ModalHeader toggle={this.toggleModal}>UdensObjektss eksports</ModalHeader>
                <ModalBody>
                  Te būs udens_objektss eksports, piemēram, uz PDF.
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleModal}>Aizvērt</Button>
                </ModalFooter>
              </Modal> */}
        </div>
    }
}

class VnkIesniegumsTabs extends Component {

    renderTabNavLinks = (activeTab, isNew) => {
        return Object.keys(this.tabCfgs).map(k => (
            <NavItem key={k}>
                <NavLink className={k === activeTab ? 'active' : ''} disabled={isNew && this.tabCfgs[k].disableWhenNew}
                         onClick={() => this.props.addUrlQuery({tab: k === 'pamatinfo' ? undefined : k})}>
                    {this.tabCfgs[k].title}
                </NavLink>
            </NavItem>
        ))
    }

    renderTabContents = (activeTab, isNew) => {
        return Object.keys(this.tabCfgs).map(k => {
                const Comp = this.tabCfgs[k].comp ? this.tabCfgs[k].comp : NotFound
                return <TabPane key={k} tabId={k}>
                    <Comp/>
                </TabPane>
            }
        )
    }

    render() {
        const {activeTab, isNew, getTabCfgsFunc} = this.props
        // we cache tabs
        if (!this.tabCfgs) {
            const f = getTabCfgsFunc ? getTabCfgsFunc : getTabCfgs
            this.tabCfgs = f()
        }
        return <>
            <Nav tabs>
                {this.renderTabNavLinks(activeTab, isNew)}
            </Nav>
            <TabContent activeTab={activeTab}>
                {this.renderTabContents(activeTab, isNew)}
            </TabContent>
        </>
    }
}

export class VnkIesniegumsComp extends Component {

    mapPaneSizeChange = (w) => {
        this.props.guiSet('vnk_iesniegums_karte_width', w);
    }

    mapPaneSizeChangeHor = (h) => {
        this.props.guiSet('vnk_iesniegums_karte_height', h);
    }

    render() {

        const {selectedItem: vnkIesniegums} = this.props.vnkIesniegums

        // loading while we have no pazemesUdensObjekts
        if (!vnkIesniegums) {
            return (<Loading/>)
        }

        // initial cfgs can come in as props, or we can create default ones
        const {
            query, getVectorLayerCfgsFunc, getTabCfgsFunc, mapId, addUrlQuery,
        } = this.props

        const isNew = !vnkIesniegums.id
        const activeTab = query && query.tab && !isNew ? query.tab : 'pamatinfo'

        const f = getVectorLayerCfgsFunc ? getVectorLayerCfgsFunc : getVectorLayerCfgs
        const vectorLayerCfgs = f({vnkIesniegums, activeTab})

        const largeScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 900

        return (<>
                    <SplitterLayout
                        key={largeScreen ? 'hor' : 'ver'}
                        vertical={!largeScreen}
                        customClassName="pane-paddings-hor"
                        percentage
                        primaryIndex={0}
                        secondaryInitialSize={largeScreen ? this.props.gui.vnk_iesniegums_karte_width : this.props.gui.vnk_iesniegums_karte_height}
                        onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}>

                        <div className={largeScreen ? 'leftside' : 'topside'}>
                            <VnkIesniegumsTabs activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc}
                                              addUrlQuery={addUrlQuery}/>
                        </div>

                        <div className="rightside">
                            <VnkIesniegumsActionButtons/>
                            <div className="karte-wrapper">
                                <Karte mapId={mapId ? mapId : 'vnk_iesniegums_kartina'} vectorLayerCfgs={vectorLayerCfgs}/>
                            </div>
                        </div>
                    </SplitterLayout>
            </>)
    }
}

//connect udens_objektss componente with udensObjekts state
const mapStateToProps = ({vnkIesniegums, gui, location}) => ({
    vnkIesniegums,
    gui,
    query: location.query
})

const VnkIesniegums = connect(mapStateToProps, {addUrlQuery, guiSet})(VnkIesniegumsComp)
export default VnkIesniegums
