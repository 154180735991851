import {callApi} from './helpers'

export const loadPazinojumi = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'pazinojums_list',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const pazinojumsApskatits = (pazinojumsId) => (dispatch, getState) => {
    const success = () => {
        dispatch(loadPazinojumi(getState().location.query));
        dispatch(getJaunuPazinojumuSkaits())
    }
    return dispatch(callApi({
        viewName: 'pazinojums_lietotajs_skatits',
        crudMethod: 'save',
        crudParam: {pazinojums_id: pazinojumsId},
        success
    }))
}

export const visiPazinojumiApskatiti = () => (dispatch, getState) => {
    const success = () => {
        dispatch(loadPazinojumi(getState().location.query));
        dispatch(getJaunuPazinojumuSkaits())
    }
    return dispatch(callApi({
        viewName: 'pazinojums_lietotajs_skatits_visi',
        crudMethod: 'save',
        crudParam: {},
        success
    }))
}

export const getJaunuPazinojumuSkaits = () => (dispatch) => {
    return dispatch(callApi({
        viewName: 'pazinojumu_skaits',
        crudMethod: 'list'
    }))
}

