import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {
  nobidesSelected, nobidesActions
} from '../../actions/nobidesActions'
import {
  getTextFilterCfg,
  dateFormatter,
  getNumberFilterCfg,
  getBooleanSelectFilterCfg,
  getBooleanFormatter
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'stacijas_nosaukums',
      text: 'Stacija',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'gads',
      text: 'Gads',
      width: 120,
      filterCfg: getNumberFilterCfg()
    }, {
      dataField: 'ir_novertejums',
      text: 'Ir veikts novērtējums',
      width: 110,
      filterCfg: getBooleanSelectFilterCfg(),
      formatter: getBooleanFormatter()
    }, {
      dataField: 'datums_no',
      text: 'No',
      width: 100,
      formatter: dateFormatter
    }, {
      dataField: 'datums_lidz',
      text: 'Līdz',
      width: 100,
      formatter: dateFormatter
    }
  ]};

const  getFormFieldCfgs = ({kodif}) => {

  return [
    {
      name: 'stacijas_nosaukums',
      type: 'text',
      required: true
    },
    {
      name: 'gads',
      type: 'number',
      required: true
    },
    {
      name: 'ir_novertejums',
      renderer: getBooleanFormatter()
    },
    {
      name: 'datums_no',
      type: 'date',
      hiddenFunc: ({currentValues}) => !currentValues.ir_novertejums,
      validation: (values) => {
        if (values.ir_novertejums && !values.datums_no) {
          return 'Obligāts lauks'
        }
      },
    },
    {
      name: 'datums_lidz',
      type: 'date',
      hiddenFunc: ({currentValues}) => !currentValues.ir_novertejums,
      validation: (values) => {
        if (values.ir_novertejums && !values.datums_lidz) {
          return 'Obligāts lauks'
        }
      },
    },
  ]};

class Nobides extends Component {

  render() {
    const nobides = this.props.nobides
    if(!nobides || nobides.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={this.props.nobides}
        formWidthGuiOption="nobides_form_width"
        metadataFormCfg="uo_nobides_edit"
        extraGridProps={this.extraGridProps}
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
      />
    )

  }

}

const mapStateToProps = ({nobides, kodif, location}) => ({nobides, kodif, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : nobidesSelected,
  addUrlQuery,
  ...nobidesActions
})(Nobides)
