import {
    callApi, 
    getDefaultEditActions,
    pushUrlToBrowserHistory
} from './helpers'
import {
  redirect
} from 'redux-first-router'

export const loadVirszemesRiskiUnIznemumi = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'virszemes_udens_objekts_riski_list',
        crudMethod: 'listPaging',
        crudParam: query
    }))
}

export const loadPazemesRiskiUnIznemumi = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'pazemes_udens_objekts_riski_list',
        crudMethod: 'listPaging',
        crudParam: query
    }))
}

export const virszemesRiskiUnIznemumiActions = getDefaultEditActions({
    actionTypeName: 'virszemesRisksUnIznemums',
    stateName: 'virszemesRisksUnIznemums',
    apiViewName: 'virszemes_udens_objekts_riski_view',
    overridedActions: {
      createAct: () => (dispatch) => {
        dispatch({
            type: 'virszemes_udens_objekts_riski_view_new_success',
            payload: {
                id: null,
                udens_objekts_id: null,
                uo_kods: null,
                uo_nosaukums: null,
                gads: null,
                biogeni: null,
                bistamas: null,
                hidro: false,
                parrobezu: false,
                pludi: false,
                cits: false
            }
        })
      }
    },
    afterSave: (dispatch, getState, result, savingNew) => {
      dispatch({
        type: 'virszemes_udens_objekts_riski_view_clear'
      })
      dispatch(redirect({
        type: 'loc_virszemes_risks_un_iznemumi_ieraksts',
        payload: {
          id: result.id,
          kods: result.uo_kods
        }
      }))
    },
    createActArgsToCrudParams: ({ kods }) => {
        return {
            uo_kods: kods
        }
    }
})

export const pazemesRiskiUnIznemumiActions = getDefaultEditActions({
    actionTypeName: 'pazemesRisksUnIznemums',
    stateName: 'pazemesRisksUnIznemums',
    apiViewName: 'pazemes_udens_objekts_riski_view',
    afterSave: (dispatch, getState, result, savingNew) => {
      dispatch({
        type: 'pazemes_udens_objekts_riski_view_clear'
      })
      dispatch(redirect({
        type: 'loc_pazemes_risks_un_iznemumi_ieraksts',
        payload: {
          id: result.id,
          kods: result.uo_kods
        }
      }))
    },
    createActArgsToCrudParams: ({ kods }) => {
        return {
            uo_kods: kods
        }
    }
})

export const virszemesRisksUnIznemumsSelected = (id) => {
    return {
      type: 'virszemesRiskiUnIznemumi_selected',
      payload: id
    }
}

export const pazemesRisksUnIznemumsSelected = (id) => {
    return {
      type: 'pazemesRiskiUnIznemumi_selected',
      payload: id
    }
}

export const virszemesRisksUnIznemumsDoubleSelected = ({ id, uo_kods }) => (dispatch, getState) => {
    pushUrlToBrowserHistory(getState)
    dispatch(redirect({
      type: 'loc_virszemes_risks_un_iznemumi_ieraksts',
      payload: {
        id,
        kods: uo_kods
      }
    }))
}

export const pazemesRisksUnIznemumsDoubleSelected = ({ id, uo_kods }) => (dispatch, getState) => {
    pushUrlToBrowserHistory(getState)
    dispatch(redirect({
      type: 'loc_pazemes_risks_un_iznemumi_ieraksts',
      payload: {
        id,
        kods: uo_kods
      }
    }))
}