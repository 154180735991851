import React, { Component } from 'react';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav} from 'reactstrap'
import {connect} from 'react-redux'

import { AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import logo from '../../assets/img/brand/LVGMC-logo.svg'
import logoSmall from '../../assets/img/brand/lvgmc_logo_small.png'
import Breadcrumb from './Breadcrumb'
import { onAtradneNextOrPrevInTopMenu, onAtradneNextOrPrevInTitle,
  onAtradneDalaNextOrPrevInTitle} from '../../actions/atradnesActions'
import {openNextOrPrevDok} from '../../actions/dokumentacijaActions'
import {openParskats, openParskatsWithFilter, openParskatsWithFilterFromList} from '../../actions/geofondActions'
import {dateFormatter} from '../grid/ColumnHelpers'
import Link from 'redux-first-router-link'
import {getCurrentSupApp} from '../../actions/helpers'
import AtradneOrParskatsSearchField from './AtradneOrParskatsSearchField'
import CurrentUserDropdown from './CurrentUserDropdown'
import * as constants from '../../constants'

class DefaultHeader extends Component {

  openHelpUrl = (url) => () => {
    window.open(url)
  }

  render() {

    const {user, location, atradne, atradnesDala, onAtradneNextOrPrevInTitle,
      onAtradneDalaNextOrPrevInTitle, openNextOrPrevDok,
      pase, krajums, licence, limits, pazinojumuSkaits, urbums, urbumu_atradne, urbumu_ieguve,
      parskats, openParskats, openParskatsWithFilter, openParskatsWithFilterFromList} = this.props;

    //atradnes titles
    let atradneTitle, atradneNr, atradneDalaTitle, ieprPecNr, nakPecNr, ieprPecNos, nakPecNos, ieprDalaPecNos, nakDalaPecNos = null
    let atradneArh, dalaArh = false
    if(location.type === 'loc_atradne' && atradne && atradne.id){
      atradneTitle = atradne.nosaukums
      atradneNr = atradne.numurs
      atradneArh = !!atradne.arhivets_iemesls
      ieprPecNr = atradne.ieprPecNr
      nakPecNr = atradne.nakPecNr
      ieprPecNos = atradne.ieprPecNos
      nakPecNos = atradne.nakPecNos
    }
    if(location.type === 'loc_atradnes_dala' && atradnesDala){
      atradneTitle = atradnesDala.atradnes_nosaukums
      atradneNr = atradnesDala.atradne
      atradneArh = !!atradnesDala.atradnes_arhivets_iemesls
      if(atradnesDala.id){
        atradneDalaTitle = atradnesDala.nosaukums
        dalaArh = !!atradnesDala.arhivets_iemesls
        ieprPecNr = atradnesDala.ieprPecNr
        nakPecNr = atradnesDala.nakPecNr
        ieprPecNos = atradnesDala.ieprPecNos
        nakPecNos = atradnesDala.nakPecNos
        ieprDalaPecNos = atradnesDala.ieprDalaPecNos
        nakDalaPecNos = atradnesDala.nakDalaPecNos
      }
    }

    //dokumentacija arrows
    let dokTitle, prevDokId, nextDokId, dokType, si = null
    if((location.type === 'loc_atradnes_pase' || location.type === 'loc_atradnes_dala_pase') && pase && pase.selectedItem && pase.selectedItem.id){
      si = pase.selectedItem
      dokTitle = `${si.adresats.nosaukums} ${dateFormatter(si.izsniegts_datums)} - ${dateFormatter(si.derigs_lidz_datums)}`
      dokType = 'pase'
    } else if((location.type === 'loc_atradnes_licence' || location.type === 'loc_atradnes_dala_licence') && licence && licence.selectedItem && licence.selectedItem.id){
      si = licence.selectedItem
      dokTitle = `${si.numurs || ''} ${si.adresats.nosaukums} ${dateFormatter(si.izsniegts_datums)} - ${dateFormatter(si.derigs_lidz_datums)}`
      dokType = 'licence'
    } else if((location.type === 'loc_atradnes_limits' || location.type === 'loc_atradnes_dala_limits') && limits && limits.selectedItem && limits.selectedItem.id){
      si = limits.selectedItem
      dokTitle = `${si.adresats.nosaukums} ${dateFormatter(si.izsniegts_datums)} - ${dateFormatter(si.derigs_lidz_datums)}`
      dokType = 'limits'
    } else if((location.type === 'loc_atradnes_krajums' || location.type === 'loc_atradnes_dala_krajums') && krajums && krajums.selectedItem && krajums.selectedItem.id){
      si = krajums.selectedItem
      dokTitle = `${si.dalas_nosaukums ? si.dalas_nosaukums : ''} ${si.kategorija || ''} - ${si.izrakteni.map(izr => izr.klasifikators).join(', ')}`
      dokType = 'krajums'
    } else if(location.type === 'loc_urbums' && urbums && urbums.selectedItem && urbums.selectedItem.id){
      const sUrb = urbums.selectedItem
      dokTitle = `${sUrb.nr_pirmavota ? sUrb.nr_pirmavota : ''} - ${sUrb.adrese ? sUrb.adrese : ''}`
    } else if(location.type === 'loc_urbumu_atradne' && urbumu_atradne && urbumu_atradne.selectedItem && urbumu_atradne.selectedItem.id){
      const sUrb = urbumu_atradne.selectedItem
      dokTitle = `${sUrb.id} - ${sUrb.nosaukums ? sUrb.nosaukums : ''}`
    } else if(location.type === 'loc_urbumu_ieguve' && urbumu_ieguve && urbumu_ieguve.selectedItem && urbumu_ieguve.selectedItem.id){
      const sUrb = urbumu_ieguve.selectedItem
      dokTitle = `${sUrb.id} - ${sUrb.nosaukums ? sUrb.nosaukums : ''}`
    }
    if(si){
      nextDokId = si.nak
      prevDokId = si.iepr
    }

    //parskats header
    let parskatsTitle, prevParskId, nextParskId, prevParskFilter, nextParskFilter, offsetWithPage, openParskatsWithFilterMethod = null;
    if ((location.type === 'loc_parskats' || location.type === 'loc_parskats_from_list') && parskats && parskats.inv_numurs){
      parskatsTitle = parskats.inv_numurs
      if (parskatsTitle)
        parskatsTitle = parskatsTitle.length > 25 ? parskatsTitle.substring(0, 22) + '...' : parskatsTitle
      prevParskId = parskats.prevParskId
      nextParskId = parskats.nextParskId
      const {query} = location
      if(query && query['offset'] != null && parskats.count){
        const offset = parseInt(query['offset'])
        const page = query['p'] ?? 1
        offsetWithPage = (page - 1) * constants.DEFAULT_SARAKSTS_PAGE_SIZE + offset
        if(offsetWithPage > 0){
          prevParskFilter = {...query, offset: offset - 1}
        }
        if(offsetWithPage + 1 < parskats.count){
          nextParskFilter = {...query, offset: offset + 1}
        }
        openParskatsWithFilterMethod = location.type === 'loc_parskats' ? openParskatsWithFilter : openParskatsWithFilterFromList
      }
    }

    const pazinojumiTotal = pazinojumuSkaits && pazinojumuSkaits.list ? pazinojumuSkaits.list.reduce((total, ps)=> ps.sk + total, 0) : 0

    const instrUrl = ({
      'zdz': 'https://videscentrs.lvgmc.lv/lapas/zemes-dzilu-informacijas-sistema',
      'bais': 'https://videscentrs.lvgmc.lv/lapas/udens-apsaimniekosana-un-pludu-parvaldiba'
    })[getCurrentSupApp(location)] ?? 'https://videscentrs.lvgmc.lv/'

    return (
      <>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand href="/" className="d-lg-down-none"
          minimized={{
            src: logoSmall,
            alt:'LVĢMC Logo',
            height: 36,
            width: 36
          }}
          full={{
            src: logo,
            alt:'LVĢMC Logo',
            height: 36,
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Breadcrumb/>

        { atradneNr ?
        <div className="atradne-title d-sm-down-none">
          <h4 className="atradne-num">
            { ieprPecNr ?
              <i className="fa fa-caret-left" title={ieprPecNr} aria-hidden="true" onClick={() => onAtradneNextOrPrevInTitle(ieprPecNr)}></i>
            : null }
            <span className={atradneArh ? 'cross-out': ''}>{atradneNr}</span>
            { nakPecNr ?
              <i className="fa fa-caret-right" title={nakPecNr} aria-hidden="true" onClick={() => onAtradneNextOrPrevInTitle(nakPecNr)}></i>
            : null }
          </h4>
          <h4 className="atradne-name">
            { ieprPecNos ?
              <i className="fa fa-caret-left" title={ieprPecNos} aria-hidden="true" onClick={() => onAtradneNextOrPrevInTitle(ieprPecNos)}></i>
            : null }
            <span className={atradneArh ? 'cross-out bold': 'bold'}>{atradneTitle}</span>
            { nakPecNos ?
              <i className="fa fa-caret-right" title={nakPecNos} aria-hidden="true" onClick={() => onAtradneNextOrPrevInTitle(nakPecNos)}></i>
            : null }
          </h4>
          { atradneDalaTitle ?
            <h4 className="atradne-name part">
              { ieprDalaPecNos ?
                <i className="fa fa-caret-left" aria-hidden="true" onClick={() => onAtradneDalaNextOrPrevInTitle(atradneNr, ieprDalaPecNos)}></i>
              : null }
              <span className={dalaArh || atradneArh ? 'cross-out': ''}>{atradneDalaTitle}</span>
              { nakDalaPecNos ?
              <i className="fa fa-caret-right" aria-hidden="true" onClick={() => onAtradneDalaNextOrPrevInTitle(atradneNr, nakDalaPecNos)}></i>
              : null }
            </h4>
          : null }
        </div>
        : null }

        {dokType || dokTitle ?
          <div className="atradne-title d-sm-down-none">
            <h4 className="atradne-name">
              { prevDokId ?
                <i className="fa fa-caret-left" aria-hidden="true" onClick={() => openNextOrPrevDok(dokType, si, prevDokId)}></i>
              : null }
              <span>{dokTitle}</span>
              { nextDokId ?
                <i className="fa fa-caret-right" aria-hidden="true" onClick={() => openNextOrPrevDok(dokType, si, nextDokId)}></i>
              : null }
            </h4>
          </div>
        : null}

        {parskatsTitle ?
          <div className="atradne-title d-sm-down-none">
            <h4 className="atradne-num">
            { prevParskId ?
                <i className="fa fa-caret-left" aria-hidden="true" onClick={() => openParskats(prevParskId)}></i>
              : null }
              <span className="no-wrap">{parskatsTitle}</span>
              { nextParskId ?
                <i className="fa fa-caret-right" aria-hidden="true" onClick={() => openParskats(nextParskId)}></i>
              : null }
            </h4>
            {prevParskFilter || nextParskFilter ?
              <h4 className="atradne-num">
              { prevParskFilter ?
                  <i className="fa fa-caret-left" aria-hidden="true" onClick={() => openParskatsWithFilterMethod(prevParskFilter)}></i>
                : null }
                <span className='outer-span'>
                  <span className="no-wrap" style={{fontSize: '90%'}}>Atlasītie pārskati</span>
                  <span className="no-wrap" style={{fontSize: '75%'}}>{offsetWithPage + 1}. no {parskats.count}</span>
                </span>
                { nextParskFilter ?
                  <i className="fa fa-caret-right" aria-hidden="true" onClick={() => openParskatsWithFilterMethod(nextParskFilter)}></i>
                : null }
              </h4>
            : null}
          </div>
        : null}

          <AtradneOrParskatsSearchField className="atradne-search d-md-down-none"/>

          <Nav navbar>

              {user && user.tiesibas.includes('PAZINOJUMI') ?
              <UncontrolledDropdown className="padding-10 nav-item">
                <DropdownToggle nav>
                <i className="fa fa-bell-o fa-lg"></i>{pazinojumiTotal > 0 ? <Badge pill color="danger">{pazinojumiTotal}</Badge> : null }
                </DropdownToggle>
                {pazinojumiTotal > 0 ?
                <DropdownMenu>
                  {pazinojumuSkaits.list.map((ps, i) =>
                    <Link key={i} to={{ type: 'loc_pazinojumi', query : { f: JSON.stringify({veids: ps.paz_veids, ir_skatits: 'f'})}}}>
                      <DropdownItem>{ps.paz_veids}<Badge color="info">{ps.sk}</Badge></DropdownItem>
                    </Link>
                  )}
                </DropdownMenu>
                : null }
              </UncontrolledDropdown>
              : null }

              <CurrentUserDropdown className="d-md-down-none"/>

              <span className="nav-link pointer help-btn">
                <i title="Lietošanas informācija" onClick={this.openHelpUrl(instrUrl)} className="fa fa-lg fa-question-circle"></i>
              </span>
          </Nav>
      </>
    );
  }
}

const mapStateToProps = ({user, location, atradne, atradnesDala, pase, krajums, licence,
  limits, pazinojumuSkaits, urbums, urbumu_atradne, urbumu_ieguve, parskats}) =>
{return {user, location, atradne: atradne.selectedItem, pase, krajums, licence, limits,
  atradnesDala: atradnesDala.selectedItem, pazinojumuSkaits, urbums, urbumu_atradne, urbumu_ieguve, parskats: parskats.selectedItem}}

export default connect(mapStateToProps, {onAtradneNextOrPrevInTopMenu,
  onAtradneNextOrPrevInTitle, onAtradneDalaNextOrPrevInTitle, openNextOrPrevDok, openParskats, openParskatsWithFilter, openParskatsWithFilterFromList})( DefaultHeader )
