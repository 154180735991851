import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {hidrogeoImportActions, hidrogeoImportsSelected} from "../../actions/importsActions"
import {
  getFormatterForKodif, getSelectFilterCfg,
  getTextFilterCfg,
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {getKlasifikatorsFieldCfg} from "../forms/FormHelpers";

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'file.filename',
      text: 'Fails',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'tips',
      text: 'Tips',
      formatter: getFormatterForKodif(kodif.hidrogeoImportsTipi),
      filterCfg: getSelectFilterCfg(kodif.hidrogeoImportsTipi)
    }
  ]};

const  getFormFieldCfgs = ({kodif}) => {

  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'hidrogeoImportsTipi'),
      name: 'tips',
      renderer: getFormatterForKodif(kodif.hidrogeoImportsTipi)
    },
    {
      label: 'Faila ielāde',
      name: 'file_info_id',
      type: 'file',
      required: true,
    },
  ]};

class HidrogeoImports extends Component {

  getCurrentQueryGrupas = () => {
    const {query} = this.props
    const ef = query && query.ef ? JSON.parse(query.ef) : {}
    return ef.grupas ? ef.grupas.split(',') : []
  }

  render() {
    const { hidrogeo_imports } = this.props
    if(hidrogeo_imports.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    return (
        <GridWithForm
            {...this.props}
            columns={this.columns}
            formFieldCfgs={this.formFieldCfgs}
            storeObj={this.props.hidrogeo_imports}
            formWidthGuiOption="hidrogeo_imports_form_width"
            metadataFormCfg="hidrogeo_imports"
            tiesibasLabot="URBUMI"
            handleDelete={null}
            editable={false}
        />
    )

  }

}

const mapStateToProps = ({hidrogeo_imports, kodif, location}) => ({hidrogeo_imports, kodif, query: location.query})

export default connect(mapStateToProps, {
  selectedAct: hidrogeoImportsSelected,
  addUrlQuery,
  ...hidrogeoImportActions
})(HidrogeoImports)
