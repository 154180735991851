import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  dateFormatter,
  getEditIconColumn
} from '../grid/ColumnHelpers'
import Link from 'redux-first-router-link'
import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import { guiSet } from '../../actions/uiActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import { AtradneOrDalaLink, getLinkParams, irParbauditiColumn, pielikumiColumn } from '../dokumentacija/common'
import { getTextFilterCfg, getNumberFilterCfg, getDateFilterCfg, getBadgeFormatterForKodif, getSelectFilterCfg, periodsOrYearFormatter } from '../grid/ColumnHelpers'
import { round } from '../../utils'
import { DateInputField } from '../forms/Form'
import { addUrlQuery } from '../../actions/uiActions'
import { format, parse, getYear } from 'date-fns'
import * as constants from '../../constants'
import { Button } from 'reactstrap'

const thisYear = getYear(new Date())
const years = Array(3).fill().map((_, i) => {
  const year = thisYear - 2 + i
  return {
    year,
    no: format(new Date(year, 0, 1), constants.DATE_FORMAT),
    lidz: format(new Date(year, 11, 31), constants.DATE_FORMAT)
  }
})

const getColumns = ({ kodif }) => {
  return [
    getEditIconColumn((row) =>
      getLinkParams({
        loc: 'loc_atradnes_izmaina_grouped',
        locDala: 'loc_atradnes_dala_izmaina_grouped',
        numurs: row.atr_numurs,
        isDala: row.atr_ieraksta_veids === 'D',
        atrId: row.atradne_id,
        extraPayload: { id: row._isGrouped ? row.id : row.izmainu_grupa_id }
      })
    ),
    {
      dataField: 'ievades_statuss',
      text: 'Statuss',
      formatter: getBadgeFormatterForKodif(kodif.ievadesStatuss),
      width: 75
    },
    {
      dataField: 'datums',
      text: 'Izmaiņu periods',
      formatter: periodsOrYearFormatter,
      width: 100
    },
    {
      dataField: 'atr_numurs',
      text: 'Atradne vai daļa',
      formatter: (numurs, row) => <AtradneOrDalaLink
        id={row.atradne_id}
        numurs={numurs}
        ieraksta_veids={row.atr_ieraksta_veids}
        nosaukums={row.atr_label}
        kodif={kodif}
        query={{ tab: 'izmainas' }}
      />,
      width: 156,
      filterCfg: {
        type: 'autocomplete',
        autocompleteCfg: {
          remoteSearchViewName: 'atradne_search'
        }
      },
      sort: false
    }, {
      dataField: 'dalas_nosaukums',
      text: 'Struktūrelements',
      filterCfg: getTextFilterCfg(),
      width: 140
    }, {
      dataField: 'lic_adresats_nosaukums',
      text: 'Licences / atļaujas adresāts',
      width: 120,
      formatter: (nos, row) => row.licence_id ?
        <Link
          to={getLinkParams({
            numurs: row.atr_numurs,
            atrId: row.atradne_id,
            isDala: row.atr_ieraksta_veids === 'D',
            loc: 'loc_atradnes_licence',
            locDala: 'loc_atradnes_dala_licence',
            extraPayload: { id: row.licence_id },
          })}>
          {nos}
        </Link>
        : null,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'lic_numurs',
      text: 'Licences / atļaujas Nr.',
      width: 100,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'lic_izsniegts_datums',
      text: 'Licence / atļauja derīga no',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'lic_derigs_lidz_datums',
      text: 'Licence / atļauja derīga līdz',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'lic_anulets_datums',
      text: 'Licence / atļauja anulēta',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106,
      hidden: true
    },
    {
      dataField: 'lim_adresats_nosaukums',
      text: 'Limita adresāts',
      width: 120,
      formatter: (nos, row) => row.lim_id ?
        <Link
          to={getLinkParams({
            numurs: row.atr_numurs,
            atrId: row.atradne_id,
            isDala: row.atr_ieraksta_veids === 'D',
            loc: 'loc_atradnes_limits',
            locDala: 'loc_atradnes_dala_limits',
            extraPayload: { id: row.lim_id },
          })}>
          {nos}
        </Link>
        : null,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'lim_izsniegts_datums',
      text: 'Limits derīgs no',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'lim_derigs_lidz_datums',
      text: 'Limits derīgs līdz',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'lim_anulets_datums',
      text: 'Limits anulēts',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106,
      hidden: true
    }, {
      dataField: 'lim_apj_kategorija',
      text: 'Kategorija',
      formatter: getBadgeFormatterForKodif(kodif.kategorijas),
      filterCfg: getSelectFilterCfg(kodif.kategorijas),
      width: 75
    }, {
      dataField: 'q_izrakteni',
      text: 'Derīgie izrakteņi',
      filterCfg: getSelectFilterCfg(kodif.DIZRAKT),
      width: 130
    }, {
      dataField: 'lim_apj_krajumu_stavoklis_uz_datums',
      text: 'Limitā noteiktais krājumu stāvoklis uz',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106,
      hidden: true
    }, {
      dataField: 'limita_apjoms_korigetais',
      text: 'Limita apjoms',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            {daudzums} <span dangerouslySetInnerHTML={{ __html: row.lim_apj_daudzuma_merv_nosaukums }}></span>
          </>
        }
        return null
      },
      width: 130,
      align: 'right',
      filterCfg: getNumberFilterCfg()
    }, {
      dataField: 'krajumi_pirms_izmainas',
      text: 'Krājumi pirms izmaiņas',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            <span className={`${daudzums < 0 ? 'red' : ''}`}>{round(daudzums)}</span>
          </>
        }
        return null
      },
      width: 90,
      align: 'right',
      filterCfg: getNumberFilterCfg()
    },
    {
      dataField: 'daudzums',
      filterCfg: getNumberFilterCfg(),
      align: 'right',
      text: 'Krājumu izmaiņas',
      width: 90
    },
    {
      dataField: 'izmainas_veids_nosaukums',
      text: 'Izmaiņu iemesls',
      width: 100,
      filterCfg: getSelectFilterCfg(kodif.IZMAINA),
    },
    {
      dataField: 'krajumi_pec_izmainas',
      text: 'Krājumi pēc izmaiņas',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            <span className={`bold ${daudzums < 0 ? 'red' : ''}`}>{round(daudzums)}</span>
          </>
        }
        return null
      },
      width: 90,
      align: 'right',
      filterCfg: getNumberFilterCfg(),
    }, {
      dataField: 'q2_izmantosanas',
      text: 'Izmantošana',
      width: 110,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'piezimes',
      text: 'Piezīmes',
      width: 130,
      filterCfg: getTextFilterCfg()
    },
    {
      ...pielikumiColumn(),
      text: 'Pamatojošā dokumentācija'
    },
    {
      dataField: 'q_adm_ters',
      text: 'Administratīvā teritorija',
      filterCfg: getTextFilterCfg(),
      width: 130,
      hidden: true
    },
    irParbauditiColumn()
  ]
};

class IzmainaSarakstsBase extends Component {

  constructor(props) {
    super(props)
    if (!props.query || (!props.query.pno && !props.query.plidz)) {
      props.addUrlQuery({ pno: years[1].no, plidz: years[1].lidz })
    }
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet(this.props.karte_height_gui, h)
  }

  onChangeBilancePeriod = (fieldName, date) => {
    this.props.addUrlQuery({ [fieldName]: date ? format(date, constants.DATE_FORMAT) : undefined })
  }

  onYearBtnClick = (y) => () => {
    this.props.addUrlQuery({ pno: y.no, plidz: y.lidz })
  }

  render() {

    const { kodif, izmainas, gui, izmainaSelected, query, karte_height_gui, mapId, extraColumns, skipColumns, exportViewname } = this.props
    const { list, selected, loading, count } = izmainas

    const vectorLayerCfgs = {
      izmainas: {
        title: 'Izmaiņas',
        features: list,
        styles: maputils.dokStyles.dokLimitiStyles,
        tooltipFunc: (feat) => `${feat.get('atr_label')} ${dateFormatter(feat.get('gads'))} ${feat.get('q_izrakteni')}`,
        selected: selected,
        legend: {
          features: [
            {
              label: 'Izmaiņu limits',
              geomType: 'polygon'
            },
            {
              label: 'Izmaiņu limits (izvēlēts)',
              geomType: 'polygon',
              selected: true
            }
          ]
        }
      }
    }

    if (!this.columns) {
      this.columns = getColumns({
        kodif
      })
      if (extraColumns) {
        this.columns = extraColumns.concat(this.columns)
      }
      if (skipColumns) {
        this.columns = this.columns.filter(c => skipColumns.indexOf(c.dataField) < 0)
      }
    }

    const pno = query && query.pno ? parse(query.pno, constants.DATE_FORMAT, new Date()) : undefined
    const plidz = query && query.plidz ? parse(query.plidz, constants.DATE_FORMAT, new Date()) : undefined

    return (
      <SplitterLayout
        vertical
        percentage
        primaryIndex={1}
        secondaryInitialSize={gui[karte_height_gui]}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <IzraktenisMap mapId={mapId} vectorLayerCfgs={vectorLayerCfgs} />

        <Grid
          onRowSelected={izmainaSelected}
          loading={loading}
          selectedId={selected}
          dataList={list}
          totalCount={count}
          columns={this.columns}
          exportViewname={exportViewname}
        >
          {this.props.children}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <b>Periods:</b> &nbsp;&nbsp;
          <DateInputField
            setFieldValue={this.onChangeBilancePeriod}
            fieldName="pno"
            currentVal={pno}
            extraFieldProps={{ placeholder: "No" }}
          />
          &nbsp;-&nbsp;
          <DateInputField
            setFieldValue={this.onChangeBilancePeriod}
            fieldName="plidz"
            currentVal={plidz}
            extraFieldProps={{ placeholder: "Līdz" }}
          />
          &nbsp;&nbsp;
          {years.map(y =>
            <Button key={y.year} onClick={this.onYearBtnClick(y)} color={query && query.pno === y.no && query.plidz === y.lidz ? "primary" : "secondary"}>{y.year}</Button>
          )}
          <BBoxFilterButtonToUrl />
        </Grid>

      </SplitterLayout>
    )
  }
}

const mapStateToProps = ({ kodif, gui, location }) => {
  return { kodif, gui, query: location.query }
}
export default connect(mapStateToProps, { guiSet, addUrlQuery })(IzmainaSarakstsBase)
