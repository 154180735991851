import React, {Component} from 'react';
import {connect} from 'react-redux'
import Form from '../forms/Form'
import { pazemesSlodzuButiskumiActions } from '../../actions/slodzuButiskumsActions';
import { getDefaultTypeaheadOptionsFromKodifArray, getKlasifikatorsFieldCfg } from '../forms/FormHelpers';
import { getBooleanFormatter, getBooleanSelectFilterCfg, getFormatterForKodif } from '../grid/ColumnHelpers';

const getFormFieldCfgs = ({kodif}) => {
  return [{
        name: 'pazemes_udens_objekts_id',
        label: 'Ūdens objekts',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
            remoteSearchViewName: 'pazemes_udens_objekts_search',
            labelFieldName: 'uo_nosaukums'
        },
        renderer: (val, row) => (
            <span>{ row.uo_nosaukums }</span>
        )
    }, {
        name: 'gads',
        label: 'Slodžu izvērtējuma gads',
        type: 'number',
        renderClassName: 'bold',
        required: true
    }, {
        name: 'periods',
        label: 'Periods',
        renderClassName: "bold",
        required: true,
        type: 'autocomplete',
        autocompleteCfg: {
            options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS')
        }
    }, {
        ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaSlodze'),
        renderer: getFormatterForKodif(kodif.pazemesUdensObjektaSlodze),
        name: 'punktveida_slodze',
        label: 'Punktveida slodze'
    }, {
        ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaSlodze'),
        renderer: getFormatterForKodif(kodif.pazemesUdensObjektaSlodze),
        name: 'izkliedeta_slodze',
        label: 'Izkliedētā slodze'
    }, {
        ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaSlodze'),
        renderer: getFormatterForKodif(kodif.pazemesUdensObjektaSlodze),
        name: 'udens_ieguves_slodze',
        label: 'Ūdens ieguves slodze'
    }, {
        name: 'citas_slodzes',
        label: 'Citas slodzes',
        type: 'text',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg()
    }
  ]
};

class PazemesSlodzuButiskums extends Component {

  render() {
    const {pazemesSlodzuButiskums, kodif, saveAct, stopEditAct, startEditAct, deleteAct} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: kodif
      })
    }

    const {editable, submiting, selectedItem} = pazemesSlodzuButiskums

    return (
      <div className="form-wrapper-pane">
        <Form
          editable={editable}
          submiting={submiting}
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onSubmit={saveAct}
          onReset={stopEditAct}
          onStartEdit={startEditAct}
          onDelete={deleteAct}
          tiesibasLabot={'BAIS_PU_LABOT'}
        />
      </div>
    )
  }
}

const mapStateToProps = ({pazemesSlodzuButiskums, kodif}) => ({pazemesSlodzuButiskums, kodif})

export default connect(mapStateToProps, {...pazemesSlodzuButiskumiActions})(PazemesSlodzuButiskums)
