import * as helpers from './helpers'

const defaultListReducerForPazemesUdensObjekti = helpers.createListReducerWithMapClicking({
  listViewName: 'pazemes_udens_objekti_list',
  actionsName: 'pazemes_udens_objekts',
  mapIdName: 'udens_objekti_saraksts',
  layerIdName: 'pazemesUdensObjekti'
})
export const pazemesUdensObjekti = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducerForPazemesUdensObjekti({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForUdensObjekts = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_view',
  actionsName: 'pazemes_udens_objekts'
})
export const pazemesUdensObjekts = (state = helpers.editGetInitialState(), {type, payload}) => {
  return defaultEditReducerForUdensObjekts({state, type, payload})
}

const defaultEditReducerForAtrasanas = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_atrasanas_view',
  actionsName: 'udens_objekts_atrasanas'
})
export const pazemesUdensObjektsAtrasanas = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForAtrasanas({state, type, payload})
  }
}

const defaultEditReducerForUdensObjektsKvantitate = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_kvantitate_view',
  actionsName: 'pazemes_udens_objekts_kvantitate'
})
export const pazemesUdensObjektsKvantitate = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUdensObjektsKvantitate({state, type, payload})
  }
}

const defaultEditReducerForUdensObjektsRiskiUnIznemumi = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_risks_tab_view',
  actionsName: 'pazemes_udens_objekts_risks'
})

const defaultEditReducerForUoKim = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_kvalitate_kimiska',
  actionsName: 'pazemes_udens_objekts_kim'
})

export const pazemesUdensObjektsKim = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
      // clear atrasanas when atradne clears
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoKim({state, type, payload})
  }
}

export const pazemesUdensObjektsRiskiUnIznemumi = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
      // clear atrasanas when atradne clears
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUdensObjektsRiskiUnIznemumi({state, type, payload})
  }
}

const defaultEditReducerForUdensObjektsSlodzes = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_slodzes_tab_view',
  actionsName: 'pazemes_udens_objekts_slodzes'
})

export const pazemesUdensObjektsSlodzes = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
      // clear atrasanas when atradne clears
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUdensObjektsSlodzes({state, type, payload})
  }
}

const defaultEditReducerForUdensObjektsPapildinformacija = helpers.createEditReducer({
  editViewName: 'pazemes_udens_objekts_papildinformacija_view',
  actionsName: 'pazemes_udens_objekts_papildinformacija'
})

export const pazemesUdensObjektsPapildinformacija = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
      // clear atrasanas when atradne clears
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUdensObjektsPapildinformacija({state, type, payload})
  }
}

const defaultEditReducerForUoPazemesPasakumi = helpers.createEditReducer({
  editViewName: 'uo_pasakumi_view',
  actionsName: 'uoPazemesPasakumi'
})
export const uoPazemesPasakumi = (state = helpers.editGetInitialState(), {type, payload}) => {
  switch (type) {
    case 'pazemes_udens_objekts_clear':
      return helpers.editGetInitialState()
    default:
      return defaultEditReducerForUoPazemesPasakumi({state, type, payload})
  }
}

//
// const defaultEditReducerForUoEko = helpers.createEditReducer({
//   editViewName: 'udens_objekts_kvalitate_ekologija',
//   actionsName: 'udens_objekts_eko'
// })
// export const udensObjektsEko = (state = helpers.editGetInitialState(), {type, payload}) => {
//   switch (type) {
//     // clear atrasanas when atradne clears
//     case 'udens_objekts_clear':
//       return helpers.editGetInitialState()
//     default:
//       return defaultEditReducerForUoEko({state, type, payload})
//   }
// }
//
// const defaultEditReducerForUoRiski = helpers.createEditReducer({
//   editViewName: 'udens_objekts_riski',
//   actionsName: 'udens_objekts_riski'
// })
// export const udensObjektsRiski = (state = helpers.editGetInitialState(), {type, payload}) => {
//   switch (type) {
//     // clear atrasanas when atradne clears
//     case 'udens_objekts_clear':
//       return helpers.editGetInitialState()
//     default:
//       return defaultEditReducerForUoRiski({state, type, payload})
//   }
// }
//
// const defaultEditReducerForUoSlodzes = helpers.createEditReducer({
//   editViewName: 'udens_objekts_slodzes',
//   actionsName: 'udens_objekts_slodzes'
// })
// export const udensObjektsSlodzes = (state = helpers.editGetInitialState(), {type, payload}) => {
//   switch (type) {
//     // clear atrasanas when atradne clears
//     case 'udens_objekts_clear':
//       return helpers.editGetInitialState()
//     default:
//       return defaultEditReducerForUoSlodzes({state, type, payload})
//   }
// }
