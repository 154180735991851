import React from 'react'
import {
    Badge,
    Button
} from 'reactstrap';
import { format } from 'date-fns'
import * as constants from '../../constants'
import Link from 'redux-first-router-link';
import { round } from '../../utils'
import _ from 'lodash'
import { lv } from 'date-fns/locale'

export const getBadgeFormatterForKodif = (kodif, badgeColorProp = 'badgeColor') => (cellContent, row) => {
    if (!cellContent) {
        return ''
    }
    const kod = kodif[cellContent]
    if (kod) {
        return (
            <Badge color={kod[badgeColorProp] || 'secondary'}>{kod.nos}</Badge>
        )
    } else {
        return cellContent
    }
}

export const getMultiBadgeFormatterForKodifUniqueLetters = (kodif) => (cellContent, row) => {
    if (!cellContent) {
        return ''
    }
    const badgeRenderer = getBadgeFormatterForKodif(kodif)
    return _.uniq(cellContent.split('')).map(k => <div className="badge-column" key={k}>{badgeRenderer(k)}</div>)
}

export const getMultiBadgeFormatterForKodif = (kodif) => (cellContent, row) => {
    if (!cellContent) {
        return ''
    }
    const badgeRenderer = getBadgeFormatterForKodif(kodif)
    return Object.keys(cellContent).filter(k => cellContent[k] === true).map(k => <div className="badge-column" key={k}>{badgeRenderer(k)}</div>)
}

export const IDRenderer = (cellContent, row) => {
    if (!cellContent) {
        return ''
    }
    return <span className="id_renderer">{cellContent}</span>
}

export const getFormatterForKodif = (kodif) => (cellContent, row) => {
    if (!cellContent) {
        return ''
    }
    return kodif[cellContent] ? kodif[cellContent].nos : cellContent
}

export const getBooleanFormatter = (withBadges = false, ifTrueName = 'Jā', ifFalseName = 'Nē') => (cellContent, row) => {
    if (cellContent === true) return withBadges ? <Badge color='success'>{ifTrueName}</Badge> : ifTrueName
    if (cellContent === false) return withBadges ? <Badge color='danger'>{ifFalseName}</Badge> : ifFalseName
    return cellContent
}

export const getBooleanSelectFilterCfg = (options = { "t": 'Jā', "f": 'Nē' }) => {
    return {
        type: 'select',
        placeholder: 'Izvēlies..',
        options: options
    }
}

export const getTextFilterCfg = () => ({
    type: 'text',
    placeholder: 'Meklēt..'
})

export const getSelectFilterCfg = (kodif) => {
    const options = Object.keys(kodif).reduce((finalMap, i) => {
        finalMap[i] = `${kodif[i].nos}`
        return finalMap
    }, {})
    return {
        type: 'select',
        placeholder: 'Izvēlies..',
        options: options
    }
}

export const getmultiSelectFilterCfg = (kodif) => {
    return {
        type: 'multiselect',
        placeholder: 'Izvēlieties...',
        options: kodif
    }
}

const hasFailiField = (metadataView) => metadataView.fields && metadataView.fields.filter(f => f.name === 'faili').length > 0

export const getSelectFilterCfgForViewsFromMetadata = (metadata, withFaili) => {
    const options = Object.keys(metadata).filter(k => metadata[k].nosaukums && (!withFaili || hasFailiField(metadata[k]))).reduce((finalMap, k) => {
        finalMap[k] = `${metadata[k].nosaukums}`
        return finalMap
    }, {})
    if (!withFaili) {
        options.from_gis = 'Darbvirsmas ĢIS'
    }
    return {
        type: 'select',
        placeholder: 'Izvēlies..',
        options: options
    }
}

const getMetadataField = ({ metadata, path, viewName }) => {
    if (metadata[viewName] && metadata[viewName].fields) {
        return metadata[viewName].fields.find(f => f.name === path)
    }
    return null
}

export const translateAuditPath = ({ metadata, path, viewName }) => {
    const formatedPathObj = path.reduce((formatedPathObj, pathName) => {
        if (pathName === '' || pathName.startsWith('null_')) {
            const ind = pathName.startsWith('null_') ? ` ind: ${pathName.substring(5)}` : ''
            return { text: `${formatedPathObj.text} (Jauna vērtība${ind})`, lastViewName: formatedPathObj.lastViewName }
        }
        if (/^\d+$/.test(pathName)) {
            return { text: `${formatedPathObj.text} (${pathName})`, lastViewName: formatedPathObj.lastViewName }
        }
        const fieldDef = getMetadataField({ metadata, path: pathName, viewName: formatedPathObj.lastViewName })
        if (fieldDef) {
            const label = pathName === 'id' ? 'ID' : fieldDef.label
            return {
                text: formatedPathObj.text ? `${formatedPathObj.text} -> ${label}` : label,
                lastViewName: fieldDef.isComplexType && fieldDef.type ? fieldDef.type : formatedPathObj.lastViewName
            }
        }
        return {
            text: formatedPathObj.text ? `${formatedPathObj.text} -> ${pathName}` : pathName,
            lastViewName: formatedPathObj.lastViewName
        }
    }, { text: '', lastViewName: viewName })

    return formatedPathObj.text
}

export const booleanRenderer = (value) => {
    if (value === "true" || value === true) {
        return 'Jā'
    }
    if (value === "false" || value === false) {
        return 'Nē'
    }
    return value
}

export const translateAuditValue = (value) => {
    return booleanRenderer(value)
}

export const getDateFilterCfg = () => ({
    type: 'date',
    withoutEmptyComparatorOption: true
})

export const getNumberFilterCfg = () => ({
    type: 'number',
    withoutEmptyComparatorOption: true,
    placeholder: 'Skaitlis'
})

export const getYearFilterCfg = () => ({
    type: 'year',
    defaultValue: (new Date()).getFullYear()
})

export const dateFormatter = (cellContent) => {
    if (!cellContent) {
        return ''
    }
    return format(cellContent, constants.DATE_FORMAT)
}

export const yearFormatter = (cellContent) => {
    if (!cellContent) {
        return ''
    }
    return format(cellContent, constants.YEAR_FORMAT)
}

export const dateTimeFormatter = (cellContent) => {
    if (!cellContent) {
        return ''
    }
    return format(cellContent, constants.DATE_TIME_FORMAT)
}

export const getNumberFormatter = (toDecPlaces = 6) => (cellContent) => {
    if (!cellContent && cellContent !== 0) {
        return ''
    }
    return round(cellContent, toDecPlaces)
}

export const getEditIconColumn = (rowToLinkObjFunc) => {
    return {
        dataField: 'df1',
        text: '',
        isDummyField: true,
        formatter: (cellContent, row) => {
            return (
                <Link to={rowToLinkObjFunc(row)}>
                    <Button color="secondary" size="sm">
                        <i className="fa fa-file-text-o"></i>
                    </Button>
                </Link>
            );
        },
        width: 36,
        sort: false,
        classes: 'td-button'
    }
}

export const getDeleteIconColumn = (deleteRow) => {
    return {
        // Kurs pec kartas
        dataField: 'df9',
        text: 'Izdzēst',
        isDummyField: true,
        formatter: (cellContent, row, rowIndex) => {
            return (
                <Button color="secondary" size="sm" onClick={() =>
                (
                    deleteRow(row)
                )}>
                    <i className="fa fa-ban" aria-hidden="true"></i>
                </Button>
            );
        },
        editable: (cell, row, rowIndex, colIndex) => {
            return false
        },
        width: 45,
        sort: false,
        classes: 'td-button'
    }
}

export const getCheckColumn = ({ onCheck, CheckColumnHeaderComponent }) => (
    {
        dataField: 'checked',
        text: '',
        formatter: (cellContent, row) => (cellContent ?
            <i className="fa fa-check-square-o pointer" onClick={onCheck(row.id)}></i> :
            <i className="fa fa-square-o pointer" onClick={onCheck(row.id)}></i>
        ),
        headerFormatter: () => <CheckColumnHeaderComponent />,
        headerAlign: 'left',
        width: 30,
        sort: false,
        classes: 'td-button'
    }
)

export const CheckColumnHeader = ({ gridData, onCheckAll }) => {
    if (gridData && gridData.list) {
        const cnt = gridData.list.length
        const checkedCnt = gridData.list.filter(r => r.checked).length
        return checkedCnt === 0 ?
            <i className="fa fa-square-o pointer" onClick={onCheckAll}></i> :
            checkedCnt === cnt ?
                <i className="fa fa-check-square-o pointer" onClick={onCheckAll}></i> :
                <i className="fa fa-minus-square-o pointer" onClick={onCheckAll}></i>
    }
    return null
}

export const Periods = ({ datums, periods }) => {
    if (periods === 'G') {
        return `${format(datums, 'yyyy')}. gads (kopā)`
    }
    if (periods === 'C') {
        return `${format(datums, 'yyyy')}. gada ${format(datums, 'QQQ', { locale: lv })}`
    }
    if (periods === 'M') {
        return `${format(datums, 'yyyy')}. gada ${format(datums, 'MMMM', { locale: lv })}`
    }
    if (periods === 'D') {
        return `izmaiņas līdz ${dateFormatter(datums)}`
    }
}

export const periodsOrYearFormatter = (datums, row) => row._isGrouped ? yearFormatter(datums) :
    (row.ievades_periods && datums) ?
        <Periods datums={datums} periods={row.ievades_periods} />
        : null