import { atradneDoubleSelected } from './actions/atradnesActions'
import { addMapParamsToUrl } from './actions/karteActions'
import { openDokFromMap } from './actions/dokumentacijaActions'
import { virszemesUdensObjektsDoubleSelected } from './actions/virszemesUdensObjektiActions';
import { urbumsDoubleSelected, urbumuAtradneDoubleSelected, urbumuIeguveDoubleSelected } from './actions/hidrogeoActions'
import { openParskatsWithFilter } from './actions/geofondActions';

const mapIds = [
    'atradnes_saraksts',
    'pases_saraksts',
    'limits_saraksts',
    'limitu_atlikumi_saraksts',
    'licence_saraksts',
    'krajumu_saraksts',
    'izmainu_saraksts',
    'urbumi_saraksts',
    'urbumu_atradnes_saraksts',
    'urbumu_ieguves_saraksts',
    'udens_objekti_saraksts',
    'vnk_iesniegumi_saraksts',
    'skersprofili_saraksts',
    'bilance',
    'komersanta_izmainu_saraksts',
    'parskats_saraksts'
];

// here we add additional actions which should be called before/after specific actions
const mapMiddleware = store => next => action => {

    if (action.type === 'MAP_dblclickedFeature') {
        console.log(action.layerId + '--' + action.mapId + '--' + action.payload.numurs);
        if (action.layerId === 'atradnes' && action.mapId === 'atradnes_saraksts') {
            store.dispatch(atradneDoubleSelected(action.payload.numurs))
        }
        if (action.layerId === 'pases' && action.mapId === 'pases_saraksts') {
            store.dispatch(openDokFromMap(action.payload, 'loc_atradnes_pase', 'loc_atradnes_dala_pase'))
        }
        if (action.layerId === 'limiti' && action.mapId === 'limits_saraksts') {
            store.dispatch(openDokFromMap(action.payload, 'loc_atradnes_limits', 'loc_atradnes_dala_limits'))
        }
        if (action.layerId === 'limitu_atlikumi' && action.mapId === 'limitu_atlikumi_saraksts') {
            store.dispatch(openDokFromMap(action.payload, 'loc_atradnes_limits', 'loc_atradnes_dala_limits', 'limits_id'))
        }
        if (action.layerId === 'licences' && action.mapId === 'licence_saraksts') {
            store.dispatch(openDokFromMap(action.payload, 'loc_atradnes_licence', 'loc_atradnes_dala_licence'))
        }
        if (action.layerId === 'krajumi' && action.mapId === 'krajumu_saraksts') {
            store.dispatch(openDokFromMap(action.payload, 'loc_atradnes_krajums', 'loc_atradnes_dala_krajums'))
        }
        if ((action.layerId === 'virszemesUdensObjekti' || action.layerId === 'virszemesUdensObjektiLabels') && action.mapId === 'udens_objekti_saraksts') {
            store.dispatch(virszemesUdensObjektsDoubleSelected(action.payload.kods))
        }
        if (action.layerId === 'urbumi' && action.mapId === 'urbumi_saraksts') {
            store.dispatch(urbumsDoubleSelected(action.payload.id))
        }
        if (action.layerId === 'vnkIesniegumi' && action.mapId === 'vnk_iesniegumi_saraksts') {
            store.dispatch(virszemesUdensObjektsDoubleSelected(action.payload.kods))
        }
        if (action.layerId === 'atradnes' && action.mapId === 'urbumu_atradnes_saraksts') {
            store.dispatch(urbumuAtradneDoubleSelected(action.payload.id))
        }
        if (action.layerId === 'ieguves' && action.mapId === 'urbumu_ieguves_saraksts') {
            store.dispatch(urbumuIeguveDoubleSelected(action.payload.id))
        }
        if (action.layerId === 'parskati' && action.mapId === 'parskats_saraksts') {
            store.dispatch(openParskatsWithFilter(action.payload.query, action.payload.inv_numurs))
        }
        if (action.layerId === 'parskats_atradnes' && action.mapId === 'parskats') {
            store.dispatch(atradneDoubleSelected(action.payload.numurs))
        }
    }
    if (action.type === 'MAP_changedBBox' && mapIds.indexOf(action.mapId) !== -1) {
        store.dispatch(addMapParamsToUrl({ mapId: action.mapId, ...action.payload }))
    }
    next(action);
}

export default mapMiddleware
