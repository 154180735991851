import * as helpers from './helpers'

const defaultListReducerForUzzinas = helpers.createListReducer({listViewName: 'uzzina_list'})
export const uzzinas = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForUzzinas({state, type, payload, reqId})
}

const defaultEditReducerForUzzina = helpers.createEditReducer({editViewName: 'uzzina_view', actionsName: 'uzzina'})
export const uzzina = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultEditReducerForUzzina({state, type, payload, reqId})
}
