import React, { Component } from 'react'
import { connect } from 'react-redux'
import Link from 'redux-first-router-link'
import { izmainaActionsGrouped } from '../../actions/izmainaActions'
import Form, { InputField, DateInputField } from '../forms/Form'
import { getDefaultTypeaheadOptionsFromKodif, validateYears } from '../forms/FormHelpers'
import { getCommonFormFieldCfgs, LicenceLink, getLinkParams } from '../dokumentacija/common'
import { dateFormatter, yearFormatter } from '../grid/ColumnHelpers'
import { FormGroup, Label, Button } from 'reactstrap'
import Loading from '../Loading'
import { licenceField, limitaApjomsField, izrakteniField, izmainasVeidsField, izmantosanaField, ievadesStatuss, merv } from './Izmaina'
import { lastDayOfMonth, format, parse, getYear } from 'date-fns'
import { lv } from 'date-fns/locale'
import TypeaheadField from '../forms/TypeaheadField'
import * as constants from '../../constants'
import { round } from '../../utils'
import { Periods } from '../grid/ColumnHelpers'

const getCeturksni = (gads) => ({
  [dateFormatter(new Date(gads, 2, 31))]: { nos: '1. ceturksnis' },
  [dateFormatter(new Date(gads, 5, 30))]: { nos: '2. ceturksnis' },
  [dateFormatter(new Date(gads, 8, 30))]: { nos: '3. ceturksnis' },
  [dateFormatter(new Date(gads, 11, 31))]: { nos: '4. ceturksnis' }
})

const getMenesi = (gads) =>
  [...Array(12).keys()].reduce((months, i) => ({ ...months, [dateFormatter(lastDayOfMonth(new Date(gads, i, 1)))]: { nos: format(new Date(gads, i, 1), 'LLLL', { locale: lv }) } }), {})

const getFormFieldCfgs = ({ kodif, user, tiesibasLabotPamatlaukus, ir_parstavis }) => {

  const tiesibasLabotVienuIzmainu = (row) => user.tiesibas.includes('PUBLICET_ATRADNES_IZMAINAS') ||
    (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') && row.ievades_statuss !== 'P') ||
    (ir_parstavis === true && row.ievades_statuss === 'K')

  const commonFields = getCommonFormFieldCfgs({ kodif })
  const izmainasMultiFieldCfgs = [
    ievadesStatuss({ kodif, user }),
    {
      name: 'datums',
      label: 'Izmaiņu periods',
      required: true,
      customField: (props) => {
        const { currentValues, currentValRow, lastEditedFieldName, fieldNameRow, setFieldValue } = props
        if (!currentValues.gads || validateYears(currentValues.gads.toString())) {
          return 'Vispirms ievadiet izmaiņu gadu augstāk!'
        }
        if (lastEditedFieldName === `${fieldNameRow}.ievades_periods`) {
          currentValRow.datums = null
          if (currentValRow.ievades_periods === 'G') {
            currentValRow.datums = new Date(currentValues.gads, 11, 31)
          }
          if (currentValRow.ievades_periods === 'D') {
            const today = new Date()
            const lastDayOfYear = new Date(currentValues.gads, 11, 31)
            currentValRow.datums = today.getFullYear() === currentValues.gads ? today : lastDayOfYear
          }
        }
        return <>
          <FormGroup check inline>
            <TypeaheadField
              {...props}
              fieldName={`${fieldNameRow}.ievades_periods`}
              currentVal={currentValRow.ievades_periods}
              autocompleteCfg={{
                options: getDefaultTypeaheadOptionsFromKodif(kodif.izmainuIevadesPeriods)
              }}
              extraFieldProps={{ placeholder: 'Ievades periods' }}
            />
          </FormGroup>
          {currentValRow.ievades_periods ?
            <FormGroup check inline>
              {currentValRow.ievades_periods === 'G' ?
                yearFormatter(currentValRow.datums)
                : currentValRow.ievades_periods === 'C' ?
                  <TypeaheadField
                    {...props}
                    fieldName={`${fieldNameRow}.datums`}
                    currentVal={dateFormatter(currentValRow.datums)}
                    autocompleteCfg={{
                      options: getDefaultTypeaheadOptionsFromKodif(getCeturksni(currentValues.gads))
                    }}
                    extraFieldProps={{
                      placeholder: 'izvēlieties ceturksni'
                    }}
                    setFieldValue={(fieldName, value) =>
                      setFieldValue(fieldName, value ? parse(value, constants.DATE_FORMAT, new Date()) : null)
                    }
                  /> : currentValRow.ievades_periods === 'M' ?
                    <TypeaheadField
                      {...props}
                      fieldName={`${fieldNameRow}.datums`}
                      currentVal={dateFormatter(currentValRow.datums)}
                      autocompleteCfg={{
                        options: getDefaultTypeaheadOptionsFromKodif(getMenesi(currentValues.gads))
                      }}
                      extraFieldProps={{
                        placeholder: 'izvēlieties mēnesi'
                      }}
                      setFieldValue={(fieldName, value) =>
                        setFieldValue(fieldName, value ? parse(value, constants.DATE_FORMAT, new Date()) : null)
                      }
                    /> : currentValRow.ievades_periods === 'D' ?
                      <DateInputField
                        {...props}
                        fieldName={`${fieldNameRow}.datums`}
                        currentVal={currentValRow.datums}
                        extraFieldProps={{
                          placeholderText: 'izmaiņas līdz datumam',
                          showYearDropdown: false,
                          maxDate: new Date(currentValues.gads, 11, 31),
                          minDate: new Date(currentValues.gads, 0, 1)
                        }}
                      /> : null}
            </FormGroup>
            : null}
        </>
      },
      renderer: (datums, values, { currentValues }) => values.ievades_periods != null && datums != null && currentValues.gads != null ?
        <Periods datums={datums} periods={values.ievades_periods} /> : null,
    },
    {
      name: 'daudzums',
      required: true,
      customField: (props) => {
        const { currentValRow, currentValues, fieldNameRow, lastEditedFieldName } = props
        if (lastEditedFieldName === `${fieldNameRow}.daudzums` || lastEditedFieldName === `${fieldNameRow}.zime`) {
          currentValues.daudzums = currentValues.izmainas.reduce((sum, izm) => sum + (izm.zime === 'S' ? izm.daudzums * -1 : izm.daudzums), 0)
        }
        return <>
          <FormGroup check inline >
            <Label check>
              <InputField {...props} fieldName={`${fieldNameRow}.zime`} fieldType='radio' currentVal={currentValRow.zime}
                extraFieldProps={{ value: 'S', checked: currentValRow.zime === 'S', id: 'zime_S' }}
              /> Samazināt
            </Label>
          </FormGroup>
          <FormGroup check inline >
            <Label check>
              <InputField {...props} fieldName={`${fieldNameRow}.zime`} fieldType='radio' currentVal={currentValRow.zime}
                extraFieldProps={{ value: 'P', checked: currentValRow.zime === 'P', id: 'zime_P' }}
              /> Palielināt
            </Label>
          </FormGroup>
          <FormGroup>
            <FormGroup check inline >
              <InputField
                {...props}
                fieldName={`${fieldNameRow}.daudzums`}
                fieldType="number"
                currentVal={currentValRow.daudzums}
                extraFieldProps={{ placeholder: 'Izmaiņas daudzums', style: { width: 200, marginRight: 10 } }}
              />
              <Label>{merv(currentValues)}</Label>
            </FormGroup>
          </FormGroup>
        </>
      },
      renderer: (daudzums, values, { currentValues }) => daudzums != null ? <><b> {values.zime === 'S' ? daudzums * -1 : daudzums}</b>  {merv(currentValues)}</> : null,
    },
    izmantosanaField({ kodif }),
    {
      name: 'piezimes',
      type: 'text'
    }
  ]
  if (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') || ir_parstavis) {
    izmainasMultiFieldCfgs.push({
      name: 'faili',
      type: 'files'
    })
  }
  if (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS')) {
    izmainasMultiFieldCfgs.push(commonFields.find(cf => cf.name === 'ir_parbauditi'))
  }
  const allFields = [
    commonFields.find(cf => cf.name === 'atradne'),
    commonFields.find(cf => cf.name === 'dalas_nosaukums'),
    {
      ...licenceField(),
      renderer: (lic, dataObj) => lic ? <LicenceLink licence={lic} atradne={dataObj.atradne} /> : null
    },
    limitaApjomsField(),
    {
      ...izrakteniField({ kodif }),
      editable: true
    },
    {
      name: 'gads',
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues, lastEditedFieldName } = props
        // set initial vards uzvards from nosaukums
        if (lastEditedFieldName === 'gads') {
          currentValues.izmainas?.forEach(izm => {
            if (izm.datums && getYear(izm.datums).toString() !== currentValues.gads) {
              izm.datums = parse(`${currentValues.gads}.${format(izm.datums, 'MM.dd')}`, 'yyyy.MM.dd', new Date())
            }
          })
        }
        return changedProps
      },
      validation: (values) => validateYears(values.gads.toString()),
      renderClassName: "bold",
      editable: true
    },
    izmainasVeidsField({ kodif, user })
  ]
  if (!tiesibasLabotPamatlaukus) {
    allFields.forEach(f => f.editable = false)
  }
  allFields.forEach(f => f.sizeForm = 4)
  return [
    ...allFields,
    {
      name: 'izmainas',
      required: true,
      multiFieldCfgs: izmainasMultiFieldCfgs,
      extraFieldProps: {
        canRemoveRow: tiesibasLabotVienuIzmainu,
        canEditRow: tiesibasLabotVienuIzmainu
      },
      createNewRow: () => ({ ir_parbauditi: user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS'), faili: [], ievades_statuss: 'K', zime: 'S' })
    },
    {
      name: 'daudzums',
      label: 'Kopējais izmaiņu daudzums',
      sizeForm: 4,
      editable: false,
      renderer: (daudzums, dataObj, props) => {
        const { currentValues } = props
        return <>
          {currentValues.limita_apjoms ?
            <> <p className="no-margin">
              {currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId ?
                round(currentValues.limita_apjoms.daudzums + currentValues.sum_prev_limita_apjoma_izmainu_daudzums) :
                currentValues.limita_apjoms.korigetais_daudzums}
              &nbsp;{merv(currentValues)} &nbsp;(limita apjoms)
              {currentValues.limita_apjoms.la_krajumu_stavoklis_uz_datums ? `, ar krājumu stāvokli uz ${dateFormatter(currentValues.limita_apjoms.la_krajumu_stavoklis_uz_datums)}` : null}
            </p>
              <p className="no-margin">
                {currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId ?
                  round(currentValues.limita_apjoms.daudzums + currentValues.sum_prev_izmainu_daudzums) :
                  round(currentValues.limita_apjoms.daudzums + currentValues.limita_apjoms.sum_izmainu_daudzums)}
                &nbsp;{merv(currentValues)} &nbsp;(limita atlikums pirms izmaiņām)
              </p ></>
            : null}
          <p className="no-margin"><b> {round(currentValues.daudzums)} </b> {merv(currentValues)} &nbsp;(izmaiņu daudzums)</p>
          {currentValues.limita_apjoms ?
            <p className="no-margin">
              {currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId ?
                round(currentValues.limita_apjoms.daudzums + currentValues.sum_prev_izmainu_daudzums + currentValues.daudzums) :
                currentValues.limitaAtlikums = round(currentValues.limita_apjoms.daudzums + currentValues.limita_apjoms.sum_izmainu_daudzums + currentValues.daudzums)}
              &nbsp;{merv(currentValues)} &nbsp;(jaunais limita atlikums)
            </p>
            : null}
        </>
      }
    }
  ]
}

class IzmainaGrouped extends Component {

  render() {
    const { izmaina_grouped, kodif, saveAct, stopEditAct, startEditAct, deleteAct, user } = this.props

    const { editable, submiting, selectedItem } = izmaina_grouped
    if (!selectedItem) {
      return (<Loading />)
    }

    const tiesibasLabotPamatlaukus = user.tiesibas.includes('PUBLICET_ATRADNES_IZMAINAS') ||
      (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') && !selectedItem.ievades_statuss.includes('P')) ||
      (selectedItem.ir_parstavis === true && selectedItem.ievades_statuss.split('').filter(a => a !== 'K').length === 0)

    if (!this.formFieldCfgs || this.prevId !== selectedItem.id) {
      this.formFieldCfgs = getFormFieldCfgs({ kodif, user, tiesibasLabotPamatlaukus, ir_parstavis: selectedItem.ir_parstavis })
      this.prevId = selectedItem.id
    }

    const tiesibasLabot = user.tiesibas.includes('PUBLICET_ATRADNES_IZMAINAS') ||
      (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') && selectedItem.ievades_statuss.split('').filter(a => a !== 'P').length > 0) ||
      (selectedItem.ir_parstavis === true && selectedItem.ievades_statuss.includes('K'))

    const extraElements = <> {!editable && (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') || selectedItem.ir_parstavis === true) ? <Link to={getLinkParams({
      loc: 'loc_atradnes_izmaina_grouped',
      locDala: 'loc_atradnes_dala_izmaina_grouped',
      numurs: selectedItem.atradne.numurs,
      isDala: selectedItem.atradne.ieraksta_veids === 'D',
      atrId: selectedItem.atradne.id,
      extraPayload: { id: 'jauns' }
    })}>
      <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;{user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') ? 'Jauna izmaiņa ' : 'Ievadīt ieguvi '}atradnē "{selectedItem.atradne.numurs}"</Button>
    </Link> : null}
    </>

    return (
      <div className="form-wrapper-pane">
        <Form
          sizeLabel={2}
          sizeForm={10}
          editable={editable}
          submiting={submiting}
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onSubmit={saveAct}
          onReset={stopEditAct}
          onStartEdit={startEditAct}
          onDelete={deleteAct}
          metadataFormCfg="izmaina_view_grouped"
          tiesibasLabot={tiesibasLabot}
          extraElements={extraElements}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ izmaina_grouped, kodif, user }) => ({ izmaina_grouped, kodif, user })

export default connect(mapStateToProps, { ...izmainaActionsGrouped })(IzmainaGrouped)
