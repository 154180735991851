import { addUrlQuery } from './uiActions'

export const addMapParamsToUrl = ({ mapId, x, y, z }) => (dispatch, getState) => {
  const newQueryParams = { x, y, z, mapId }
  // if there is no current bbox filter config in url
  // we enable it, as it is the first time user moves map
  const { query } = getState().location
  if (!query || !query.b) {
    newQueryParams.b = 't'
  }
  dispatch(addUrlQuery(newQueryParams))
}

export const changeMapBBox = ({ mapBbox, mapId }) =>
  ({ type: 'MAP_changedBBox', payload: mapBbox, mapId })

export const clickedFeature = ({ featureProps, layerId, mapId }) =>
  ({ type: 'MAP_clickedFeature', payload: featureProps, layerId, mapId })

export const doubleClickedFeature = ({ featureProps, layerId, mapId }) =>
  ({ type: 'MAP_dblclickedFeature', payload: featureProps, layerId, mapId })

export const clickedOutsideFeature = ({ mapId }) => ({ type: 'MAP_clickedOutsideFeature', mapId })

export const modifiedFeature = ({ featureProps, layerId, mapId, featureGeom }) =>
  ({ type: 'MAP_modifiedFeature', payload: featureProps, layerId, mapId, featureGeom })

export const tileLayerLoadingError = ({ error, layer }) =>
({
  type: `tilelayer_${layer}_failure`,
  error
})
