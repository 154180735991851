import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {
    pazemesUdensObjektiPapildinformacijaActions
} from '../../actions/pazemesUdensObjektiActions'
import FormCard from "../forms/FormCard";

class PapildinformacijaComp extends Component {

    getFormFieldWrapperCfgs = () => {
        return [
            {
                id: 'geo_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Ģeogrāfiskais raksturojums',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'nesejslanu_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Ūdens nesējslāņu raksturojums',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'iezi_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Pārklājošie ieži',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'aizsargatiba_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Aizsargātība',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'papildinasanas_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Papildināšanās',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'monitorings_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Monitorings',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'stacijas_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Stacijas',
                    pinnable: true,
                    size: 12
                }
            },
        ]
    }

    getFormFieldCfgs = ({kodif, user}) => {
        return [
            {
                name: 'rakst_virszemes_udeni',
                fieldWrapper: 'geo_wrap',
                type: 'textarea'
            },
            {
                name: 'fiziogeo_raksturojums',
                fieldWrapper: 'geo_wrap',
                type: 'textarea'
            },
            {
                name: 'nesejslanu_tips_litologija',
                fieldWrapper: 'nesejslanu_wrap',
                type: 'textarea'
            },
            {
                name: 'hidrogeo_stratifikacija_html',
                fieldWrapper: 'nesejslanu_wrap',
                // type: 'html'
            },
            {
                name: 'nesejslanu_rakst_ipasibas',
                fieldWrapper: 'nesejslanu_wrap',
                type: 'textarea'
            },
            {
                name: 'nesejslanu_biezums',
                fieldWrapper: 'nesejslanu_wrap',
                type: 'textarea'
            },
            {
                name: 'parklajosie_iezi_litologija',
                fieldWrapper: 'iezi_wrap',
                type: 'textarea'
            },
            {
                name: 'parklajosie_iezi_biezums',
                fieldWrapper: 'iezi_wrap',
                type: 'textarea'
            },
            {
                name: 'aizsargatiba_kvartara_nesejslanu',
                fieldWrapper: 'aizsargatiba_wrap',
                type: 'textarea'
            },
            {
                name: 'aizsargatiba_pamatiezu_nesejslanu',
                fieldWrapper: 'aizsargatiba_wrap',
                type: 'textarea'
            },
            {
                name: 'zemes_lietojuma_veids',
                fieldWrapper: 'aizsargatiba_wrap',
                type: 'textarea'
            },
            {
                name: 'zemes_lietojuma_veids_html',
                fieldWrapper: 'aizsargatiba_wrap',
                // type: 'html'
            },
            {
                name: 'ipasi_jutigas_teritorijas',
                fieldWrapper: 'aizsargatiba_wrap',
                type: 'textarea'
            },
            {
                name: 'atkarigas_sauszemes_ekosistemas',
                fieldWrapper: 'aizsargatiba_wrap',
                type: 'textarea'
            },
            {
                name: 'papildinasanas_mehanismi',
                fieldWrapper: 'papildinasanas_wrap',
                type: 'textarea'
            },
            {
                name: 'gada_videjais_nokrisnu_daudzums',
                fieldWrapper: 'papildinasanas_wrap',
                type: 'textarea'
            },
            {
                name: 'papildinasanas_atslodzes_zonas',
                fieldWrapper: 'papildinasanas_wrap',
                type: 'textarea'
            },
            {
                name: 'monitorings_piezimes',
                fieldWrapper: 'monitorings_wrap',
                type: 'textarea'
            },
            {
                name: 'staciju_izvietojums_html',
                fieldWrapper: 'monitorings_wrap',
                type: 'textarea'
            },
            {
                name: 'noverojumu_veidi_un_biezums',
                fieldWrapper: 'monitorings_wrap',
                type: 'textarea'
            },
            {
                name: 'monitorings_paskaidrojumi',
                fieldWrapper: 'monitorings_wrap',
                type: 'textarea'
            },
            {
                name: 'stacijas',
                label: false,
                sizeLabel: 0,
                sizeForm: 12,
                fieldWrapper: 'stacijas_wrap',
                editable: false,
                multiFieldCfgs:[{
                    name: 'nosaukums',
                    label: 'Nosaukums'
                },
                {
                    name: 'grupa',
                    label: 'Grupa'
                },
                {
                    name: 'tips',
                    label: 'Tips'
                },
                {
                    name: 'piederiba',
                    label: 'Piederība'
                },
                {
                    name: 'urbuma_nr',
                    label: 'Urbuma nr.'
                }

                ]
            }
        ]}

    render() {
        const {selectedItem:pazemesUdensObjektsPapildinformacija, editable, submiting} = this.props.pazemesUdensObjektsPapildinformacija

        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, getFormFieldWrapperCfgsFunc, kodif, metadataFormCfg} = this.props

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : this.getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif,
                user
            })
        }

        if(!this.formFieldWrapperCfgs){
            const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : this.getFormFieldWrapperCfgs
            this.formFieldWrapperCfgs = f()
        }

        return <div className="form-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={pazemesUdensObjektsPapildinformacija}
                        formFieldCfgs={this.formFieldCfgs}
                        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
                        metadataFormCfg={metadataFormCfg}
                        onSubmit={this.props.save}
                        onReset={this.props.stopEdit}
                        onStartEdit={this.props.startEdit}
                        onDelete={user.tiesibas.includes('BAIS_DZEST') ? this.props.delete : null}
                        tiesibasLabot="BAIS_PU_LABOT"
                    />
            </div>
    }
}

const mapStateToProps = ({pazemesUdensObjektsPapildinformacija, kodif, user}) => ({pazemesUdensObjektsPapildinformacija, kodif, user, metadataFormCfg: "pazemes_udens_objekts_papildinformacija_view"})
const PazemesPapildinformacija =  connect(mapStateToProps, {
  startEdit:pazemesUdensObjektiPapildinformacijaActions.startEditAct,
  stopEdit:pazemesUdensObjektiPapildinformacijaActions.stopEditAct,
  delete:pazemesUdensObjektiPapildinformacijaActions.deleteAct,
  save:pazemesUdensObjektiPapildinformacijaActions.saveAct
})(PapildinformacijaComp)
export default PazemesPapildinformacija
