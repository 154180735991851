import React, {Component} from 'react';
import {connect} from 'react-redux'
import {krajumsActions} from '../../actions/krajumsActions'
import {getCommonFormFieldCfgs, DokumentacijasKartina} from '../dokumentacija/common'
import {getAtradnesVeidsFromIzraktKraj, checkAtradnesTypeFromVeids, 
  getKudrasSpecificFields, getSapropelisSpecificFields} from '../forms/FormHelpers'

const formFields = ['id', 'atradne', 'dalas_nosaukums', 'izrakteni', 'izpetes_gads', 'krajumu_statuss', 'krajumu_apr_veids',
   'kategorija', 'kudras_tips', 'kudras_sadal_pak', 'saprop_tips', 'saprop_veids', 'saprop_klase', 
   'daudzums', 'daudzums_zem_udens', 'daudzums_tukst_t', 'daudzums_tukst_mkub', 
   'sakums_datums', 'derigs_lidz_datums', 'derigs_lidz_iemesls', 'piezimes', 'ir_parbauditi', 'aktualizacijas_laiks']

const getHiddenFuncByIzraktenisVeids = (kodif, showForVeidi) => ({currentValues}) => {
  const izr = currentValues.izrakteni
  if(!izr || izr.length === 0){
    return true
  }
  const veidi = izr.map(i => getAtradnesVeidsFromIzraktKraj(kodif, i.klasifikators)).join('')
  return !checkAtradnesTypeFromVeids(veidi, showForVeidi)
}

const getFormFieldCfgs = ({kodif}) => {
  const commonFields = [...getCommonFormFieldCfgs({kodif}), ...getKudrasSpecificFields(kodif), ...getSapropelisSpecificFields(kodif)]
  return formFields.map(ff => {
    const cf = commonFields.find(cf => cf.name === ff)
    if(ff === 'izrakteni'){
      cf.required = true
    }
    if(ff === 'kudras_tips' || ff === 'kudras_sadal_pak'){
      cf.hiddenFunc = getHiddenFuncByIzraktenisVeids(kodif, 'KD')
    }
    if(ff === 'saprop_tips' || ff === 'saprop_veids' || ff === 'saprop_klase'){
      cf.hiddenFunc = getHiddenFuncByIzraktenisVeids(kodif, 'S')
    }
    if(ff === 'daudzums' || ff === 'daudzums_zem_udens'){
      cf.hiddenFunc = getHiddenFuncByIzraktenisVeids(kodif, 'B')
    }
    if(ff === 'daudzums_tukst_t' || ff === 'daudzums_tukst_mkub'){
      cf.hiddenFunc = getHiddenFuncByIzraktenisVeids(kodif, 'KSDC')
    }
    if(ff === 'sakums_datums'){
      cf.required = false
    }
    if(ff === 'derigs_lidz_datums'){
      cf.required = false
    }
    return cf
  })
}
  
class Krajums extends Component {

    render() {
      
      const {krajums, kodif, saveAct, stopEditAct, startEditAct, deleteAct, koordSelectAct, koordChangeAct} = this.props

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif: kodif
        })
      }

      return <DokumentacijasKartina
        geomObj={krajums}
        formFieldCfgs={this.formFieldCfgs}
        saveAct={saveAct}
        stopEditAct={stopEditAct}
        startEditAct={startEditAct} 
        deleteAct={deleteAct}
        koordSelectAct={koordSelectAct} 
        koordChangeAct={koordChangeAct}
        karteWidthProp='krajuma_kartina_karte_width'
        mapId='krajuma_kartina'
        editableLayerIdName='krajums_koord'
        layerTitles={{
          feat_border: 'Krājumu robeža',
          feat: 'Krājumi',
          koord: 'Krājumu robežpunkti',
          koord_labels: 'Krājumu robežpunktu numuri',
        }}
        metadataFormCfg="krajums_view"
        tiesibasLabot="LABOT_ATRADNES_KRAJUMUS"
      />
    }
}

const mapStateToProps = ({krajums, kodif}) => ({krajums, kodif})
export default connect(mapStateToProps, {...krajumsActions})(Krajums)
