import {
  callApi,
  getDefaultEditActions
} from './helpers'

export const loadFonaSlanuAdministresanaSaraksts = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'fona_slanu_administresana_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const kartesFonaSlanuAdministresanaSelected = (id) => {
  return { type: 'kartesFonaSlanis_selected', payload: id }
}

const fonaSlanuAdministresanaAfterSaveOrDelete = (dispatch, getState) => {
  dispatch(loadFonaSlanuAdministresanaSaraksts(getState().location.query))
}

export const kartesFonaSlanisActions = getDefaultEditActions({
  actionTypeName: 'kartesFonaSlanis',
  stateName: 'kartesFonaSlani',
  combinedPage: true,
  apiViewName: 'fona_slanu_administresana_edit',
  afterDelete: fonaSlanuAdministresanaAfterSaveOrDelete,
  afterSave: fonaSlanuAdministresanaAfterSaveOrDelete,
  overridedActions: {
    createAct: () => (dispatch) => {
      dispatch({
        type: 'fona_slanu_administresana_edit_new_success',
        payload: {
          parent_id: null,
          nosaukums: null,
          kods: null,
          sort_order: null,
          sadalas: [],
          var_labot: true,
          vai_ir_mapite: true,
          vai_ir_slanis: false
        }
      })
    }
  }
})

export const loadKartesFonaSlanuAdministresanaSarakstsByLocation = (query) => (dispatch, getState) => {
  if (getState().kartesFonaSlaniByLocation.lastReqId === 0) {
    return dispatch(callApi({
      viewName: 'fona_slanu_administresana_view_search',
      crudMethod: 'list',
      crudParam: query
    }))
  }
}
