import React, {Component} from 'react';
import {connect} from 'react-redux'
import {rubrikaActions} from '../../actions/geofondActions'
import Form from '../forms/Form'

const getFormFieldCfgs = ({kodif, user}) => {
    const fields = [
      {
        name: 'nosaukums',
        label: 'Rubrikas kods',
        type: 'text',
        required: true
      },
      {
        name: 'piezimes',
        label: 'Rubrikas nosaukums',
        type: 'text',
        required: true
      },
  ]

  return fields
}

class Rubrika extends Component {

    render() {
      const {rubrika, kodif, saveAct, stopEditAct, startEditAct, deleteAct, user} = this.props

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif, user
        })
    }

    const {editable, submiting, selectedItem} = rubrika
    
    return (
        <div className="form-wrapper-pane">
            <Form 
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                onSubmit={saveAct}
                onReset={stopEditAct}
                onStartEdit={startEditAct}
                onDelete={deleteAct}
                tiesibasLabot="PARSKATS_LABOT"
            />
        </div>
    )
  }
}

const mapStateToProps = ({rubrika, kodif, user}) => ({rubrika, kodif, user})

export default connect(mapStateToProps, {...rubrikaActions})(Rubrika)
