import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getTextFilterCfg,
  getFormatterForKodif,
  getEditIconColumn, getmultiSelectFilterCfg
} from '../grid/ColumnHelpers'
import {
    Button, 
    UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, 
    Modal, ModalHeader, ModalBody,
} from 'reactstrap';

import {DateInputField} from '../forms/Form'
import * as constants from '../../constants'
import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';
import Link from 'redux-first-router-link';

import {guiSet} from '../../actions/uiActions'
import {virszemesUdensObjektsSelected, virszemesUdensObjektsDoubleSelected} from '../../actions/virszemesUdensObjektiActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import ArchivedFilterButtonToUrl from '../grid/ArchivedFilterButtonToUrl'

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_virszemes_udens_objekts',
        payload: {
            kods: row.kods
        }
    }
    )
)

const getColumns = ({kodif}) => {
  return [editIconColumn, {
    dataField: 'kods',
    text: 'Kods',
    width: 176,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'nosaukums',
    text: 'Nosaukums',
    width: 235,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'adm_teritorija',
    text: 'Adm. teritorija',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'uo_tips',
    text: 'Tips',
    width: 220,
    filterCfg: getmultiSelectFilterCfg(kodif.UO_TIPS)
  }, {
    dataField: 'kategorija',
    text: 'Kategorija',
    formatter: getFormatterForKodif(kodif.virszemesUdensObjektaKategorija),
    width: 120,
    filterCfg: getmultiSelectFilterCfg(kodif.virszemesUdensObjektaKategorija)
  }, {
    dataField: 'izcelsme',
    text: 'Izcelsme',
    formatter: getFormatterForKodif(kodif.udensObjektaIzcelsme),
    width: 120,
    filterCfg: getmultiSelectFilterCfg(kodif.udensObjektaIzcelsme)
  }]
};

class VirszemesUdensObjekti extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalSearch: false,
        exportPopup: false,
        exportStartYear: null,
        exportEndYear: null,
        exportType: null
      };
    }
  
    openModalExport = (type) => {
      this.setState({ 
        exportPopup: true,
        exportType: type
      });
    };
  
    closeModalExport = () => {
      this.setState({ 
        exportPopup: false,
        exportStartYear: null,
        exportEndYear: null,
        exportType: null
      });
    };
  
    toggleModalSearch = () => {
      this.setState({
        modalSearch: !this.state.modalSearch
      });
    }
  
    mapPaneSizeChange = (h) => {
      this.props.guiSet('udeni_saraksts_karte_height', h);
    }
  
    rowEvents = {
      onDoubleClick: (e, row, rowIndex) => {
        this.props.udensObjektsDoubleSelected(row.kods)
      }
    }
  
    render() {
      const vectorLayerCfgs = {
        virszemesUdensObjektiLabels : {
          title: `Ūdensobjektu nosaukumi`,
          features: this.props.virszemesUdensObjekti.list,
          styles: maputils.uoStyles.uoLabelsStyle,
          selected: this.props.virszemesUdensObjekti.selected,
          zIndex: 6,
          declutter: true
        },
        virszemesUdensObjekti : {
          title: 'Ūdensobjekti',
          features: this.props.virszemesUdensObjekti.list,
          styles: maputils.uoStyles.uoStyle,
          tooltipFunc: (feat) => `${feat.get('nosaukums')} (${feat.get('kods')})`,
          selected: this.props.virszemesUdensObjekti.selected,
          legend: {
            features: [
              {
                label: 'Ūdensobjekts',
                geomType: 'polygon',
                selected: false
              },
              {
                label: 'Ūdensobjekts (izvēlēts)',
                geomType: 'polygon',
                selected: true
              }
            ]
          },
          zIndex: 5
        }
      }
  
      if(!this.columns){
        this.columns = getColumns({
          kodif: this.props.kodif
        })
      }
  
      return (
        <>
          <SplitterLayout
            vertical
            percentage
            primaryIndex={1}
            secondaryInitialSize={this.props.gui.udeni_saraksts_karte_height}
            onSecondaryPaneSizeChange={this.mapPaneSizeChange}>
            <Karte mapId="udens_objekti_saraksts" vectorLayerCfgs={vectorLayerCfgs} />
  
            <Grid
              onRowSelected={this.props.udensObjektsSelected}
              loading={this.props.virszemesUdensObjekti.loading}
              selectedId={this.props.virszemesUdensObjekti.selected}
              dataList={this.props.virszemesUdensObjekti.list}
              totalCount={this.props.virszemesUdensObjekti.count}
              columns={this.columns}
              rowEvents={this.rowEvents}
              enablePaging={false}
            >
              { this.props.user.tiesibas.includes('BAIS_VU_PAMAT_LABOT') ? (
                <Link to={{ type: 'loc_virszemes_udens_objekts',
                  payload: {
                        kods: 'jauns'
                      }
                }}>
                  <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                </Link>
              ) : null }
              <BBoxFilterButtonToUrl/>
              <ArchivedFilterButtonToUrl/>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="primary" caret>
                  Datu eksports
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this.openModalExport("virszemes_udens_objekts_kvalitate_ekologija_visi")}
                  >
                    Ekoloģiskā kvalitāte
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.openModalExport("virszemes_udens_objekts_kvalitate_kimiska_visi")}
                  >
                    Ķīmiskā kvalitāte
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Grid>
          </SplitterLayout>
  
          <Modal isOpen={this.state.exportPopup} className="modal-sm">
            <ModalHeader>Eksportēt visus ŪO {this.state.exportType === "virszemes_udens_objekts_kvalitate_ekologija_visi" ? "ekoloģisko" : "ķīmisko"} kvalitāti</ModalHeader>
            <ModalBody className="text-center">
              <div className="alert alert-info text-left">
                Eksportējamie dati ir indikatīvi, un precīzos datus iespējams saņemt, vēršoties LVĢMC
              </div>
              <div className='mb-2'>
                <b>No:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                <DateInputField
                  fieldName="start_year"
                  currentVal={
                  this.state.exportStartYear
                      ? new Date(this.state.exportStartYear, 0, 1)
                      : null
                  }
                  setFieldValue={(fieldName, newVal) =>
                    this.setState({ exportStartYear: newVal ? newVal.getFullYear() : null })
                  }
                  extraFieldProps={{
                    dateFormat: constants.YEAR_FORMAT, 
                    showYearPicker: true,
                    popperContainer: ({ children }) => <div>{children}</div>,
                  }}
                />
              </div>
              <div className='mb-2'>
                <b>Līdz:</b>&nbsp;&nbsp;
                <DateInputField
                  fieldName="end_year"
                  currentVal={
                  this.state.exportEndYear
                      ? new Date(this.state.exportEndYear, 0, 1)
                      : null
                  }
                  setFieldValue={(fieldName, newVal) =>
                  this.setState({ exportEndYear: newVal ? newVal.getFullYear() : null })
                  }
                  extraFieldProps={{
                    dateFormat: constants.YEAR_FORMAT,
                    showYearPicker: true,
                    popperContainer: ({ children }) => <div>{children}</div>
                  }}
               />
              </div>
              <div className="mt-3 float-left">
                <a
                  href={`/services/export/${this.state.exportType}?${this.state.exportStartYear ? `startYear=${this.state.exportStartYear}` : ''}&${this.state.exportEndYear ? `endYear=${this.state.exportEndYear}` : ''}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary mr-2"
                >
                   Eksportēt
                </a>
                <Button color="secondary" onClick={this.closeModalExport}>Aizvērt</Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
  
  const mapStateToProps = ({kodif, virszemesUdensObjekti, gui, user}) => {
    return {kodif, virszemesUdensObjekti, gui, user}
  }
  
  export default connect(mapStateToProps, {udensObjektsSelected: virszemesUdensObjektsSelected, guiSet, udensObjektsDoubleSelected: virszemesUdensObjektsDoubleSelected})(VirszemesUdensObjekti)
  