import React, {Component} from 'react'
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    getSelectFilterCfgForViewsFromMetadata,
    getMultiBadgeFormatterForKodif
} from '../grid/ColumnHelpers'
import Loading from '../Loading'
import _ from 'lodash'
import {viewNameFromMetadata} from '../../routes'
import {DateInputField} from '../forms/Form'
import {parse, format} from 'date-fns'
import * as constants from '../../constants'
import {addUrlQuery} from '../../actions/uiActions'

export const getColumns = ({kodif, metadata}) => {
    const veidsRenderer = getMultiBadgeFormatterForKodif(kodif.atradnesVeids)
    const ierakstaVeidsRenderer = getMultiBadgeFormatterForKodif(kodif.atradnesIerakstaVeids)
    return [
        {
            dataField: 'ieraksta_veids',
            text: '',
            formatter: (cellContent) => {
                const veidsObj = cellContent.split('').reduce((veidi,veids) => {veidi[veids]=true;return veidi;}, {})
                return ierakstaVeidsRenderer(veidsObj)
            },
            width: 176,
            filterCfg: getSelectFilterCfg(_.omit(kodif.atradnesIerakstaVeids, ['D']))
        },{
            dataField: 'numurs',
            text: 'Numurs',
            width: 85,
            filterCfg: getTextFilterCfg()
        },
        {
            dataField: 'nosaukums',
            text: 'Atradnes nosaukums',
            filterCfg: getTextFilterCfg()
        },
        {
            dataField: 'veids',
            text: 'Veids',
            formatter: (cellContent) => {
                const veidsObj = cellContent.split('').reduce((veidi,veids) => {veidi[veids]=true;return veidi;}, {})
                return veidsRenderer(veidsObj)
            },
            width: 120,
            filterCfg: getSelectFilterCfg(kodif.atradnesVeids)
        },
        {
            dataField: 'dala_nosaukums',
            text: 'Atradnes daļa',
            filterCfg: getTextFilterCfg()
        },
        {
            dataField: 'sadala',
            text: 'Sadaļa',
            filterCfg: getSelectFilterCfgForViewsFromMetadata(metadata),
            formatter: (viewname) => viewNameFromMetadata({viewname, metadata})
        },
        {
            dataField: 'skaits',
            text: 'Labojumu skaits',
            width: 90
        }
  ]};


class AuditsAtradnesAtskaite extends Component {

      onChangePeriod = (fieldName, date) => {
        this.props.addUrlQuery({[fieldName]: date ? format(date, constants.DATE_FORMAT) : undefined})
      }

      render() {

        const {metadata, kodif, auditStatsByAtradne, query} = this.props

        if (!metadata.loaded) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif,
                metadata
            })
        }

        const {loading, list, count} = auditStatsByAtradne

        const pno = query && query.pno ? parse(query.pno, constants.DATE_FORMAT, new Date()) : undefined
        const plidz = query && query.plidz ? parse(query.plidz, constants.DATE_FORMAT, new Date()) : undefined

        return (
            <div className="saraksts-wrapper">
                <Grid
                    loading={loading}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                >
                <span className='margin-10'><b>Periods:</b></span>
                <DateInputField
                  setFieldValue={this.onChangePeriod}
                  fieldName="pno"
                  currentVal={pno}
                  extraFieldProps={{placeholder:"No"}}
                />
                &nbsp;-&nbsp;
                <DateInputField
                  setFieldValue={this.onChangePeriod}
                  fieldName="plidz"
                  currentVal={plidz}
                  extraFieldProps={{placeholder:"Līdz"}}
                />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = ({auditStatsByAtradne, kodif, metadata, location}) => ({auditStatsByAtradne, kodif, metadata, query: location.query})
export default connect(mapStateToProps, {addUrlQuery})(AuditsAtradnesAtskaite)
