import {
  callApi, getDefaultEditActions, pushUrlToBrowserHistory
} from './helpers'
import {redirect} from "redux-first-router";
import * as maputils from "../components/karte/maputils";

export const loadStacijasMeteo = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'noverojumu_stacijas_list',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const stacijasMeteoSelected = (id) => {
  return {
    type: 'stacijasMeteo_selected',
    payload: id
  }
}

export const stacijasDoubleSelected = (id) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_stacija_show',
    payload: {
      id: id
    }
  }))
}

export const stacijaActions = getDefaultEditActions({
  actionTypeName: 'stacija',
  stateName: 'stacija',
  apiViewName: 'noverojumu_stacija_view',
})

export const stacijaGenerateAizsargjosla = ({ id }) => (dispatch) => {
  dispatch(callApi({
    viewName: 'noverojumu_stacija_generet_aizsargjoslas',
    crudMethod: 'save',
    crudParam: {
      vvis_stacija_id: id
    },
    success: (result) => {
      dispatch(stacijaActions.fetchAct(result))
    }
  }))
}

export const atisObjektsActions = getDefaultEditActions({
  actionTypeName: 'atis_objekts',
  stateName: 'atisObjekts',
  apiViewName: 'atis_objekts_view',
  combinedPage: true,
  hasKoordsField: true,
  geomToJson: (koords, newGeom) => {
    return maputils.GeoJSONFormat.writeGeometry(newGeom)
  },
  createActArgsToCrudParams: ({ vvis_stacija_id }) => ({ vvis_stacija_id }),
  afterSave: (dispatch, getState, result) => {
    dispatch({ type: 'stacija_clear' })
    dispatch(stacijaActions.fetchAct({
      id: result.vvis_stacija_id
    }))
  },
  afterDelete: (dispatch, getState) => {
    const stacija = getState().stacija.selectedItem
    dispatch(stacijaActions.fetchAct({
      id: stacija.id
    }))
  }
})

export const atisObjektsGeometrija = (veids, stacija) => {
  return {
    type: `atis_objekts_${veids}`,
    payload: stacija
  }
}

export const koordSelectAct = (koord) => {
  return { type: 'atis_objekts_koord_selected', payload:koord }
}

export const koordChangeAct = (koord) => {
  return { type: 'atis_objekts_koord_changed', payload:koord }
}

export const koordDeleteAct = (koord) => {
  return { type: 'atis_objekts_koord_deleted', payload:koord }
}