import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {geofondImportActions, geofondImportsSelected} from "../../actions/geofondImportsActions"
import {
    dateTimeFormatter,
    getDateFilterCfg,
    getFormatterForKodif,
    getSelectFilterCfg,
    getTextFilterCfg,
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import axios from 'axios';
import {csrfHeader} from "../../api";

const getColumns = ({kodif}) => {
    return [
        {
            dataField: 'inv_numurs',
            text: 'Inventāra numurs',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'lnb_id',
            text: 'LNB identifikators',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'folder_name',
            text: 'Mape',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'status',
            text: 'Statuss',
            formatter: getFormatterForKodif(kodif.geofondImportsStatus),
            filterCfg: getSelectFilterCfg(kodif.geofondImportsStatus)
        }, {
            dataField: 'error_message',
            text: 'Kļūda',
            filterCfg: getTextFilterCfg()
        },
        {
            dataField: 'start_time',
            text: 'Sākās imports',
            filterCfg: getDateFilterCfg(),
            formatter: dateTimeFormatter
        },
        {
            dataField: 'end_time',
            text: 'Beidzās imports',
            filterCfg: getDateFilterCfg(),
            formatter: dateTimeFormatter
        }
    ]};

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: [],
            folderName: '',
            error: null,
            success: null,
            progress: 0,
            is_uploading: false,
        };
    }

    triggerFileInput = (event) => {
        event.preventDefault();
        this.fileInput.click();
    };

    handleFolderChange = (event) => {
        const files = Array.from(event.target.files);
        this.setState({ selectedFiles: files, error: null, success: null});

        if (files.length > 0) {
            // Infer folder name from the relative path of the first file
            const folderPath = files[0].webkitRelativePath;
            const folderName = folderPath.substring(0, folderPath.indexOf('/'));
            this.setState({ folderName: folderName });
        }
    };

    handleUpload = async (event) => {
        const getFileExtension = (fileName) => {
            return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        }

        event.preventDefault();
        this.setState({is_uploading: true, error: null, success: null})
        const formData = new FormData();
        this.state.selectedFiles.forEach(file => {
            if (getFileExtension(file.name).toLowerCase() === 'jp2') {
                const newBlob = new Blob([file], { type: 'image/jp2' })
                formData.append('files', newBlob, file.webkitRelativePath);
            } else {
                formData.append('files', file);
            }
        });
        try {
            await axios.post(`/services/upload-lnb?folderName=${this.state.folderName}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...csrfHeader()
                },
                onUploadProgress: progressEvent => {
                    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(percentage)
                    this.setState({progress: percentage})
                },
                // maxContentLength: Infinity,
                // maxBodyLength: Infinity
            });
            console.log('Upload successful');
            this.setState({is_uploading: false, error: null, success: 'Augšupielāde veiksmīga!'})
        } catch (error) {
            this.setState({
                error: `${error}`,
                success: null,
                is_uploading: false,
            })
            console.error('Error uploading files', error);
        }
    };

    renderError () {
        if (this.state.error) {
            return (
                <p className="alert alert-danger">{ this.state.error }</p>
            )
        }
    }

    renderSuccess () {
        if (this.state.success) {
            return (
                <p className="alert alert-success">{ this.state.success }</p>
            )
        }
    }

    renderFolderName () {
        if (this.state.folderName) {
            return (
                <p className="alert alert-info">{ this.state.folderName }</p>
            )
        } else {
            return (
                <p className="alert alert-info">{ 'Nav izvēlēta mape' }</p>
            )
        }
    }

    renderProgressBar() {
        if (this.state.is_uploading) {
            return <progress className="combined-progress" id="uploadProgress" value={this.state.progress} max="100"></progress>;
        }
        return null;
    }

    render() {
        return (
            <div className="lnb-file-upload-flex-container">
                <p className="alert alert-heading">{ 'Pielikumu augšupielādē' }</p>
                <input
                    className="btn btn-primary"
                    type="file"
                    webkitdirectory="true"
                    directory="true"
                    onChange={this.handleFolderChange}
                    ref={input => this.fileInput = input}
                    style={{ display: 'none' }}
                />
                <button className="btn btn-primary" disabled={this.state.is_uploading} onClick={this.triggerFileInput}><i className="fa fa-folder-open"></i> Izvēlēties...</button>
                {this.renderFolderName()}
                <button className="btn btn btn-success" disabled={this.state.is_uploading} onClick={this.handleUpload}><i className="fa fa-upload"></i> Augšupielādēt</button>
                {this.renderProgressBar()}
                {this.renderSuccess()}
                {this.renderError()}
            </div>
        );
    }
}

const  getFormFieldCfgs = ({kodif}) => {

    return [
        {
            label: '',
            name: 'dummy',
            customField: (props) => {
                return <FileUpload {...props}/>
            },
            renderer: (fieldValue) => {
                return <FileUpload/>
            },
        }
    ]};

class GeofondImports extends Component {

    render() {
        const { geofond_imports } = this.props

        if(geofond_imports.loading) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs({
                kodif: this.props.kodif
            })
        }

        return (
            <GridWithForm
                {...this.props}
                columns={this.columns}
                formFieldCfgs={this.formFieldCfgs}
                storeObj={this.props.geofond_imports}
                formWidthGuiOption="geofond_imports_form_width"
                metadataFormCfg="geofond_imports"
                tiesibasLabot="PARSKATS_LABOT"
                handleDelete={null}
                handleSave={null}
                editable={false}
            />
        )

    }

}

const mapStateToProps = ({geofond_imports, kodif, location}) => ({geofond_imports, kodif, query: location.query})

export default connect(mapStateToProps, {
    selectedAct: geofondImportsSelected,
    addUrlQuery,
    ...geofondImportActions
})(GeofondImports)