import React from 'react';
import {connect} from 'react-redux'
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from 'reactstrap';
import {login} from '../actions/lietotajsActions'
import {Formik, Field} from 'formik';

const validate = values => {
    const errors = {}
    if(!values.username){
        errors.username = 'Lietotājvārds ir obligāts lauks'
    }
    if(!values.password){
        errors.password = 'Parole ir obligāts lauks'
    }
    return errors
}

const Login = (props) => {
    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="5">
                        <Card className="p-4">
                            <CardBody>
                                <Formik
                                    onSubmit={props.login}
                                    initialValues={{
                                    username: '',
                                    password: ''
                                }}
                                    validate={validate}
                                    render={({handleSubmit, isSubmitting, errors, touched, isValid}) => (
                                    <Form onSubmit={handleSubmit}>
                                        <h2>Zemes Dzīļu IS</h2>
                                        <p className="text-muted">Ievadiet Aktīvās Direktorijas lietotāju/paroli</p>
                                        <InputGroup className="mb-3">
                                            <Input
                                                type="text"
                                                name="username"
                                                placeholder="Lietotājvārds"
                                                autoComplete="username"
                                                autoFocus
                                                tag={Field}
                                                invalid={errors.username && touched.username}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <i className="fa fa-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <Input
                                                type="password"
                                                name="password"
                                                placeholder="Parole"
                                                autoComplete="current-password"
                                                tag={Field}
                                                invalid={errors.password && touched.password}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <i className="fa fa-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <Row>
                                            <Col xs="6">
                                                <Button
                                                    color="primary"
                                                    className="px-4"
                                                    disabled={isSubmitting || !isValid}
                                                    type="submit">Ieiet</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => {return {}}

export default connect(mapStateToProps, {login})( Login )
