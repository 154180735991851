import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg,
    getEditIconColumn,
    getNumberFilterCfg,
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import { dzeramaUdensSatecesBaseinsSelected } from '../../actions/dzeramaUdensSatecesBaseiniActions';
import Link from 'redux-first-router-link';
import { Button } from 'reactstrap';

const editIconColumn = getEditIconColumn((row) => (
    {   
        type: 'loc_dzerama_udens_sateces_baseins',
        payload: {
            id: row.id
        }
    }
))

const getUrbumsFormatter = (fieldValue) => fieldValue.map((urb, i) => 
    <div key={i} className="badge-column">
        <Link
            to={{
                type: 'loc_urbums',
                payload: {
                    id: urb.urbumi_id
                }
            }}
        >
            {urb.urbumi_id}
        </Link>
    </div>
)

const getPazemesUdenuObjektsFormatter = (fieldValue) => fieldValue.map((puo, i) => 
    <div key={i} className="badge-column">
        <Link
            to={{
                type: 'loc_pazemes_udens_objekts',
                payload: {
                    kods: puo.kods
                }
            }}
        >
            {puo.kods}
        </Link>
    </div>
)

const getColumns = ({kodif}) => {
  return [editIconColumn,
    {
        dataField: 'kods',
        text: 'Kods',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'q_adm_ters',
        text: 'Administratīvā teritorija',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'nosaukums',
        text: 'Nosaukums',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'operators',
        text: 'Operators',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'atlaujas_nr',
        text: 'Atļaujas numurs',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'platiba',
        text: 'Platība',
        width: 120,
        filterCfg: getNumberFilterCfg()
    }, {
        dataField: 'udens_ieguves_vietas',
        text: 'Ūdens ieguves vietas',
        formatter: getUrbumsFormatter,
        filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
                remoteSearchViewName: 'dzerama_udens_sateces_baseins_urbumi_search'
            }
        }
    }, {
        dataField: 'pazemes_udens_objekti',
        text: 'PŪO',
        formatter: getPazemesUdenuObjektsFormatter,
        filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
                remoteSearchViewName: 'dzerama_udens_sateces_baseins_pazemes_udenu_objekti_search'
            }
        }
    }
]};


class DzeramaUdensSatecesBaseiniSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('dzerama_udens_sateces_baseini_saraksts_karte_height', h);
    }

    render() {

        const {kodif, dzeramaUdensSatecesBaseini, gui, dzeramaUdensSatecesBaseinsSelected} = this.props
        const {list, selected, loading, count} = dzeramaUdensSatecesBaseini

        const vectorLayerCfgs = {
            dzeramaUdensSatecesBaseini : {
                title: 'Dzeramā ūdens sateces baseini',
                features: list,
                styles: maputils.urbumiStyles,
                tooltipFunc: (feat) => `${feat.get('nosaukums')}`,
                selected: selected,
                legend: maputils.urbumiLegend
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.dzerama_udens_sateces_baseini_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="dzerama_udens_sateces_baseini_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid 
                    onRowSelected={dzeramaUdensSatecesBaseinsSelected}
                    loading={loading}
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns} 
                >
                    { this.props.user.tiesibas.includes('SATECES_BASEINI_LABOT') ? (
                        <Link to={{ 
                            type: 'loc_dzerama_udens_sateces_baseins',
                            payload: {
                                id: 'jauns'
                            }
                        }}>
                            <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                        </Link>
                    ) :  null }
                    <BBoxFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, dzeramaUdensSatecesBaseini, gui, user}) => {
    return {kodif, dzeramaUdensSatecesBaseini, gui, user}
}

export default connect(mapStateToProps, {dzeramaUdensSatecesBaseinsSelected, guiSet})(DzeramaUdensSatecesBaseiniSaraksts)