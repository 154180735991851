import React, {Component} from 'react';
import {connect} from 'react-redux'
import {paseActions} from '../../actions/dokumentacijaActions'
import {getCommonFormFieldCfgs, DokumentacijasKartina, PaseLink} from './common'

const formFields = ['ir_derigs', 'atradne', 'dalas_nosaukums', 'adresats', 'kadastra_apzimejumi', 'ipasums', 
'izsniegts_datums', 'derigs_lidz_datums', 'anulets_datums', 'pase_sadalijumi' , 'piezimes', 'aktualizacijas_laiks', 'limiti', 'licences']
  
const getFormFieldCfgs = ({kodif, user}) => {
  const commonFields = getCommonFormFieldCfgs({kodif})
  const fields = formFields.map(ff => commonFields.find(cf => cf.name === ff))
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fields.push(commonFields.find(cf => cf.name === 'faili'))
  }
  fields.push({
    name: 'ieprieksejais',
    type: 'autocomplete',
    autocompleteCfg: {
        initialOptionsViewName: 'pase_search_by_atradne',
        isValueObjStructure: true,
    },
    overridePropsFunc: (props) => {
      const changedProps = {...props}
      const {currentValues} = changedProps
      const placeholder = 'Izvēlieties pasi, kas pievienota norādītajai atradnei'
      if(currentValues.atradne && currentValues.atradne.id){
        changedProps.extraFieldProps = {disabled : false, placeholder}
        const extraFilterParams = {atradne_id: currentValues.atradne.id}
        if(currentValues.id) {
          extraFilterParams.not_pase = currentValues.id
        }
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams}
      } else {
        changedProps.extraFieldProps = {disabled : true, placeholder}
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams : null}
      }
      return changedProps      
    },
    renderer: (pas, dataObj) => <PaseLink pase={pas} atradne={dataObj.atradne}/>
  })
  fields.push({
    name: 'ieprieksejie',
    renderer: (pases, dataObj) =>  <div className="saistitas">
        {dataObj.nakamie.map((pas, i) => <div key={i}><PaseLink className='links' pase={pas} atradne={dataObj.atradne} irSaistita/> (nākamā)</div>)}
        {dataObj.ieprieksejie.map((pas, i) => <div key={i}><PaseLink className='links' pase={pas} atradne={dataObj.atradne} irSaistita/> (iepriekšējā)</div>)}
        </div>
  })
  return fields
}

const childGeometryTooltipFunc = (feat) => `ID ${feat.get('id') || ''} ${feat.get('kategorijas') || ''} ${feat.get('izrakteni') ? feat.get('izrakteni').map(i => i.klasifikators).join(', ') : ''}`
  
class Pase extends Component {

    render() {
      
      const {pase, kodif, saveAct, stopEditAct, startEditAct, deleteAct, koordSelectAct, koordChangeAct, user} = this.props

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif, user
        })
      }

      return <DokumentacijasKartina
        sizeForm={9}
        sizeLabel={3}
        geomObj={pase}
        formFieldCfgs={this.formFieldCfgs}
        saveAct={saveAct}
        stopEditAct={stopEditAct}
        startEditAct={startEditAct} 
        deleteAct={deleteAct}
        koordSelectAct={koordSelectAct} 
        koordChangeAct={koordChangeAct}
        karteWidthProp='pases_kartina_karte_width'
        mapId='pases_kartina'
        editableLayerIdName='pase_koord'
        layerTitles={{
          feat_border: 'Sadalījuma robeža',
          feat: 'Pases krājumu sadalījums',
          koord: 'Sadalījuma robežpunkti',
          koord_labels: 'Robežpunktu numuri',
        }}
        metadataFormCfg="pase_view"
        childGeometryEditing
        childGeometryTooltipFunc={childGeometryTooltipFunc}
      />
    }
}

const mapStateToProps = ({pase, kodif, user}) => ({pase, kodif, user})
export default connect(mapStateToProps, {...paseActions})(Pase)
