import React from 'react'
import { connect } from 'react-redux'

import Grid from '../grid/Grid'

import {
  getTextFilterCfg,
} from '../grid/ColumnHelpers'


const getColumns = () => {
  return [
    {
      dataField: 'facility_id',
      text: 'facility_id',
      width: 50,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'paris_kods',
      text: 'Kods',
      width: 50,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'paris_urbumi_id',
      text: 'Urbumi ID',
      filterCfg: getTextFilterCfg(),
      width: 50,
    }, {
      dataField: 'teksts',
      text: 'Problēma',
      filterCfg: getTextFilterCfg(),
      width: 200,
    },
  ]
}

class ParisValidacijaRezultati extends React.PureComponent {

  render() {

    if ( ! this.columns) {
      this.columns = getColumns()
    }

    const {loading, list, count} = this.props.paris
    return (
      <div>
        <h2>Pārskatu sistēmas “2-Ūdens” ūdens ieguves vietu un DB “Urbumi” ierakstu validācijas rezultāti</h2>
        <Grid
          loading={loading}
          dataList={list}
          totalCount={count}
          columns={this.columns}
          extraGridProps={this.extraGridProps}
        />
      </div>
    )
  }

}

const mapState = ({paris, gui}) => ({paris, gui})

export default connect(mapState)(ParisValidacijaRezultati)
