import * as helpers from './helpers'

const defaultListReducerForKrajums = helpers.createListReducerWithMapClicking({
    listViewName: 'krajums_list', 
    actionsName: 'krajums',
    mapIdName: 'krajumu_saraksts',
    layerIdName: 'krajumi'
})
export const krajumi = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForKrajums({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForKrajums = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'krajums_view',
    actionsName: 'krajums',
    mapIdName: 'krajuma_kartina',
    layerIdName: 'krajums_koord',
})
export const krajums = (state = helpers.editWithGeometryInitialState(), {type, payload, mapId, layerId, featureGeom}) => {
    return defaultEditReducerForKrajums({state, type, payload, mapId, layerId, featureGeom})
}
