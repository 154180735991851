import {
    callApi,
    getDefaultEditActions
} from './helpers'

export const loadUdensapgadesSistemas = (query) => (dispatch, getState) => {
  return dispatch(callApi({
    viewName: 'udensapgades_sistemas_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const udensapgadesSistemaSelected = (id) => {
  return { type: 'udensapgadesSistema_selected', payload : id }
}

const udensapgadesSistemasAfterSaveOrDelete = (dispatch, getState) => {
  dispatch(loadUdensapgadesSistemas(getState().location.query))
}

export const udensapgadesSistemasActions = getDefaultEditActions({
  actionTypeName : 'udensapgadesSistema',
  stateName: 'udensapgadesSistemas',
  combinedPage: true,
  apiViewName: 'udensapgades_sistemas_edit',
  afterDelete: udensapgadesSistemasAfterSaveOrDelete,
  afterSave: udensapgadesSistemasAfterSaveOrDelete,
})