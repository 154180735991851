import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getFormatterForKodif,
  getSelectFilterCfg,
  getTextFilterCfg,
  getNumberFormatter, getYearFilterCfg,
} from '../grid/ColumnHelpers'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import { udensNvSelected } from "../../actions/udens2Actions"

import Grid from '../grid/Grid'

const getColumns = ({kodif}) => {
  return [{
    dataField: 'report_id',
    text: 'Report ID',
    width: 120,
  }, {
    dataField: 'gads',
    text: 'Gads',
    width: 120,
    filterCfg: getYearFilterCfg()
  }, {
    dataField: 'veids',
    text: 'Veids',
    width: 120,
    formatter: getFormatterForKodif(kodif.udens2Veids),
    filterCfg: getSelectFilterCfg(kodif.udens2Veids)
  }, {
    dataField: 'code',
    text: 'ID numurs',
    width: 120,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'name',
    text: 'Nosaukums',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'adrese',
    text: 'Vieta',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'tips',
    text: 'Tips',
    width: 320,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'daudzums',
    text: 'Ņemtā ūdens daudzums, tūkst.m3/gadā',
    width: 140,
    formatter: getNumberFormatter(6)
  }, {
    dataField: 'coord_x',
    text: 'Garums',
    width: 120,
    formatter: getNumberFormatter()
  }, {
    dataField: 'coord_y',
    text: 'Platums',
    width: 120,
    formatter: getNumberFormatter()
  }]
};

class UdensNV extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalSearch: false
    };
  }

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet('udens_nv_saraksts_karte_height', h);
  }

  rowEvents = {
    // onDoubleClick: (e, row, rowIndex) => {
    //   this.props.virszemesUdensObjektsDoubleSelected(row.kods)
    // }
  }

  render() {
    const vectorLayerCfgs = {
      udens2 : {
        title: 'Ūdens ņemšanas vietas',
        features: this.props.udensNv.list,
        styles: maputils.uoStyles.udens2Style,
        tooltipFunc: (feat) => feat.get('nosaukums'),
        selected: this.props.udensNv.selected
      }
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    return (
      <SplitterLayout
        vertical
        percentage
        primaryIndex={1}
        secondaryInitialSize={this.props.gui.udens_nv_saraksts_karte_height}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <Karte mapId="udens2" vectorLayerCfgs={vectorLayerCfgs} />

        <Grid
          onRowSelected={this.props.udensNvSelected}
          loading={this.props.udensNv.loading}
          selectedId={this.props.udensNv.selected}
          dataList={this.props.udensNv.list}
          totalCount={this.props.udensNv.count}
          columns={this.columns}
          rowEvents={this.rowEvents}
        >
        </Grid>

      </SplitterLayout>
    )
  }
}

const mapStateToProps = ({kodif, udensNv, gui, user}) => {
  return {kodif, udensNv, gui, user}
}

export default connect(mapStateToProps, {guiSet, udensNvSelected})(UdensNV)
