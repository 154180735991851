import React, {Component} from 'react';
import {connect} from 'react-redux'
import Form from '../forms/Form'
import { virszemesRiskiUnIznemumiActions } from '../../actions/riskiUnIznemumiActions';
import { getDefaultTypeaheadOptionsFromKodifArray } from '../forms/FormHelpers';
import { getBooleanFormatter, getBooleanSelectFilterCfg } from '../grid/ColumnHelpers';

const getFormFieldCfgs = ({kodif}) => {
  return [{
        name: 'udens_objekts_id',
        label: 'Ūdens objekts',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
            remoteSearchViewName: 'udens_objekts_search',
            labelFieldName: 'uo_nosaukums'
        },
        renderer: (val, row) => (
            <span>{ row.uo_nosaukums }</span>
        )
    }, {
        name: 'gads',
        label: 'Riska izvērtējuma gads',
        type: 'number',
        renderClassName: 'bold',
        required: true
    }, {
        name: 'periods',
        label: 'Periods',
        renderClassName: "bold",
        required: true,
        type: 'autocomplete',
        autocompleteCfg: {
            options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS')
        }
    }, {
        name: 'biogeni',
        label: 'Biogēni (N, P)',
        renderer: (val) => {
            if (val === 'x') {
                return 'Biogēnu problēma'
            } if (val) {
                return val.toUpperCase()
            }
            return 'Nē'
        }
    }, {
        name: 'bistamas',
        label: 'Bīstamās un prioritārās vielas',
        renderer: (val) => {
            if (val === '?') {
                return val
            }
            return getBooleanFormatter()(!!val)
        }
    }, {
        name: 'hidro',
        label: 'Hidromorfoloģija/bioloģija',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg()
    }, {
        name: 'parrobezu',
        label: 'Pārrobežu',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg()
    }, {
        name: 'pludi',
        label: 'Plūdi',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg()
    }, {
        name: 'cits',
        label: 'Cits',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg()
    }
  ]
};

class VirszemesRisksUnIznemums extends Component {

  render() {
    const {virszemesRisksUnIznemums, kodif, saveAct, stopEditAct, startEditAct, deleteAct} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: kodif
      })
    }

    const {editable, submiting, selectedItem} = virszemesRisksUnIznemums

    return (
      <div className="form-wrapper-pane">
        <Form
          editable={editable}
          submiting={submiting}
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onSubmit={saveAct}
          onReset={stopEditAct}
          onStartEdit={startEditAct}
          onDelete={deleteAct}
          tiesibasLabot={'BAIS_VU_PAMAT_LABOT'}
        />
      </div>
    )
  }
}

const mapStateToProps = ({virszemesRisksUnIznemums, kodif}) => ({virszemesRisksUnIznemums, kodif})

export default connect(mapStateToProps, {...virszemesRiskiUnIznemumiActions})(VirszemesRisksUnIznemums)
