import {callApi, getDefaultEditActions, pushUrlToBrowserHistory} from './helpers'
import {redirect} from 'redux-first-router'

export const loadUzzinas = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'uzzina_list',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const uzzinaActions = getDefaultEditActions({
  actionTypeName : 'uzzina',
  stateName: 'uzzina',
  apiViewName: 'uzzina_view',
  createActArgsToCrudParams : ({numurs}) => {
    if(numurs){
      return {numurs}
    }
    return {}
  }
})

export const openUzzina = (id) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_uzzina',
    payload: {
        id: id
    }
   }))
}