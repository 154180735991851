import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    dateFormatter,
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {openDok, paseSelected} from '../../actions/dokumentacijaActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import {getCommonColumns, getDoubleClickHandler, getEditColumnForDokSaraksts} from './common'
import ManiObjektiButton from '../grid/ManiObjektiButton'

const editIconColumn = getEditColumnForDokSaraksts({loc: 'loc_atradnes_pase', locDala: 'loc_atradnes_dala_pase'})

const columnNames = ['ir_derigs', 'dalas_nosaukums', 'adresats_nosaukums', 'kadastra_apzimejumi', 'ipasums_nosaukums', 'izsniegts_datums',
     'derigs_lidz_datums', 'anulets_datums', 'atradne',
     'limiti', 'licences', 'pielikumi_filename' ,'aktualizacijas_laiks', 'q_adm_ters']
const getColumns = ({kodif, user}) => {
  const allColumns = getCommonColumns({kodif, user}).filter(c => columnNames.indexOf(c.dataField) !== -1)
  return [editIconColumn,...allColumns]
};

class PaseSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('pase_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: getDoubleClickHandler({loc: 'loc_atradnes_pase', locDala: 'loc_atradnes_dala_pase', openDok: this.props.openDok})
    }

    render() {

        const {kodif, pases, gui, paseSelected, user} = this.props
        const {list, selected, loading, count} = pases

        const vectorLayerCfgs = {
            pases : {
                title: 'Pases',
                features: list,
                styles: maputils.dokStyles.dokPaseStyles,
                tooltipFunc: (feat) => `${feat.get('atradne') ? feat.get('atradne').tah_label : ''} ${dateFormatter(feat.get('izsniegts_datums'))} - ${feat.get('adresats_nosaukums')}`,
                selected: selected,
                legend: {
                    features: [
                        {
                          label: 'Pase',
                          geomType: 'polygon'
                        },
                        {
                          label: 'Pase (izvēlēta)',
                          geomType: 'polygon',
                          selected: true
                        }
                    ]
                  }
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif,
                user
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.pase_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="pases_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={paseSelected}
                    loading={loading}
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >
                <BBoxFilterButtonToUrl/>
                <ManiObjektiButton allText="Rādīt visas" maniText="Rādīt tikai manas pases"/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, pases, gui, user}) => {
    return {kodif, pases, gui, user}
}

export default connect(mapStateToProps, {paseSelected, guiSet, openDok})(PaseSaraksts)
