import * as helpers from './helpers'

export const atradnesVeids = {
    B: { nos: 'Būvmateriāli', badgeColor: 'warning', mapColor: '#ffc107' },
    K: { nos: 'Kūdra', badgeColor: 'primary', mapColor: '#31669C' },
    D: { nos: 'Dziedniecības dūņas', badgeColor: 'color_7', mapColor: '#f14dbb' },
    S: { nos: 'Sapropelis', badgeColor: 'success', mapColor: '#4dbd74' },
    C: { nos: 'Cits', badgeColor: 'color_4', mapColor: '#444d74' },
    U: { nos: 'Urbumi', badgeColor: 'info', mapColor: '#63c2de' },
}

export const atradnesIerakstaVeids = {
    A: { nos: 'Atradne', badgeColor: 'dark', mapColor: '#2f353a' },
    P: { nos: 'Prognozēto resursu laukums', badgeColor: 'light', mapColor: '#f0f3f5' },
    D: { nos: 'Atradnes daļa', badgeColor: 'light', mapColor: '#cc66ff' },
}

export const lietotajiAvots = {
    L: { nos: 'LVĢMC', badgeColor: 'color_1' },
    P: { nos: 'Vides portāls', badgeColor: 'color_3' },
    T: { nos: 'Testa', badgeColor: 'color_4' },
}

export const atradnesIerakstaVeidsExtended = {
    ...atradnesIerakstaVeids,
    AP: { nos: 'Atradne un Prognozēto resursu laukums', badgeColor: 'secondary', mapColor: '#999999' },
    PA: { nos: 'Prognozēto resursu laukums un Atradne', badgeColor: 'secondary', mapColor: '#999999' },
}

export const atradnesSasaistesTips = {
    S: { nos: 'saistīts objekts' },
    T: { nos: 'viens un tas pats objekts' },
}

export const izmainuIevadesPeriods = {
    G: { nos: 'gads (kopā)' },
    C: { nos: 'ceturksnis' },
    M: { nos: 'mēnesis' },
    D: { nos: 'līdz noteiktam datumam' }
}

export const urbumiTehniskaisStavoklis = {
    'aiztamponēts': { nos: 'Aiztamponēts', badgeColor: 'secondary', mapColor: '#444d74' },
    'likvidēts': { nos: 'Likvidēts', badgeColor: 'secondary', mapColor: '#444d74' },
    'nav zināms': { nos: 'Nav zināms', badgeColor: 'warning', mapColor: '#ffc107' },
    'default': { nos: 'Nav zināms', badgeColor: 'warning', mapColor: '#ffc107' },
    'potenciālais piesārņojuma novadītājs': { nos: 'Potenciālais piesārņojuma novadītājs', badgeColor: 'warning', mapColor: '#ffc107' },
    'nav iekārtots': { nos: 'Nav iekārtots', badgeColor: 'warning', mapColor: '#ffc107' },
    'projektējamais': { nos: 'Projektējamais', badgeColor: 'warning', mapColor: '#ffc107' },
    'iekonservēts': { nos: 'Iekonservēts', badgeColor: 'warning', mapColor: '#ffc107' },
    'darbojošs': { nos: 'Darbojošs', badgeColor: 'warning', mapColor: '#ffc107' },
    'nedarbojas tehnisku iemeslu dēļ': { nos: 'Nedarbojas tehnisku iemeslu dēļ', badgeColor: 'warning', mapColor: '#ffc107' },
    'neizmanto': { nos: 'Neizmanto', badgeColor: 'warning', mapColor: '#ffc107' },
    'rezervē': { nos: 'Rezervē', badgeColor: 'warning', mapColor: '#ffc107' },
    'piemests': { nos: 'Piemests', badgeColor: 'warning', mapColor: '#ffc107' },
    'jātamponē': { nos: 'Jātamponē', badgeColor: 'warning', mapColor: '#ffc107' },
    'jākonservē': { nos: 'Jākonservē', badgeColor: 'warning', mapColor: '#ffc107' },
    'sanitārais': { nos: 'Sanitārais', badgeColor: 'warning', mapColor: '#ffc107' },
    'labs': { nos: 'Labs', badgeColor: 'warning', mapColor: '#ffc107' },
    'apmierinošs': { nos: 'Apmierinošs', badgeColor: 'warning', mapColor: '#ffc107' },
    'neapmierinošs': { nos: 'Neapmierinošs', badgeColor: 'warning', mapColor: '#ffc107' },
    'stingrā režīma aizsargjosla neeksiste': { nos: 'Stingrā režīma aizsargjosla neeksiste', badgeColor: 'warning', mapColor: '#ffc107' },
    'antisanitārs': { nos: 'Antisanitārs', badgeColor: 'warning', mapColor: '#ffc107' },
    'jaukts': { nos: 'Jaukts', badgeColor: 'warning', mapColor: '#ffc107' },
    'iekonservēts, labā stāvoklī': { nos: 'Iekonservēts, labā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'iekonservēts, apmierinošā stāvoklī': { nos: 'Iekonservēts, apmierinošā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'iekonservēts, neapmierinošā stāvoklī': { nos: 'Iekonservēts, neapmierinošā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'darbojošs, labā stāvoklī': { nos: 'Darbojošs, labā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'darbojošs, apmierinošā stāvoklī': { nos: 'Darbojošs, apmierinošā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'darbojošs, neapmierinošā stāvoklī': { nos: 'Darbojošs, neapmierinošā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'nedarbojas, labā stāvoklī': { nos: 'Nedarbojas, labā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'nedarbojas, apmierinošā stāvoklī': { nos: 'Nedarbojas, apmierinošā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'nedarbojas, neapmierinošā stāvoklī': { nos: 'Nedarbojas, neapmierinošā stāvoklī', badgeColor: 'warning', mapColor: '#ffc107' },
    'citi': { nos: 'Citi', badgeColor: 'warning', mapColor: '#ffc107' },
    'apsekošanas laikā nav atrodams': { nos: 'Apsekošanas laikā nav atrodams', badgeColor: 'warning', mapColor: '#ffc107' }
}

export const izmainuVesturesVeids = {
    INS: { nos: 'Pievienots', badgeColor: 'color_1' },
    DWN: { nos: 'Lejupielādēts', badgeColor: 'color_2'},
    UPD: { nos: 'Labots', badgeColor: 'primary' },
    DEL: { nos: 'Dzēsts', badgeColor: 'warning' },
    CFM: { nos: 'Mērķis ', badgeColor: 'color_3'}
}

export const personasDatuPiekluvesTips = {
    'Pievienots': { nos: 'Pievienots', badgeColor: 'color_1' },
    'Skatīts': { nos: 'Skatīts', badgeColor: 'color_2' },
    'Labots': { nos: 'Labots', badgeColor: 'primary' },
    'Dzēsts': { nos: 'Dzēsts', badgeColor: 'warning' }
}

export const izmainuVesturesStatuss = {
    success: { nos: 'OK', badgeColor: 'success' },
    error: { nos: 'Kļūda', badgeColor: 'danger' }
}

export const kategorijas = {
    A: { nos: 'A', badgeColor: 'color_1', ievadeDokumentacija: true },
    N: { nos: 'N', badgeColor: 'color_3', ievadeDokumentacija: true },
    P: { nos: 'P', badgeColor: 'color_4' },
}

export const virszemesUdensObjektaKategorija = {
    upe: { nos: 'Upe' },
    ezers: { nos: 'Ezers' },
    parejas_objekts: { nos: 'Pārejas objekts' },
    piekarastes_objekts: { nos: 'Piekrastes objekts' }
}

export const udensObjektaKategorija = {
    upe: { nos: 'Upe' },
    ezers: { nos: 'Ezers' },
    parejas_objekts: { nos: 'Pārejas objekts' },
    piekarastes_objekts: { nos: 'Piekrastes objekts' },
    pazemes: { nos: 'Pazemes' }
}

export const udensObjektaIzcelsme = {
    dabisks: { nos: 'Dabisks' },
    mv: { nos: 'Mākslīgi veidots' },
    sp: { nos: 'Stipri pārveidots' },
}

export const pazemesUdensObjektaTesti = {
    labs_augsta_ticamiba: { nos: 'Labs (augsta ticamība)' },
    labs_videja_ticamiba: { nos: 'Labs (vidēja ticamība)' },
    labs_zema_ticamiba: { nos: 'Labs (zema ticamība)' },
    slikts: { nos: 'Slikts' },
    nav_attiecinams: { nos: 'Nav attiecināms' },
    nav_veikts: { nos: 'Nav veikts' }
}

export const pazemesUdensObjektaNovertejums = {
    labs_augsta_ticamiba: { nos: 'Labs (augsta ticamība)', badgeColor: 'success' },
    labs_videja_ticamiba: { nos: 'Labs (vidēja ticamība)', badgeColor: 'success' },
    labs_zema_ticamiba: { nos: 'Labs (zema ticamība)', badgeColor: 'success' },
    slikts: { nos: 'Slikts', badgeColor: 'danger' },
}

export const pazemesUdensObjektaIznemumi = {
    tehniskais: { nos: 'Tehniskais iemesls' },
    izmaksu: { nos: 'Izmaksu nesamērīgums' },
    dabas: { nos: 'Dabas apstākļi' },
    neparvarama_vara: { nos: 'Nepārvaramā vara' },
    negadijumi: { nos: 'Negadījumi' },
    kvantitates_izmaina: { nos: 'Kvantitātes izmaiņa' },
    nenoteiktiba: { nos: 'Nenoteiktība problēmas cēlonī' },
    maksliga_papildinasana: { nos: 'Mākslīgā papildināšana' },
    nav: { nos: 'Nav' }
}

export const pazemesUdensObjektaSlodze = {
    butiska: { nos: 'Būtiska' },
    nebutiska: { nos: 'Nebūtiska' }
}

export const personasTips = {
    J: { nos: 'Juridiska', badgeColor: 'color_5' },
    F: { nos: 'Fiziska', badgeColor: 'color_6' }
}

export const ep_tips = {
    E: { nos: 'Ezers', badgeColor: 'color_5' },
    P: { nos: 'Purvs', badgeColor: 'color_6' }
}

export const uoKvalitate = {
    1: { nos: 'Augsta', badgeColor: 'color_kval_augsta' },
    2: { nos: 'Laba', badgeColor: 'color_kval_laba', badgeColorKimiska: 'color_kval_augsta' },
    3: { nos: 'Vidēja', badgeColor: 'color_kval_videja' },
    4: { nos: 'Slikta', badgeColor: 'color_kval_slikta', badgeColorKimiska: 'color_kval_loti_slikta' },
    5: { nos: 'Ļoti slikta', badgeColor: 'color_kval_loti_slikta' },
    'N-1': { nos: 'Paraugs bija monitoringa programmā, bet netika ievākts' },
    'N-2': { nos: 'Nepietiekams sugu skaits, lai aprēķinātu indeksus' },
    'N-3': { nos: 'Neloģiska indeksa vērtība, kura tiek izslēgta no gala novērtējuma' },
    'N-4': { nos: 'Paraugs ievākts neatbilstošā sezonā, nav vērtēts' },
    'N-5': { nos: 'Paraugs ir ievākts, bet pagaidām nav izstrādātas kvalitātes klašu robežvērtības' },
}

export const salidzZimes = {
    'lt': { nos: '<' },
    'lte': { nos: '≤' },
    'gt': { nos: '>' },
    'gte': { nos: '≥' },
}

export const baisImportsTipi = {
    'biol': { nos: 'Kvalitāte -> Bioloģiskie elementi' },
    'hidro_upes': { nos: 'Kvalitāte -> Hidromorfoloģija upēm' },
    'hidro_ezeri': { nos: 'Kvalitāte -> Hidromorfoloģija ezeriem' },
    'aprekinamie': { nos: 'Kvalitāte -> Aprēķināmie parametri' },
    'kopvertejums': { nos: 'Kvalitāte -> Kopvertejums' },
    'seki': { nos: 'Kvalitāte -> Seki kvalitāte L1, L3 tipa ezeriem'},
    'riski': { nos: 'Riski' },
    'slodzes': { nos: 'Slodzes' }
}

export const baisImportsTipiKval = {
    'biol': { nos: 'Kvalitāte -> Bioloģiskie elementi' },
    'hidro_upes': { nos: 'Kvalitāte -> Hidromorfoloģija upēm' },
    'hidro_ezeri': { nos: 'Kvalitāte -> Hidromorfoloģija ezeriem' },
    'aprekinamie': { nos: 'Kvalitāte -> Aprēķināmie parametri' },
    'kopvertejums': { nos: 'Kvalitāte -> Kopvertejums' },
    'seki': { nos: 'Kvalitāte -> Seki kvalitāte L1, L3 tipa ezeriem'},
}

export const baisImportsTipiPamat = {
    'riski': { nos: 'Riski' },
    'slodzes': { nos: 'Slodzes' }
}

export const baisPazemesImportsTipi = {
    'pasakumi': { nos: 'Pasakumi' },
}

export const hidrogeoImportsTipi = {
    'kim_sastavs': { nos: 'Ķīmiskais sastāvs' },
    'udens_lim': { nos: 'Ūdens līmeņi' }
}

export const geofondImportsStatus = {
    'PROCESSING': { nos: 'Dati tiek augšupielādēti' },
    'SUCCESS': { nos: 'Veiksmīgi izpildīts' },
    'FAILED': { nos: 'Notikusi kļūda' }
}

export const ievadesStatuss = {
    K: { nos: 'Iesniegts', badgeColor: 'color_4', loma: 'LABOT_ATRADNES_IZMAINAS' },
    A: { nos: 'Apstiprināts', badgeColor: 'color_8', loma: 'LABOT_ATRADNES_IZMAINAS' },
    P: { nos: 'Publicēts', badgeColor: 'success', loma: 'PUBLICET_ATRADNES_IZMAINAS' }
}

export const kimiskaMatrica = {
    U: { nos: 'Ūdens' },
    G: { nos: 'Gliemju' },
    Z: { nos: 'Zivju' },
    B: { nos: 'Bīstamās' }
}

export const qlAttiecasUz = {
    P: { nos: 'Pazemes' },
    V: { nos: 'Virszemes' },
}

export const pasakumsSlodze = {
    punktveida: { nos: 'Punktveida piesārņojumam' },
    izkliedetais: { nos: 'Izkliedētajam piesārņojumam' },
    hidromorf: { nos: 'Hidromorfoloģiskajai slodzei' },
    cita: { nos: 'Citi pasākumi' },
}

export const pasakumiSlodze = {
    punktveida: { nos: 'Punktveida piesārņojums' },
    izkliedetais: { nos: 'Izkliedētais piesārņojums' },
    hidromorf: { nos: 'Hidromorfoloģiskā slodze' },
    cita: { nos: 'Citi pasākumi' },
}

export const pasakumsVeids = {
    pamata: { nos: 'Pamata pasākums' },
    papildus: { nos: 'Papildu pasākums' },
}

export const pasakumsUoKategorija = {
    ezeri: { nos: 'Ezeri' },
    upes: { nos: 'Upes' },
    upes_ezeri: { nos: 'Upes, ezeri' },
    upes_ezeri_pazemes: { nos: 'Upes, ezeri, pazemes ūdeņi' },
    dzeramais: { nos: 'Dzeramais ūdens' },
    pazemes: { nos: 'Pazemes ūdeņi' },
    piekrastes: { nos: 'Piekrastes ūdeņi' },
}

export const pasakumsMerogs = {
    nacionals: { nos: 'Nacionālā mērogā' },
    uo: { nos: 'Ūdensobjekta mērogā' },
}

export const udens2Veids = {
    '2UUNVP': { nos: 'Pazemes' },
    '2UUNVV': { nos: 'Virszemes' },
}

export const udens2AiVeids =
    ['Industriālās NAI', 'Komunālās NAI', 'Neatkarīgās jeb individuālās NAI', 'Tukšs/nezināms'].reduce((pv, i) => { pv[i] = { nos: i }; return pv }, {})

export const udens2AiTips =
    ['Bioloģiskā', 'Ķīmiskā', 'Mehāniskā', 'Tukšs/nezināms'].reduce((pv, i) => { pv[i] = { nos: i }; return pv }, {})

export const udens2AiFaktiska =
    ['Biogēnu redukcija', 'Nesasniedzot 1. attīrīšanas pakāpi', 'Otrējā attīrīšana', 'Pirmējā attīrīšana', 'Tukšs/nezināms'].reduce((pv, i) => { pv[i] = { nos: i }; return pv }, {})

export const parisVknKrasa = {
    '#ff0000': { nos: 'Sarkans' },
    '#ff8800': { nos: 'Oranžs' },
}

export const atisKodsObjekts = {
    '3207000001': { nos: 'sauszemes hidrometrisko (hidroloģisko) novērojumu postenis' },
    '3207000002': { nos: 'jūras piekrastes hidrometrisko (hidroloģisko) novērojumu postenis' },
    '3207000003': { nos: 'pazemes ūdeņu valsts monitoringa postenis un dziļurbums' },
    '3207000004': { nos: 'seismoloģiskais monitoringa postenis' },
    '3207000005': { nos: 'citi stacionāri valsts nozīmes monitoringa punkti un posteņi' },
    '5208220501': { nos: 'stacionārs valsts nozīmes meteoroloģisko novērojumu monitoringa punkts vai postenis' },
    '5208220502': { nos: 'meteoroloģisko novērojumu stacijas vai posteņa novērojumu laukums' },
    '5208220503': { nos: 'meteoroloģisko novērojumu postenis' },
    '5208220504': { nos: 'meteoroloģiskā radiolokācijas iekārta' },
}
export const atisKodsAizsargjosla = {
    '7311030100': { nos: 'ekspluatācijas aizsargjoslas teritorija ap meteoroloģisko novērojumu stacijas vai posteņa novērojumu laukumu' },
    '7311030200': { nos: 'ekspluatācijas aizsargjoslas teritorija ap meteoroloģisko novērojumu posteni' },
    '7311030300': { nos: 'ekspluatācijas aizsargjoslas teritorija ap stacionāru valsts nozīmes meteoroloģisko novērojumu monitoringa punktu vai posteni' },
    '7311030400': { nos: 'ekspluatācijas aizsargjoslas teritorija ap meteoroloģisko radiolokācijas iekārtu' },
    '7311030500': { nos: 'ekspluatācijas aizsargjoslas teritorija ap sauszemes hidrometrisko (hidroloģisko) novērojumu posteni' },
    '7311030600': { nos: 'ekspluatācijas aizsargjoslas teritorija ap jūras piekrastes hidrometrisko (hidroloģisko) novērojumu posteni' },
    '7311030700': { nos: 'ekspluatācijas aizsargjoslas teritorija ap pazemes ūdeņu valsts monitoringa posteni un dziļurbumu' },
    '7311030800': { nos: 'ekspluatācijas aizsargjoslas teritorija ap seismoloģiskā monitoringa posteni' },
    '7311030900': { nos: 'ekspluatācijas aizsargjoslas teritorija ap citiem stacionāriem valsts nozīmes monitoringa punktiem un posteņiem' },
}

export const atisAizsargjoslaVeids = {
    centrs: { nos: 'No iekārtas centra' },
    robeza: { nos: 'No laukuma ārējās robežas' },
    linija: { nos: 'Uz abām pusēm no līnijas' },
}

export const atisObjektsGeometry = {
    point: { nos: 'Punkts' },
    polygon: { nos: 'Poligons' },
    line: { nos: 'Līnija' }
}

export const troksniAvots = {
    rīga: { nos: 'Rīgas Dome' },
    dzelzcelš: { nos: 'Dzelzceļš' },
    lidostas: { nos: 'Lidostas' },
    autoceļi: { nos: 'Autoceļi' }
}

export const troksniLayer = {
    NoiseContours_airportsInAgglomeration_Lden: { nos: 'Lidostas trokšņu kontūra dienā' },
    NoiseContours_airportsInAgglomeration_Lnight: { nos: 'Lidostas trokšņu kontūra naktī' },
    NoiseContours_allSourcesInAgglomeration_Lden: { nos: 'Kopējās aglomerācijas trokšņu kontūra dienā' },
    NoiseContours_allSourcesInAgglomeration_Lnight: { nos: 'Kopējās aglomerācijas trokšņu kontūra naktī' },
    NoiseContours_industryInAgglomeration_Lden: { nos: 'Industriālās aglomerācijas trokšņu kontūra dienā' },
    NoiseContours_industryInAgglomeration_Lnight: { nos: 'Industriālās aglomerācijas trokšņu kontūra naktī' },
    NoiseContours_railwaysInAgglomeration_Lden: { nos: 'Dzelzceļa aglomerācijas trokšņu kontūra dienā' },
    NoiseContours_railwaysInAgglomeration_Lnight: { nos: 'Dzelzceļa aglomerācijas trokšņu kontūra naktī' },
    NoiseContours_roadsInAgglomeration_Lden: { nos: 'Autoceļu aglomerācijas trokšņu kontūra dienā' },
    NoiseContours_roadsInAgglomeration_Lnight: { nos: 'Autoceļu aglomerācijas trokšņu kontūra naktī' },
}
export const troksniSource = {
    agglomerationAir: { nos: "Aircraft noise inside agglomeration" },
    agglomerationAllSources: { nos: "Noise from all sources inside …" },
    agglomerationIndustry: { nos: "Industrial noise inside agglomeration" },
    agglomerationMajorAirport: { nos: "Noise from major airports inside …" },
    agglomerationMajorRailway: { nos: "Noise from major railways inside …" },
    agglomerationMajorRoad: { nos: "Noise from major roads inside …" },
    agglomerationRailway: { nos: "Railway noise inside agglomeration" },
    agglomerationRoad: { nos: "Road noise inside agglomeration" },
    allSources: { nos: "All noise sources inside and outside …" },
    majorAirport: { nos: "Major airports outside agglomerations" },
    majorAllSources: { nos: "Major sources outside agglomerations" },
    majorRailway: { nos: "Major railways outside agglomerations" },
    majorRoad: { nos: "Major roads outside agglomerations" },
}

export const troksniCategory = {
    Lden40: { nos: "Lden value equal to 40 dB" },
    Lden45: { nos: "Lden value equal to 45 dB" },
    Lden50: { nos: "Lden value equal to 50 dB" },
    Lden55: { nos: "Lden value equal to 55 dB" },
    Lden60: { nos: "Lden value equal to 60 dB" },
    Lden65: { nos: "Lden value equal to 65 dB" },
    Lden70: { nos: "Lden value equal to 70 dB" },
    Lden75: { nos: "Lden value equal to 75 dB" },
    Lnight40: { nos: "Lnight value equal to 40 dB" },
    Lnight45: { nos: "Lnight value equal to 45 dB" },
    Lnight50: { nos: "Lnight value equal to 50 dB" },
    Lnight55: { nos: "Lnight value equal to 55 dB" },
    Lnight60: { nos: "Lnight value equal to 60 dB" },
    Lnight65: { nos: "Lnight value equal to 65 dB" },
    Lnight70: { nos: "Lnight value equal to 70 dB" },
    Lden4044: { nos: "Lden range from 40 to 44 dB" },
    Lden4549: { nos: "Lden range from 45 to 49 dB" },
    Lden5054: { nos: "Lden range from 50 to 54 dB" },
    Lden5559: { nos: "Lden range from 55 to 59 dB" },
    Lden6064: { nos: "Lden range from 60 to 64 dB" },
    Lden6569: { nos: "Lden range from 65 to 69 dB" },
    Lden7074: { nos: "Lden range from 70 to 74 dB" },
    LdenGreaterThan75: { nos: "Lden value equal or greater than 75 dB" },
    LdenLowerThan40: { nos: "Lden value lower than 40 dB" },
    Lnight4044: { nos: "Lnight range from 40 to 44 dB" },
    Lnight4549: { nos: "Lnight range from 45 to 49 dB" },
    Lnight5054: { nos: "Lnight range from 50 to 54 dB" },
    Lnight5559: { nos: "Lnight range from 55 to 59 dB" },
    Lnight6064: { nos: "Lnight range from 60 to 64 dB" },
    Lnight6569: { nos: "Lnight range from 65 to 69 dB" },
    LnightGreaterThan70: { nos: "Lden value equal or greater than 70 dB" },
    LnightLowerThan40: { nos: "Lnight value lower than 40 dB" },
}

export const adServisaKonektors = {
    sql : { nos : "SQL"},
    gaiss : { nos : "GAISS"},
}

export const adServisaTips = {
    automātisks : { nos : "Automātisks"},
    manuāls : { nos : "Manuāls"},
} 

export const adIerakstaStatuss = {
    'nosūtīts' : { nos: 'Nosūtīts', badgeColor: 'success' },
    'izveidots' : { nos: 'Izveidots', badgeColor: 'primary' },
    'kļūda' : { nos: 'Kļūda', badgeColor: 'danger' }
}

export const adRemoteAuditServiss = {
    GAISS : { nos : "GAISS" },
    CKAN : { nos : "CKAN" }
}

export const adEksportaDatuFormats = {
    json : { nos : "JSON" },
    csv : { nos : "CSV" }
}

export const vnkPiesarnojosasVielasTips = {
    'Ūdeņu piesārņošana': { nos: 'Ūdeņu piesārņošana' },
    'Augsnes, grunts un ūdenstilpju gultnes piesārņošana': { nos: 'Augsnes, grunts un ūdenstilpju gultnes piesārņošana' }
}

export const dzeramaUdensSatecesBaseiniKategorija = {
    '0-10': { nos: '0-10' },
    '10-100': { nos: '10-100' },
    '100-1000': { nos: '100-1000' },
    '> 1000': { nos: '> 1000' }
}

const pazinojumuVeidiArr = ['Atradnes arhivēšana', 'Atradnes izveidošana', 'Dokumenta termiņš', 'Ierobežotas pieejamības beigas', 'Sistēmas process']
export const pazinojumuVeidi = pazinojumuVeidiArr.reduce((pv, i) => { pv[i] = { nos: i }; return pv }, {})

const addBadgesToTheseGroups = ['UZZINU_DOK', 'LIC_TIPI', 'UO_PERIODS']
const addBadges = (kodifGroups) => {
    addBadgesToTheseGroups.forEach(gr => {
        const kodifs = kodifGroups[gr]
        if (kodifs) {
            Object.keys(kodifs).forEach((k, i) => {
                kodifs[k].badgeColor = 'color_' + (i % 11)
            })
        }
    })
}

export const kodif = (state = {
    requested: false,
    requestedGrupas: false,
    loaded: false,
    urbumiTehniskaisStavoklis,
    atradnesVeids,
    atradnesIerakstaVeids,
    atradnesIerakstaVeidsExtended,
    atradnesSasaistesTips,
    kategorijas,
    izmainuVesturesVeids,
    personasDatuPiekluvesTips,
    izmainuVesturesStatuss,
    udensObjektaKategorija,
    virszemesUdensObjektaKategorija,
    pazemesUdensObjektaNovertejums,
    pazemesUdensObjektaTesti,
    pazemesUdensObjektaIznemumi,
    pazemesUdensObjektaSlodze,
    udensObjektaIzcelsme,
    personasTips,
    pazinojumuVeidi,
    uoKvalitate,
    lietotajiAvots,
    salidzZimes,
    baisImportsTipi,
    geofondImportsStatus,
    baisImportsTipiKval,
    baisImportsTipiPamat,
    baisPazemesImportsTipi,
    hidrogeoImportsTipi,
    ievadesStatuss,
    kimiskaMatrica,
    qlAttiecasUz,
    udens2Veids,
    udens2AiVeids,
    udens2AiTips,
    udens2AiFaktiska,
    parisVknKrasa,
    pasakumsSlodze,
    pasakumiSlodze,
    pasakumsVeids,
    pasakumsUoKategorija,
    pasakumsMerogs,
    atisKodsObjekts,
    atisKodsAizsargjosla,
    atisAizsargjoslaVeids,
    atisObjektsGeometry,
    ep_tips,
    troksniAvots,
    troksniLayer,
    troksniSource,
    troksniCategory,
    adServisaKonektors,
    adServisaTips,
    adIerakstaStatuss,
    adRemoteAuditServiss,
    adEksportaDatuFormats,
    vnkPiesarnojosasVielasTips,
    dzeramaUdensSatecesBaseiniKategorija,
    izmainuIevadesPeriods
}, { type, payload }) => {
    switch (type) {
        case 'datasource_list_list_success':
            const adDataSources = payload.reduce((acc, item) => {
                const key = item.cp;
                acc[key] = { nos: key.toUpperCase() };
                return acc;
            }, {});

            return {
                ...state,
                adDataSources,
            };
        case 'klasifikatori_list_request':
            return { ...state, requested: true }
        case 'klasifikatori_list_success':
            const kodifs = payload.reduce((byKf, i) => {
                byKf[i.kf] = byKf[i.kf] || {};
                byKf[i.kf][i.nos] = i
                if (i.q_frakcijas) {
                    i.frakcijas = i.q_frakcijas.split("%&%").map(f => ({ nos: f }))
                } else {
                    i.frakcijas = []
                }
                if (i.q1_daudzuma_merv) {
                    i.daudzuma_merv = i.q1_daudzuma_merv.split("%&%").map(f => ({ nos: f }))
                }
                if (i.q2_lic_tipi) {
                    i.lic_tipi = i.q2_lic_tipi.split("%&%").map(f => ({ nos: f }))
                }
                return byKf;
            }, {});
            addBadges(kodifs)
            return { ...state, ...kodifs, loaded: true }
        case 'klasifikatora_grupas_list_request':
            return { ...state, requestedGrupas: true }
        case 'klasifikatora_grupas_list_success':
            const kodifGrupas = {}
            payload.forEach(k => {
                kodifGrupas[k.kods] = { ...k, nos: k.nos }
            });
            return { ...state, kodifGrupas }
        default:
            return state
    }
}

const defaultListReducerForKodifikatori = helpers.createListReducer({ listViewName: 'klasifikatori_edit', actionsName: 'kodifikators' })
const defaultEditReducerForKodifikatori = helpers.createEditReducer({ editViewName: 'klasifikatori_edit', actionsName: 'kodifikators' })
export const kodifikatori = (state = helpers.listEditGetInitialState(), { type, payload, reqId }) => {
    //overwrite or add custom actions catches here

    const stateAfterList = defaultListReducerForKodifikatori({ state, type, payload, reqId })
    const stateAfterEdit = defaultEditReducerForKodifikatori({ state: stateAfterList, type, payload, reqId })
    if (type === 'klasifikatori_aizstat_delete_success') {
        return {
            ...stateAfterEdit,
            selectedItem: null
        }
    }
    return stateAfterEdit
}
