import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {getBooleanFormatter, getNumberFormatter} from "../grid/ColumnHelpers"
import {virszemesUdensObjektsEkoActions, virszemesUoEkoYearFetch} from '../../actions/virszemesUdensObjektiActions'
import FormCard from '../forms/FormCard'
import {
  Row,
} from 'reactstrap'
import {getTableRenderer, mdToGridColumns} from "./common";
import {getKlasifikatorsFieldCfg, getKodifRenderer} from "../forms/FormHelpers"
import ExportSheetsButton from "../grid/ExportSheetsButton"
import NextPrevBox from "../forms/NextPrevBox"
import ExcelJS from 'exceljs';

const getMappingFromMetadata = (metadata, name) => {
  return metadata[name].fields.map(item => ({
    name: item.name,
    label: item.label,
  }))
}

const getMappingFromFormCfgs = (formFieldCfgs, name) => {
  return formFieldCfgs
      .filter(item => item.name.includes(name))
      .map(item => ({
        name: item.name.replace(name, ""),
        label: item.label
      }));
};

const mapObjectKeys = (data, mappings) => {
  return mappings.reduce((acc, { name, label }) => {
    if (data.hasOwnProperty(name)) {
      acc[label] = data[name];
    }
    return acc;
  }, {});
};

const mapKeys = (data, formFieldCfgs, text) => {
  const mappings = getMappingFromFormCfgs(formFieldCfgs, text);
  return data.map(item => mapObjectKeys(item, mappings));
}

const getRaditajiSheet = (workbook, raditaji, metadata, uoTips) => {
  const mappings = getMappingFromMetadata(metadata, "virszemes_udens_objekts_kvalitate_ekologija_raditaji");
  const headerOrder = [
    "Apzīmējums",
    "Nosaukums",
    "Mērvienība",
    "Vidējā vērtība",
    "Augsta",
    "Laba",
    "Vidēja",
    "Slikta",
    "Ļoti slikta",
    "Kvalitātes klase",
    "Seki kvalitāte",
  ];

  const worksheet = workbook.addWorksheet("Fizikāli Ķīmiskie Parametri");

  worksheet.columns = headerOrder.map(header => ({ header, key: header, width: 20 }));

  const data = raditaji.map(row => mapObjectKeys(row, mappings));
  data.forEach(row => worksheet.addRow(row));

  const colors = {
    "1": "FF00B0F0",   // Light blue
    "2": "FF92D050",   // Light green
    "3": "FFFFFF00",   // Yellow
    "4": "FFFFC000",   // Orange
    "5": "FFFF0000",   // Red
  };

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      const apzimejumsCell = row.getCell("Apzīmējums");
      const vertibaCell = row.getCell("Vidējā vērtība");
      const kvalitateCell = row.getCell("Kvalitātes klase");
      const sekiKvalitateCell = row.getCell("Seki kvalitāte");

      const kvalitate = kvalitateCell.value;
      const sekiKvalitate = sekiKvalitateCell.value;

      if ((uoTips === "L1" || uoTips === "L3") && apzimejumsCell.value === 'Seki') {
        if (sekiKvalitate && colors[sekiKvalitate]) {
          vertibaCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors[sekiKvalitate] },
          };
        }
      } else if (kvalitate && colors[kvalitate]) {
        vertibaCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors[kvalitate] },
        };
      }
    }
  });

  const kvalitatesKlaseColumnIndex = worksheet.columns.findIndex(col => col.key === "Kvalitātes klase") + 1;
  worksheet.spliceColumns(kvalitatesKlaseColumnIndex, 2);

  return worksheet;
};

const getExcelData = (data, formFieldCfgs, metadata) => {
  const workbook = new ExcelJS.Workbook();

  const kvalitateData = mapKeys([data.kvalitate], formFieldCfgs, "kvalitate.");
  const kvalitateSheet = workbook.addWorksheet("Kvalitāte");
  kvalitateSheet.columns = Object.keys(kvalitateData[0]).map(key => ({ header: key, key, width: 20 }));
  kvalitateData.forEach(row => kvalitateSheet.addRow(row));

  getRaditajiSheet(workbook, data.raditaji, metadata, data.uo_tips);

  const biologiskieData = mapKeys([data.biologiskie], formFieldCfgs, "biologiskie.")
  const biologiskieSheet = workbook.addWorksheet("Bioloģiskie Elementi");
  biologiskieSheet.columns = Object.keys(biologiskieData[0]).map(key => ({ header: key, key, width: 20 }));
  biologiskieData.forEach(row => biologiskieSheet.addRow(row));

  const hidroData = mapKeys([data.hidro], formFieldCfgs, "hidro.")
  const hidroSheet = workbook.addWorksheet("Hidromorfoloģiskie Elementi");
  hidroSheet.columns = Object.keys(hidroData[0]).map(key => ({ header: key, key, width: 20 }));
  hidroData.forEach(row => hidroSheet.addRow(row));

  return workbook
};

const getFormFieldWrapperCfgs = () => {
  return [
    {
      id: 'kvalitate_wrap',
      comp: FormCard,
      compProps: {
        title: 'Kvalitātes klase',
        pinnable: false,
        size: 12
      },
    },
    {
      id: 'raditaji_wrap',
      comp: FormCard,
      compProps: {
        title: 'Fizikāli ķīmiskie parametri',
        pinnable: true,
        size: 12
      },
    },
    {
      id: 'biologiskie_wrap',
      comp: FormCard,
      compProps: {
        title: 'Bioloģiskie kvalitātes elementi',
        pinnable: true,
        // size: 12
      },
    },
    {
      id: 'hidro_wrap',
      comp: FormCard,
      compProps: {
        title: 'Hidromorfoloģiskie kvalitātes elementi',
        pinnable: true,
        // size: 12
      },
    },
    {
      id: 'visi_wrap',
      comp: FormCard,
      compProps: {
        title: 'Kvalitātes novērtējumā izmantotie dati',
        pinnable: true,
        size: 12
      },
    },
  ]
}

const getFormFieldCfgs = ({ metadata, kodif, uoTips }) => () => {
  const getHeaderFormatter = (level) => {
    return (column) => {
      return <><i className={`fa fa-circle kvalitate-${level}-fg`}></i> { column.text }</>
    }

  }

  const fizKimCols = [
    {
      name: 'apzimejums',
    },
    {
      name: 'nosaukums',
    },
    {
      name: 'mervieniba',
    },
    {
      name: 'vertiba',
      formatter: (val, row) => {
        return getNumberFormatter(row.precizitate)(val)
      },
      classes: (cell, row) => {
        if ((uoTips === "L1" || uoTips === "L3") && row.apzimejums === "Seki") {
          return row.seki_kvalitate ? `kvalitate-${row.seki_kvalitate}` : '';
        }
        
        if (row.kvalitate) {
          return `kvalitate-${row.kvalitate}`;
        }
        
        return '';
      }
    },
    {
      name: 'kval_augsta',
      headerFormatter: getHeaderFormatter(1)
    },
    {
      name: 'kval_laba',
      headerFormatter: getHeaderFormatter(2)
    },
    {
      name: 'kval_videja',
      headerFormatter: getHeaderFormatter(3)
    },
    {
      name: 'kval_slikta',
      headerFormatter: getHeaderFormatter(4)
    },
    {
      name: 'kval_loti_slikta',
      headerFormatter: getHeaderFormatter(5)
    },
  ]

  const visiDatiCols = [
    {
      name: 'parauga_numurs',
    },
    {
      name: 'apzimejums',
    },
    {
      name: 'nosaukums',
    },
    {
      name: 'nosaukums_aguns',
    },
    {
      name: 'vertiba',
      formatter: (val, row) => {
        return getNumberFormatter(row.precizitate)(val)
      }
    },
    {
      name: 'mervieniba',
    },
    {
      name: 'karodzini',
    },
    {
      name: 'ir_ql',
      formatter: getBooleanFormatter(false)
    },
    {
      name: 'metodika',
    },
  ]

  const getField = (field, label, wrapper) => {
    return {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: field,
      label: label,
      fieldWrapper: wrapper,
      renderer: getKodifRenderer(kodif.uoKvalitate)
    }
  }

  const getBiologiskieField = (field, label) => {
    return {
      ...getField(field, label, 'biologiskie_wrap'),
      renderer: (value) => {
        const v = Number(value)
        if (kodif.uoKvalitate[v]) {
          return kodif.uoKvalitate[v].nos
        } else if (kodif.uoKvalitate[value]) {
          return kodif.uoKvalitate[value].nos
        } else {
          return value
        }
      }
    }
  }

  const getHidroField = (field, label) => {
    return getField(field, label, 'hidro_wrap')
  }

  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska',
      label: 'Kopējā ekoloģiskā kvalitāte',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska_apr',
      editable: false,
      label: 'Kopējā ekoloģiskā kvalitāte (aprēķinātā)',
      fieldWrapper: 'kvalitate_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      name: 'raditaji',
      label: undefined,
      sizeForm: 12,
      fieldWrapper: 'raditaji_wrap',
      editable: false,
      renderer: getTableRenderer(
        mdToGridColumns('virszemes_udens_objekts_kvalitate_ekologija_raditaji', fizKimCols, metadata)
      )
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska_fiz_kim',
      label: 'Kvalitāte pēc fizikāli ķīmiskiem parametriem',
      fieldWrapper: 'raditaji_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
      name: 'kvalitate.ekologiska_fiz_kim_apr',
      editable: false,
      label: 'Kvalitāte pēc fizikāli ķīmiskiem parametriem (aprēķinātā)',
      fieldWrapper: 'raditaji_wrap',
      renderer: getKodifRenderer(kodif.uoKvalitate)
    },
    {
      ...getBiologiskieField('biologiskie.zoobentoss', 'Zoobentoss'),
    },
    {
      ...getBiologiskieField('biologiskie.fitoplanktons', 'Fitoplanktons'),
    },
    {
      ...getBiologiskieField('biologiskie.makrofiti', 'Makrofīti'),
    },
    {
      ...getBiologiskieField('biologiskie.fitobentoss', 'Fitobentoss'),
    },
    {
      ...getBiologiskieField('biologiskie.zivis', 'Zivis'),
    },
    {
      ...getBiologiskieField('biologiskie.kopvertejums', 'Bioloģijas kopvērtējums'),
    },
    {
      name: 'biologiskie.piezimes',
      label: 'Piezīmes',
      type: 'textarea',
      fieldWrapper: 'biologiskie_wrap',
    },
    {
      ...getHidroField('hidro.morf_parveidojumi', 'Morfoloģiskie pārveidojumi')
    },
    {
      ...getHidroField('hidro.hidro_rezima_izmainas', 'Hidroloģiskā režīma izmaiņas')
    },
    {
      name: 'hidro.udens_apmainas_koef',
      label: 'Ūdens apmaiņas koeficients',
      fieldWrapper: 'hidro_wrap',
      hiddenFunc: ({currentValues}) => currentValues.veids === 'ezers'
    },
    {
      ...getHidroField('hidro.nepartrauktiba', 'Nepārtrauktība'),
      hiddenFunc: ({currentValues}) => currentValues.veids === 'upe'
    },
    {
      ...getHidroField('hidro.kopvertejums', 'Hidromorfoloģijas kopvērtējums')
    },
    {
      name: 'hidro.piezimes',
      label: 'Piezīmes',
      type: 'textarea',
      fieldWrapper: 'hidro_wrap',
    },
    {
      name: 'visi_dati',
      label: undefined,
      sizeForm: 12,
      fieldWrapper: 'visi_wrap',
      editable: false,
      renderer: getTableRenderer(
        mdToGridColumns('virszemes_udens_objekts_kvalitate_ekologija_visi_dati', visiDatiCols, metadata)
      )
    },
  ]
}

class EkologijaComp extends Component {

  render() {

    const {virszemesUdensObjektsEko, user, getFormFieldCfgsFunc, kodif,
      metadata, getFormFieldWrapperCfgsFunc} = this.props

    const {selectedItem, editable, submiting} = virszemesUdensObjektsEko

    // loading while we have no virszemesUdensObjekts
    if (!selectedItem) {
      return (<Loading/>)
    }

    const onNextPrevYear = (gads) => {
      this.props.yearFetch({ kods: selectedItem.kods, gads })
    }
    const uoTips = selectedItem.uo_tips
    // we cache form field cfgs
    // reload form fields if virszemesUdensObjekts changes
    if(!this.formFieldCfgs || virszemesUdensObjektsEko.id !== this.prevUoId){
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({ metadata, kodif, uoTips })
      this.formFieldCfgs = f({
        kodif,
        user
      })
      this.prevUoId = selectedItem.id
    }

    if(!this.formFieldWrapperCfgs){
      const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs
      this.formFieldWrapperCfgs = f()
    }

    const exportBtn = <ExportSheetsButton sheetsData={getExcelData(selectedItem, this.formFieldCfgs, metadata)} gads={selectedItem.gads} />
    // const exportBtn = <ExportButton viewname={"virszemes_udens_objekts_kvalitate_ekologija"} customQuery={{f: JSON.stringify({'uo_id': selectedItem.id, 'kods': selectedItem.kods, 'gads': selectedItem.gads})}}/>

    return <div className="form-container">
      <NextPrevBox currentValue={selectedItem.gads} onChange={onNextPrevYear}></NextPrevBox>
      <Form
        editable={editable}
        submiting={submiting}
        dataObj={selectedItem}
        formFieldCfgs={this.formFieldCfgs}
        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEditAct}
        onStartEdit={this.props.startEditAct}
        formWrapperComp={Row}
        metadataFormCfg="virszemes_udens_objekts_kvalitate_ekologija"
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
        extraElements={exportBtn}
      />
    </div>
  }
}

const mapStateToProps = ({virszemesUdensObjektsEko, kodif, metadata, user}) => ({virszemesUdensObjektsEko, kodif, metadata, user})
const VirszemesEkologija =  connect(mapStateToProps, { yearFetch: virszemesUoEkoYearFetch, ...virszemesUdensObjektsEkoActions })(EkologijaComp)
export default VirszemesEkologija

