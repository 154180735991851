import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {
  raditajiQlActions, raditajsQlSelected
} from '../../actions/raditajiActions'
import {
  getTextFilterCfg,
  getFormatterForKodif, getSelectFilterCfg, dateFormatter
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'matrica',
      text: 'Matrica',
      width: 80,
      filterCfg: getSelectFilterCfg(kodif.kimiskaMatrica),
      formatter: getFormatterForKodif(kodif.kimiskaMatrica)
    }, {
      dataField: 'attiecas_uz',
      text: 'Vide',
      width: 80,
      filterCfg: getSelectFilterCfg(kodif.qlAttiecasUz),
      formatter: getFormatterForKodif(kodif.qlAttiecasUz)
    }, {
      dataField: 'raditaja_nosaukums',
      text: 'Nosaukums',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'metode',
      text: 'Metode',
      width: 200,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'vertiba',
      text: 'Vērtība',
      width: 120,
      formatter: (value, row) => {
        return [value, row.mervieniba].join(' ')
      }
    }, {
      dataField: 'datums_no',
      text: 'No',
      width: 100,
      formatter: dateFormatter
    }, {
      dataField: 'datums_lidz',
      text: 'Līdz',
      width: 100,
      formatter: dateFormatter
    }
  ]};

const  getFormFieldCfgs = ({kodif}) => {

  return [
    {
      name: 'matrica',
      type: 'radio',
      options: kodif.kimiskaMatrica,
      renderer: getFormatterForKodif(kodif.kimiskaMatrica),
      required: true
    },
    {
      name: 'attiecas_uz',
      label: 'Vide',
      type: 'radio',
      options: kodif.qlAttiecasUz,
      renderer: getFormatterForKodif(kodif.qlAttiecasUz),
      required: true
    },
    {
      name: 'raditajs_kimiska_nosaukumi_id',
      label: 'Nosaukums',
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'raditajs_kimiska_choice',
        labelFieldName: 'raditaja_nosaukums',
        keyField: 'raditajs_kimiska_nosaukumi_id'
      },
      overridePropsFunc: (props) => {
        const changedProps = {...props}
        const {currentValues} = changedProps
        if (changedProps.lastEditedFieldName === "attiecas_uz" || changedProps.lastEditedFieldName === "matrica") {
          changedProps.currentVal = null
          currentValues.raditajs_kimiska_nosaukumi_id = null
        }
        if (currentValues.matrica && currentValues.attiecas_uz){
          changedProps.extraFieldProps = {disabled : false}
          const extraFilterParams = {matrica: currentValues.matrica, attiecas_uz: currentValues.attiecas_uz}
          changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams}
        }
        else {
          changedProps.extraFieldProps = {disabled : true, extraFilterParams : null}
        }
        return changedProps
      },
      renderer: (fieldValue, dataObj) => dataObj.raditaja_nosaukums,
      required: true
    },
    {
      name: 'metode',
      type: 'text',
      required: true
    },
    {
      name: 'vertiba',
      type: 'number',
      required: true
    },
    {
      name: 'datums_no',
      type: 'date',
      required: true,
    },
    {
      name: 'datums_lidz',
      type: 'date',
      required: false,
    },
  ]};

class RaditajiQL extends Component {

  getCurrentQueryGrupas = () => {
    const {query} = this.props
    const ef = query && query.ef ? JSON.parse(query.ef) : {}
    return ef.grupas ? ef.grupas.split(',') : []
  }

  extraGridProps = {
    rowClasses: (row, rowIndex) => {
      if(row && row.ir_arhivets === true){
        return 'row-archived';
      }
    }
  }

  render() {
    const raditajiQl = this.props.raditajiQl
    if(!raditajiQl || raditajiQl.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={this.props.raditajiQl}
        formWidthGuiOption="raditaji_ql_form_width"
        metadataFormCfg="raditajs_ql_edit"
        extraGridProps={this.extraGridProps}
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
      />
    )

  }

}

const mapStateToProps = ({raditajiQl, kodif, location}) => ({raditajiQl, kodif, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : raditajsQlSelected,
  addUrlQuery,
  ...raditajiQlActions
})(RaditajiQL)
