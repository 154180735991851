import * as helpers from './helpers'

const defaultListReducerForAtradnesVesture = helpers.createListReducer({listViewName: 'audit_list_for_atradne'})
export const atradnesVesture = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAtradnesVesture({state, type, payload, reqId})
}

const defaultListReducerForAudits = helpers.createListReducer({listViewName: 'audit_list'})
export const audits = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAudits({state, type, payload, reqId})
}

const defaultListReducerForAtradnesDalaVesture = helpers.createListReducer({listViewName: 'audit_list_for_atradnes_dala'})
export const atradnesDalasVesture = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAtradnesDalaVesture({state, type, payload, reqId})
}

const defaultListReducerForAuditsAtradnesAtskaite = helpers.createListReducer({listViewName: 'audit_stats_by_atradne'})
export const auditStatsByAtradne = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAuditsAtradnesAtskaite({state, type, payload, reqId})
}

const defaultListReducerForPersonasDatuAudits = helpers.createListReducer({listViewName: 'personas_datu_audit_list'})
export const personasDatuAudits = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForPersonasDatuAudits({state, type, payload, reqId})
}