import { crudCall, exportOds } from '../api'
import * as maputils from '../components/karte/maputils'
import { redirect, push } from 'redux-first-router'
import { CURRENT_SUBAPP, getSakumsLocation } from '../routes'

let reqCounter = 0
export const callApi = ({
    viewName,
    apiMethod,
    apiArgs,
    crudMethod,
    crudParam,
    success,
    failure,
    resultTransformator
}) => (dispatch, getState) => {
    if (!apiMethod && !crudMethod) throw new Error('either apiMethod or crudMethod should be defined')

    if (crudParam && crudParam.exportOds === true) {
        const { type, query, payload } = getState().location
        const newQuery = { ...query, exportOds: undefined }
        dispatch(redirect({ type, payload, query: newQuery }))
        return exportOds({ viewName, param: crudParam })
    }

    reqCounter++
    const reqId = reqCounter

    const actionType = crudMethod ? `${viewName}_${crudMethod}` : viewName

    dispatch({
        type: `${actionType}_request`,
        reqId
    })

    const successCallback = ((result) => {
        let res = result
        if (resultTransformator) {
            res = resultTransformator(result)
        }
        dispatch({
            type: `${actionType}_success`,
            payload: res,
            reqId
        });
        if (success) {
            return success(res, reqId)
        }
    })
    const failureCallback = ((error) => {
        // if we recieve 401 and we have user it means our session has timed out
        // we can show login screen but all the state will stay and what if different user comes in...
        if (error && error.status && error.status === 401) {
            const { user, location } = getState()
            if (user && user.id) {
                const path = location.pathname ? location.pathname : '/'
                window.location = path
            }
        }
        dispatch({
            type: `${actionType}_failure`,
            error,
            reqId
        })
        if (failure) {
            return failure(error, reqId, dispatch, getState)
        }
    })

    if (crudMethod) {
        return crudCall({ viewName, method: crudMethod, param: crudParam })
            .then(successCallback)
            .catch(failureCallback)
    } else {
        const wrapArgs = (Array.isArray(apiArgs)) ? apiArgs : [apiArgs]
        return apiMethod(...wrapArgs)
            .then(successCallback)
            .catch(failureCallback)
    }

}

const getPrevLocationAction = (state) => {
    const { prev } = state.location
    const type = prev && prev.type ? prev.type : getSakumsLocation()
    const payload = prev && prev.payload ? prev.payload : {}
    const query = prev && prev.query ? prev.query : {}
    return { type, payload, query }
}

export const redirectToPrevLocation = (dispatch, getState) => {
    dispatch(redirect(getPrevLocationAction(getState())))
}

export const redirectToPrevLocationForced = (dispatch, getState) => {
    const action = getPrevLocationAction(getState())
    action.payload = { ...action.payload, forcedAtTime: (new Date()).getTime() }
    dispatch(redirect(action))
}

export const searchAction = (query, typeahead, remoteSearchViewName, extraFilterParams, limit = 20) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: remoteSearchViewName,
        crudMethod: 'search',
        crudParam: {
            filter: JSON.stringify({ query, ...extraFilterParams }),
            limit
        },
        success: typeahead.onRemoteSuccess,
        failure: typeahead.onRemoteFailure,
    }))
}

//combinedPage - page contains other items (e.g. grid), when not combined page gets redirected to prev location
export const getDefaultEditActions = ({
    actionTypeName,
    stateName,
    apiViewName,
    combinedPage = false,
    resultTransformator,
    afterDelete,
    valuesTransformator,
    afterSave,
    afterSaveFailure,
    hasKoordsField = false,
    geomToJson,
    childGeomEditCollection,
    fetchArgsToCrudParams = (({ id }) => id),
    createActArgsToCrudParams,
    overridedActions = {},
    geomType
}) => {
    const startEditAct = overridedActions.startEditAct || (() => (dispatch, getState) => {
        dispatch({ type: `${actionTypeName}_edit` })
    })
    const stopEditAct = overridedActions.stopEditAct || (() => (dispatch, getState) => {
        //go to prev location if new item
        if (!combinedPage && getState()[stateName].selectedItem.id == null) {
            return dispatch(redirectToPrevLocation)
        }
        return dispatch({ type: `${actionTypeName}_stop_edit` })
    })
    const createAct = overridedActions.createAct || ((argsObj) => (dispatch, getState) => {
        const apiArgs = {
            viewName: apiViewName,
            crudMethod: 'new',
            resultTransformator: resultTransformator
        }
        if (createActArgsToCrudParams) {
            apiArgs.crudParam = createActArgsToCrudParams(argsObj)
        }
        return dispatch(callApi(apiArgs))
    })
    const deleteAct = overridedActions.deleteAct || ((id) => (dispatch, getState) => {
        const success = () => {
            if (!combinedPage) {
                dispatch(redirectToPrevLocationForced)
            }
            if (afterDelete) {
                afterDelete(dispatch, getState)
            }
        }
        const crudParam = getState()[stateName].selectedItem ? getState()[stateName].selectedItem.id : id
        return dispatch(callApi({
            viewName: apiViewName,
            crudMethod: 'delete',
            crudParam,
            success
        }))
    })
    const saveAct = overridedActions.saveAct || ((values) => (dispatch, getState) => {
        const savingNew = !values.id
        const success = (result) => {
            if (afterSave) {
                afterSave(dispatch, getState, result, savingNew)
            } else if (savingNew) {
                const { type, payload, query } = getState().location
                dispatch(redirect({
                    type,
                    payload: payload ? { ...payload, id: result.id } : { id: result.id },
                    query
                }))
            }
        }
        let crudParam = values
        if (hasKoordsField) {
            const defaultGeomToJson = (koords, newGeom) => {
                if (koords && koords.length > 0) {
                    return maputils.GeoJSONFormat.writeGeometry(maputils.fromKoordArrToOlGeom(koords, false, geomType))
                } else if (newGeom) {
                    return maputils.GeoJSONFormat.writeGeometry(newGeom)
                } else {
                    return null
                }
            }

            const { koords, newGeom } = getState()[stateName]
            crudParam.geom = (geomToJson || defaultGeomToJson)(koords, newGeom)
        }
        if (childGeomEditCollection) {
            const { childKoords } = getState()[stateName]
            const childrenFromForm = crudParam[childGeomEditCollection]
            if (childKoords && childrenFromForm) {
                childrenFromForm.forEach(ch => {
                    const foundKoords = childKoords.find(ck => (ch.id && ch.id === ck.id) || (ch.key && ch.key === ck.key))
                    if (foundKoords) {
                        if (foundKoords.koords && foundKoords.koords.length > 0) {
                            ch.geom = maputils.GeoJSONFormat.writeGeometry(maputils.fromKoordArrToOlGeom(foundKoords.koords, false, geomType))
                        } else {
                            ch.geom = null
                        }
                    }
                })
            }
        }
        if (valuesTransformator) {
            crudParam = valuesTransformator(values, getState)
        }
        return dispatch(callApi({
            viewName: apiViewName,
            crudMethod: 'save',
            crudParam,
            success,
            failure: afterSaveFailure ? afterSaveFailure : undefined,
            resultTransformator: resultTransformator
        }))
    })
    const fetchAct = overridedActions.fetchAct || ((argsObj) => (dispatch, getState) => {
        if (argsObj && (argsObj['id'] === 'jauns' || argsObj['numurs'] === 'jauns' || argsObj['kods'] === 'jauns')) {
            return dispatch(createAct(argsObj))
        } else {
            return dispatch(callApi({
                viewName: apiViewName,
                crudMethod: 'get',
                crudParam: fetchArgsToCrudParams(argsObj, getState),
                resultTransformator: resultTransformator
            }))
        }
    })
    let allActions = { startEditAct, stopEditAct, createAct, deleteAct, saveAct, fetchAct }
    if (hasKoordsField || childGeomEditCollection) {
        const koordSelectAct = (id) => {
            return { type: `${actionTypeName}_koord_selected`, payload: id }
        }
        const koordChangeAct = (koord) => {
            return { type: `${actionTypeName}_koord_changed`, payload: koord }
        }
        const koordDeleteAct = (koord) => {
            return { type: `${actionTypeName}_koord_deleted`, payload: koord }
        }
        allActions = { ...allActions, koordSelectAct, koordChangeAct, koordDeleteAct }
    }
    return allActions
}

// if we redirect without location link, prev url is not automatically stored in browser history
export const pushUrlToBrowserHistory = (getState) => {
    //remember prev url
    const { location } = getState()
    let url = location.pathname
    if (location.search) {
        url = url + '?' + location.search
    }
    push(url)
}

export const addExtraFilterToQuery = (query, mapSize) => {
    const modQuery = { ...query }
    if (modQuery && (modQuery.b === 't' || !!modQuery.ef)) {
        const otherFilters = modQuery.f ? JSON.parse(modQuery.f) : {}
        const extraFilter = modQuery.ef ? JSON.parse(modQuery.ef) : {}
        //add filters for bbox, if enabled
        if (mapSize && modQuery.b === 't' && modQuery.x && modQuery.y) {
            const { x, y, z } = modQuery
            // get x, y, z and current map size
            const bbox = maputils.getExtentFromXYZAndMapSize(x, y, z, mapSize)
            extraFilter.xmin = bbox[0]
            extraFilter.ymin = bbox[1]
            extraFilter.xmax = bbox[2]
            extraFilter.ymax = bbox[3]
        }
        modQuery.f = JSON.stringify({ ...otherFilters, ...extraFilter })
    }
    return modQuery
}

export const getDateCrudParams = (query) => {
    let crudParam = query
    if (query && (query.pno || query.plidz)) {
        const filters = query.f ? JSON.parse(query.f) : {}
        if (query.pno) {
            filters.pno = query.pno
        }
        if (query.plidz) {
            filters.plidz = query.plidz
        }
        crudParam = { ...query, f: JSON.stringify(filters) }
    }
    return crudParam
}

export const injectActionQueryParameter = (query, inject_arr) => {

    // injicē procesa_id parametros, lai vienmēr tiktu passots,
    // arī izmantojot tabulas automātiskos filtrus

    // actionā,
    // - return dispatch(callpi({ ..., crudParam: query }))
    // + return dispatch(callpi({ ..., crudParam: injectActionQueryParameter(query, { extra: param }) }))


    const newQuery = { ...query }
    if (newQuery.f) {
        newQuery.f = JSON.stringify({ ...JSON.parse(newQuery.f), ...inject_arr })
    }
    newQuery.params = { ...newQuery.params, ...inject_arr }

    return newQuery

}

export const childGeomSelectAct = (payload) => {
    return { type: 'child_geom_selected', payload }
}

export const getCurrentSupApp = (location) => {
    if (CURRENT_SUBAPP) {
        return CURRENT_SUBAPP
    }
    if (location && location.routesMap && location.type) {
        const route = location.routesMap[location.type]
        if (route) {
            return route.subApp
        }
    }
    return undefined
}