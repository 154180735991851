import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg, 
    getNumberFilterCfg, 
    getEditIconColumn,
    getBooleanFormatter, 
    getBooleanSelectFilterCfg,
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {urbumuAtradneSelected, urbumuAtradneDoubleSelected} from '../../actions/hidrogeoActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import { ATRADNES_PAZEMES_UDENU_OBJEKTI_NOT_FOUND } from '../../constants';

const editIconColumn = getEditIconColumn((row) => (
    {   
        type: 'loc_urbumu_atradne',
        payload: {
            id: row.id
        }
    }
    )
)

const getColumns = ({kodif}) => {
  return [editIconColumn,
    {
        dataField: 'id',
        text: 'DB numurs',
        width: 100,
        filterCfg: getTextFilterCfg()
    },{
        dataField: 'nosaukums',
        text: 'Nosaukums',
        filterCfg: getTextFilterCfg()
    },{
        dataField: 'pazemes_udenu_objekti',
        text: 'Pazemes ūdeņu objekti',
        filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
                remoteSearchViewName: 'urbumi_atradnes_pazemes_udenu_objekti_search'
            }
        },
        formatter: (puo) => puo === ATRADNES_PAZEMES_UDENU_OBJEKTI_NOT_FOUND ? '-' : puo
    },{
        dataField: 'pazemes_udenu_veidi',
        text: 'Pazemes ūdeņu veidi',
        filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
                remoteSearchViewName: 'urbumi_atradnes_pazemes_udenu_veidi_distinct_search'
            }
        }
    },{
        dataField: 'krajspek',
        text: 'Krājumi spēkā',
        width: 120,
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg()
    }, {
        dataField: 'merkis',
        text: 'Mērķis',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'udizm_kods',
        text: 'Ūdens izmantošana',
        width: 230,
        formatter: (kods, row) => kods !== '-1' ? `${kods} - ${row.udizm_nos}` : null,
        filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
                remoteSearchViewName: 'urbumi_udizm_search'
            }
        }
    }, {
        dataField: 'atr_vieta',
        text: 'Atrašanās vieta',
        filterCfg: getTextFilterCfg()
    },{
        dataField: 'prog_udens_paterins',
        text: 'Progn. patēriņš, m3/dnn',
        width: 180,
        filterCfg: getNumberFilterCfg()
    }, {
        dataField: 'prog_udens_paterins_lidz',
        text: 'līdz gadam',
        width: 120,
        filterCfg: getNumberFilterCfg()
    }
]};


class UrbumuAtradnesSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('urbumu_atradnes_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            this.props.urbumuAtradneDoubleSelected(row.id)
        }
    }

    render() {

        const {kodif, urbumuAtradnes, gui, urbumuAtradneSelected} = this.props
        const {list, selected, loading, count} = urbumuAtradnes

        const vectorLayerCfgs = {
            atradnes : {
                title: 'Atradnes',
                features: list,
                styles: maputils.urbumiStyles,
                tooltipFunc: (feat) => `${feat.get('id')} - ${feat.get('nosaukums') ? feat.get('nosaukums') : ''}`,
                selected: selected
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.urbumu_atradnes_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="urbumu_atradnes_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid 
                    onRowSelected={urbumuAtradneSelected} 
                    loading={loading}    
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns} 
                    rowEvents={this.rowEvents}
                >
                <BBoxFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, urbumuAtradnes, gui}) => {
    return {kodif, urbumuAtradnes, gui}
}

export default connect(mapStateToProps, {urbumuAtradneSelected, urbumuAtradneDoubleSelected, guiSet})(UrbumuAtradnesSaraksts)