import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {getDefaultRendererForMultiField, getKlasifikatorsFieldCfg} from '../forms/FormHelpers'
import {getNumberFormatter, getFormatterForKodif } from '../grid/ColumnHelpers'
import {pazemesUdensObjektiActions} from '../../actions/pazemesUdensObjektiActions'

class PamatinformacijaComp extends Component {
    getFormFieldCfgs = ({kodif}) => {
        return [
            {
                name: 'kods',
                renderClassName: "bold",
            },
            {
                name: 'nosaukums',
                renderClassName: "bold"
            }, {
                ...getKlasifikatorsFieldCfg(kodif, 'PAZEMES_UO_TIPS'),
                name: 'uo_tips',
            },
            {
                name: 'platiba',
                renderer: getNumberFormatter()
            },
            {
                name: 'upju_baseinu_apgabali',
                label: 'Upju baseina apgabals',
                type: 'autocomplete',
                autocompleteCfg: {
                    multiple: true,
                    keyField: 'upju_baseinu_apgabals_id',
                    initialOptionsViewName: 'upju_baseinu_apgabali_search',
                    extraFilterParams : {},
                    isValueObjStructure: true
                },
                renderer: getDefaultRendererForMultiField('tah_label')
            },
            {
                name: 'paskaidrojumi',
                type: 'textarea',
                fieldEditable: true,

            },
            {
                name: 'kvant_stavoklis',
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaNovertejums'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaNovertejums),
            },
            {
                name: 'kim_stavoklis',
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaNovertejums'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaNovertejums),
            }
        ]}

    render() {
        const {selectedItem:pazemesUdensObjekts, editable, submiting} = this.props.pazemesUdensObjekts

        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, kodif, metadataFormCfg} = this.props

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : this.getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif
            })
        }
        return <div className="form-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={pazemesUdensObjekts}
                        formFieldCfgs={this.formFieldCfgs}
                        metadataFormCfg={metadataFormCfg}
                        onSubmit={this.props.save}
                        onReset={this.props.stopEdit}
                        onStartEdit={this.props.startEdit}
                        onDelete={user.tiesibas.includes('BAIS_DZEST') ? this.props.delete : null}
                        tiesibasLabot="BAIS_PU_LABOT"
                    />
            </div>
    }
}

// connect PazemesPamatinformacija with pazemesUdensObjekts state
const mapStateToProps = ({pazemesUdensObjekts, kodif, user}) => ({pazemesUdensObjekts, kodif, user, metadataFormCfg: "pazemes_udens_objekts_view"})
const PazemesPamatinformacija =  connect(mapStateToProps, {
  startEdit:pazemesUdensObjektiActions.startEditAct,
  stopEdit:pazemesUdensObjektiActions.stopEditAct,
  delete:pazemesUdensObjektiActions.deleteAct,
  save:pazemesUdensObjektiActions.saveAct
})(PamatinformacijaComp)
export default PazemesPamatinformacija
