import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg,
    getEditIconColumn,
    getDateFilterCfg,
    dateTimeFormatter
} from '../grid/ColumnHelpers'
import {Button} from 'reactstrap';

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';
import Link from 'redux-first-router-link';

import {guiSet} from '../../actions/uiActions'
import {vnkIesniegumiSelected, vnkIesniegumiDoubleSelected} from '../../actions/vnkActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import ArchivedFilterButtonToUrl from '../grid/ArchivedFilterButtonToUrl'

const editIconColumn = getEditIconColumn((row) => (
        {
            type: 'loc_vnk_iesniegums',
            payload: {
                id: row.id
            }
        }
    )
)

const getColumns = ({metadata, kodif}) => {
    return [
        editIconColumn,
    {
        dataField: 'id',
        text: 'Nr',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'sniedzejs',
        text: 'Iesniedzējs',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'sniedzeja_amats',
        text: 'Iesniedzēja amats',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'iesniegsanas_datums',
        text: 'Iesniegšanas datums',
        formatter: dateTimeFormatter,
        filterCfg: getDateFilterCfg()
    }, {
        dataField: 'vietas_adrese',
        text: 'Adrese',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'op_reg_num',
        text: 'Operatora reģistrācijas numurs',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'op_nosaukums',
        text: 'Operators',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'op_darbibas_veids',
        text: 'Darbības veids',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'kait_veidi',
        text: 'Kaitējuma veids',
        filterCfg: getTextFilterCfg()
    }]
};

class VnkSaraksts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSearch: false
        };
    }

    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    }

    mapPaneSizeChange = (h) => {
        this.props.guiSet('vnk_iesniegumi_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            this.props.vnkIesniegumiDoubleSelected(row.id)
        }
    }

    render() {

        const vectorLayerCfgs = {
            vnkIesniegumi : {
                title: 'VNK iesniegumi',
                features: this.props.vnkIesniegumi.list,
                styles: maputils.pointStyles,
                epsgCode: 3059,
                tooltipFunc: (feat) => `Nr: ${feat.get('id')}`,
                selected: this.props.vnkIesniegumi.selected,
                legend: maputils.defaultSinglePointLegend
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                metadata: this.props.metadata,
                kodif: this.props.kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={this.props.gui.vnk_iesniegumi_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <Karte mapId="vnk_iesniegumi_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={this.props.vnkIesniegumiSelected}
                    loading={this.props.vnkIesniegumi.loading}
                    selectedId={this.props.vnkIesniegumi.selected}
                    dataList={this.props.vnkIesniegumi.list}
                    totalCount={this.props.vnkIesniegumi.count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >

                    { this.props.user.tiesibas.includes('VNK_LABOT') ? (
                        <Link to={{ type: 'loc_vnk_iesniegums',
                            payload: {
                                id: 'jauns'
                            }
                        }}>
                            <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                        </Link>
                    ) : null }
                    <BBoxFilterButtonToUrl/>
                    <ArchivedFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, metadata, vnkIesniegumi, gui, user}) => {
    return {kodif, metadata, vnkIesniegumi, gui, user}
}

export default connect(mapStateToProps, {vnkIesniegumiSelected, guiSet, vnkIesniegumiDoubleSelected, metadataFormCfg: "videi_nodaritie_kaitejumi_list"})(VnkSaraksts)