import {
  callApi, getDefaultEditActions,
  pushUrlToBrowserHistory
} from './helpers'
import {
  redirect
} from 'redux-first-router'


export const pasakumi = (query) => (dispatch, getState) => {
  const filters = query?.f ? JSON.parse(query?.f) : {}
  filters.path = getState().location.pathname
  if (filters.path === '/pazemes-udens/pasakumi') {
    filters.uo_kategorija = 'pazemes'
  }
  if (filters.path === '/virszemes-udens/pasakumi') {
    filters.uo_kategorija = ['ezeri', 'upes', 'upes_ezeri_pazemes', 'piekrastes', 'upes_ezeri']
  }
  return dispatch(callApi({
    viewName: 'pasakumi_list',
    crudMethod: 'listPaging',
    crudParam: {...query, f: JSON.stringify(filters)}
  }))
}

export const pasakumsSelected = (id) => {
  return {
    type: 'pasakumi_selected',
    payload: id
  }
}

export const pasakumsVirszemesDoubleSelected = ({ id, uo_kods }) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_virszemes_pasakums',
    payload: {
      id,
      kods: uo_kods
    }
  }))
}

export const pasakumsVirszemesActions = getDefaultEditActions({
  actionTypeName: 'pasakums',
  stateName: 'pasakums',
  apiViewName: 'pasakums_view',
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({
      type: 'pasakums_clear'
    })
    dispatch(redirect({
      type: 'loc_virszemes_pasakums',
      payload: {
        id: result.id,
        kods: result.uo_kods
      }
    }))
  },
  createActArgsToCrudParams: ({ kods }) => {
    return {
      uo_kods: kods
    }
  }
})

export const pasakumsPazemesDoubleSelected = ({ id, uo_kods }) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_pazemes_pasakums',
    payload: {
      id,
      kods: uo_kods
    }
  }))
}

export const pasakumsPazemesActions = getDefaultEditActions({
  actionTypeName: 'pasakums',
  stateName: 'pasakums',
  apiViewName: 'pasakums_view',
  afterSave: (dispatch, result) => {
    dispatch({
      type: 'pasakums_clear'
    })
    dispatch(redirect({
      type: 'loc_pazemes_pasakums',
      payload: {
        id: result.id,
        kods: result.uo_kods
      }
    }))
  },
  createActArgsToCrudParams: ({ kods }) => {
    return {
      uo_kods: kods
    }
  }
})

export const loadPasakumuKlasif = (query) => (dispatch, getState) => {
  const filters = query?.f ? JSON.parse(query?.f) : {}
  const path = getState().location.pathname
  if (path === '/pazemes-udens/pasakumu-klasifikators') {
    filters.uo_kategorija = 'pazemes'
  }
  if (path === '/virszemes-udens/pasakumu-klasifikators') {
    filters.uo_kategorija = ['ezeri', 'upes', 'upes_ezeri_pazemes', 'piekrastes', 'upes_ezeri']
  }
  return dispatch(callApi({
    viewName: 'pasakumu_klasifikators_edit',
    crudMethod: 'listPaging',
    crudParam: {...query, f: JSON.stringify(filters)}
  }))
}

export const pasakumuKlasifSelected = (id) => {
  return {
    type: 'pasakumuKlasif_selected',
    payload: id
  }
}

const afterSaveOrDelete = (dispatch, getState) => {
  dispatch(loadPasakumuKlasif(getState().location.query))
}

export const pasakumuKlasifActions = getDefaultEditActions({
  actionTypeName: 'pasakumuKlasif',
  stateName: 'pasakumuKlasif',
  combinedPage: true,
  apiViewName: 'pasakumu_klasifikators_edit',
  afterDelete: afterSaveOrDelete,
  afterSave: afterSaveOrDelete,
})
