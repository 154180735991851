import {
    callApi,
    pushUrlToBrowserHistory,
    addExtraFilterToQuery,
    getDefaultEditActions
} from './helpers'
import {
    redirect
} from 'redux-first-router'


export const vnkIesniegumi = (query) => (dispatch, getState) => {
    const mapSize = getState().gui['vnk_iesniegumi_saraksts_karte_size']
    return dispatch(callApi({
        viewName: 'videi_nodaritie_kaitejumi_list',
        crudMethod: 'listPaging',
        crudParam: addExtraFilterToQuery(query, mapSize)
    }))
}

export const vnkIesniegumiSelected = (id) => {
    return {
        type: 'vnk_iesniegums_selected',
        payload: id
    }
}

export const vnkIesniegumiDoubleSelected = (id) => (dispatch, getState) => {
    pushUrlToBrowserHistory(getState)
    dispatch(redirect({
        type: 'loc_vnk_iesniegums',
        payload: {
            id: id
        }
    }))
}

export const vnkIesniegumsActions = getDefaultEditActions({
    actionTypeName: 'vnk_iesniegums',
    stateName: 'vnkIesniegums',
    apiViewName: 'videi_nodaritie_kaitejumi_view',
    hasKoordsField: true,
    // combinedPage: true,
    afterSave: (dispatch, getState, result) => {
        dispatch({
            type: 'vnk_iesniegums_clear'
        })
        dispatch(redirect({
            type: 'loc_vnk_iesniegums',
            payload: {
                id: result.id
            }
        }))
    }
})