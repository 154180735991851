import React, {Component} from 'react';
import {connect} from 'react-redux'

import Loading from '../Loading'
import {
  // Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from 'reactstrap';

import SplitterLayout from 'react-splitter-layout';

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'
import {guiSet, addUrlQuery} from '../../actions/uiActions'
import VirszemesPamatinformacija from './VirszemesPamatinformacija'
import VirszemesAtrasanas from './VirszemesAtrasanas'
import VirszemesPasakumi from './VirszemesPasakumi'
import VirszemesEkologija from "./VirszemesEkologija"
import NotFound from '../NotFound'
import VirszemesKimiska from "./VirszemesKimiska";
import VirszemesRiski from "./VirszemesRiski";
import VirszemesSlodzes from "./VirszemesSlodzes";

const getTabCfgs = () => {
  return {
    pamatinfo: {
      title: 'Pamatinformācija',
      comp: VirszemesPamatinformacija
    },
    atrasanas: {
      title: 'Atrašanās vieta',
      comp: VirszemesAtrasanas
    },
    ekologija: {
      title: 'Ekoloģiskā kvalitāte',
      comp: VirszemesEkologija
    },
    kimija: {
      title: 'Ķīmiskā kvalitāte',
      comp: VirszemesKimiska
    },
    slodzes: {
      title: 'Slodžu būtiskums',
      comp: VirszemesSlodzes
    },
    riski: {
      title: 'Risks un izņēmumi',
      comp: VirszemesRiski
    },
    pasakumi: {
      title: 'Pasākumi',
      comp: VirszemesPasakumi
    }
  }
}

//create vector layers for udens_objektss kartina
const getVectorLayerCfgs = ({virszemesUdensObjekts, virszemesUdensObjektsAtrasanas, activeTab}) => {
  let cfg = {}
  if (!!virszemesUdensObjekts.id) {
    cfg.virszemesUdensObjekts = {
      features: virszemesUdensObjekts,
      title: 'Ūdens objekts',
      styles: maputils.uoStyles.uoStyle,
      opacity: 0.9,
      tooltipFunc: undefined,
      zoomTo: virszemesUdensObjekts.id,
      legend: maputils.defaultSinglePolygonLegend
    }

    if (virszemesUdensObjekts.sateces_baseins) {
      cfg.sateces_baseins = {
        features: virszemesUdensObjekts.sateces_baseins,
        title: 'Sateces baseins',
        styles: maputils.uoStyles.usikStyle,
        opacity: 0.9,
        tooltipFunc: undefined,
        legend: maputils.defaultSinglePolygonLegend
      }
    }

    if (activeTab === 'atrasanas' && virszemesUdensObjektsAtrasanas && virszemesUdensObjektsAtrasanas.selectedItem) {
      if (virszemesUdensObjektsAtrasanas.selectedItem.usik) {
        cfg.usik = {
          features: virszemesUdensObjektsAtrasanas.selectedItem.usik,
          title: 'ŪSIK',
          styles: maputils.uoStyles.usikStyle,
          opacity: 0.9,
          tooltipFunc: undefined,
          layerProps: {
            visible: false
          },
          legend: maputils.defaultSinglePolygonLegend
        }
      }
      if (virszemesUdensObjektsAtrasanas.selectedItem.iadt) {
        cfg.iadt = {
          features: virszemesUdensObjektsAtrasanas.selectedItem.iadt,
          title: 'Īpaši aizsargājamas dabas teritorijas',
          styles: maputils.uoStyles.usikStyle,
          opacity: 0.9,
          tooltipFunc: (feat) => feat.get('iadt_nosaukums'),
          layerProps: {
            visible: false
          },
          legend: maputils.defaultSinglePolygonLegend
        }
      }
    }
  }
  return cfg
}

class UdensObjektsActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return <div className="actionButtons">
      {/*<Button className="float-right" color="secondary" onClick={this.toggleModal}><i className="fa fa-print"></i>&nbsp;&nbsp;Eksportēt</Button>*/}

      {/* <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="modal-lg">
                <ModalHeader toggle={this.toggleModal}>UdensObjektss eksports</ModalHeader>
                <ModalBody>
                  Te būs udens_objektss eksports, piemēram, uz PDF.
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleModal}>Aizvērt</Button>
                </ModalFooter>
              </Modal> */}
    </div>
  }
}

class UdensObjektsTabs extends Component {

  renderTabNavLinks = (activeTab, isNew) => {
    return Object.keys(this.tabCfgs).map(k => (
      <NavItem key={k}>
        <NavLink className={k === activeTab ? 'active' : ''} disabled={isNew && this.tabCfgs[k].disableWhenNew}
                 onClick={() => this.props.addUrlQuery({tab: k === 'pamatinfo' ? undefined : k})}>
          {this.tabCfgs[k].title}
        </NavLink>
      </NavItem>
    ))
  }

  renderTabContents = (activeTab, isNew) => {
    return Object.keys(this.tabCfgs).map(k => {
        const Comp = this.tabCfgs[k].comp ? this.tabCfgs[k].comp : NotFound
        return <TabPane key={k} tabId={k}>
          <Comp/>
        </TabPane>
      }
    )
  }

  render() {
    const {activeTab, isNew, getTabCfgsFunc} = this.props
    // we cache tabs
    if (!this.tabCfgs) {
      const f = getTabCfgsFunc ? getTabCfgsFunc : getTabCfgs
      this.tabCfgs = f()
    }
    return <>
      <Nav tabs>
        {this.renderTabNavLinks(activeTab, isNew)}
      </Nav>
      <TabContent activeTab={activeTab}>
        {this.renderTabContents(activeTab, isNew)}
      </TabContent>
    </>
  }
}

export class UdensObjektsComp extends Component {

  mapPaneSizeChange = (w) => {
    this.props.guiSet('udens_objekts_karte_width', w);
  }

  mapPaneSizeChangeHor = (h) => {
    this.props.guiSet('udens_objekts_karte_hor_height', h);
  }

  render() {

    const {selectedItem: virszemesUdensObjekts} = this.props.virszemesUdensObjekts

    // loading while we have no virszemesUdensObjekts
    if (!virszemesUdensObjekts) {
      return (<Loading/>)
    }

    // initial cfgs can come in as props, or we can create default ones
    const {
      query, getVectorLayerCfgsFunc, getTabCfgsFunc, mapId, addUrlQuery,
      virszemesUdensObjektsAtrasanas
    } = this.props

    const isNew = !virszemesUdensObjekts.id
    const activeTab = query && query.tab && !isNew ? query.tab : 'pamatinfo'

    const f = getVectorLayerCfgsFunc ? getVectorLayerCfgsFunc : getVectorLayerCfgs
    const vectorLayerCfgs = f({virszemesUdensObjekts, virszemesUdensObjektsAtrasanas, activeTab})

    return (<>
        {activeTab === 'pasakumi' || activeTab === 'ekologija' || activeTab === 'kimija' || activeTab === 'izmainas' || activeTab === 'vesture' ?
          <div className="leftside-single">
            <UdensObjektsActionButtons/>
            <UdensObjektsTabs activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc}
                              addUrlQuery={addUrlQuery}/>
          </div>
          : activeTab === 'something horizontal?' ?
            <SplitterLayout
              key="hor"
              vertical
              percentage
              primaryIndex={0}
              secondaryInitialSize={this.props.gui.udens_objekts_karte_hor_height}
              onSecondaryPaneSizeChange={this.mapPaneSizeChangeHor}>
              <div className="topside">
                <UdensObjektsActionButtons/>
                <UdensObjektsTabs activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc}
                                  addUrlQuery={addUrlQuery}/>
              </div>
              <Karte mapId={mapId ? mapId : 'udens_objekts_kartina'} vectorLayerCfgs={vectorLayerCfgs}/>
            </SplitterLayout>
            :
            <SplitterLayout
              key="ver"
              customClassName="pane-paddings-hor"
              percentage
              primaryIndex={0}
              secondaryInitialSize={this.props.gui.udens_objekts_karte_width}
              onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

              <div className="leftside">
                <UdensObjektsTabs activeTab={activeTab} isNew={isNew} getTabCfgsFunc={getTabCfgsFunc}
                                  addUrlQuery={addUrlQuery}/>
              </div>

              <div className="rightside">
                <UdensObjektsActionButtons/>
                <div className="karte-wrapper">
                  <Karte mapId={mapId ? mapId : 'udens_objekts_kartina'} vectorLayerCfgs={vectorLayerCfgs}/>
                </div>
              </div>
            </SplitterLayout>
        }
      </>

    )
  }
}

//connect udens_objektss componente with virszemesUdensObjekts state
const mapStateToProps = ({virszemesUdensObjekts, virszemesUdensObjektsAtrasanas, gui, location}) => ({
  virszemesUdensObjekts,
  virszemesUdensObjektsAtrasanas,
  gui,
  query: location.query
})

const VirszemesUdensObjekts = connect(mapStateToProps, {addUrlQuery, guiSet})(UdensObjektsComp)
export default VirszemesUdensObjekts
