import React, {Component} from 'react';
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
  getTextFilterCfg, 
  getDateFilterCfg, 
  dateFormatter,
  getSelectFilterCfgForViewsFromMetadata
} from '../grid/ColumnHelpers'
import {getFileHref} from '../forms/FormHelpers'
import {getLinkFromViewname} from '../../routes'
import {AtradneOrDalaLink} from '../dokumentacija/common'

const getColumns = ({kodif, metadata}) => {
  return [
      {
        dataField: 'viewname',
        text: 'Sadaļa',
        filterCfg: getSelectFilterCfgForViewsFromMetadata(metadata, true),
        formatter: (viewname, row) => getLinkFromViewname({
            viewname, 
            entity_id: row.entity_id, 
            metadata, 
            atr_numurs: row.atradne ? row.atradne.numurs : null, 
            atr_id: row.atradne ? row.atradne.id : null, 
        }),
        width: 110,
    }, {
        dataField: 'atradnes',
        text: 'Saistītā atradne vai daļa',
        formatter: (atradnes) => atradnes.map(atr => <AtradneOrDalaLink
            id={atr.id}
            numurs={atr.numurs}
            ieraksta_veids={atr.ieraksta_veids}
            nosaukums={atr.tah_label}
            kodif={kodif}/>),
        filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
                remoteSearchViewName: 'atradne_search'
            }
        },
        sort: false,
        width: 200
    },
    {
      dataField: 'filename',
      text: 'Datne',
      width: 200,
      formatter: (cellContent, row) => {
        return <div className="attach-filename">{getFileHref({file_id: row.id, file_sha256: row.sha_256, filename: cellContent})}</div>
      },
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'content_type',
      text: 'Datnes veids',
      width: 100,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'upload_time',
      text: 'Ievietots',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 130
    }
  ]};

class PielikumuSaraksts extends Component {

  render() {

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif,
        metadata:  this.props.metadata
      })
    }

    const {loading, list, count} = this.props.pielikumi

    return (
      <div className="saraksts-wrapper">
        <Grid
          loading={loading}
          dataList={list}
          totalCount={count}
          columns={this.columns}
        >
        </Grid>
      </div>
    )
  }

}

const mapStateToProps = ({pielikumi, kodif, metadata}) => ({pielikumi, kodif, metadata})

export default connect(mapStateToProps)(PielikumuSaraksts)
