import {
  callApi,
  getDefaultEditActions,
} from './helpers'

export const loadNobides = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'uo_nobides_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const nobidesSelected = (id) => {
  return {
    type: 'nobides_selected',
    payload: id
  }
}

const afterSaveOrDelete = (dispatch, getState) => {
  dispatch(loadNobides(getState().location.query))
}

export const nobidesActions = getDefaultEditActions({
  actionTypeName: 'nobides',
  stateName: 'nobides',
  combinedPage: true,
  apiViewName: 'uo_nobides_edit',
  afterDelete: afterSaveOrDelete,
  afterSave: afterSaveOrDelete,
})
