import React, {Component} from 'react';
import Form from '../forms/Form'
import { Button } from 'reactstrap';
import {connect} from 'react-redux'
import {adIerakstsActions, downloadIeraksts, adReimportDataActions} from '../../actions/adIerakstiActions'
import { getKodifRenderer } from "../forms/FormHelpers";
import FileSaver from 'file-saver';
import {dateFormatter, dateTimeFormatter} from '../grid/ColumnHelpers';

const getFormFieldCfgs  = ({ onClickDownload, kodif }) => {
    const fields = [
      {
        name: 'servisa_nosaukums',
        label: 'Servisa nosaukums',
        type: 'text',
      }, {
        name: 'konektors',
        label: 'Konektors',
        renderer: getKodifRenderer(kodif.adServisaKonektors)
      }, {
        name: 'faila_nosaukums',
        label: 'Faila nosaukums',
        type: 'text'
      }, {
        name: 'tips',
        label: 'Tips',
        renderer: getKodifRenderer(kodif.adServisaTips)
      }, {
        name: 'data_source',
        label: 'Datu avots',
        hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'sql',
        renderer: getKodifRenderer(kodif.adDataSources),
      }, {
        name: 'statuss',
        label: 'Statuss',
        renderer: getKodifRenderer(kodif.adIerakstaStatuss)
      }, {
        name: 'kļūdas_teksts',
        label: 'Kļūdas teksts',
        hiddenFunc: ({ currentValues}) => currentValues.statuss !== 'kļūda',
        renderer: (fieldValue) => { 
          const formattedText = fieldValue.replace(/\\n/g, '\n').replace(/\\t/g, '\t')
          return (
          <span
            style={{
              display: 'inline-block',
              whiteSpace: 'pre-wrap',
              maxHeight: '100px',
              overflowY: 'auto',
            }}
          >
            {formattedText}
          </span>)}
      }, {
        name: 'izveides_laiks',
        label: 'Izveides laiks',
        renderer: dateTimeFormatter
      }, {
        name: 'perioda_sākuma_datums',
        label: 'Sākuma datums',
        renderer: dateFormatter
      }, {
        name: 'perioda_beigu_datums',
        label: 'Beigu datums',
        renderer: dateFormatter
      }, {
        name: 'uid',
        label: 'UID'
      }, {
        name: 'stacijas',
        label: 'Stacijas',
        sizeForm: 20,
        hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss',
        multiFieldCfgs: [ {
          name: 'tips',
          label: 'Tips'
        }, {
          name: 'grupa',
          label: 'Grupa'
        }, {
          name: 'nosaukums',
          label: 'Nosaukums'
        }, {
          name: 'augstums_vjl',
          label: 'Augstums virs jūras līmeņa'
        }, {
          name: 'ir_aktīvs',
          label: 'Ir aktīva',
          type: 'checkbox'
        }]
      }, {
        name: 'atmosferas_novērojumi',
        label: 'Atmosfēras novērojumi',
        sizeForm: 20,
        hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss',
        multiFieldCfgs: [ {
          name: 'nosaukums',
          label: 'Nosaukums'
        }, {
          name: 'kods',
          label: 'Kods'
        }, {
          name: 'mērvienība',
          label: 'Mērvienība'
        }, {
          name: 'ir_aktīvs',
          label: 'Ir aktīvs',
          type: 'checkbox'
        }]
      }, {
        name: 'nokrisnu_novērojumi',
        label: 'Nokrišņu novērojumi',
        sizeForm: 20,
        hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss',
        multiFieldCfgs: [ {
          name: 'nosaukums',
          label: 'Nosaukums'
        }, {
          name: 'kods',
          label: 'Kods'
        }, {
          name: 'mērvienība',
          label: 'Mērvienība'
        }, {
          name: 'ir_aktīvs',
          label: 'Ir aktīvs',
          type: 'checkbox'
        }]
      }, {
        name: 'konfigurācijas_apraksts',
        label: 'Servisa konfigurācijas apraksts',
        type: 'textarea',
        renderer: (fieldValue) => (<span className="display-linebreak">{fieldValue}</span>)
      },
  ]
  return fields
}


class AdIeraksts extends Component {

  onClickDownload = async (rowId) => {
    try {
      await this.props.downloadIeraksts(Number(rowId));
  
      const { dati, faila_nosaukums } = this.props.ad_ieraksts_dati.selectedItem;
  
      const isJson = faila_nosaukums.endsWith('.json');
      const mimeType = isJson ? 'application/json' : 'text/csv';
  
      const blob = new Blob([dati], { type: mimeType });
      FileSaver.saveAs(blob, faila_nosaukums);
    } catch (error) {
      console.error('Kļūda lejupielādējot datus:', error);
    }
  };

  render() {
    const { kodif, user, ad_ieraksts } = this.props;
    const tiesibasLabot = user.tiesibas.includes('AD_GAISS_LABOT')
    if (!this.formFieldCfgs) {
      this.formFieldCfgs = getFormFieldCfgs({
        kodif, user
      });
    }
    
    const selectedItem = ad_ieraksts.selectedItem;

    return (
        <div className="form-wrapper-pane">
          <Form
            editable={false}
            dataObj={selectedItem}
            formFieldCfgs={this.formFieldCfgs}
            extraElements={
              <>
                {!selectedItem?.var_veikt_manuālu_ielādi && 
                  <div className="alert alert-warning">
                    Notiek ieraksta ģenerēšana.
                  </div>
                }
                {ad_ieraksts?.responseSuccess === true && 
                  <div className="alert alert-success">
                    Ieraksts veiksmīgi ģenerēts.
                  </div>
                }
                {(ad_ieraksts?.responseSuccess === false) && 
                  <div className="alert alert-danger">
                    Kļūda ģenerējot ierakstu.
                  </div>
                }
                {tiesibasLabot && 
                  <Button disabled={!selectedItem?.var_veikt_manuālu_ielādi} className="float-left" color="primary" onClick={() => this.props.reimportIerakstsData({id : selectedItem.id})}>
                    Ģenerēt ierakstu
                  </Button>
                }
                <Button color='secondary' onClick={() => this.onClickDownload(selectedItem.id)}> 
                <i className="fa fa-download"></i>&nbsp;&nbsp;Lejupielādēt datus
                </Button>
              </>
            }
          />
        </div>
    );
  }
}


const mapStateToProps = ({ad_ieraksts, ad_ieraksts_dati, kodif, user}) => ({ad_ieraksts, ad_ieraksts_dati, kodif, user})

export default connect(mapStateToProps, {...adIerakstsActions, downloadIeraksts, reimportIerakstsData: adReimportDataActions.saveAct})(AdIeraksts)
