import * as helpers from './helpers'

const defaultListReducerForVirszemesRiskiUnIznemumi = helpers.createListReducer({
    listViewName: 'virszemes_udens_objekts_riski_list',
    actionsName: 'virszemesRiskiUnIznemumi',
})
export const virszemesRiskiUnIznemumiList = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForVirszemesRiskiUnIznemumi({state, type, payload, reqId})
}

const defaultListReducerForPazemesRiskiUnIznemumi = helpers.createListReducer({
    listViewName: 'pazemes_udens_objekts_riski_list',
    actionsName: 'pazemesRiskiUnIznemumi',
})
export const pazemesRiskiUnIznemumiList = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForPazemesRiskiUnIznemumi({state, type, payload, reqId})
}

const defaultEditReducerForVirszemesRisksUnIznemumsIeraksts = helpers.createEditReducer({
    editViewName: 'virszemes_udens_objekts_riski_view',
    actionsName: 'virszemesRisksUnIznemums'
})
export const virszemesRisksUnIznemums = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultEditReducerForVirszemesRisksUnIznemumsIeraksts({state, type, payload})
}

const defaultEditReducerForPazemesRisksUnIznemumsIeraksts = helpers.createEditReducer({
    editViewName: 'pazemes_udens_objekts_riski_view',
    actionsName: 'pazemesRisksUnIznemums'
})
export const pazemesRisksUnIznemums = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultEditReducerForPazemesRisksUnIznemumsIeraksts({state, type, payload})
}