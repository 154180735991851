import * as helpers from './helpers'

const defaultListReducerForUpjuBaseinuApgabali = helpers.createListReducerWithMapClicking({
    listViewName: 'upju_baseinu_apgabali_list',
    actionsName: 'upju_baseinu_apgabals',
    mapIdName: 'upju_baseinu_apgabali_list',
    layerIdName: 'upjuBaseinuApgabals'
})
export const upjuBaseinuApgabali = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForUpjuBaseinuApgabali({state, type, payload, reqId, mapId, layerId})
}

const defaultListReducerForUpjuBaseinuApgabals = helpers.createEditReducer({editViewName: 'upju_baseinu_apgabali_list', actionsName: 'upju_baseinu_apgabals'})
export const upjuBaseinuApgabals = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultListReducerForUpjuBaseinuApgabals({state, type, payload})
}
