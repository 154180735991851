import React, {Component} from 'react';
import {connect} from 'react-redux'
import Form from '../forms/Form'
import { pazemesRiskiUnIznemumiActions } from '../../actions/riskiUnIznemumiActions';
import { getDefaultTypeaheadOptionsFromKodifArray, getKlasifikatorsFieldCfg } from '../forms/FormHelpers';
import { getFormatterForKodif } from '../grid/ColumnHelpers';

const getFormFieldCfgs = ({kodif}) => {
  return [{
        name: 'pazemes_udens_objekts_id',
        label: 'Ūdens objekts',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
            remoteSearchViewName: 'pazemes_udens_objekts_search',
            labelFieldName: 'uo_nosaukums'
        },
        renderer: (val, row) => (
            <span>{ row.uo_nosaukums }</span>
        )
    }, {
        name: 'gads',
        label: 'Riska izvērtējuma gads',
        type: 'number',
        renderClassName: 'bold',
        required: true,
    }, {
        name: 'periods',
        label: 'Periods',
        renderClassName: "bold",
        required: true,
        type: 'autocomplete',
        autocompleteCfg: {
            options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS')
        }
    }, {
        name: 'kvantitate',
        label: 'Risks (kvantitāte)',
        type: 'textarea'
    }, {
        name: 'kvalitate',
        label: 'Risks (kvalitāte)',
        type: 'textarea'
    }, {
        ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaIznemumi'),
        renderer: getFormatterForKodif(kodif.pazemesUdensObjektaIznemumi),
        name: 'iznemumi',
        label: 'Izņēmumi'
    }
  ]
};

class PazemesRisksUnIznemums extends Component {

  render() {
    const {pazemesRisksUnIznemums, kodif, saveAct, stopEditAct, startEditAct, deleteAct} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: kodif
      })
    }

    const {editable, submiting, selectedItem} = pazemesRisksUnIznemums

    return (
      <div className="form-wrapper-pane">
        <Form
          editable={editable}
          submiting={submiting}
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onSubmit={saveAct}
          onReset={stopEditAct}
          onStartEdit={startEditAct}
          onDelete={deleteAct}
          tiesibasLabot={'BAIS_PU_LABOT'}
        />
      </div>
    )
  }
}

const mapStateToProps = ({pazemesRisksUnIznemums, kodif}) => ({pazemesRisksUnIznemums, kodif})

export default connect(mapStateToProps, {...pazemesRiskiUnIznemumiActions})(PazemesRisksUnIznemums)
