import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bilanceSelected, bilanceChangeGrouped } from '../../actions/izmainaActions'
import IzmainaSarakstsBase from './IzmainaSarakstsBase'
import ManiObjektiButton from '../grid/ManiObjektiButton'
import { Label, Input, FormGroup } from 'reactstrap'

const skipColumns = ['ievades_statuss', 'pielikumi_filename']

class Bilance extends Component {

    render() {

        const { bilance, bilanceSelected, bilanceChangeGrouped } = this.props
        const { isGrouped } = bilance

        return (
            <IzmainaSarakstsBase
                mapId="bilance"
                karte_height_gui="bilance_karte_height"
                izmainas={bilance}
                izmainaSelected={bilanceSelected}
                skipColumns={skipColumns}
                exportViewname={isGrouped ? 'bilance_grouped' : 'bilance'}
            >
                <ManiObjektiButton allText="Rādīt visas" maniText="Rādīt tikai manās atradnēs" />
                <FormGroup check inline>
                    <Label check>
                        <Input type="checkbox" value={isGrouped} onChange={bilanceChangeGrouped} checked={isGrouped} /> apvienot izmaiņas gada ietvaros
                    </Label>
                </FormGroup>
            </IzmainaSarakstsBase>
        )
    }
}

const mapStateToProps = ({ bilance }) => {
    return { bilance }
}
export default connect(mapStateToProps, { bilanceSelected, bilanceChangeGrouped })(Bilance)
