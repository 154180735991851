import React, {Component} from 'react';

import { Typeahead } from "react-bootstrap-typeahead";

export default class MultiselectFilter extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataObjVal: props.defaultValue || []
    }
    if(props.getFilterFunc){
      props.getFilterFunc(this.setFilterValue)
    }
    this.options = props.options
    this.opts = Object.keys(this.options)
  }

  componentDidMount() {
    if(this.state.dataObjVal){
      this.props.onFilter(this.state.dataObjVal)
    }
  }

  // called from grid when clearing filters
  setFilterValue = (newVal) => {
    this.setState({
      dataObjVal: newVal
    })
    this.props.onFilter(newVal)
  }

  onSelect = (selected) => {
    this.setFilterValue(selected)
  }

  renderValue = (val) => {
    return this.options[val].nos
  }

  render() {
    const { dataObjVal } = this.state
    const { dataField } = this.props.column

    return <div onClick={e => e.stopPropagation()}>
      <Typeahead
        id={`typeahead-filter-${dataField}`}
        name={dataField}
        options={this.opts}
        onChange={this.onSelect}
        selected={dataObjVal}
        labelKey={this.renderValue}
        multiple
        clearButton={true}
        flip={true}
        // fixed position gets hidden in firefox and safari beacuse of sticky table header, so use absolute
        positionFixed={false}
        filterBy={() => true}
        maxHeight={'200px'}
      />
    </div>
  }

}
