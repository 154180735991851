import * as helpers from './helpers'

const defaultListReducer = (viewName, actionsName, args) => {
  return helpers.createListReducerWithMapClicking({
    listViewName: viewName,
    actionsName: actionsName,
    mapIdName: 'udens2',
    layerIdName: 'udens2',
  })
}

export const udensNv = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducer('udens2_nv_list', 'udensNv')({state, type, payload, reqId, mapId, layerId})
}

export const udensAi = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducer('udens2_ai_list', 'udensAi')({state, type, payload, reqId, mapId, layerId})
}

export const udensNov = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducer('udens2_nov_list', 'udensNov')({state, type, payload, reqId, mapId, layerId})
}

export const udensNovSpecific = (state = helpers.editGetInitialState(), {type, payload}) => {
  return helpers.createEditReducer({
    editViewName: 'udens2_nov_view',
    actionsName: 'udensNovSpecific'
  })({state, type, payload})
}

export const udensParskati = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
  return helpers.createListReducer({
    listViewName: 'udens2_parskati_list',
    actionsName: 'udensParskati'
  })({state, type, payload, reqId})
}

export const udensParskats = (state = helpers.editGetInitialState(), {type, payload}) => {
  return helpers.createEditReducer({
    editViewName: 'udens2_parskats_view',
    actionsName: 'udensParskats'
  })({state, type, payload})
}


const defaultListReducerForVkn = helpers.createListReducer({listViewName: 'paris_vkn_edit', actionsName: 'parisVkn'})
const defaultEditReducerForVkn = helpers.createEditReducer({editViewName: 'paris_vkn_edit', actionsName: 'parisVkn'})
export const parisVkn = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForVkn({state, type, payload, reqId})
  return defaultEditReducerForVkn({state: stateAfterList, type, payload, reqId})
}
