import React, { Component }  from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import {connect} from 'react-redux'
import TypeaheadField from '../forms/TypeaheadField'
import {onAtradneNextOrPrevInTopMenu, onAtradneSelectedInTopMenu} from '../../actions/atradnesActions'
import {openParskatsTopMenu, onParskatsNextOrPrevInTopMenu} from '../../actions/geofondActions'
import {getCurrentSupApp} from '../../actions/helpers'

class AtradneOrParskatsSearchField extends Component {

    render() {

        const {user, atradneTopMenu, onAtradneNextOrPrevInTopMenu, location,
            openParskatsTopMenu, onAtradneSelectedInTopMenu, className,
            onParskatsNextOrPrevInTopMenu, parskatsTopMenu} = this.props

        const autocompleteCfg = {remoteSearchViewName: 'atradne_search'}
        const extraFieldProps = {placeholder: 'Meklēt atradni',  minLength: 1}
        const canSearchAtradne = user && user.tiesibas.includes('SKATIT_ATRADNES')

        const isParskats =  (['loc_parskats', 'loc_parskats_from_list', 'loc_geofond', 'loc_rubrika']).includes(location.type) || location.type.startsWith('loc_geofond')
        const parskats_autocompleteCfg = {remoteSearchViewName: 'parskats_search'}
        const parskats_extraFieldProps = {placeholder: 'Meklēt pārskatu',  minLength: 1}
        const canSearchParskats = user && user.tiesibas.includes('PARSKATS_SKATIT')

        let next, prev;
        if(isParskats){
            next = parskatsTopMenu.next
            prev = parskatsTopMenu.prev
        } else {
            next = atradneTopMenu.next
            prev = atradneTopMenu.prev
        }

        const subApp = getCurrentSupApp(location)
        if(subApp && subApp !== 'zdz'){
            return null
        }

        return (
            <InputGroup className={className}>
            { prev ?
            <InputGroupAddon addonType="prepend">
            <InputGroupText title={prev.tah_label} className="pointer" onClick={() => isParskats ? onParskatsNextOrPrevInTopMenu(prev.tah_id) : onAtradneNextOrPrevInTopMenu(prev.tah_id)}>
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
            </InputGroupText>
            </InputGroupAddon>
            : null }
            {isParskats && canSearchParskats ?
            <TypeaheadField
                fieldName="parskatsSearch"
                autocompleteCfg={parskats_autocompleteCfg}
                extraFieldProps={parskats_extraFieldProps}
                setFieldValue={openParskatsTopMenu}
            />
            : null }
            {!isParskats && canSearchAtradne ?
            <TypeaheadField
                fieldName="atradneSearch"
                autocompleteCfg={autocompleteCfg}
                extraFieldProps={extraFieldProps}
                setFieldValue={onAtradneSelectedInTopMenu}
            />
            : null }
            { next ?
            <InputGroupAddon addonType="append">
            <InputGroupText title={next.tah_label} className="pointer" onClick={() => isParskats ? onParskatsNextOrPrevInTopMenu(next.tah_id) : onAtradneNextOrPrevInTopMenu(next.tah_id)}>
                <i className="fa fa-caret-right" aria-hidden="true"></i>
            </InputGroupText>
            </InputGroupAddon>
            : null }
        </InputGroup>
        )
    }
}

const mapStateToProps = ({user, atradneTopMenu, location, parskatsTopMenu}) => ({user, atradneTopMenu, location, parskatsTopMenu})

export default connect(mapStateToProps, {onAtradneNextOrPrevInTopMenu,
    onAtradneSelectedInTopMenu, openParskatsTopMenu, onParskatsNextOrPrevInTopMenu})( AtradneOrParskatsSearchField )
