import * as helpers from './helpers'

const defaultListReducerForUdensapgadesSistemas = helpers.createListReducer({listViewName: 'udensapgades_sistemas_edit', actionsName: 'udensapgadesSistema'})
const defaultEditReducerForUdensapgadesSistemas = helpers.createEditReducer({editViewName: 'udensapgades_sistemas_edit', actionsName: 'udensapgadesSistema'})

export const udensapgadesSistemas = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForUdensapgadesSistemas({state, type, payload, reqId})
  return defaultEditReducerForUdensapgadesSistemas({state: stateAfterList, type, payload, reqId})
}