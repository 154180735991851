import * as helpers from './helpers'

const defaultListReducerForParskati = helpers.createListReducer({
  listViewName: 'parskats_list',
  actionsName: 'parskati'
})

export const parskati = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
  return defaultListReducerForParskati({state, type, payload, reqId})
}

const defaultListReducerForParskati_karte = helpers.createListReducerWithMapClicking({
  listViewName: 'parskats_map',
  actionsName: 'parskati_karte',
  mapIdName: 'parskats_saraksts',
  layerIdName: 'parskati'
})

export const parskati_karte = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducerForParskati_karte({state, type, payload, reqId, mapId, layerId})
}

// const defaultEditReducerForParskats = helpers.createEditReducer({
//   editViewName: 'parskats_view',
//   actionsName: 'parskats'
// })

// export const parskats = (state = helpers.editGetInitialState(), {type, payload}) => {
//   return defaultEditReducerForParskats({state, type, payload})
// }


const defaultEditReducerForParskats = helpers.createEditReducerWithGeometryEditing({
  editViewName: 'parskats_view',
  actionsName: 'parskats',
  mapIdName: 'parskats',
  layerIdName: 'parskats_koord',
  geomType: 'MultiPoint'
})

export const parskats = (state = helpers.editWithGeometryInitialState(), {type, payload, mapId, layerId, featureGeom}) => {
  switch (type) {
    // clear atrasanas when atradne clears
    case 'parskats_clear':
        return helpers.editWithGeometryInitialState()
    default:
      return defaultEditReducerForParskats({state, type, payload, mapId, layerId, featureGeom})
  }
}

const defaultListReducerForRubrikas = helpers.createListReducerWithMapClicking({
  listViewName: 'rubrika_list',
  actionsName: 'rubrikas'
})
export const rubrikas = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
  return defaultListReducerForRubrikas({state, type, payload, reqId})
}

const defaultEditReducerForRubrika = helpers.createEditReducer({
  editViewName: 'rubrika_view',
  actionsName: 'rubrika'
})
export const rubrika = (state = helpers.editGetInitialState(), {type, payload}) => {
  return defaultEditReducerForRubrika({state, type, payload})
}

const defaultParskatsTopMenuReducer = helpers.createTopMenuSearchReducer({
  objectType: 'parskats',
  payloadLinkField: 'id'
})
export const parskatsTopMenu = (state = helpers.topMenuSearchInitialState(), {type, payload}) => {
  return defaultParskatsTopMenuReducer({state, type, payload})
}
