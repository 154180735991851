import * as helpers from './helpers'

const defaultListReducerForUrbumi = helpers.createListReducerWithMapClicking({
    listViewName: 'urbumi_list', 
    actionsName: 'urbumi',
    mapIdName: 'urbumi_saraksts',
    layerIdName: 'urbumi'
})
export const urbumi = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForUrbumi({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForUrbums = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'urbumi_view', 
    actionsName: 'urbums',
    mapIdName: 'urbums_kartina', 
    layerIdName: 'urbums_koord',
})
export const urbums = (state = helpers.editWithGeometryInitialState(), {type, payload, mapId, layerId, featureGeom}) => {
    return defaultEditReducerForUrbums({state, type, payload, mapId, layerId, featureGeom})
}

const defaultListReducerForMerijumi = helpers.createListReducer({
    listViewName: 'urbuma_merijums_view',
    actionsName: 'merijumi'
})

export const merijumi = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForMerijumi({state, type, payload, reqId})
}

const defaultListReducerForIeguvesLimeni = helpers.createListReducer({
    listViewName: 'urbuma_ieguves_limeni_view',
    actionsName: 'ieguves_limeni'
})

export const ieguves_limeni = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForIeguvesLimeni({state, type, payload, reqId})
}

const defaultListReducerForNoverotieLimeni = helpers.createListReducer({
    listViewName: 'urbuma_noverotie_limeni_view',
    actionsName: 'noverotie_limeni'
})

export const noverotie_limeni = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForNoverotieLimeni({state, type, payload, reqId})
}

const defaultEditReducerForMerijums = helpers.createEditReducer({editViewName: 'urbuma_merijums_edit', actionsName: 'merijums'})
export const merijums = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultEditReducerForMerijums({state, type, payload})
}

const defaultListReducerForParaugi = helpers.createListReducer({
    listViewName: 'urbuma_paraugi_list',
    actionsName: 'paraugi'
})

export const paraugi = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    return defaultListReducerForParaugi({state, type, payload, reqId})
}

const defaultEditReducerForUrbumaParaugs = helpers.createEditReducer({
    editViewName: 'urbuma_paraugs_edit', actionsName: 'urbuma_paraugs'})
export const urbuma_paraugs = (state = helpers.editGetInitialState(), {type, payload}) => {
    return defaultEditReducerForUrbumaParaugs({state, type, payload})
}

const defaultListReducerForUrbumuAtradnes = helpers.createListReducerWithMapClicking({
    listViewName: 'urbumi_atradnes_list', 
    actionsName: 'urbumi_atradnes',
    mapIdName: 'urbumu_atradnes_saraksts',
    layerIdName: 'atradnes'
})
export const urbumuAtradnes = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForUrbumuAtradnes({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForUrbumuAtradne = helpers.createEditReducer({editViewName: 'urbumi_atradnes_view', actionsName: 'urbumu_atradne'})
export const urbumu_atradne = (stateFirst = helpers.editGetInitialState(), {type, payload, mapId, layerId}) => {
    const state = defaultEditReducerForUrbumuAtradne({state:stateFirst, type, payload})
    switch (type) {
        case 'urbumi_atradnes_urbums_selected':
            const selectedUrbums = payload.isSelect ? payload.id : null
            return {
                ...state,
                selectedUrbums
            }
        case 'MAP_clickedOutsideFeature':
            if(mapId === 'urbumu_atradne_kartina'){
                return {
                    ...state,
                    selectedUrbums: null,
                }
            } else {
                return state
            }
        case 'MAP_clickedFeature':
            if(mapId === 'urbumu_atradne_kartina' && layerId === 'urbumi'){
                    return {
                        ...state,
                        selectedUrbums: payload.id,
                    }
            }
            return state
        default:  
            return state
    }
}

const defaultListReducerForUrbumuIeguves = helpers.createListReducerWithMapClicking({
    listViewName: 'urbumi_ieguves_vietas_list', 
    actionsName: 'urbumu_ieguves',
    mapIdName: 'urbumu_ieguves_saraksts',
    layerIdName: 'ieguves'
})
export const urbumuIeguves = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForUrbumuIeguves({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForUrbumuIeguve = helpers.createEditReducer({editViewName: 'urbumi_ieguves_vietas_view', actionsName: 'urbumu_ieguve'})
export const urbumu_ieguve = (stateFirst = helpers.editGetInitialState(), {type, payload, mapId, layerId}) => {
    const state = defaultEditReducerForUrbumuIeguve({state:stateFirst, type, payload})
    switch (type) {
        case 'urbumi_ieguves_vietas_view_get_success':
            return {
                ...state,
                selectedItem : {
                    ...state.selectedItem,
                    urbumi: state.selectedItem.urbumi_ieguves.reduce((urbumi, ieguve) => { 
                        const ind = urbumi.findIndex(urb => urb.id === ieguve.urb_id)
                        if(ind < 0) {
                            urbumi.push({
                                ...ieguve,
                                ori_id: ieguve.id,
                                id: ieguve.urb_id
                            })
                        }
                        return urbumi
                    }, [])
                }
            }
        case 'urbumi_ieguve_urbums_selected':
            const selectedIeguve = payload.isSelect ? payload.id : null
            let selectedUrbums = null
            if(selectedIeguve){
                const ieguve = state.selectedItem.urbumi_ieguves.find(ui => ui.id === selectedIeguve)
                if(ieguve){
                    selectedUrbums = ieguve.urb_id
                }
            }
            return {
                ...state,
                selectedUrbums,
                selectedIeguves : [selectedIeguve]
            }
        case 'MAP_clickedOutsideFeature':
            if(mapId === 'urbumu_ieguve_kartina'){
                return {
                    ...state,
                    selectedUrbums : null,
                    selectedIeguves : []
                }
            } else {
                return state
            }
        case 'MAP_clickedFeature':
            if(mapId === 'urbumu_ieguve_kartina' && layerId === 'urbumi'){
                    return {
                        ...state,
                        selectedUrbums: payload.id,
                        selectedIeguves: state.selectedItem.urbumi_ieguves.filter(ui => ui.urb_id === payload.id).map(ui => ui.id)
                    }
            }
            return state
        default:  
            return state
    }
}