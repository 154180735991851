import React from 'react';
import {connect} from 'react-redux'
import {UncontrolledAlert} from 'reactstrap';

const formatMsg = (message) => {
    if (!message)
        return null
        // Error('get errors message')
    if (message.message)
        return `${message.message}`
    return `${message}`
}

const Alert = ({color, message}) => (
    <UncontrolledAlert color={color}>
        {formatMsg(message)}
    </UncontrolledAlert>
)

let reshowCnt = 0

const Alerts = ({errors, flash}) => {

    const errAlerts = Object
        .keys(errors)
        .filter(k => errors[k])
        .map((k) => {
            const key = errors[k].reshow ? k + (reshowCnt++) : k
            return <Alert key={key} message={errors[k]} color="danger"/>
        })

    const {message} = flash

    const flashAlert = message
        ? <Alert message={message} color="primary"/>
        : null

    if (errAlerts.length === 0 && !flashAlert) {
        return null;
    }

    return (
        <div className="alert-area-out">
            <div className="alert-area-in">
                {errAlerts}
                {flashAlert}
            </div>
        </div>

    )
}

const mapStateToProps = ({errors, flash}) => ({errors, flash})
export default connect(mapStateToProps)(Alerts)