import React, { Component } from 'react'
import { connect } from 'react-redux'
import { izmainaSelected, izmainaChecked, izmainaCheckedAll, changeIevadesStatus } from '../../actions/izmainaActions'
import IzmainaSarakstsBase from './IzmainaSarakstsBase'
import {
    getCheckColumn,
    CheckColumnHeader
} from '../grid/ColumnHelpers'
import IevadesStatusFilter from './IevadesStatusFilter'
import { UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Badge } from 'reactstrap'

const IzmainaCheckColumnHeader = connect(({ izmainas }) => ({ gridData: izmainas }), { onCheckAll: izmainaCheckedAll })(CheckColumnHeader)

const getExtraColumns = ({ onCheck }) => [
    getCheckColumn({
        onCheck,
        CheckColumnHeaderComponent: IzmainaCheckColumnHeader
    })
]

const skipColumnsWithPerm = []
const skipColumns = ['pielikumi_filename']

class IzmainaSaraksts extends Component {

    onCheck = (id) => (e) => {
        e.stopPropagation()
        this.props.izmainaChecked(id)
    }

    changeIevadesStatus = (toStatus, ids) => () => {
        this.props.changeIevadesStatus(toStatus, ids)
    }

    render() {

        const { izmainas, izmainaSelected, kodif, user } = this.props

        const editRole = user && user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS')

        if (!this.extraColumns) {
            this.extraColumns = editRole ? getExtraColumns({
                onCheck: this.onCheck
            }) : []
        }

        const { list } = izmainas
        let atzimetas = 0
        if (list && list.length > 0) {
            atzimetas = list.filter(izm => izm.checked).length
        }

        return (
            <IzmainaSarakstsBase
                mapId="izmainu_saraksts"
                karte_height_gui="izmainu_saraksts_karte_height"
                izmainas={izmainas}
                izmainaSelected={izmainaSelected}
                extraColumns={this.extraColumns}
                skipColumns={user && user.tiesibas.includes('SKATIT_ATRADNES_IZMAINU_PIELIKUMUS') ? skipColumnsWithPerm : skipColumns}
            >
                {editRole ?
                    <UncontrolledButtonDropdown className="button-on-left">
                        <DropdownToggle color={atzimetas > 0 ? 'primary' : 'secondary'} caret>
                            Tabulā atzīmēta{atzimetas === 1 ? '' : 's'} {atzimetas} izmaiņa{atzimetas === 1 ? '' : 's'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                Object.keys(kodif.ievadesStatuss).map(k => {
                                    const is = kodif.ievadesStatuss[k]
                                    if (!user.tiesibas.includes(is.loma)) {
                                        return null
                                    }
                                    let izmForEditCnt = 0
                                    let izmForEditIds = []
                                    if (list && list.length > 0) {
                                        izmForEditIds = list.filter(izm =>
                                            izm.checked &&
                                            izm.ievades_statuss !== k
                                            && (!izm.ievades_statuss || user.tiesibas.includes(kodif.ievadesStatuss[izm.ievades_statuss].loma))).map(izm => izm.id)
                                        izmForEditCnt = izmForEditIds.length
                                    }
                                    return <DropdownItem key={k} disabled={izmForEditCnt === 0} onClick={this.changeIevadesStatus(k, izmForEditIds)}>
                                        Mainīt {izmForEditCnt} izmaiņ{izmForEditCnt === 1 ? 'u' : 'as'} uz
                                        <Badge className="inline-badge" color={is.badgeColor}>{is.nos}</Badge>
                                    </DropdownItem>
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    : null}
                <IevadesStatusFilter />

            </IzmainaSarakstsBase>
        )
    }
}

const mapStateToProps = ({ izmainas, kodif, user }) => {
    return { izmainas, kodif, user }
}
export default connect(mapStateToProps, { izmainaSelected, izmainaChecked, changeIevadesStatus })(IzmainaSaraksts)
