import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import { raditajsEkologijaSelected, raditajiEkologijaActions } from '../../actions/raditajiActions'
import {
  getTextFilterCfg,
} from '../grid/ColumnHelpers'
import {
  getDefaultRendererForMultiField, getKlasifikatorsFieldCfg,
  getMultiKlasifikatorsFieldCfg, getKodifRenderer
} from '../forms/FormHelpers'
import {addUrlQuery} from '../../actions/uiActions'

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'apzimejums',
      text: 'Apzīmējums',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums_aguns',
      text: 'Nosaukums AGŪNS',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'uba',
      text: 'Upju baseinu apgabali',
      filterCfg: getTextFilterCfg(),
      formatter: (value) => {
        return value.map(x => { return x.tah_label }).join('\n')
      },
      classes: 'display-linebreak'
    }, {
      dataField: 'tipi',
      text: 'Ūdens objektu tipi',
      filterCfg: getTextFilterCfg(),
      formatter: (value) => {
        return value.map(x => { return x.klasifikators }).join('\n')
      },
      classes: 'display-linebreak'
    }
  ]};

const  getFormFieldCfgs = ({kodif}) => {

  const getAutocompleteField = (name, values) => {
    return {
      ...getKlasifikatorsFieldCfg(kodif, 'salidzZimes'),
      name: name,
      label: undefined,
      // autocompleteCfg: {
      //   options: values.map((v) => {
      //     const sz = kodif.salidzZimes[v]
      //     return {
      //       tah_id: v,
      //       tah_label: sz.nos
      //     }
      //   })
      // },
      renderer: getKodifRenderer(kodif.salidzZimes),
      hiddenFunc: ({ dataObj }) => {
        return dataObj.inEditMode
      }
    }
  }

  return [
    {
      name: 'apzimejums',
      type: 'text',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      name: 'nosaukums',
      type: 'text',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      name: 'nosaukums_aguns',
      type: 'text',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      name: 'mervieniba',
      type: 'text',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      name: 'precizitate',
      type: 'number',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      name: "uba",
      type: 'autocomplete',
      autocompleteCfg: {
        multiple: true,
        keyField: 'upju_baseinu_apgabals_id',
        remoteSearchViewName: 'upju_baseinu_apgabals_search'
      },
      renderer: getDefaultRendererForMultiField('tah_label'),
    },
    {
      ...getMultiKlasifikatorsFieldCfg(kodif, 'UO_TIPS'),
      name: "tipi",
    },
    {
      name: 'vai_manuals',
    },
    {
      name: 'ir_arhivets',
      type: 'checkbox',
    },
    // {
    //   name: 'piezimes',
    //   type: 'textarea',
    //   renderClassName: "italic",
    // },
    {
      name: 'limiti',
      multiFieldCfgs: [
        {
          ...getKlasifikatorsFieldCfg(kodif, 'uoKvalitate'),
          name: 'kvalitate',
          renderer: getKodifRenderer(kodif.uoKvalitate)
        },
        {
          ...getAutocompleteField('vertiba_no_zime', ['gt', 'gte'])
        },
        {
          name: 'vertiba_no',
          type: 'number',
        },
        {
          ...getAutocompleteField('vertiba_lidz_zime', ['lt', 'lte'])
        },
        {
          name: 'vertiba_lidz',
          type: 'number',
        },
      ],
      extraFieldProps: {
        // canAdd: false, // TODO - import initial data
        // canEdit: false,
        canRemove: false,
      },
      hiddenFunc: (a, b) => {
        return false
      }
    }
  ]};

class RaditajiEkologija extends Component {

  getCurrentQueryGrupas = () => {
    const {query} = this.props
    const ef = query && query.ef ? JSON.parse(query.ef) : {}
    return ef.grupas ? ef.grupas.split(',') : []
  }

  extraGridProps = {
    rowClasses: (row, rowIndex) => {
      if(row && row.ir_arhivets === true){
        return 'row-archived';
      }
    }
  }

  render() {
    const raditajiEkologija = this.props.raditajiEkologija
    if(!raditajiEkologija || raditajiEkologija.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={this.props.raditajiEkologija}
        formWidthGuiOption="raditaji_ekologija_form_width"
        metadataFormCfg="raditajs_ekologija_edit"
        extraGridProps={this.extraGridProps}
        tiesibasLabot="BAIS_VU_KVAL_LABOT"
      />
    )

  }

}

const mapStateToProps = ({raditajiEkologija, kodif, location}) => ({raditajiEkologija, kodif, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : raditajsEkologijaSelected,
  addUrlQuery,
  ...raditajiEkologijaActions
})(RaditajiEkologija)