import React, {Component} from 'react';
import {connect} from 'react-redux'
import {parskatsActions, koordChangeAct, pieklutPielikumiemAction} from '../../actions/geofondActions'
import Form from '../forms/Form'
import {
  getDefaultTypeaheadOptionsFromKodifArray,
  getDefaultRendererForMultiField,
} from '../forms/FormHelpers'
import {getBooleanSelectFilterCfg, getBooleanFormatter, IDRenderer} from '../grid/ColumnHelpers'
import {getAradnesLinksRenderer, Saite} from '../dokumentacija/common'
import {getYear} from 'date-fns'

import SplitterLayout from 'react-splitter-layout';
import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'
import {guiSet, showErrorMsg} from '../../actions/uiActions'
import KoordTable, {ShowKoordTableBtn} from '../karte/KoordTable'
import {FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, UncontrolledTooltip} from "reactstrap"
import {InputField} from '../forms/Form'

const getSaiteField = (name, label, addButtonText) => (
  {
    name,
    label,
    multiField: true,
    extraFieldProps: {
      addButtonText
    },
    customField: (props) => {
      const {fieldName, currentVal} = props
      return <>
        <FormGroup check inline>
          <InputField
            {...props}
            fieldName={`${fieldName}.nosaukums`}
            currentVal={currentVal.nosaukums}
            extraFieldProps={{placeholder: 'Saites nosaukums'}}
          />
          <InputField
            {...props}
            fieldName={`${fieldName}.saite`}
            currentVal={currentVal.saite}
            extraFieldProps={{placeholder: 'Saites adrese'}}
          />
        </FormGroup>
      </>
    },
    hiddenFunc: ({ currentValues }) => (!currentValues?.ir_piekluve_parskatiem && currentValues?.id != null),
    renderer:  (fieldValue) => fieldValue.map(saite =>
      <p key={saite.id} className="links">
          <Saite saite={saite.saite} nosaukums={saite.nosaukums} />
      </p>)
  }
)

const getFormFieldCfgs = ({kodif, user, togglePielikumiModal}) => {
    const irLietotajsAutentificets = user.avots !== 'G'
    const makeLnbPielikumiMultifield = (fieldName, label) => {
      const multiFields = [{
        name: 'lnb_id',
        label: 'LNB ID',
        cellClassName: 'narrow',
        renderer: IDRenderer,
        editable: false,
        fieldEditable: false
      }, {
        name: 'name',
        cellClassName: 'narrow',
        label: 'Fails',
        renderer: (fieldValue, dataObj) => {
          if (user.tiesibas.includes(dataObj.access_right) && irLietotajsAutentificets) {
            return <a key={dataObj.lnb_id} title={fieldValue}
                      href={'/services/download/lnb/' + fieldValue + '/' + dataObj.lnb_id}
                      target={"_blank"} rel="noreferrer">
              {fieldValue}
            </a>
          } else {
            return fieldValue
          }},
        editable: false,
        fieldEditable: false
      }, {
        name: 'file_size',
        cellClassName: 'narrow',
        label: 'Izmērs',
        editable: false,
        fieldEditable: false
      }]

      if (user.tiesibas.includes('PARSKATS_LABOT')) {
        multiFields.push({
          name: 'access_right',
          cellClassName: 'narrow',
          label: 'Tiesības',
          type: 'autocomplete',
          required: true,
          autocompleteCfg: {
            options: [
              {tah_id: 'PARSKATS_SKATIT', tah_label: 'PARSKATS_SKATIT'},
              {tah_id: 'PARSKATS_SKATIT_IEKS_PIEL', tah_label: 'PARSKATS_SKATIT_IEKS_PIEL'},
              {tah_id: 'PARSKATS_SKATIT_IEROB', tah_label: 'PARSKATS_SKATIT_IEROB'}],
            extraFilterParams: {}
          },
          editable: true
        })
      }

      return {
        name: fieldName,
        label: label,
        multiFieldCfgs: multiFields,
        extraFieldProps: {
          canRemove: user.tiesibas.includes('PARSKATS_DZEST'),
          canEdit: false,
          canRemoveRow: (row) => user.tiesibas.includes(row.access_right),
          canAdd: false
        },
        formRowProps: {className: 'multifield-form-row'},
        hiddenFunc: ({ currentValues }) => (!currentValues?.ir_piekluve_parskatiem && currentValues?.id != null)
      }
    }

    const fields = [
      {
        name: 'inv_numurs',
        label: 'Inventāra numurs',
        type: 'text',
        required: true,
        renderClassName: "bold",
        validation: (values) => {
          const regex = /^\d+$/;
          if (values.inv_numurs != null && values.inv_numurs !== ''){
            if (!regex.test(values.inv_numurs)){
              return 'Inv. numurs drīkst saturēt tikai ciparus'
            }
          }
          return null;
        },
      },
      {
        name: 'lnb_id',
        label: 'LNB identifikators',
        editable: false,
        renderClassName: "bold"
      },
      {
        name: 'virsraksts',
        label: 'Virsraksts',
        type: 'text',
        required: true
      },
      {
        name: 'virsraksts_ru',
        label: 'Virsraksts oriģinālvalodā',
        type: 'text',
      },
      {
        name: 'autori_edit',
        label: 'Autors',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'PAUTORS'),
          multiple: true,
          keyField: 'autors',
        },
        renderer: getDefaultRendererForMultiField('autors'),
      },
      {
        name: 'rubrikas_edit',
        label: 'Tematiskie rādītāji',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
          remoteSearchViewName: 'rubrikas_search',
          multiple: true,
          keyField: 'rubrika',
        },
        renderer: getDefaultRendererForMultiField('tah_label'),
        // overridePropsFunc: (props) => {
        //   const changedProps = {...props}
        //   return changedProps
        // }
      },
      {
        name: 'teritorijas_edit',
        label: 'Teritorija',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'PTERITORIJA'),
          multiple: true,
          keyField: 'teritorija',
        },
        renderer: getDefaultRendererForMultiField('teritorija'),
      },
      // {
      //   name: 'ter_iedalijums',
      //   label: 'Admin. piesaiste',
      //   type: 'text',
      //   editable: false,
      // },
      {
        name: 'adm_teritorijas',
        label: 'Adm. teritorija piesaistītajam objektam',
        type: 'text',
        editable: false,
      },
      {
        name: 'pamatvardi',
        label: 'Pamatvārdi',
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'PPAMATVARDS'),
          multiple: true,
          keyField: 'pamatvards',
        },
        renderer: getDefaultRendererForMultiField('pamatvards'),
        // overridePropsFunc: (props) => {
        //   const changedProps = {...props}
        //   return changedProps
        // }
      },
      {
        name: 'valoda',
        label: 'Valoda',
        type: 'text',
        required: true,
      },
      {
        name: 'gads',
        label: 'Gads',
        type: 'number',
        required: true,
        validation: (values) => {
          if(values.gads != null && values.gads !== ''){
            if(values.gads < 1900){
              return 'Ievadītais gads ir pārāk mazs!';
            }
            if(values.gads > getYear(new Date())){
              return 'Ievadītais gads ir lielāks par esošo!';
            }
          }
          return null
        },
      },
      {
        name: 'organizacijas_edit',
        label: 'Organizācija',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'PORGANIZACIJA'),
          multiple: true,
          keyField: 'organizacija',
        },
        renderer: getDefaultRendererForMultiField('organizacija'),
        // formatter: getBadgeFormatterForKodif(),
        // autocompleteCfg: {
        //   options: getDefaultTypeaheadOptionsFromKodif(kodif.PORGANIZACIJA)
        // }
      },
      {
        name: 'apjoms',
        label: 'Apjoms',
        type: 'text'
      },
      {
        name: 'ir_laukumateriali',
        label: 'Ir lauka materiāli',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg(),
      },
      {
        name: 'ir_serdes',
        label: 'Ir serdes',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg(),
      },
      {
        name: 'ir_planslipejumi',
        label: 'Ir plānslīpējumi',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg(),
      },
      {
        name: 'ir_paraugi',
        label: 'Ir paraugi',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg(),
      },
      {
        name: 'ierob_pieej',
        label: 'Ierobežotā pieejamība',
        type: 'checkbox',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg(),
      },
      {
        name: 'atradnes',
        label: 'Saistītie objekti',
        type: 'autocomplete',
        editable: false,
        autocompleteCfg: {
            remoteSearchViewName: 'atradne_search',
            multiple: true,
            keyField: 'numurs',
        },
        renderer: getAradnesLinksRenderer(kodif, 'numurs', null, 'inline-links'),
        // overridePropsFunc: (props) => {
        //   const changedProps = {...props}
        //   const {editable} = props
        //   if(editable){
        //     changedProps.extraFieldProps = { disabled: true }
        //   }
        // }
      },
      {
        name: 'pieklutPielikumiem',
        label: '',
        renderer: () => {
          return (
            <>
              <Button
                disabled={!irLietotajsAutentificets}
                id="btnPiekļutPielikumiem"
                onClick={() => togglePielikumiModal(true)}
              >
                Piekļūt pielikumiem
              </Button>
              {!irLietotajsAutentificets && 
                <UncontrolledTooltip 
                  target="btnPiekļutPielikumiem" 
                  placement="right">
                  Pielikumi ir pieejami tikai reģistrētiem lietotājiem. 
                  Lai skatītu pielikumus, lūdzu autorizēties LVĢMC tīmekļa vietnē.
                </UncontrolledTooltip>
              }
            </>
          );
        },
        hiddenFunc: ({ currentValues }) => currentValues?.ir_piekluve_parskatiem
      },
      getSaiteField('pub_saites', 'Publiskie pielikumi (saites)', 'Pievienot publisku saiti')
  ]
  if(user.tiesibas.includes('PARSKATS_SKATIT_IEKS_PIEL')){
    fields.push(getSaiteField('ieks_saites', 'Iekšējie pielikumi (saites)', 'Pievienot iekšējo saiti'))
  }
  if(user.tiesibas.includes('PARSKATS_SKATIT_IEROB')){
    fields.push(getSaiteField('ierob_saites', 'Pielikumi ar ierobežotu pieejamību (saites)', 'Pievienot saiti ar ierobežotu pieejamību'))
  }
  if(user.tiesibas.includes('PARSKATS_SKATIT')){
    fields.push(
        makeLnbPielikumiMultifield('faili_pub', 'Publiskie pielikumi (datnes)'),
        makeLnbPielikumiMultifield('faili_ieks', 'Iekšējie pielikumi (datnes)'),
        makeLnbPielikumiMultifield('faili_ierob', 'Pielikumi ar ierobežotu pieejamību (datnes)')
    )
  }

  return fields
}

const getVectorLayerCfgs = ({parskats}) => {
  let cfg = {}
  if(parskats && parskats.koords){
    cfg = {...cfg,...parskatsCfg(parskats)}
  }

  return cfg;
}

const parskatsCfg = (parskats) => {
  const cfg = {}
  if(parskats.koordsFeat){
    cfg.parskats_feat = {
      title: 'Pārskata punkts',
      features: parskats.koordsFeat,
      styles: maputils.kartinasStyles.atradneBorderCurrent,
      selectable: false,
      legend: maputils.defaultSinglePointLegend
    }
  }
  cfg.parskats_koord = {
    title: `Pārskata punkta koord.`,
    features: parskats.koords,
    styles: maputils.pointStyles,
    tooltipFunc: (feat) => `Nr ${feat.get('id')} (${feat.get('x')};${feat.get('y')})`,
    selected: parskats.selectedKoord,
    zIndex: 15,
    editable: parskats.editable,
    legend: maputils.defaultSinglePointLegend
  }
  if(parskats.selectedItem && parskats.selectedItem.atradnes && parskats.selectedItem.atradnes.length > 0){
    cfg.parskats_atradnes = {
      title: 'Saistītās atradnes',
      features: parskats.selectedItem.atradnes,
      styles: maputils.kartinasStyles.atradneBorder,
      tooltipFunc: (feat) => `${feat.get('nosaukums')} (${feat.get('numurs')})`,
      zIndex: 15,
      zoomToLayer: true,
      legend: maputils.defaultSinglePointLegend
    }
  }
  return cfg
}

class Parskats extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showPielikumiModal: false,
      showExtraFields: false,
      readRules: false,
      mērķis: null,
      citsMērķisTeksts: ""
    }
  }

  togglePielikumiModal = (boolValue) => {
    this.setState({ 
      showPielikumiModal: boolValue,
      readRules: false,
      mērķis: null,
      citsMērķisTeksts: ""
    })
  }

  confirmModal = () => {
    const { parskats, pieklutPielikumiemAction } = this.props
    this.setState({
      showPielikumiModal: false,
      showExtraFields: true,
      readRules: false,
      mērķis: null,
      citsMērķisTeksts: ""
    })
    fetch(`/services/VGF_faila_piekļuve/${parskats.selectedItem.id}`, {
      method: 'PUT'
    })
    pieklutPielikumiemAction({
      id : parskats.selectedItem.id, 
      mērķis : this.state.mērķis === 'Cits' ? this.state.citsMērķisTeksts : this.state.mērķis, 
      pārskata_nr : parskats.selectedItem.inv_numurs })
  }

  handleUsageRuleChange = (e) => {
    this.setState({ readRules: e.target.checked })
  }

  handleGoalChange = (e) => {
    this.setState({ mērķis: e.target.value })
  }

  handleOtherGoalChange = (e) => {
    this.setState({ citsMērķisTeksts: e.target.value });
  }

  mapPaneSizeChange = (w) => {
    this.props.guiSet('parskats_karte_width', w);
  }

  mapPaneSizeChangeHor = (h) => {
    this.props.guiSet('parskats_karte_hor_height', h);
  }

    render() {
      const {
        parskats, kodif, mapId, getVectorLayerCfgsFunc,
        saveAct, stopEditAct, startEditAct, deleteAct,
        koordSelectAct, koordChangeAct, user} = this.props
      
        const {
          showPielikumiModal,
          readRules,
          mērķis,
          citsMērķisTeksts
        } = this.state
      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif, user,
            togglePielikumiModal: this.togglePielikumiModal,
        })
    }

    const {editable, submiting, selectedItem} = parskats

    //const vectorLayerCfgs = getVectorLayerCfgs(parskats);
    const f = getVectorLayerCfgsFunc ? getVectorLayerCfgsFunc : getVectorLayerCfgs
    const vectorLayerCfgs = f({parskats})

    const showCoordsBtn = <ShowKoordTableBtn/>

    const largeScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 900

    const linkToVGFRulesUrl = Object.values(kodif.VGF_DATNU_VEIDLAPA).find(it => it.nos === 'Saite uz noteikumiem').vertiba

    const modifiedSelectedItem = editable && user.tiesibas.includes("PARSKATS_LABOT")  ? {
      ...selectedItem,
      faili_pub: selectedItem.faili_pub?.map(row => ({ ...row, inEditMode: true })),
      faili_ieks: selectedItem.faili_ieks?.map(row => ({ ...row, inEditMode: true })),
      faili_ierob: selectedItem.faili_ierob?.map(row => ({ ...row, inEditMode: true })),
    } : selectedItem;

    return (
      <>
        <SplitterLayout
          key={largeScreen ? 'hor' : 'ver'}
          vertical={!largeScreen}
          customClassName="pane-paddings-hor"
          percentage
          primaryIndex={0}
          secondaryInitialSize={largeScreen ? this.props.gui.parskats_karte_width : this.props.gui.parskats_karte_hor_height}
          onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}>

           <div className={largeScreen ? 'leftside' : 'topside'} >
              <Form
                  editable={editable}
                  submiting={submiting}
                  dataObj={modifiedSelectedItem}
                  formFieldCfgs={this.formFieldCfgs}
                  onSubmit={saveAct}
                  onReset={stopEditAct}
                  onStartEdit={startEditAct}
                  onDelete={deleteAct}
                  extraElements={showCoordsBtn}
                  tiesibasLabot="PARSKATS_LABOT"
              />
              <KoordTable
                  editable={parskats.editable}
                  koords={parskats.koords}
                  selectedKoord={parskats.selectedKoord}
                  validation={parskats.koordsFeat && parskats.koordsFeat.validation}
                  koordSelectAct={koordSelectAct}
                  koordChangeAct={koordChangeAct}
                  showErrorMsg={showErrorMsg}
                  simpleColumns
              />
         </div>

          <div className="rightside">
            <div className="karte-wrapper">
              <IzraktenisMap mapId={mapId ? mapId : 'parskats'} vectorLayerCfgs={vectorLayerCfgs}/>
            </div>
          </div>
        </SplitterLayout>
        <Modal
          isOpen={showPielikumiModal}
          toggle={() => this.togglePielikumiModal(false)}
        >
          <ModalHeader toggle={() => this.togglePielikumiModal(false)}>
            Pielikumu forma
          </ModalHeader>
          <ModalBody>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={readRules}
                  onChange={this.handleUsageRuleChange}
                />
                <span className="ml-2">
                  Apliecinu, ka esmu iepazinies un piekrītu
                  Valsts ģeoloģijas fonda elektroniskā kataloga
                  izmantošanas noteikumiem, kas atrodas
                  {' '}
                  <a
                    href={linkToVGFRulesUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    šeit
                  </a>.
                </span>
              </Label>
            </FormGroup>

            <hr />
            <div className="mt-2">
              <b>Lūdzu norādīt Valsts ģeoloģijas fonda materiālu izmantošanas nolūku jeb mērķi (vienu):</b>
            </div>
            {Object.values(kodif.VGF_DATNU_VEIDLAPA)
              .filter(it => it.nos !== 'Saite uz noteikumiem')
              .sort((a,b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
              .map((item, idx) => {
              const isCits = (item.nos === "Cits");
              return (
                <FormGroup check key={idx} className="mb-2">
                  <Label check>
                    <Input
                      type="radio"
                      name="mērķis"
                      value={item.nos}
                      checked={mērķis === item.nos}
                      onChange={this.handleGoalChange}
                      className='mr-2'
                    />
                    {item.nos}
                  </Label>

                  {isCits && mērķis === "Cits" && (
                    <div className="mt-2 ml-4">
                      <Input
                        type="text"
                        name="citsMērķisTeksts"
                        value={citsMērķisTeksts}
                        onChange={this.handleOtherGoalChange}
                        placeholder="Ievadiet papildinformāciju"
                      />
                    </div>
                  )}
                </FormGroup>
              );
            })}
          </ModalBody>

          <ModalFooter>
            <Button
              color="primary"
              onClick={this.confirmModal}
              disabled={!this.state.readRules
                || !this.state.mērķis
                || (this.state.mērķis === "Cits" && this.state.citsMērķisTeksts.trim() === "")}
            >
              Saglabāt
            </Button>
            <Button
              color="secondary"
              onClick={() => this.togglePielikumiModal(false)}
            >
              Atcelt
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({gui, parskats, kodif, user, vvisKodifs}) => ({gui, parskats, kodif, user, vvisKodifs})

export default connect(mapStateToProps, {guiSet, showErrorMsg, ...parskatsActions, koordChangeAct, pieklutPielikumiemAction: pieklutPielikumiemAction.saveAct})(Parskats)
