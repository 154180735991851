import React, {Component} from 'react'
import {connect} from 'react-redux'
import {addUrlQuery} from '../../actions/uiActions'
import {Badge} from 'reactstrap'

class IevadesStatusFilter extends Component {

    toggleFilter = (status) => () => {
        const efObj = {...this.getQueryExtraFilter(), ievades_statuss: this.getCurrentIevadesStatuss() === status ? undefined : status}
        let ef = JSON.stringify(efObj)
        ef = ef === '{}' ? undefined : ef
        this.props.addUrlQuery({ef})
    }

    getQueryExtraFilter = () => {
        const {query} = this.props
        return query && query.ef ? JSON.parse(query.ef) : {}
    }

    getCurrentIevadesStatuss = () => this.getQueryExtraFilter().ievades_statuss

    render() {
        const {kodif} = this.props
        const currentStatus = this.getCurrentIevadesStatuss()
        return (
            <>
            { 
              Object.keys(kodif.ievadesStatuss).map(k => {
                  const is = kodif.ievadesStatuss[k]
                  return <Badge key={k} onClick={this.toggleFilter(k)} className="pointer badge-filter" color={currentStatus === k ? is.badgeColor : 'secondary'}>{is.nos}</Badge>
              }) 
            }
            </>
        )
    }
}

const mapStateToProps = ({location, kodif}) => {
    return {query: location.query, kodif}
}

export default connect(mapStateToProps, {addUrlQuery})(IevadesStatusFilter)
