import {
    getDefaultEditActions
} from './helpers'
import { setIzmainaReferences } from './atradnesActions'
import { createActArgsToCrudParams } from './dokumentacijaActions'
import {
    callApi,
    addExtraFilterToQuery,
    getDateCrudParams
} from './helpers'
import { redirect } from 'redux-first-router'
import { getSakumsLocation } from '../routes'

const updateZimeFromDaudzums = (izmaina) => {
    if (izmaina.daudzums != null) {
        izmaina.zime = izmaina.daudzums > 0 ? 'P' : 'S'
        izmaina.daudzums = Math.abs(izmaina.daudzums)
    } else {
        izmaina.zime = 'S'
    }
    return izmaina
}

const updateDaudzumsFromZime = (izmaina) => {
    if (izmaina.zime === 'S') {
        izmaina.daudzums = izmaina.daudzums * -1
    }
    return izmaina
}

export const izmainaActions = getDefaultEditActions({
    actionTypeName: 'izmaina',
    stateName: 'izmaina',
    apiViewName: 'izmaina_view',
    createActArgsToCrudParams,
    resultTransformator: (values) => {
        const izmaina = { ...values }
        izmaina.initialDaudzums = izmaina.daudzums != null ? izmaina.daudzums : 0.0
        izmaina.initialLimitaApjomsId = izmaina.limita_apjoms ? izmaina.limita_apjoms.id : null
        updateZimeFromDaudzums(izmaina)
        return izmaina
    },
    valuesTransformator: (values) => {
        const izmaina = { ...values }
        updateDaudzumsFromZime(izmaina)
        setIzmainaReferences(izmaina)
        return izmaina
    },
})

export const izmainaActionsGrouped = getDefaultEditActions({
    actionTypeName: 'izmaina_grouped',
    stateName: 'izmaina_grouped',
    apiViewName: 'izmaina_view_grouped',
    createActArgsToCrudParams,
    resultTransformator: (values) => {
        const izmaina = { ...values }
        izmaina.initialLimitaApjomsId = izmaina.limita_apjoms ? izmaina.limita_apjoms.id : null
        izmaina.izmainas = izmaina.izmainas.map(izm => {
            const izmAfterZime = updateZimeFromDaudzums({ ...izm })
            if (!izmaina.id && !izm.id) {
                izmAfterZime.inEditMode = true
            }
            return izmAfterZime
        })
        return izmaina
    },
    valuesTransformator: (values) => {
        const izmaina = { ...values }
        izmaina.izmainas = izmaina.izmainas.map(izm => updateDaudzumsFromZime({ ...izm }))
        setIzmainaReferences(izmaina)
        return izmaina
    },
    afterSaveFailure: (error, reqId, dispatch, getState) => {
        if (error.status === 404) {
            //redirect to atradne izmainas
            const numurs = getState().izmaina_grouped?.selectedItem?.atradne?.numurs
            if (numurs) {
                dispatch(redirect({
                    type: 'loc_atradne',
                    payload: {
                        numurs
                    },
                    query: { tab: 'izmainas' }
                }))
            } else {
                dispatch(redirect({
                    type: getSakumsLocation()
                }))
            }
        }
    }
})

export const loadIzmainas = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'izmainas_list',
        crudMethod: 'listPaging',
        crudParam: addExtraFilterToQuery(getDateCrudParams(query), getState().gui['izmainu_saraksts_karte_size'])
    }))
}

export const izmainaSelected = (id) => {
    return {
        type: 'izmainas_selected',
        payload: id
    }
}

export const loadBilance = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: getState().bilance?.isGrouped ? 'bilance_grouped' : 'bilance',
        crudMethod: 'listPaging',
        crudParam: addExtraFilterToQuery(getDateCrudParams(query), getState().gui['bilance_karte_size'])
    }))
}

export const bilanceSelected = (id) => {
    return {
        type: 'bilance_selected',
        payload: id
    }
}

export const bilanceChangeGrouped = () => (dispatch, getState) => {
    dispatch({ type: 'bilance_grouped' })
    return dispatch(loadBilance(getState().location.query))
}

export const izmainaChecked = (id) => ({
    type: 'izmainas_checked',
    payload: id
})

export const izmainaCheckedAll = () => ({
    type: 'izmainas_checked_all'
})

export const loadKomersantaIzmainas = (query) => (dispatch, getState) => {
    return dispatch(callApi({
        viewName: 'izmainas_list_komersants',
        crudMethod: 'listPaging',
        crudParam: addExtraFilterToQuery(getDateCrudParams(query), getState().gui['komersanta_izmainu_saraksts_karte_size'])
    }))
}

export const komersantaIzmainaSelected = (id) => {
    return {
        type: 'komersantaIzmainas_selected',
        payload: id
    }
}

export const changeIevadesStatus = (toStatus, ids) => (dispatch, getState) => {
    const success = () => {
        dispatch(loadIzmainas(getState().location.query));
    }
    return dispatch(callApi({
        viewName: 'izmainu_statusi_edit',
        crudMethod: 'save',
        crudParam: { ievades_statuss: toStatus, izmainas: ids.map(id => ({ id })) },
        success
    }))
}