import React, {Component} from 'react'
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {getColumns as getColumnsAudit, getExpandRowDef } from '../audit/Audits'
import Loading from '../Loading'

const getColumns = ({kodif, metadata}) => {
    const auditColumns = getColumnsAudit({kodif, metadata})
    const atrCol = auditColumns.find(c => c.dataField === 'atradnes')
    atrCol.filterCfg = undefined
    return auditColumns
}

class AtradnesVesture extends Component {

      render() {

        const {metadata} = this.props

        if (!metadata.loaded) {
            return (<Loading/>)
        }
        
        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif,
                metadata
            })
        }

        if(!this.expandRow){
            this.expandRow = getExpandRowDef({
                metadata
            })
        }

        const {loading, list, count} = this.props.atradnesVesture

        return (
            <div className="saraksts-wrapper">
                <Grid 
                    loading={loading}    
                    dataList={list}
                    totalCount={count}
                    columns={this.columns} 
                    expandRow={this.expandRow}
                />
            </div>
        )
    }
}

const mapStateToProps = ({atradnesVesture, kodif, metadata}) => ({atradnesVesture, kodif, metadata})
export default connect(mapStateToProps)(AtradnesVesture)

const mapStateToPropsDala = ({atradnesDalasVesture, kodif, metadata}) => ({atradnesVesture: atradnesDalasVesture, kodif, metadata})
export const AtradnesVestureDala =  connect(mapStateToPropsDala)(AtradnesVesture)
