import * as helpers from './helpers'

const defaultListReducerForAdServisi = helpers.createListReducer({listViewName: 'ad_serviss_list'})
export const ad_servisi = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForAdServisi({state, type, payload, reqId})
}

const defaultEditReducerForAdServiss = helpers.createEditReducer({editViewName: 'ad_serviss_edit', actionsName: 'ad_serviss'})
export const ad_serviss = (state = helpers.editGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultEditReducerForAdServiss({state, type, payload, reqId})
}

const defaultEditReducerForAdServisKonfiguracija = helpers.createEditReducer({editViewName: 'ad_serviss_konfiguracija_edit', actionsName: 'ad_serviss_konfiguracija'})
export const konfiguracija = (state = {...helpers.editGetInitialState(),}, { type, payload, reqId}) => {
    const result = defaultEditReducerForAdServisKonfiguracija({ state, type, payload, reqId })

    switch (type) {
      case 'ad_serviss_konfiguracija_edit_get_success':
      case 'ad_import_data_save_success':
      case 'ad_serviss_konfiguracija_edit_new_success':
      case 'ad_import_data_save_failure':
        return {
          ...result,
          editable: true,
          responseSuccess: null
        };
  
      case 'redirect':
      case 'ad_serviss_konfiguracija_aktivs_edit_save_success':
        return {
          ...result,
          editable: false,
        };
      case 'ad_import_stacijas_save_request':
        return {
          ...result, 
          loading: true
        }
      case 'ad_import_stacijas_save_success':
        return {
          ...result, 
          loading: false
        }
      case 'ad_import_data_response': {
        let responseSuccess = null
        if (payload.konfigurācija_id === result.selectedItem.id) {
          result.selectedItem.var_veikt_manuālu_ielādi = true
          result.selectedItem.ir_ieraksts = true
          responseSuccess = payload.success
        }
        return {
          ...result,
          loading: false,
          responseSuccess: responseSuccess,
        }
      }
      default:
        return result;
    }
  }
